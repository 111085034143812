

import React, { useState, useEffect, useMemo } from "react";
import ImageUploading from "react-images-uploading";
import styled from "styled-components";
import { Container, Col, Image } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { v4 as uuidv4 } from 'uuid';
import Loader from "react-js-loader";
import moment from "moment";
import AreaButton from "../../components/AreaButton";
import Button from "../../components/Button";
import Table from "../../components/Table";
import Input from "../../components/Input";
import PatientInformationsItem from "../../components/PatientInformationsItem";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import Select from "react-select";

import SessionHeader from "../../components/SessionHeader";
import Modal from "../../components/Modal";

import { toast } from 'react-toastify'

//api
import MyLaserApi from "../../api/MyLaserApi";

import S3Handler from "../../services/S3Handler";

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
    &.spaced-items {
        justify-content: space-between;
    }
`;


function PatientConsentAdd(props) {

    let [items, setItems] = useState([])
    let [reference, setReference] = useState(null)
    let [voucher, setVoucher] = useState(null)

    let history = useHistory();
    let params = useParams()
    let location = useLocation()
    
    const user = useSelector((state) => state.user) || {}
    const client = useSelector(state => state.client) || {}

    var alreadyCall = false

    useEffect(() => {
        console.log("user", user)
    }, [])

    let receivePostMessageFromIframe = (event) => {

        if (event.data === "end-quotation") {
            history.push(`/patients/${params.id}/quotations`)
        } else if (event.data && event.data.name && event.data.name === "end-quotation-with-id-accepted" && event.data.id) {

            if (!alreadyCall) {

                alreadyCall = true

                MyLaserApi.convertQuotationToSessions(params.id, event.data.id).then(() => {
                    toast.success("Le devis a été mis à jour et les séances ont été créées.")

                    MyLaserApi.upgradeQuotation(params.id, event.data.id).then(() => {
                        history.push(`/patients/${params.id}/quotations`)
                    })

                })

            }
            
        } else if (event.data && event.data.name && event.data.name === "end-quotation-with-id-refused" && event.data.id) {

            if (!alreadyCall) {

                alreadyCall = true

                MyLaserApi.deleteQuotation(params.id, event.data.id).then(() => {
                    toast.success("Le devis a été mis à jour et les séances ont été créées.")

                    MyLaserApi.upgradeQuotation(params.id, event.data.id).then(() => {
                        history.push(`/patients/${params.id}/quotations`)
                    })

                })

            }

        }

    }

    useEffect(() => {
        window.addEventListener("message", receivePostMessageFromIframe, false);

        var iitems = [];

        if (location.state && location.state.quotation) {

            setReference(location.state.quotation.reference);
            setVoucher(location.state.quotation.voucher);
        
            (JSON.parse(location.state.quotation.items)).map((e) => e.id.map((a) => iitems.push(a)))
            
            setItems(iitems)

        }

        return () => window.removeEventListener("message", receivePostMessageFromIframe, false);
        
    }, [])

    return (
        
        <Container fluid className="App">

            <Row>
            
                <Col md={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                    <Button onClick={() => {
                        history.push(`/patients/${params.id}/quotations`)
                    }}>Fermer la simulation de devis</Button>

                </Col>

            </Row>

            <Row>
            
                <Col md={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                   {/* <iframe src={`http://localhost:3001/gender?refresh=${Math.random()}&reference=${reference}&items=${items.join(',')}&gender=${client.sexe}${voucher ? "&voucher=" + voucher: ''}&client=${  btoa(  JSON.stringify({ */}

                    {/* <iframe src={`${window.quotationUrl}/gender?refresh=${Math.random()}&reference=${reference}&items=${items.join(',')}&gender=${client.sexe}${voucher ? "&voucher=" + voucher: ''}&client=${  encodeURIComponent(btoa(  JSON.stringify({
                       id: client.id,
                       agency_id: memoizedUser.agency_id
                   }))) }`} style={{height: "100vh", width: "100%"}}></iframe> */}

                   <IframeNoReload url={`${window.quotationUrl}/gender?reference=${reference}&items=${items.join(',')}&gender=${client.sexe}${voucher ? "&voucher=" + voucher: ''}&client=${  encodeURIComponent(btoa(  JSON.stringify({
                       id: client.id,
                       agency_id: user.agency_id
                   }))) }`} />

                </Col>

            </Row>

        </Container>
        
    );
}

const IframeNoReload = React.memo(({ url }) => {
    // Your iframe logic using only userId and userName
    return (
        <iframe src={url} style={{height: "100vh", width: "100%"}}></iframe>
    );
  }, (prevProps, nextProps) => {
    // Only re-render if userId or userName changes
    return prevProps.url === nextProps.url
  });

export default PatientConsentAdd;
