import "../App.css";
import styled from "styled-components";
import React, { useState, useEffect } from "react";
import Select from "react-select";

const Label = styled.span`
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 13px;
    margin-bottom: 10px;
`;

const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
    margin-right: 10px !important;
    width: 100%;
    &.small {
        width: 24%;
    }
    &.medium {
        width: 32%;
    }
    &.medius {
        width: 30%;
    }
    &.large {
        width: 100%;
    }
`;

function Picker({
    className,
    label,
    isSearchable = true,
    onChange,
    value,
    backgroundColor,
    options,
    marginRight,
    placeholder,
    width,
    isDisabled = false,
}) {
    return (
        <Col className={className}>
            {label && <Label>{label}</Label>}

            <Select
                isDisabled={isDisabled}
                placeholder={placeholder}
                isSearchable={isSearchable}
                options={options}
                onChange={onChange}
                value={value}
                styles={{
                    control: () => ({
                        backgroundColor: backgroundColor
                            ? backgroundColor
                            : "#F8F9FB",
                        // marginBottom: 20,
                        padding: 5,
                        paddingTop: 12,
                        paddingBottom: 12,
                        display: "flex",
                        borderRadius: 5,
                        width: width,
                        fontSize: 14,
                    }),
                }}
            />
        </Col>
    );
}

export default Picker;
