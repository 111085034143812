
import "../App.css";
import styled from "styled-components";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import Picker from './Picker'

const Label = styled.span`
font-family: CenturyGothicRegular;
color: #102935;
font-size: 13px;
margin-bottom: 10px;
`;

const Col = styled.div`
display: flex;
flex-direction: column;
align-items: center;
align-items: flex-start;
margin-right: 10px !important;
width: 100%;
&.small {
    width: 24%;
}
&.medium {
    width: 32%;
}
`;

function HourPicker({ className, label, isSearchable = true, onChange, value, backgroundColor, options, marginRight, placeholder, width }) {

    var options = [], i, j;
        
    for(i=8; i<21; i++) {
        for(j=0; j<4; j++) {
            let h = i + ":" + (j===0 ? "00" : 15*j) 
            options.push({ value: h, label: "Séance de " + h })
        }
    }

    return (
        <Col className={className}>
            {label && <Label>{label}</Label>}

            <Select
                placeholder={"Selectionnez un horaire"}
                isSearchable={false}
                options={options}
                onChange={onChange}
                value={value}
                styles={{ 
                    control: () => ({
                        backgroundColor: "#F8F9FB",
                        marginBottom: 20,
                        padding: 5,
                        paddingTop: 12,
                        paddingBottom: 12,
                        display: "flex",
                        borderRadius: 5,
                        width: width
                    })
                }}
            />

        </Col>
    );
}

export default HourPicker;
