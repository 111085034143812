import { v4 as uuidv4 } from "uuid";

export function generateTreament({
    data,
    type,
    type_human,
    stype,
    treatmentObj = {},
}) {
    return {
        type: type,
        type_human: type_human,
        stype: stype,
        forced_price: data.price,
        more: data.free ? "Offerte" : null,
        ...treatmentObj,
    };
}

export function generateSessions({
    data,
    type,
    type_human,
    stype,
    sessionObj = {},
    subdata = {},
}) {
    let uniqid = uuidv4();

    let sessions = [];

    for (let i = 0; i < data.session_count; i++) {
        let sprice = Math.floor(data.price / data.session_count);

        if (data.session_count == i + 1) {
            sprice = data.price - sprice * i;
        }

        sessions.push({
            date: new Date(1970, 6, 6),
            type: type,
            type_human: type_human,
            stype: stype,
            area: (() => {
                if (data.free) {
                    return "Offerte";
                } else {
                    return "-";
                }
            })(),
            data: subdata,
            attachments: [],
            forced_price: sprice,
            uniqid: uniqid,
            // treatment_id: treatment.id,
            employee_id: 0,
            ...sessionObj,
        });
    }

    return sessions;
}

export function generateTreamentAndSessions({
    data,
    type,
    type_human,
    stype,
    sessionObj = {},
    treatmentObj = {},
    subdata = {},
}) {
    let uniqid = uuidv4();

    let treatment = {
        type: type,
        type_human: type_human,
        stype: stype,
        forced_price: data.free === true ? 0 : data.price,
        more: data.free ? "Offerte" : null,
        ...treatmentObj,
    };

    let sessions = [];

    for (let i = 0; i < data.session_count; i++) {
        let sprice = Math.floor(
            data.free === true ? 0 : data.price / data.session_count
        );

        if (data.session_count == i + 1) {
            sprice = (data.free === true ? 0 : data.price) - sprice * i;
        }

        sessions.push({
            date: new Date(1970, 6, 6),
            type: type,
            type_human: type_human,
            stype: stype,
            area: (() => {
                if (data.free) {
                    return "Offerte";
                } else {
                    return "-";
                }
            })(),
            data: subdata,
            attachments: [],
            forced_price: sprice,
            uniqid: uniqid,
            // treatment_id: treatment.id,
            employee_id: 0,
            ...sessionObj,
        });
    }

    return { treatment, sessions };
}
