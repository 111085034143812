import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css';
import styled from 'styled-components'
import { Modal, Image } from 'react-bootstrap'

//components
import ModalC from './Modal'

//api
import MyLaserApi from '../api/MyLaserApi'

const Container = styled.div`
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px;
      width: 100%;
     
    `

  const Text = styled.span`
      font-family: CenturyGothicRegular;
      color:#102935;
      font-size: 13px;
      margin-top: 10px;
      text-align: center;
      &.title {
        font-family: CenturyGothicBold;
        font-size: 23px;
        }
      &.gray {
        color: #6B7389;
      }
    
    `
    
function LogoutModal(props) {

  
  let logout = () => {
    return MyLaserApi.unauth().then(() => {
      window.location.reload(false)
    });
  }


  return (
    <ModalC
      show={props.show}
      onHide={props.onHide}
      title={"Déconnexion"}
      buttonText="Me déconnecter"
      onButtonClick={logout}
    >

      <Container>
        <Image src={"/sign-out-orange.svg"}
        />
        <Text className="title">Vous souhaitez<br />
                    vous déconnecter ?</Text>
        <Text className="gray" >Pas de panique, vous pourrez vous reconnecter à tout<br />moment en utilisant vos identidents de connexion.<br />
        </Text>

      </Container>


    </ModalC>
  );
}

export default LogoutModal;