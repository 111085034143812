import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css';
import styled from 'styled-components'
import { Image } from 'react-bootstrap'
import S3Picture from "./S3Picture";

const Container = styled.button`
    display: flex;
    flex-direction: Row;
    align-items: center;
    width: 100%;
    border-style: solid;
    border-width: 1px; 
    background-color: #fff;
    border-color: #EDF0F5; 
    padding:10px;
    border-radius: 6px;
    margin-bottom: 20px;
    &.selected {
        background-color: #FDF4EF;
        border-color: #F0B594; 
    }
`

const Text = styled.span`
    margin-left: 10px;
    font-family: CenturyGothicRegular;
    color:#6B7389;
    font-size: 12px;
    &.selected {
    color:#102935;
    font-family: CenturyGothicBold;
    }
`

const SubText = styled.span`
    margin-left: 10px;
    font-family: CenturyGothicRegular;
    color:#6B7389;
    font-size: 8px;
`
function Employee(props) {

    return (
        <Container className={props.className} onClick={() => {
            props.onClick()
        }} style={props.style} >

            {props.showImage && <S3Picture className="user-profile-img-small" type="user-profil" file={props.employee_picture} /> }

            <Text className={props.className} style={{flexBasis: "100%"}}>
                {props.text}
                { props.subtext && <>
                    <br/>
                    <SubText >{props.subtext}</SubText> 
                </> }

            </Text>

            { props.children }

        </Container>
    );
}

export default Employee;