
import "../App.css";
import styled from "styled-components";
import { Image, Form } from "react-bootstrap";
import React, { useState } from "react";
import { useTable } from "react-table";
import moment from "moment";

//components
import Button from "./Button";


const Row = styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-bottom: 20px;
    `;
const Text = styled.span`
        font-family: CenturyGothicRegular;
        color: #102935;
        font-size: 12px;
        &.label {
            font-family: CenturyGothicBold;
            color: #87949a;
            font-size: 12px;
            margin-right: 5px;
        }
    `;

const CustomTr = styled.tr`
    & td:first-child { border-top-left-radius: 10px; border-bottom-left-radius: 10px; padding-left: 15px; }
    & td:last-child { border-top-right-radius: 10px; border-bottom-right-radius: 10px; padding-left: 15px; }
`

function Table(props) {

    const data = props.data;
    const columns = props.labels;

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

    return (
        <table {...getTableProps()} style={{ width: "100%", margin: 20, borderSpacing: "0px", borderCollapse: "inherit" }}>
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                                <Row style={props.style}>
                                    <Text className="label">
                                        {column.render("Header")}
                                    </Text>
                                    {props.icon && (
                                        <Image src={"/picker-arrows.svg"} />
                                    )}
                                </Row>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            { rows.length > 0 && <tbody {...getTableBodyProps()}>

                {rows.map((row, i) => {
                    prepareRow(row)

                    let renders = []

                    let rowminus = rows[i - 1]

                    if (rowminus && row.original && rowminus.original && rowminus.original.date && row.original.date) {

                        if (rowminus.original.date != row.original.date) {

                            renders.push(<tr >
                                <td colSpan={row.allCells.length} style={{ paddingTop: "5px",paddingBottom: "5px" }}> <hr style={{backgroundColor: "rgba(16, 41, 53, 0.5)"}} /> </td>
                            </tr>)

                        }

                    }

                    renders.push(

                        <CustomTr {...row.getRowProps()} 
                            style={{ 
                                borderBottomWidth: props.border ? 1 : 0, 
                                borderBottomColor: 'rgba(16,41,53,.1)', 
                                height: "40px", 
                                cursor: 'pointer', 
                                
                                borderRadius: "10px"
                            }}
                        >
                            {row.cells.map((cell) => {
                                return (
                                    <td {...cell.getCellProps()} style={{
                                        backgroundColor: (() => {
                                            if (row.original && row.original.special_note_status == 1) {
                                                return 'rgba(215, 180, 180, 0.8)'
                                            } else if (row.original && row.original.special_note_status == 2) { 
                                                return '#B4D7C1'
                                            } else {
                                                return 'transparent'
                                            }
                                        })(),  
                                    }}>
                                        <div style={props.style}>

                                            {props.checkbox && cell.column.id === 'date' && (
                                                <Form.Check type="checkbox" onChange={() => props.checkItem(row.original)} />
                                            )}

                                            {cell.value === "buutons" ? (
                                                <Row>
                                                    <Button
                                                        className="no-background"
                                                        style={{
                                                            marginRight: 20,
                                                            color: "#82D059",
                                                            textDecorationLine:
                                                                "underline",
                                                        }}
                                                    >
                                                        Modifier
                                                    </Button>
                                                    <Button
                                                        className="no-background"
                                                        style={{
                                                            color: "#F25151",
                                                            textDecorationLine:
                                                                "underline",
                                                        }}
                                                        onClick={
                                                            props.onDeletePress
                                                        }
                                                    >
                                                        Supprimer
                                                    </Button>
                                                </Row>
                                            ) : cell.value === "Partager" ? (
                                                <Button
                                                    className="blue"
                                                    style={{
                                                        marginBottom: 20,
                                                        marginTop: 20,
                                                    }}
                                                    onClick={props.onButtonClick}
                                                >
                                                    {cell.value}
                                                </Button>
                                            ) : cell.value === "Rembourser" ? (

                                                cell.value

                                            )  : (
                                                <Text
                                                    style={{ color: cell.column.id === 'id' && props.transparent ? 'transparent' : null }}
                                                    onClick={() => {
                                                        if (props.checkbox) {
                                                            props.checkItem(row.allCells[0].value)
                                                        }

                                                        props.onClick && props.onClick(row, cell)

                                                    }}>

                                                    {cell.render("Cell")}

                                                    
                                                </Text>
                                            )}
                                        </div>
                                    </td>
                                );
                            })}
                        </CustomTr>
                    );

                    return renders
                })}
            </tbody> }

            {
                rows.length == 0 && (
                    <tbody>
                        <td rowSpan={headerGroups[0].headers.length} colSpan={headerGroups[0].headers.length} style={{textAlign: 'center', fontSize: '14px'}}>Il n'y a aucune données à afficher</td>
                    </tbody>
                )
            }
        </table>
    );
}

export default Table;
