
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container, Col, Row } from "react-bootstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import Button from "../../../components/Button";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Select from "react-select";
import HourPicker from "../../../components/HourPicker";
import EmployeePicker from "../../../components/EmployeePicker";

import SessionHeader from "../../../components/SessionHeader";

//api
import MyLaserApi from "../../../api/MyLaserApi";


import ItemDetails from "../../../components/ItemDetails";
import { useSelector } from "react-redux";

import ImageListUpload from '../../../components/ImageListUpload';
import ImageListUploaded from '../../../components/ImageListUploaded';
import S3Handler from '../../../services/S3Handler';
import Picker from "../../../components/Picker";
import Input from "../../../components/Input";

import moment from 'moment'

import TimeInput from "react-time-picker-input";


const Text = styled.span`
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 14px;
    &.label {
        font-family: CenturyGothicBold;
        color: #6b7389;
        font-size: 15px;
        margin-bottom: 20px;
    }
    &.selected {
        font-family: CenturyGothicBold;
        font-size: 16px;
        margin-bottom: 20px;
        text-decoration-line: underline;
    }
    &.unselected {
        font-family: CenturyGothicBold;
        font-size: 16px;
        margin-bottom: 20px;
        color: #9fa9ae;
    }
`;

function UpdateSession() {
    const history = useHistory();
    const params = useParams();
    
    return (
        <Container fluid>
            <SessionHeader
                title={"Suivi"}
                text={"Injection Acide Hyaluronique (AH)"}
                goBack={() => history.goBack()}
            />
            
            <OtherSession />
        </Container>
    );
}


function OtherSession({ operation, label }) {

    const params = useParams();
    const history = useHistory();

    const [date, setDate] = useState(new Date());
    const [hour, setHour] = useState(moment().format('HH:mm'));
    const [note, setNote] = useState("");

    const [images, setImages] = useState([]);

    const [area, setArea] = useState("");

    const [employee, setEmployee] = useState(null);

    const user = useSelector((state) => state.user)

    const [datum, setDatum] = useState({})

    const [ cAttachments, setCAttachments ] = useState([])

    const [etiquetteTracage, setEtiquetteTracage] = useState([]);

    const [injectedMl, setInjectedMl] = useState(null);
    const [injectedMlAH, setInjectedMlAH] = useState(0.1);

    useEffect(() => {

        MyLaserApi.GetAgenciesClientsIdSession(params.id, params.id_session).then(({ data }) => {

            if (!data.date.includes('1970')) {
                let elements = JSON.parse(data.data)
                // setDatum(elements)
                // setCAttachments(data.attachments)

                setArea("Suivi " + data.area)

                // if (elements.etiquette) {
                //     setEtiquetteTracage([elements.etiquette])
                // }

                // if (elements.injection && operation === "ah") {
                //     setInjectedMlAH(elements.injection.replace(' ml', ''))
                // }

                // setNote(elements.note)

                let cdate = moment(data.date)

                setDate(cdate.toDate())
                setHour(cdate.format('HH:mm'))
                // setHour({ value: cdate.format('H:mm'), label: "Séance de " + cdate.format('H:mm') })

            }

        })

    }, [])


    return (

        <Row>
            <Col xs={4} className="col">
                <div>
                    <Text className="label">
                        <br />
                        Choisissez la date de la séance :
                        <br />
                        <br />
                    </Text>

                    <Calendar
                        onChange={setDate}
                        value={date}
                        className="calendar"
                        prev2Label=""
                        next2Label=""
                        maxDate={new Date()}

                    />

                    <br />

                    {/* <HourPicker onChange={(v) => setHour(v)}  value={hour} >  </HourPicker>
                     */}

                    <TimeInput value={hour} eachInputDropdown onChange={(value) => setHour(value)}/>


                    <EmployeePicker onChange={(selected) => {
                        setEmployee(selected.value.id)
                    }} ></EmployeePicker>

                </div>
            </Col>

            <Col xs={4} className="col">

                <div style={{ width: "100%" }}>

                    <Row>
                        <Text className="label">Information sur la séance</Text>
                    </Row>

                    <Col className="mt-4">

                        <div style={{ width: "100%" }}>


                            {/* <Picker
                                isDisabled={true}
                                options={["Glabelle", "Sourcils", "Ride péri-oculaires", "Tempes", "Cernes", "Pommettes", "Nez", "Joues", "Sillons nasogéniens", "Rides pèri-buccales", "Lèvres", "Ovale", "Menton", "Plis d'amertume"].map((e) => ({label: e, value: "Suivi " + e}))}
                                label="Zone de traitement"
                                placeholder="Zone de traitement"
                                onChange={(data) => setArea(data.value)}
                                value={{label: area, value: area}}
                                isSearchable={false}
                            /> */}

                            <Input
                                noIcon
                                placeholder="Zone de traitement"
                                label="Zone de traitement"
                                value={area}
                                readOnly
                                onChange={(e) => {}}
                            />


                            <div style={{ width: "100%" }}>
                                {/* <Picker
                                    options={[
                                        { value: 1, label: "1 ml" },
                                        { value: 2, label: "2 ml" },
                                        { value: 3, label: "3 ml" },
                                        { value: 4, label: "4 ml" },
                                        { value: 5, label: "5 ml" },
                                        { value: 6, label: "6 ml" },
                                        { value: 7, label: "7 ml" },
                                        { value: 8, label: "8 ml" },
                                        { value: 9, label: "9 ml" },
                                        { value: 10, label: "10 ml" },
                                    ]}
                                    value={injectedMl}
                                    label="Nombre de ml injecté"
                                    placeholder="Nombre de ml injecté"
                                    onChange={(data) => setInjectedMl(data)}
                                /> */}

                                <Input
                                    noIcon
                                    placeholder="Nombre de ml injecté"
                                    label="Nombre de ml injecté"
                                    value={injectedMlAH}
                                    onChange={(e) => {

                                        if (!e.target.value || e.target.value.match(/^\d{1,}(\.\d{0,2})?$/)) {
                                            setInjectedMlAH(e.target.value);
                                          }

                                    }}
                                />


                            </div>



                            <Input
                                textArea
                                noIcon
                                className="multiline"
                                placeholder="Petite rougeur au niveau du nez"
                                label="Note"
                                value={note}
                                onChange={(e) => {
                                    setNote(e.target.value);
                                }}
                            />

                        </div>
                    </Col>
                </div>
            </Col>

            <Col xs={4} className="col">

                <Col className="mt-4" style={{ width: "80%" }}>

                    <Text className="label">Etiquette de traçage :</Text>

                    <div style={{ width: "100%" }}>

                        {etiquetteTracage && etiquetteTracage[0] && typeof etiquetteTracage[0] == 'string' && etiquetteTracage[0].includes('http') && <ImageListUploaded value={etiquetteTracage} removeOne={(index) => setEtiquetteTracage([])} />}
                    
                        <ImageListUpload value={etiquetteTracage} onChange={(imageList) => {
                            setEtiquetteTracage(imageList)
                        }} label="Ajouter l'étiquette de traçage" showButton={etiquetteTracage.length === 0} />

                    </div>
                            

                    <hr />

                    <Text className="label">Pièces Jointes :</Text>

                    <br />

                    <div style={{ width: "100%" }}>

                        <ImageListUploaded value={cAttachments} removeOne={(index) => setCAttachments(cAttachments.filter((v,i) => index !== i))}/>

                        <ImageListUpload  value={images} onChange={(imageList) => {
                            setImages(imageList)
                        }} label="Ajouter une photo" />
    
                    </div>

                    <br />
                    <br />

                    <hr />
                    <br />
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                    }} >
                        <Button
                            disabled={

                                (() => {

                                    if (user.role === 1) {
                                        return date == null || hour == null || employee == null
                                    } else {
                                        return date == null || hour == null
                                    }

                                })()

                            }
                            style={{ width: "100%" }}
                            onClick={async () => {

                                let attachments = await S3Handler.uploadFromList(images)

                                let hh = hour.split(":")

                                date.setHours(hh[0])
                                date.setMinutes(hh[1])

                                let obj = {
                                    date: date,
                                    type: operation,
                                    type_human: label,
                                    area: area || "-",
                                    data: {
                                        note: note,
                                    },
                                    attachments: [ ...cAttachments, ...attachments]
                                }

                                if (user.role === 1) {
                                    obj["employee_id"] = employee
                                }

                                // if (operation === "cryolipolyse") {
                                obj.data = {...obj.data, ...datum, injection: injectedMlAH + " ml"}
                                // } 

                                if (etiquetteTracage.length !== 0) {
                                    let etiquette = await S3Handler.uploadFromList(etiquetteTracage)
                                    obj.data = {...obj.data, etiquette: etiquette[0]}
                                }

                                MyLaserApi.CreateAgenciesClientsIdSessionsFollows(
                                    params.id,
                                    obj,
                                    params.id_session
                                ).then(() => {
                                    history.goBack()
                                });


                            }}
                        >
                            Sauvegarder
                        </Button>

                    </div>
                    
                </Col>

            </Col>

        </Row>

    );
}


export default UpdateSession;
