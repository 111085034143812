import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css';
import React, { useState } from 'react';
import styled from 'styled-components'
import { Dropdown, Image, Row } from 'react-bootstrap'
import { useSelector, useDispatch } from "react-redux";

import LogoutModal from "./LogoutModal";
import S3Picture from "./S3Picture";

const Text = styled.span`
    font-family: CenturyGothicRegular;
    color:#102935;
    font-size: 14px;

    &.bold {
    font-family: CenturyGothicBold;
    }

`
const ImageContainer = styled.div`
    background-color: #fff;
    //padding:2px;
    border-radius:50px;
    border:solid;
    border-color:#EDF0F5;

`

function Menu() {
    const user = useSelector((state) => state.user);
    const [showDisconnectModal, setShowDisconnectModal] = useState(false);
 
    return (
        <div>
            <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                    <div style={{display:'flex',marginTop:20}}>
                        
                        <ImageContainer>
                            <S3Picture className="user-profile-img-small" type="user-profil" file={user.picture} />
                        </ImageContainer>

                        <Image src={"/down-arrow.svg"}
                            style={{ marginLeft: 10 }}
                        />

                    </div>

                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="/account" style={{ borderBottomWidth: 1 }}>

                        <Image src={"/user.svg"}
                            style={{ marginRight: 10 }}
                        />
                        <Text className="bold">Mon compte</Text>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setShowDisconnectModal(true)}>
                        <Image src={"/log-out.svg"}
                            style={{ marginRight: 10 }}
                        />
                        <Text>Déconnexion</Text>
                    </Dropdown.Item>

                    <Dropdown.Item>
                        <Text>Version {process.env.REACT_APP_VERSION}</Text>
                    </Dropdown.Item>
                    
                </Dropdown.Menu>
            </Dropdown>
            <LogoutModal
                show={showDisconnectModal}
                onHide={() => setShowDisconnectModal(false)}
            />
        </div>
    );
}

export default Menu;