import React from "react"

import Menu from './icons/Menu'
import Users from './icons/Users'
import FingerPrint from './icons/FingerPrint'
import Box from './icons/Box'
import BookMark from './icons/BookMark'
import Activity from './icons/Activity'


const Icons = {

    Menu: <Menu />,
    Users: <Users />,
    FingerPrint: <FingerPrint />,
    Box: <Box />,
    BookMark: <BookMark />,
    Activity: <Activity />,

    MenuSelected: <Menu selected />,
    UsersSelected: <Users selected />,
    FingerPrintSelected: <FingerPrint selected />,
    BoxSelected: <Box selected />,
    BookMarkSelected: <BookMark selected />,
    ActivitySelected: <Activity selected />,

}

export default Icons