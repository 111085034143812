export const sampleObjectDetail = {
    data: {
        "lastname": {
            "id": "lastname",
            "title": "Nom",
            "value": ""
        },
        "firstname": {
            "id": "firstname",
            "title": "Prénom",
            "value": ""
        },
        "birthdate": {
            "id": "birthdate",
            "title": "Date de naissance",
            "value": ""
        },
        "phone_number": {
            "id": "phone_number",
            "title": "Numéro de tél",
            "value": ""
        },
        "email": {
            "id": "email",
            "title": "E-mail*",
            "value": ""
        },
        "address_line": {
            "id": "address_line",
            "title": "Adresse",
            "value": ""
        },
        "address_zip_code": {
            "id": "address_zip_code",
            "title": "Code Postal",
            "value": ""
        },
        "address_city": {
            "id": "address_city",
            "title": "Ville",
            "value": ""
        },
        "agency_id": {
            "id": "agency_id",
            "title": "Centre",
            "value": ""
        },
        "agency_know": {
            "id": "agency_know",
            "title": "Comment avez-vous connu le centre ?",
            "value": ""
        },
        "profession": {
            "id": "profession",
            "title": "Profession",
            "value": ""
        },
        "sexe": {
            "id": "sexe",
            "title": "Sexe",
            "value": ""
        },
        "medical_background": {
            "id": "medical_background",
            "title": "Antécédents médicaux",
            "value": ""
        },
        "surgical_background": {
            "id": "surgical_background",
            "title": "Antécédents chirurgicaux (3 derniers mois)",
            "value": ""
        },
        "treatments_current": {
            "id": "treatments_current",
            "title": "Traitement en cours (médicament, plante et/ou complément alimentaire)",
            "value": ""
        },
        "treatment_photosensitizer_question": {
            "id": "treatment_photosensitizer_question",
            "title": "Avez-vous suivi un traitement photosensibilisant au cours des 6 derniers mois (ex : Roaccutane, Tétracycline, Millepertuis) ?",
            "value": ""
        },
        "treatment_photosensitizer_response": {
            "id": "treatment_photosensitizer_response",
            "title": "Si oui, lesquelles?",
            "value": ""
        },
        "treatment_photosensitizer_end": {
            "id": "treatment_photosensitizer_end",
            "title": "Date de fin du traitement?*",
            "value": ""
        },
        "aesthetic_question": {
            "id": "aesthetic_question",
            "title": "Avez-vous réalisé des actes esthétiques ?",
            "value": ""
        },
        "aesthetic_response": {
            "id": "aesthetic_response",
            "title": "Si oui, le(s)quel(s) ?",
            "value": ""
        },
        "allergies": {
            "id": "allergies",
            "title": "Allergies connues?*",
            "value": ""
        },
        "pregnant_question": {
            "id": "pregnant_question",
            "title": "Etes-vous enceinte ?*",
            "value": ""
        },
        "breastfeeding": {
            "id": "breastfeeding",
            "title": "Allaitez-vous ?*",
            "value": ""
        },
        "contraception_method": {
            "id": "contraception_method",
            "title": "Quel est votre moyen de contraception ?*",
            "value": ""
        },
        "treatment_already_done": {
            "id": "treatment_already_done",
            "title": "Avez-vous effectué déjà effectué des soins ou traitement de médecine esthétique (toxine botulique, injections d’acide hyaluronique, peeling,…) ?",
            "value": ""
        },
        "treatment_already_done_date": {
            "id": "treatment_already_done_date",
            "title": "Si oui, date de la dernier traitement ?",
            "value": ""
        },
        "last_sun_date": {
            "id": "last_sun_date",
            "title": "Date de votre dernière exposition au soleil / aux UV artificiels ?",
            "value": ""
        },
        "using_tanning_cream": {
            "id": "using_tanning_cream",
            "title": "Utilisez-vous une crème autobronzante?",
            "value": ""
        },
        "using_lightening_cream": {
            "id": "using_lightening_cream",
            "title": "Utilisez-vous une crème éclaircissante?",
            "value": ""
        },
        "tan": {
            "id": "tan",
            "title": "Vous bronzez?",
            "value": ""
        },
        "sunburn": {
            "id": "sunburn",
            "title": "Vous prenez des coups de soleil?",
            "value": ""
        },
        "face_laser_hair_removal_question": {
            "id": "face_laser_hair_removal_question",
            "title": "Avez-vous effectué une séance d’épilation laser sur le visage récemment ?",
            "value": ""
        },
        "face_laser_hair_removal_date": {
            "id": "face_laser_hair_removal_date",
            "title": "Si oui, date de la dernier traitement ?",
            "value": ""
        },
        "face_injection_question": {
            "id": "face_injection_question",
            "title": "Avez-vous récemment recours à des injections sur votre visage?*",
            "value": ""
        },
        "face_injection_zone": {
            "id": "face_injection_zone",
            "title": "Si oui, sur quelle zone ?",
            "value": ""
        },
        "face_injection_date": {
            "id": "face_injection_date",
            "title": "Si oui, date de fin du traitement?",
            "value": ""
        },
        "face_makeup_question": {
            "id": "face_makeup_question",
            "title": "Avez-vous récemment réalisé un maquillage semi-permanent ou un tatouage sur votre visage ?",
            "value": ""
        },
        "face_makeup_zone": {
            "id": "face_makeup_zone",
            "title": "Si oui, sur quelle zone ?",
            "value": ""
        },
        "face_makeup_date": {
            "id": "face_makeup_date",
            "title": "Si oui, quand l’avez-vous réalisé?",
            "value": ""
        },
        "treatment_face_question": {
            "id": "treatment_face_question",
            "title": "Avez-vous récemment eu recours à un traitement ou soin du visage (microneedling, …)?*",
            "value": ""
        },
        "treatment_face_zone": {
            "id": "treatment_face_zone",
            "title": "Si oui, lequel ?*",
            "value": ""
        },
        "treatment_face_date": {
            "id": "treatment_face_date",
            "title": "Si oui, précisez la date de réalisation du soin?",
            "value": ""
        },
        "event_question": {
            "id": "event_question",
            "title": "Avez-vous un évènement prévu dans les trois prochains jours (soirée, mariage, représentation…) ?*",
            "value": ""
        },
        "routine_beaute_choice": {
            "id": "routine_beaute_choice",
            "title": "Quelle est votre routine beauté quotidienne ?<br><br>Sélectionnez vos choix.",
            "value": ""
        },
        "undersign": {
            "id": "undersign",
            "title": "Je soussigné(e)*",
            "value": ""
        }
    }
}