
import "../../../../App.css";
import styled from "styled-components";
import { Image } from "react-bootstrap";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Container, Col } from "react-bootstrap";

import ImageListUploadedInline from "../../../../components/ImageListUploadedInline";
import CreateSpecialNoteTrackModal from "../../../../components/CreateSpecialNoteTrackModal";

import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal";
import Header from "../../../../components/Header";
import SideBar from "../../../../components/SideBar";
import Table from "../../../../components/Table";
import { useParams, useHistory, useLocation } from "react-router-dom";
import MyLaserApi from '../../../../api/MyLaserApi'

const SContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: transparent;
    margin-top: 20px;
    width: 96%;
    border-radius: 5px;
`;

const Text = styled.span`
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 13px;
    &.bold {
        font-family: CenturyGothicBold;
        color: #f0b594;
        font-size: 15px;
    }
    &.blue {
        color: #102935;
    }
    &.gray {
        color: #9fa9ae;
    }
    &.underline {
        text-decoration-line: underline;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
    &.spaced-items {
        justify-content: space-between;
    }
    &.custom-width {
        width: 50%;
    }
    &.center {
        align-items: center;
    }
`;


function TreatmentConsent(props) {

    let params = useParams()
    let history = useHistory()

    let [session, setSession] = useState(null)


    let [single, setSingle] = useState({})
    let [showModal, setShowModal] = useState(false)

    const [modalAttachmentsIsVisible, setModalAttachmentsIsVisible] = useState(false);
    const [modalAttachments, setModalAttachments] = useState([]);

    function loadSession() {

        MyLaserApi.GetSession(params.id, params.id_session).then((res) => {
            setSession(res.data)
            setSingle(JSON.parse(res.data.data))
        })

    }

    useEffect(() => {

        loadSession()

    }, []);

    if (!session) {
        return <div></div>
    }

    return (
        <Container fluid className="App">
            <Row>
                
                <Col
                    md={12}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Header
                        type="client"
                    />

                    <SContainer>


                        <Row className="spaced-items center">
                            <Text className="bold blue">
                                NOTE PARTICULIÈRE {" "}
                                <Text className="bold">
                                    du { moment(session.date).format('LL') } - { session.area } - { session.special_note_secondary_effect }
                                </Text>
                            </Text>

                            { (session.special_note_status == 1) && <div style={{flexDirection: "row", display: "flex", width: "35%"}} >

                                <Button style={{ width: "100%", marginRight: "10px" }} onClick={() => setShowModal(true)}>
                                    Effectuer un suivi
                                </Button>

                                <Button style={{ width: "100%", backgroundColor: "#B4D7C1" }} onClick={() => {

                                    MyLaserApi.UpdateSessionNote(params.id, params.id_session, { status: 2 }).then((res) => {
                                        if (res.status === 200) {
                                            loadSession()
                                        }
                                    })

                                }}>
                                    Fin de suivi  
                                </Button>
                            </div>}

                            { (session.special_note_status == 2) && <div style={{flexDirection: "row", display: "flex", width: "35%"}} >

                                <Button style={{ width: "100%", backgroundColor: "#D7B4B4" }} onClick={() => {

                                    MyLaserApi.UpdateSessionNote(params.id, params.id_session, { status: 1 }).then((res) => {
                                        if (res.status === 200) {
                                            loadSession()
                                        }
                                    })

                                }}>
                                    Reprendre le suivi
                                </Button>
                            </div>}

                        </Row>

                        <Row className="spaced-items" style={{background: 'white', padding: "25px", borderRadius: "5px", marginBottom: "20px", paddingTop: 0, flexDirection: "column"}}>
                            
                            <Row>
                               
                                <Text className="bold blue" style={{fontSize: "18px"}}>
                                    Tous les suivis
                                </Text>

                            </Row>

                            {session.trackings.length > 0 && <Table
                                data={session.trackings.map((el) => {
                                    return {
                                        date: moment(el.date).format("DD/MM/YYYY"),
                                        comment: el.comment,
                                        attachments: el.attachements.length === 0
                                            ? "Aucunes pièces"
                                            : <span style={{
                                                marginBottom: 20,
                                                marginTop: 20,
                                                fontFamily: "CenturyGothicBold",
                                                fontSize: "12px",
                                                color: "rgb(136 194 211)",
                                                textDecoration: "underline"
                                            }}>{el.attachements.length} image(s)</span>,
                                        attachments_files: el.attachements,
                                        employee: el.employee.firstname + " " + el.employee.lastname
                                    }
                                })}
                                labels={[
                                    {
                                        Header: "Date",
                                        accessor: "date",
                                    },
                                    {
                                        Header: "Fait par",
                                        accessor: "employee",
                                    },
                                    {
                                        Header: "Situation de l’effet secondaire",
                                        accessor: "comment",
                                    },
                                    {
                                        Header: "Effets sec.",
                                        accessor: "attachments"
                                    }
                                ]}
                                border
                                onClick={(row, cell) => {

                                    if (cell.column.id === 'attachments' && cell.row.values && row.original.attachments_files && row.original.attachments_files.length > 0) {
                                        setModalAttachmentsIsVisible(true)
                                        setModalAttachments(row.original.attachments_files)
                                    } 
                                    
                                }}
                            />}

                            {session.trackings.length === 0 && <Row>
                                <Text>
                                Il n'y a encore aucun suivi.
                                </Text>
                            </Row> }

                        </Row>

                        <Row className="spaced-items" style={{background: 'white', padding: "25px", borderRadius: "5px", marginBottom: "20px", paddingTop: 0, flexDirection: "column"}}>
                            
                            <Row className="spaced-items">
                               
                                <Text className="bold blue" style={{fontSize: "18px"}}>
                                    Détails de la séance
                                </Text>

                                <div>

                                    <b style={{paddingRight: 5}}>Status:</b>{" "}{ (() => {

                                        if (session.special_note_status == 1) {
                                            return <b style={{color: 'red'}}>En cours</b>
                                        } else if (session.special_note_status == 2) {
                                            return <b style={{color: 'green'}}>Traité !</b>
                                        }

                                    })()}

                                </div>

                            </Row>

                            <Row>

                                <Col>
                                    <Row >
                                        <b style={{paddingRight: 5}}>Date:</b>{" "} { moment(session.date).format('LL') }
                                    </Row>
                                    <Row >
                                        <b style={{paddingRight: 5}}>Zone:</b>{" "} {session.type_human} ({ session.area })
                                    </Row>
                                    <Row >
                                        <b style={{paddingRight: 5}}>Durée pulse:</b>{" "} { (single.pulse || {label: "N/C"}).label }
                                    </Row>
                                    
                                    
                                </Col>
                                <Col>
                                    <Row >
                                        <b style={{paddingRight: 5}}>Praticien:</b>{" "} { session.employee.firstname } { session.employee.lastname }
                                    </Row>
                                    <Row >
                                        <b style={{paddingRight: 5}}>Taille spot:</b>{" "} { (single.spot || {label: "N/C"}).label }
                                    </Row>
                                    <Row >
                                        <b style={{paddingRight: 5}}>Alex/Yag:</b>{" "} { (single.technologie || {label: "N/C"}).label }
                                    </Row>
                                </Col>
                                <Col>
                                    <Row >
                                        <b style={{paddingRight: 5}}>Effet secondaire:</b>{" "} { session.special_note_secondary_effect }
                                    </Row>
                                    <Row >
                                        <b style={{paddingRight: 5}}>Fluence:</b>{" "} { (single.fluence || {label: "N/C"}).label }
                                    </Row>
                                    <Row >
                                    
                                    </Row>
                                </Col>

                            </Row>

                            

                            <Row style={{flexDirection: "column"}}>
                                <Row >
                                    <b style={{paddingRight: 5}}>Notes:</b>{" "} { (single.notes || "N/C") }
                                </Row>
                            </Row>

                            {session.attachments.length > 0 && <Row style={{flexDirection: "column"}}>
                                <Row>
                                    <b style={{paddingRight: 5}}>Pièces jointes de la séancce: </b>
                                </Row>
                                <Row>
                                    <ImageListUploadedInline value={session.attachments} removeOne={() => {}} />
                                </Row>
                            </Row>}
                            
                            <Row style={{flexDirection: "column"}}>
                                <Row >
                                    <b style={{paddingRight: 5}}>Note Particulière:</b>{" "} { (session.special_note_comment || "N/C") }
                                </Row>
                            </Row>

                            {session.special_note_attachements.length > 0 && <Row style={{flexDirection: "column"}}>
                                <Row>
                                    <b style={{paddingRight: 5}}>Pièces jointes de la note particulière: </b>
                                </Row>
                                <Row>
                                    <ImageListUploadedInline value={session.special_note_attachements} removeOne={() => {}} />
                                </Row>
                            </Row>}

                        </Row>

                        

                        
                    </SContainer>
                </Col>
            </Row>

            <CreateSpecialNoteTrackModal
                show={showModal}
                onHide={() => {
                    setShowModal(false)
                    loadSession()
                }}
                clientId={params.id}
                sessionId={params.id_session}
            />

            <Modal
                show={modalAttachmentsIsVisible}
                onHide={() => setModalAttachmentsIsVisible(false)}
                title={"Pièces Jointes"}
                size="lg"
            >
                {
                    modalAttachments.map((file) => {
                        return <div style={{textAlign: 'center'}}>
                            <a href={file} target="_blank"> Ouvrir en grand </a> <br />
                            <img src={file} style={{maxWidth: "100%"}}/>
                        </div>
                    })
                }

            </Modal>
            
        </Container>
    );
}

export default TreatmentConsent;
