
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container, Col, Row, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
// import Calendar from "react-calendar";
// import "react-calendar/dist/Calendar.css";

import Button from "../../../components/Button";
import Picker from "../../../components/Picker";
import Input from "../../../components/Input";
import EmployeePicker from "../../../components/EmployeePicker";
import HourPicker from "../../../components/HourPicker";
import ImageListUpload from "../../../components/ImageListUpload"

import { useHistory, useParams } from "react-router-dom";

import SessionHeader from "../../../components/SessionHeader";

//api
import MyLaserApi from "../../../api/MyLaserApi";

import S3Handler from "../../../services/S3Handler";
import moment from "moment";

import TimeInput from "react-time-picker-input";


import { Calendar } from 'react-date-range';

import * as locales from 'react-date-range/dist/locale';

const Text = styled.span`
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 14px;
    &.label {
        font-family: CenturyGothicBold;
        color: #6b7389;
        font-size: 15px;
        margin-bottom: 20px;
    }
    &.selected {
        font-family: CenturyGothicBold;
        font-size: 16px;
        margin-bottom: 20px;
        text-decoration-line: underline;
    }
    &.unselected {
        font-family: CenturyGothicBold;
        font-size: 16px;
        margin-bottom: 20px;
        color: #9fa9ae;
    }
`;

function CreateSession() {

    const history = useHistory();
    const params = useParams();
    const [label, setLabel] = useState("")
 
    useEffect(() => {

        switch (params.type) {
            case "laser":
                return setLabel("Épilation Laser");
            case "mesotherapy":
                return setLabel("Soin Mésothérapie");
            case "prx":
                return setLabel("Soin PRX");
            case "hydrafacial":
                return setLabel("Soin Hydrafaciale");
            case "ah":
                return setLabel("Injection Acide Hyaluronique (AH)");
            case "botox":
                return setLabel("Injection Botox");
            case "hyacorp":
                return setLabel("Injection Hyacorp");
            case "peeling":
                return setLabel("Peeling TCA visage");
            case "hair":
                return setLabel("Traitement Capillaire");
            case "cryolipolyse":
                return setLabel("Cryolipolyse");
            case "peeling-intimate":
                return setLabel("Peeling Intime");
            case "blemiderm":
                return setLabel("Blemiderm")
            case "dermamelan":
                return setLabel("Dermamelan")
            case "unknow":
                return setLabel("Traitement non catégorisé")
            default:
                return setLabel("Traitement Capillaire");
        }

    }, [])

    return (
        <Container fluid>
            <SessionHeader
                text={label}
                goBack={() => history.goBack()}
            />

            {params.type !== "laser" && (
                <OtherSession operation={params.type} label={label} />
            )}
        </Container>
    );
}

function OtherSession({ operation, label }) {

    const params = useParams();
    const history = useHistory();

    const [date, setDate] = useState(new Date());
    const [hour, setHour] = useState(moment().format('HH:mm'));
    const [note, setNote] = useState("");

    const [images, setImages] = useState([]);

    const [etiquetteTracage, setEtiquetteTracage] = useState([]);

    const [area, setArea] = useState("");

    const [injectedMl, setInjectedMl] = useState(null);

    const [injectedMlAH, setInjectedMlAH] = useState(0.1);

    const [employee, setEmployee] = useState(null);

    const [datum, setDatum] = useState({})

    const [isNewClient, setIsNewClient] = useState("true");

    const [superLabel, setSuperLabel] = useState(null);

    const [price, setPrice] = useState(0);


    const user = useSelector((state) => state.user)

    // useEffect(() => {

    //     const start = moment();
        
    //     const remainder = 15 - (start.minute() % 15);
    //     const dateTime = moment(start).add(remainder, "minutes")

    //     setHour({ value: dateTime.format('H:mm'), label: "Séance de " + dateTime.format('H:mm') })

    // })

    return (

        <Row>
            
            <Col xs={4} className="col">
                
                <div>

                    <Text className="label">
                        <br />
                        Choisissez la date de la séance :
                        <br />
                        <br />
                    </Text>

                    <Calendar
                        onChange={setDate}
                        date={date}
                        maxDate={new Date()}
                        locale={locales["fr"]}
                    />

                    <br />

                    <TimeInput value={hour} eachInputDropdown onChange={(value) => setHour(value)}/>

                    {user.role === 1 && <EmployeePicker 
                        label="Choisir l'employé qui a effectué la séance" 
                        value={employee}
                        onChange={(e) => {
                            setEmployee(e.target.value)
                        }} 
                    ></EmployeePicker>}

                </div>
                
            </Col>

            <Col xs={4} className="col">

                <div style={{ width: "100%" }}>

                    <Row>
                        <Text className="label">Information sur la séance</Text>
                    </Row>

                    <Col className="mt-4">

                        <div style={{ width: "100%" }}>

                            {operation === "hydrafacial" && (

                                <Picker
                                    options={[
                                        { value: "0", label: "Soin hydrafacial offert"},
                                        { value: "1", label: "Signature HydraFacial®" }, 
                                        { value: "2", label: "Soin Platinium HydraFacial® (Hydrafacial + Booster personnalisé)" }, 
                                        { value: "3", label: "Soin Signature My Laser x HydraFacial® ( Hydrafacial + Mésothérapie personnalisée)" }, 
                                        { value: "4", label: "Keravive Hydrafacial" },
                                        { value: "5", label: "Soin Hydrafacial x PRX (Hydrafacial + PRX)" },
                                        { value: "6", label: "Soin Hydrafacial x Mésothérapie x PRX" },
                                    ]}
                                    label="Type de traitement"
                                    placeholder="Type de traitement"
                                    onChange={(data) => setDatum({...datum, type: data})}
                                    isSearchable={false}
                                />

                            )}

                            {operation === "prx" && (

                                <Picker
                                    options={[
                                        { value: "0", label: "Soin PRX offert parrainage" },
                                        { value: "1", label: "Soin PRX" }
                                    ]}
                                    label="Type de traitement"
                                    placeholder="Type de traitement"
                                    onChange={(data) => setDatum({...datum, type: data})}
                                    isSearchable={false}
                                />

                            )}

                            {operation === "ah" && (<>

                                <span>Information patient :  </span>
                                <br/>
                
                                <div key={`inline-radio`} className="mb-3" style={{marginTop: "15px"}}>
                                    <Form.Check 
                                        inline
                                        type={"radio"}
                                        id={`inline-radio`}
                                        label={`Nouveau patient`}
                                        value={"true"}
                                        name="is_new_client"
                                        onChange={(e) => {
                                            setIsNewClient(e.target.value)
                                        }}
                                        checked={isNewClient == "true"}
                                    />

                                    <Form.Check
                                        inline
                                        type={"radio"}
                                        label={`Patient My Laser`}
                                        id={`disabled-inline-radio`}
                                        value={"false"}
                                        name="is_new_client"
                                        onChange={(e) => {
                                            setIsNewClient(e.target.value)
                                        }}
                                        checked={isNewClient == "false"}

                                    />
                                </div>

                                <Picker
                                    options={["Glabelle", "Sourcils", "Ride péri-oculaires", "Tempes", "Cernes", "Pommettes", "Nez", "Joues", "Sillons nasogéniens", "Rides pèri-buccales", "Lèvres", "Ovale", "Menton", "Plis d'amertume"].map((e) => ({label: e, value: e}))}
                                    label="Zone de traitement"
                                    placeholder="Zone de traitement"
                                    onChange={(data) => setArea(data.value)}
                                    isSearchable={false}
                                />

                                <div style={{ width: "100%" }}>
                                    {/* <Picker
                                        options={[
                                            { value: 1, label: "1 ml" },
                                            { value: 2, label: "2 ml" },
                                            { value: 3, label: "3 ml" },
                                            { value: 4, label: "4 ml" },
                                            { value: 5, label: "5 ml" },
                                            { value: 6, label: "6 ml" },
                                            { value: 7, label: "7 ml" },
                                            { value: 8, label: "8 ml" },
                                            { value: 9, label: "9 ml" },
                                            { value: 10, label: "10 ml" },
                                        ]}
                                        value={injectedMl}
                                        label="Nombre de ml injecté"
                                        placeholder="Nombre de ml injecté"
                                        onChange={(data) => setInjectedMl(data)}
                                    /> */}

                                    <Input
                                        noIcon
                                        placeholder="Prix du traitement"
                                        label="Prix du traitement"
                                        value={injectedMlAH}
                                        onChange={(e) => {

                                            if (!e.target.value || e.target.value.match(/^\d{1,}(\.\d{0,2})?$/)) {
                                                setInjectedMlAH(e.target.value);
                                            }

                                        }}
                                    />

                                </div>

                            </>)}

                            {operation === "botox" && (<>

                                <Picker
                                    options={["Rides frontales", "Rides de la patte d'oie", "Rides du lion", "Rides de plissement du nez"].map((e) => ({label: e, value: e}))}
                                    label="Zone de traitement"
                                    placeholder="Zone de traitement"
                                    onChange={(data) => setArea(data.value)}
                                    isSearchable={false}
                                />

                                <div style={{ width: "100%" }}>
                                    <Picker
                                        options={[
                                            { value: 1, label: "1" },
                                            { value: 2, label: "2" },
                                            { value: 3, label: "3" },
                                            { value: 4, label: "4" },
                                            { value: 5, label: "5" }
                                        ]}
                                        value={injectedMl}
                                        label="Nombre de ml injecté"
                                        placeholder="Nombre de ml injecté"
                                        onChange={(data) => setInjectedMl(data)}
                                    />
                                </div>

                            </>)}

                            {operation === "hyacorp" && (<>

                                <div style={{ width: "100%" }}>
                                    <Picker
                                        options={[
                                            { value: 1, label: "100 ml (50 ml par fesse)" },
                                            { value: 2, label: "200 ml (100 ml par fesse)" },
                                            { value: 3, label: "300 ml (150 ml par fesse)" },
                                            { value: 4, label: "400 ml (200 ml par fesse)" },
                                        ]}
                                        value={injectedMl}
                                        label="Nombre de ml injecté"
                                        placeholder="Nombre de ml injecté"
                                        onChange={(data) => setInjectedMl(data)}
                                    />
                                </div>

                            </>)}

                            {operation === "unknow" && (<>

                                {/* <div style={{ width: "100%" }}>
                                    <input />
                                </div> */}

                                <div style={{ width: "100%" }}>
                                    <Picker
                                        options={[
                                            "Varicosite/couperose",
                                            "Laser Frax", 
                                            "Acné active", 
                                            "Taches brune/mêlasma/lentigo",
                                            "Détatouage", 
                                            "Hair bleaching", 
                                            "Carbon peel", 
                                            "Laser frax rejuvenation",
                                            "Endolift"
                                        ].map((el) => {
                                            return { value: el, label: el }
                                        })}
                                        value={superLabel}
                                        label="Type de traitement"
                                        placeholder="Type de traitement"
                                        onChange={(data) => setSuperLabel(data)}
                                    />

                                    <Input
                                        noIcon
                                        placeholder="Prix du traitement"
                                        label="Prix du traitement"
                                        value={price}
                                        onChange={(e) => {

                                            if (!e.target.value || e.target.value.match(/^\d{1,}(\.\d{0,2})?$/)) {
                                                setPrice(e.target.value);
                                            }

                                        }}
                                    />

                                </div>

                            </>)}

                            <Input
                                textArea
                                noIcon
                                className="multiline"
                                placeholder="Petite rougeur au niveau du nez"
                                label="Note"
                                value={note}
                                onChange={(e) => {
                                    setNote(e.target.value);
                                }}
                            />

                        </div>
                    </Col>
                </div>
            </Col>

            <Col xs={4} className="col">

                <Col className="mt-4" style={{ width: "80%" }}>

                    {
                        [
                            "peeling",
                            "ah",
                            "botox"
                        ].includes(operation) && (

                            <div style={{ width: "100%" }}>
                            
                                <ImageListUpload value={etiquetteTracage} onChange={(imageList) => {
                                    setEtiquetteTracage(imageList)
                                }} label="Ajouter l'étiquette de traçage" showButton={etiquetteTracage.length === 0} />

                            </div>
                            
                        )
                    }

                    <hr />

                    <Text className="label">Pièces Jointes :</Text>


                    <div style={{ width: "100%" }}>
                        <ImageListUpload  value={images} onChange={(imageList) => {
                            setImages(imageList)
                        }} label="Ajouter une photo" />
                    </div>

                    <br />
                    <br />
                    <hr />
                    <br />
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                    }} >
                        <Button
                            disabled={

                                (() => {

                                    if (operation === "unknow") {
                                        if (price == 0 || superLabel == null) {
                                            return true
                                        }
                                    }

                                    if (user.role === 1) {
                                        return date == null || hour == null || employee == null
                                    } else {
                                        return date == null || hour == null
                                    }

                                })()

                            }
                            style={{ width: "100%" }}
                            onClick={async () => {

                                let attachments = await S3Handler.uploadFromList(images)

                                let hh = hour.split(":")

                                date.setHours(hh[0])
                                date.setMinutes(hh[1])

                                let obj = {
                                    date: date,
                                    type: operation,
                                    type_human: label,
                                    area: area || "-",
                                    data: {
                                        note: note,
                                    },
                                    attachments: attachments
                                }

                                if (user.role === 1) {
                                    obj["employee_id"] = employee
                                }

                                if (operation === "hydrafacial") {
                                    obj.data = {...obj.data, ...datum}

                                    if (datum.type.value == 0) {
                                        obj.forced_price = 0
                                    } else if (datum.type.value == 1) {
                                        obj.forced_price = 180
                                    } else if (datum.type.value == 2) {
                                        obj.forced_price = 220
                                    } else if (datum.type.value == 3) {
                                        obj.forced_price = 280
                                    } else if (datum.type.value == 4) {
                                        obj.forced_price = 500
                                    } else if (datum.type.value == 5) {
                                        obj.forced_price = 280
                                    } else if (datum.type.value == 6) {
                                        obj.forced_price = 290
                                    }

                                    obj.area = datum.type.label

                                } else if (operation === "prx") {
                                    obj.data = {...obj.data, ...datum}

                                    if (datum.type.value == 0) {
                                        obj.forced_price = 0
                                    } else if (datum.type.value == 1) {
                                        obj.forced_price = 190
                                    }

                                    obj.area = datum.type.label

                                } else if (operation === "ah") {
                                    obj.data = {...obj.data, injection: injectedMlAH + " ml"}

                                    let price = 0

                                    for (let index = 1; index <= Math.ceil(injectedMlAH); index++) {

                                        if (index == 1) {
                                            if (isNewClient) {
                                                price += 350
                                            } else {
                                                price += 250
                                            }
                                        } else if (index == 2) {
                                            price += 250
                                        } else {
                                            price += 200
                                        }

                                    }   

                                    obj.forced_price = price

                                } else if (operation === "botox") {
                                    obj.data = {...obj.data, injection: injectedMl.label}

                                    let price = 0

                                    for (let index = 1; index <= injectedMl.value; index++) {
                                        price += 350
                                    }

                                    obj.forced_price = price
                                } else if (operation === "hyacorp") {
                                    obj.data = {...obj.data, injection: injectedMl.label}

                                    if (injectedMl.value == 1) {
                                        obj.forced_price = 2500
                                    } else if (injectedMl.value == 2) {
                                        obj.forced_price = 3100
                                    } else if (injectedMl.value == 3) {
                                        obj.forced_price = 4250
                                    } else if (injectedMl.value == 4) {
                                        obj.forced_price = 5400
                                    }

                                }

                                if (etiquetteTracage.length !== 0) {
                                    let etiquette = await S3Handler.uploadFromList(etiquetteTracage)
                                    obj.data = {...obj.data, etiquette: etiquette[0]}
                                }

                                if (operation == "unknow") {
                                    obj.data = {}
                                    obj.forced_price = price
                                    obj.type_human = superLabel.value
                                }

                                MyLaserApi.PostAgenciesClientsIdSessions(
                                    params.id,
                                    obj
                                ).then(() => {
                                    history.goBack()
                                });

                            }}
                        >
                            Étape suivante
                        </Button>

                    </div>
                    
                </Col>

            </Col>

        </Row>

    );
}

export default CreateSession;
