import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container, Col, Row, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
// import Calendar from "react-calendar";
// import "react-calendar/dist/Calendar.css";

import Button from "../../../components/Button";
import Picker from "../../../components/Picker";
import Input from "../../../components/Input";
import EmployeePicker from "../../../components/EmployeePicker";
import HourPicker from "../../../components/HourPicker";
import ImageListUpload from "../../../components/ImageListUpload";

import { useHistory, useParams, useLocation } from "react-router-dom";

import SessionHeader from "../../../components/SessionHeader";

//api
import { v4 as uuidv4 } from "uuid";
import { setDate } from "date-fns";
import MyLaserApi from "../../../api/MyLaserApi";

const Text = styled.span`
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 14px;
    &.label {
        font-family: CenturyGothicBold;
        color: #6b7389;
        font-size: 15px;
        margin-bottom: 20px;
    }
    &.selected {
        font-family: CenturyGothicBold;
        font-size: 16px;
        margin-bottom: 20px;
        text-decoration-line: underline;
    }
    &.unselected {
        font-family: CenturyGothicBold;
        font-size: 16px;
        margin-bottom: 20px;
        color: #9fa9ae;
    }
`;

const MyButton2 = styled.button`
    background: white;
    margin: 5px;
    display: flex;
    align-items: center;
    padding-top: 35px;
    padding-bottom: 35px;
    width: 100%;
    color: rgb(16, 41, 53);
    text-transform: uppercase;
    border: 3px solid rgb(240, 181, 148, 0);
    box-shadow: rgba(0, 0, 0, 0.05) 1px 1px 5px 5px;
    position: relative;

    &.selected {
        /* background: #f5f5f5; */
        color: rgb(240, 181, 148);
        border: 3px solid rgb(240, 181, 148);
    }

    &.selected span {
        color: rgb(240, 181, 148);
    }
`;

function generateTreament({
    data,
    type,
    type_human,
    stype,
    treatmentObj = {},
}) {
    return {
        type: type,
        type_human: type_human,
        stype: stype,
        forced_price: data.price,
        more: data.free ? "Offerte" : null,
        ...treatmentObj,
    };
}

function generateSessions({
    data,
    type,
    type_human,
    stype,
    sessionObj = {},
    subdata = {},
}) {
    let uniqid = uuidv4();

    let sessions = [];

    for (let i = 0; i < data.session_count; i++) {
        let sprice = Math.floor(data.price / data.session_count);

        if (data.session_count == i + 1) {
            sprice = data.price - sprice * i;
        }

        sessions.push({
            date: new Date(1970, 6, 6),
            type: type,
            type_human: type_human,
            stype: stype,
            area: (() => {
                if (data.free) {
                    return "Offerte";
                } else {
                    return "-";
                }
            })(),
            data: subdata,
            attachments: [],
            forced_price: sprice,
            uniqid: uniqid,
            // treatment_id: treatment.id,
            employee_id: 0,
            ...sessionObj,
        });
    }

    return sessions;
}

function generateTreamentAndSessions({
    data,
    type,
    type_human,
    stype,
    sessionObj = {},
    treatmentObj = {},
    subdata = {},
}) {
    let uniqid = uuidv4();

    let treatment = {
        type: type,
        type_human: type_human,
        stype: stype,
        forced_price: data.free === true ? 0 : data.price,
        more: data.free ? "Offerte" : null,
        ...treatmentObj,
    };

    let sessions = [];

    for (let i = 0; i < data.session_count; i++) {
        let sprice = Math.floor(
            data.free === true ? 0 : data.price / data.session_count
        );

        if (data.session_count == i + 1) {
            sprice = (data.free === true ? 0 : data.price) - sprice * i;
        }

        sessions.push({
            date: new Date(1970, 6, 6),
            type: type,
            type_human: type_human,
            stype: stype,
            area: (() => {
                if (data.free) {
                    return "Offerte";
                } else {
                    return "-";
                }
            })(),
            data: subdata,
            attachments: [],
            forced_price: sprice,
            uniqid: uniqid,
            // treatment_id: treatment.id,
            employee_id: 0,
            ...sessionObj,
        });
    }

    return { treatment, sessions };
}

const MyInput = styled.input`
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    font-family: CenturyGothicRegular;
    font-size: 14px;
    color: #102935;
`;

function MyButton({ title, subtitle, selected, onClick, padding = "35px" }) {
    return (
        <MyButton2
            className={`${selected ? "selected" : ""}`}
            onClick={onClick}
            style={{
                height: "100%",
                width: "100%",
                paddingTop: padding,
                paddingBottom: padding,
            }}
        >
            <Text className="label" style={{ margin: "auto" }}>
                {title}
            </Text>
            {subtitle && (
                <span
                    style={{
                        position: "absolute",
                        right: 0,
                        left: 0,
                        bottom: 10,
                        margin: "auto",
                        fontSize: "12px",
                    }}
                >
                    {subtitle}
                </span>
            )}
        </MyButton2>
    );
}

function TitleWithSubTitle({ title, subtitle, top, bottom }) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                textAlign: "left",
                alignSelf: "flex-start",
                marginTop: top ? 30 : 0,
                marginBottom: bottom ? 30 : 0,
                color: "black",
            }}
        >
            <Text className="label" style={{ marginBottom: 0, color: "black" }}>
                {title}
            </Text>
            <Text
                style={{ color: "#6b7389", fontSize: "12px", color: "black" }}
            >
                {subtitle}
            </Text>
        </div>
    );
}

function Validate({ onClick, disabled = false, more = "" }) {
    return (
        <Row style={{ marginTop: "35px" }}>
            <Col xs={9} className="col"></Col>

            <Col xs={3} className="col">
                <Button
                    disabled={disabled}
                    className={`${disabled ? "disabled" : ""}`}
                    style={{ width: "100%" }}
                    onClick={onClick}
                >
                    Créer {more}
                </Button>
            </Col>
        </Row>
    );
}

function CreateSession() {
    const [label, setLabel] = useState("");
    const [type, setType] = useState("");

    const history = useHistory();
    const location = useLocation();
    const params = useParams();

    return (
        <Container fluid>
            <SessionHeader
                title={`Créer un devis (${
                    type === "" ? "Choix du type" : type
                })`}
                text={label}
                goBack={() => history.goBack()}
                showReturn={type != ""}
                return={() => {
                    setType("");
                }}
            />

            {type === "" && (
                <Row>
                    <Col xs={2} className="col">
                        {" "}
                    </Col>

                    <Col xs={8} className="col">
                        <Row>
                            {(params.type === "soins"
                                ? [
                                      "Mésothérapie",
                                      "Peeling Intime",
                                      "Hair bleaching",
                                      "Laser Frax (ME)",
                                      "PRP",
                                      "Hollywood Peel",
                                      "Cryolipolise",
                                  ]
                                : [
                                      "Injection AH",
                                      "Autre Injection",
                                      "Peeling TCA",
                                      "VARICOSITÉ/COUPEROSE",
                                      "LASER FRAX",
                                      "ACNÉ ACTIVE",
                                      "TÂCHES BRUNE/MELASMA/LENTIGO",
                                      "DÉTATOUAGE",
                                  ]
                            ).map((name) => {
                                return (
                                    <Col xs={4} className="col">
                                        <MyButton2
                                            onClick={() => setType(name)}
                                        >
                                            <Text
                                                className="label"
                                                style={{
                                                    margin: "auto",
                                                    color: "black",
                                                }}
                                            >
                                                {name === "Autre Injection"
                                                    ? "Injection Botox"
                                                    : name}
                                            </Text>
                                        </MyButton2>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Col>

                    <Col xs={2} className="col"></Col>
                </Row>
            )}

            {type !== "" && (
                <Row>
                    <Col
                        xs={12}
                        className="col"
                        style={{ paddingLeft: "50px", paddingRight: "50px" }}
                    >
                        {type === "Mésothérapie" && (
                            <Mesotherapie
                                type={"mesotherapy"}
                                type_human={type}
                            />
                        )}
                        {type === "Hydrafacial" && (
                            <Hydrafacial type="hydrafacial" type_human={type} />
                        )}
                        {type === "Peeling Intime" && (
                            <PellingIntime
                                type="peeling-intimate"
                                type_human={type}
                            />
                        )}
                        {type === "PRX" && <PRX type="prx" type_human={type} />}
                        {type === "Laser Frax" && (
                            <LaserFrax type={"laser-frax"} type_human={type} />
                        )}
                        {type === "Laser Frax (ME)" && (
                            <LaserFraxMes
                                type={"laser-frax-mes"}
                                type_human={type}
                                stype={"soins"}
                            />
                        )}
                        {type === "PRP" && (
                            <PRP type={"prp"} type_human={type} />
                        )}
                        {type === "Hollywood Peel" && (
                            <CarbonPeel
                                type={"carbon-peel"}
                                type_human={type}
                            />
                        )}
                        {type === "Cryolipolise" && (
                            <Cryolipolise
                                type={"cryolipolise"}
                                type_human={type}
                            />
                        )}
                        {type === "Hair bleaching" && (
                            <HairBleaching
                                type="hair-bleaching"
                                type_human={type}
                            />
                        )}
                        {type === "Blemiderm" && (
                            <Blemiderm type="blemiderm" type_human={type} />
                        )}
                        {type === "PERI-OCULAIRE + MÉSOTHÉRAPIE" && (
                            <PeriOculaireMeso
                                type="peri-oculaire-meso"
                                type_human={type}
                            />
                        )}

                        {type === "Injection AH" && (
                            <InjectionAH type={"ah"} type_human={type} />
                        )}
                        {type === "Autre Injection" && (
                            <InjectionBotox type={"botox"} type_human={type} />
                        )}
                        {type === "Peeling TCA" && (
                            <PeelingTCA type={"peeling"} type_human={type} />
                        )}
                        {type === "VARICOSITÉ/COUPEROSE" && (
                            <VaricositeCouperose
                                type={"varicosite-couperose"}
                                type_human={type}
                            />
                        )}
                        {type === "LASER FRAX" && (
                            <LaserFraxMes
                                type={"laser-frax-mes"}
                                type_human={type}
                                stype={"med"}
                            />
                        )}
                        {type === "ACNÉ ACTIVE" && (
                            <AcneActive
                                type={"acne-active"}
                                type_human={type}
                            />
                        )}
                        {type === "TÂCHES BRUNE/MELASMA/LENTIGO" && (
                            <TachesBrune
                                type={"taches-brune"}
                                type_human={type}
                            />
                        )}
                        {type === "DÉTATOUAGE" && (
                            <Detatouage type={"detatouage"} type_human={type} />
                        )}
                        {type === "ENDOLIFT" && (
                            <Endolift type={"endolift"} type_human={type} />
                        )}
                        {type === "Dermamelan" && (
                            <Dermamelan type={"dermamelan"} type_human={type} />
                        )}
                    </Col>
                </Row>
            )}
        </Container>
    );
}

function PeriOculaireMeso({ type, type_human }) {
    const history = useHistory();
    const location = useLocation();

    const [data, setData] = useState({
        session_count: 1,
        free: false,
        price: 250,
    });

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                textAlign: "left",
                alignSelf: "flex-start",
                width: "100%",
            }}
        >
            <TitleWithSubTitle
                title="Quel rythme de traitement souhaitez-vous suivre ?"
                subtitle="Cliquez sur votre choix."
            />

            <Row>
                <Col xs={4} className="col">
                    <MyButton
                        title="À LA SÉANCE"
                        subtitle="180€"
                        onClick={() =>
                            setData({ ...data, session_count: 1, price: 180 })
                        }
                        selected={data.session_count === 1}
                    />
                </Col>

                <Col xs={4} className="col">
                    <MyButton
                        title="FORFAIT 3 SÉANCES"
                        subtitle="490€"
                        onClick={() =>
                            setData({ ...data, session_count: 3, price: 490 })
                        }
                        selected={data.session_count === 3}
                    />
                </Col>

                <Col xs={4} className="col">
                    <MyButton
                        title="FORFAIT 5 SÉANCES"
                        subtitle="690€"
                        onClick={() =>
                            setData({ ...data, session_count: 5, price: 690 })
                        }
                        selected={data.session_count === 5}
                    />
                </Col>
            </Row>

            <Validate
                onClick={() => {
                    // console.log(data)
                    let { treatment, sessions } = generateTreamentAndSessions({
                        data,
                        type,
                        type_human,
                        stype: "soins",
                    });

                    treatment.sessions = sessions;

                    history.push(location.pathname + "-validate", {
                        treatment,
                    });
                }}
            />
        </div>
    );
}

function Blemiderm({ type, type_human }) {
    const history = useHistory();
    const location = useLocation();

    const [data, setData] = useState({
        session_count: 1,
        free: false,
        price: 250,
    });

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                textAlign: "left",
                alignSelf: "flex-start",
                width: "100%",
            }}
        >
            <TitleWithSubTitle
                title="Quel rythme de traitement souhaitez-vous suivre ?"
                subtitle="Cliquez sur votre choix."
            />

            <Row>
                <Col xs={2} className="col"></Col>

                <Col xs={4} className="col">
                    <MyButton
                        title="À LA SÉANCE"
                        subtitle="250€ (Skincare non inclu)"
                        onClick={() =>
                            setData({ ...data, session_count: 1, price: 250 })
                        }
                        selected={data.session_count === 1}
                    />
                </Col>

                <Col xs={4} className="col">
                    <MyButton
                        title="FORFAIT 3 SÉANCES"
                        subtitle="580€ (Skincare inclu)"
                        onClick={() =>
                            setData({ ...data, session_count: 3, price: 580 })
                        }
                        selected={data.session_count === 3}
                    />
                </Col>

                <Col xs={2} className="col"></Col>
            </Row>

            <Validate
                onClick={() => {
                    // console.log(data)
                    let { treatment, sessions } = generateTreamentAndSessions({
                        data,
                        type,
                        type_human,
                        stype: "soins",
                    });

                    treatment.sessions = sessions;

                    history.push(location.pathname + "-validate", {
                        treatment,
                    });
                }}
            />
        </div>
    );
}

function Dermamelan({ type, type_human }) {
    const history = useHistory();
    const location = useLocation();

    const [data, setData] = useState({
        session_count: 3,
        free: false,
        price: 1120,
    });

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                textAlign: "left",
                alignSelf: "flex-start",
                width: "100%",
            }}
        >
            <TitleWithSubTitle
                title="Quel rythme de traitement souhaitez-vous suivre ?"
                subtitle="Cliquez sur votre choix."
            />

            <Row>
                <Col xs={2} className="col"></Col>

                <Col xs={4} className="col">
                    <MyButton
                        title="1 TRAITEMENT COMPLET"
                        subtitle="1120€"
                        onClick={() =>
                            setData({ ...data, session_count: 3, price: 1120 })
                        }
                        selected={data.session_count === 3}
                    />
                </Col>

                {/* <Col xs={4} className="col">
                <MyButton title="FORFAIT 5 SÉANCES" subtitle="640€" onClick={() => setData({...data, session_count: 5, free: false, price: 640})} selected={data.session_count === 5} />
            </Col> */}

                <Col xs={2} className="col"></Col>
            </Row>

            <Validate
                onClick={() => {
                    // console.log(data)
                    let { treatment, sessions } = generateTreamentAndSessions({
                        data,
                        type,
                        type_human,
                        stype: "med",
                    });

                    treatment.sessions = sessions;

                    history.push(location.pathname + "-validate", {
                        treatment,
                    });
                }}
            />
        </div>
    );
}

function Mesotherapie({ type, type_human }) {
    const history = useHistory();
    const location = useLocation();

    const [data, setData] = useState({
        session_count: 1,
        free: false,
        price: 1500,
    });

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                textAlign: "left",
                alignSelf: "flex-start",
                width: "100%",
            }}
        >
            <TitleWithSubTitle
                title="Quel rythme de traitement souhaitez-vous suivre ?"
                subtitle="Cliquez sur votre choix."
            />

            <Row>
                <Col xs={2} className="col"></Col>

                <Col xs={4} className="col">
                    <MyButton
                        title="À LA SÉANCE"
                        subtitle="1500 MAD"
                        onClick={() =>
                            setData({ ...data, session_count: 1, price: 1500 })
                        }
                        selected={data.session_count === 1}
                    />
                </Col>

                <Col xs={4} className="col">
                    <MyButton
                        title="FORFAIT 5 SÉANCES"
                        subtitle="5000 MAD"
                        onClick={() =>
                            setData({
                                ...data,
                                session_count: 5,
                                free: false,
                                price: 5000,
                            })
                        }
                        selected={data.session_count === 5}
                    />
                </Col>

                <Col xs={2} className="col"></Col>
            </Row>

            <TitleWithSubTitle
                title="Souhaitez vous appliquer une remise ?"
                subtitle="Cliquez sur votre choix."
                top={true}
            />

            <Row>
                <Col xs={2} className="col"></Col>

                <Col xs={4} className="col">
                    <MyButton
                        title="Partenariat"
                        onClick={() =>
                            setData({
                                ...data,
                                session_count: 1,
                                free: true,
                                price: 160,
                            })
                        }
                        subtitle="0 MAD"
                        selected={data.free}
                    />
                </Col>

                <Col xs={4} className="col">
                    <MyButton
                        title="Aucune"
                        subtitle=""
                        onClick={() => setData({ ...data, free: false })}
                        selected={!data.free}
                    />
                </Col>

                <Col xs={2} className="col"></Col>
            </Row>

            <Validate
                onClick={() => {
                    // console.log(data)
                    let { treatment, sessions } = generateTreamentAndSessions({
                        data,
                        type,
                        type_human,
                        stype: "soins",
                    });

                    treatment.sessions = sessions;

                    history.push(location.pathname + "-validate", {
                        treatment,
                    });
                }}
            />
        </div>
    );
}

function Hydrafacial({ type, type_human }) {
    const history = useHistory();
    const location = useLocation();

    const [data, setData] = useState({
        session_count: 1,
        free: false,
        price: 0,
        name: "HYDRAFACIAL SIGNATURE",
    });

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                textAlign: "left",
                alignSelf: "flex-start",
                width: "100%",
            }}
        >
            <TitleWithSubTitle
                title="Quel soin souhaitez-vous réaliser ? "
                subtitle="Cliquez sur votre choix."
            />

            <Row>
                <Col xs={2} className="col"></Col>

                <Col xs={3} className="col">
                    <MyButton
                        title={<>Signature HydraFacial®</>}
                        subtitle="180€"
                        onClick={() =>
                            setData({
                                ...data,
                                price: 180,
                                name: "Signature HydraFacial®",
                            })
                        }
                        selected={data.name === "Signature HydraFacial®"}
                    />
                </Col>

                <Col xs={3} className="col">
                    <MyButton
                        title={
                            <>
                                PLATINIUM HYDRAFACIAL <br />
                                (HYDRA + BOOSTER PERSONNALISÉ)
                            </>
                        }
                        subtitle="220€"
                        onClick={() =>
                            setData({
                                ...data,
                                free: false,
                                price: 220,
                                name: "Soin Platinium HydraFacial® (Hydrafacial + Booster personnalisé)",
                            })
                        }
                        selected={
                            data.name ===
                            "Soin Platinium HydraFacial® (Hydrafacial + Booster personnalisé)"
                        }
                    />
                </Col>

                <Col xs={3} className="col">
                    <MyButton
                        title={
                            <>
                                SIGNATURE <br />
                                MY LASER X HYDRAFACIAL
                                <br />
                                (HYDRA + MÉSO PERSONNALISÉE)
                            </>
                        }
                        subtitle="280€"
                        onClick={() =>
                            setData({
                                ...data,
                                free: false,
                                price: 280,
                                name: "Soin Signature My Laser x HydraFacial® ( Hydrafacial + Mésothérapie personnalisée)",
                            })
                        }
                        selected={
                            data.name ===
                            "Soin Signature My Laser x HydraFacial® ( Hydrafacial + Mésothérapie personnalisée)"
                        }
                    />
                </Col>

                <Col xs={2} className="col"></Col>
            </Row>

            <Row>
                <Col xs={2} className="col"></Col>

                <Col xs={3} className="col">
                    <MyButton
                        title={
                            <>
                                PRX X HYDRAFACIAL
                                <br /> (HYDRA + PRX)
                            </>
                        }
                        subtitle="280€"
                        onClick={() =>
                            setData({
                                ...data,
                                free: false,
                                price: 280,
                                name: "Soin Hydrafacial x PRX (Hydrafacial + PRX)",
                            })
                        }
                        selected={
                            data.name ===
                            "Soin Hydrafacial x PRX (Hydrafacial + PRX)"
                        }
                    />
                </Col>

                <Col xs={3} className="col">
                    <MyButton
                        title={
                            <>
                                DIAMANT <br />
                                MY LASER X HYDRAFACIAL <br />
                                (HYDRA + MÉSO PERSONNALISÉE + PRX)
                            </>
                        }
                        subtitle="290€"
                        onClick={() =>
                            setData({
                                ...data,
                                free: false,
                                price: 290,
                                name: "Soin Hydrafacial x Mésothérapie x PRX",
                            })
                        }
                        selected={
                            data.name ===
                            "Soin Hydrafacial x Mésothérapie x PRX"
                        }
                    />
                </Col>

                <Col xs={3} className="col">
                    <MyButton
                        title={<>HYDRAFACIAL KERAVIVE</>}
                        subtitle="500€"
                        onClick={() =>
                            setData({
                                ...data,
                                free: false,
                                price: 500,
                                name: "Keravive Hydrafacial",
                            })
                        }
                        selected={data.name === "Keravive Hydrafacial"}
                    />
                </Col>

                <Col xs={2} className="col"></Col>
            </Row>

            <TitleWithSubTitle
                title="Souhaitez vous appliquer une remise ?"
                subtitle="Cliquez sur votre choix."
                top={true}
            />

            <Row>
                <Col xs={2} className="col"></Col>

                <Col xs={4} className="col">
                    <MyButton
                        title={"Partenariat"}
                        subtitle="0€ uniquement hydrafacial signature"
                        onClick={() =>
                            setData({
                                ...data,
                                free: true,
                                name: "Signature HydraFacial®",
                            })
                        }
                        selected={data.free}
                    />
                </Col>

                <Col xs={4} className="col">
                    <MyButton
                        title="Aucune"
                        subtitle=""
                        onClick={() => setData({ ...data, free: false })}
                        selected={!data.free}
                    />
                </Col>

                <Col xs={2} className="col"></Col>
            </Row>

            <Validate
                onClick={() => {
                    let { treatment, sessions } = generateTreamentAndSessions({
                        data,
                        type,
                        type_human,
                        stype: "soins",
                        sessionObj: {
                            area: data.name,
                        },
                    });

                    treatment.sessions = sessions;

                    history.push(location.pathname + "-validate", {
                        treatment,
                    });
                }}
            />
        </div>
    );
}

function PellingIntime({ type, type_human }) {
    const [zones, setZones] = useState([
        {
            name: "Aisselles",
            selected: false,
            price: 1500,
            price2: 2500,
        },
        {
            name: "Zone Périanale",
            selected: false,
            price: 1500,
            price2: 2500,
        },
        // {
        //     name: "Genoux",
        //     selected: false,
        //     price: 390,
        //     price2: 490
        // },
        // {
        //     name: "Cou",
        //     selected: false,
        //     price: 390,
        //     price2: 490
        // },
        // {
        //     name: "Coude",
        //     selected: false,
        //     price: 290,
        //     price2: 390
        // },
        {
            name: "Aine",
            selected: false,
            price: 1500,
            price2: 2500,
        },
        {
            name: "Maillot",
            selected: false,
            price: 1500,
            price2: 2500,
        },
        {
            name: "Intérieur des cuisses",
            selected: false,
            price: 1500,
            price2: 2500,
        },
    ]);

    const history = useHistory();
    const location = useLocation();

    const [data, setData] = useState({
        session_count: 1,
        free: false,
        price: 0,
    });

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                textAlign: "left",
                alignSelf: "flex-start",
                width: "100%",
            }}
        >
            <Row>
                <Col xs={7} className="col">
                    <TitleWithSubTitle
                        title="Quel rythme de traitement souhaitez-vous suivre ?"
                        subtitle="Cliquez sur la ou les zones souhaitées."
                        bottom={true}
                    />

                    <Row style={{ width: "100%" }}>
                        <Col xs={2} className="col"></Col>
                        <Col xs={8} className="col">
                            <Row style={{ width: "100%" }}>
                                {zones.map((szone) => {
                                    return (
                                        <Col xs={6} className="col">
                                            <MyButton
                                                padding="20px"
                                                title={szone.name}
                                                selected={szone.selected}
                                                onClick={() => {
                                                    let _zones = zones.map(
                                                        (zone) => {
                                                            if (
                                                                zone.name ===
                                                                szone.name
                                                            ) {
                                                                zone.selected =
                                                                    !zone.selected;
                                                            }
                                                            return zone;
                                                        }
                                                    );
                                                    setZones(_zones);
                                                }}
                                            />
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Col>
                    </Row>

                    <TitleWithSubTitle
                        title="Quel rythme de traitement souhaitez-vous suivre ? "
                        subtitle="Cliquez sur votre choix."
                        top={true}
                        bottom={true}
                    />

                    <Row style={{ width: "100%" }}>
                        <Col xs={6} className="col">
                            <MyButton
                                title="1 SÉANCE"
                                onClick={() =>
                                    setData({ ...data, session_count: 1 })
                                }
                                selected={data.session_count === 1}
                            />
                        </Col>
                        <Col xs={6} className="col">
                            <MyButton
                                title="2 SÉANCES"
                                onClick={() =>
                                    setData({ ...data, session_count: 2 })
                                }
                                selected={data.session_count === 2}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Validate
                disabled={zones.filter((zone) => zone.selected).length === 0}
                onClick={() => {
                    let treatments = zones
                        .filter((zone) => zone.selected)
                        .map((treatment) => {
                            return generateTreament({
                                type,
                                type_human,
                                stype: "soins",
                                data: {
                                    price:
                                        data.session_count === 1
                                            ? treatment.price
                                            : treatment.price2,
                                },
                                treatmentObj: {
                                    more: treatment.name,
                                },
                            });
                        });

                    let sessions = zones
                        .filter((zone) => zone.selected)
                        .map((treatment) => {
                            return generateSessions({
                                type,
                                type_human,
                                stype: "soins",
                                data: {
                                    price:
                                        data.session_count === 1
                                            ? treatment.price
                                            : treatment.price2,
                                    session_count: data.session_count,
                                },
                                sessionObj: {
                                    area: treatment.name,
                                },
                            });
                        });

                    treatments = treatments.map((treatment, index) => {
                        return {
                            ...treatment,
                            sessions: sessions[index],
                        };
                    });

                    history.push(location.pathname + "-validate", {
                        treatments,
                    });
                }}
            />
        </div>
    );
}

function PRX({ type, type_human }) {
    const history = useHistory();
    const location = useLocation();

    const [data, setData] = useState({
        session_count: 1,
        free: false,
        price: 190,
    });

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                textAlign: "left",
                alignSelf: "flex-start",
                width: "100%",
            }}
        >
            <TitleWithSubTitle
                title="Quel rythme de traitement souhaitez-vous suivre ?"
                subtitle="Cliquez sur votre choix."
            />

            <Row>
                <Col xs={2} className="col"></Col>

                <Col xs={8} className="col">
                    <MyButton
                        title="À LA SÉANCE"
                        subtitle="190€"
                        onClick={() =>
                            setData({ ...data, price: 190, session_count: 1 })
                        }
                        selected={data.session_count === 1}
                    />
                </Col>

                <Col xs={2} className="col"></Col>
            </Row>

            <TitleWithSubTitle
                title="Souhaitez vous appliquer une remise ?"
                subtitle="Cliquez sur votre choix."
                top={true}
            />

            <Row>
                <Col xs={2} className="col"></Col>

                <Col xs={4} className="col">
                    <MyButton
                        title="Partenariat"
                        onClick={() =>
                            setData({ ...data, session_count: 1, free: true })
                        }
                        subtitle="0€"
                        selected={data.free}
                    />
                </Col>

                <Col xs={4} className="col">
                    <MyButton
                        title="Aucune"
                        subtitle=""
                        onClick={() => setData({ ...data, free: false })}
                        selected={!data.free}
                    />
                </Col>

                <Col xs={2} className="col"></Col>
            </Row>

            <Validate
                onClick={() => {
                    // console.log(data)
                    let { treatment, sessions } = generateTreamentAndSessions({
                        data,
                        type,
                        type_human,
                        stype: "soins",
                    });

                    treatment.sessions = sessions;

                    history.push(location.pathname + "-validate", {
                        treatment,
                    });
                }}
            />
        </div>
    );
}

function LaserFrax({ type, type_human }) {
    const history = useHistory();
    const location = useLocation();

    const [data, setData] = useState({
        session_count: 1,
        free: false,
        price: 280,
    });

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                textAlign: "left",
                alignSelf: "flex-start",
                width: "100%",
            }}
        >
            <TitleWithSubTitle
                title="Quel rythme de traitement souhaitez-vous suivre ?"
                subtitle="Cliquez sur votre choix."
            />

            <Row>
                <Col xs={3} className="col"></Col>

                <Col xs={6} className="col">
                    <MyButton
                        title="À LA SÉANCE"
                        subtitle="280€"
                        onClick={() =>
                            setData({ ...data, price: 280, session_count: 1 })
                        }
                        selected={data.session_count === 1}
                    />
                </Col>

                <Col xs={3} className="col"></Col>
            </Row>

            <Validate
                onClick={() => {
                    // console.log(data)
                    let { treatment, sessions } = generateTreamentAndSessions({
                        data,
                        type,
                        type_human,
                        stype: "soins",
                    });

                    treatment.sessions = sessions;

                    history.push(location.pathname + "-validate", {
                        treatment,
                    });
                }}
            />
        </div>
    );
}

function PRP({ type, type_human }) {
    const history = useHistory();
    const location = useLocation();

    const [data, setData] = useState({
        session_count: 1,
        free: false,
        price: 2500,
    });

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                textAlign: "left",
                alignSelf: "flex-start",
                width: "100%",
            }}
        >
            <TitleWithSubTitle
                title="Quel rythme de traitement souhaitez-vous suivre ?"
                subtitle="Cliquez sur votre choix."
                bottom={true}
            />

            <Row>
                <Col xs={1} className="col"></Col>

                <Col className="col">
                    <MyButton
                        title="À LA SÉANCE"
                        subtitle="2500 MAD"
                        onClick={() =>
                            setData({ ...data, price: 2500, session_count: 1 })
                        }
                        selected={data.session_count === 1}
                    />
                </Col>

                <Col className="col">
                    <MyButton
                        title="FORFAIT 3 SÉANCES"
                        subtitle="6000 MAD"
                        onClick={() =>
                            setData({ ...data, price: 6000, session_count: 3 })
                        }
                        selected={data.session_count === 3}
                    />
                </Col>

                <Col className="col">
                    <MyButton
                        title="FORFAIT 5 SÉANCES"
                        subtitle="8000 MAD"
                        onClick={() =>
                            setData({ ...data, price: 8000, session_count: 5 })
                        }
                        selected={data.session_count === 5}
                    />
                </Col>

                <Col xs={1} className="col"></Col>
            </Row>

            <Validate
                onClick={() => {
                    // console.log(data)
                    let { treatment, sessions } = generateTreamentAndSessions({
                        data,
                        type,
                        type_human,
                        stype: "soins",
                    });

                    treatment.sessions = sessions;

                    history.push(location.pathname + "-validate", {
                        treatment,
                    });
                }}
            />
        </div>
    );
}

function CarbonPeel({ type, type_human }) {
    const history = useHistory();
    const location = useLocation();

    const [data, setData] = useState({
        session_count: 1,
        free: false,
        price: 0,
    });

    const [zones, setZones] = useState([
        {
            name: "Mains",
            selected: false,
            price: 1000,
            price3: 4000,
        },
        {
            name: "Visage",
            selected: false,
            price: 1500,
            price3: 2500,
        },

        {
            name: "Mains + Visage",
            selected: false,
            price: 2500,
            price3: 6000,
        },
        // {
        //     name: "Cou",
        //     selected: false,
        //     price: 180,
        //     price3: 490
        // },
        // {
        //     name: "Fesses",
        //     selected: false,
        //     price: 230,
        //     price3: 600
        // },
        // {
        //     name: "Poitrine",
        //     selected: false,
        //     price: 180,
        //     price3: 490
        // }
    ]);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                textAlign: "left",
                alignSelf: "flex-start",
                width: "100%",
            }}
        >
            <TitleWithSubTitle
                title="Quel rythme de traitement souhaitez-vous suivre ?"
                subtitle="Cliquez sur votre choix."
                bottom={true}
            />

            <Row>
                <Col xs={2} className="col"></Col>

                <Col className="col">
                    <MyButton
                        title="À LA SÉANCE"
                        onClick={() => setData({ ...data, session_count: 1 })}
                        selected={data.session_count === 1}
                    />
                </Col>

                <Col className="col">
                    <MyButton
                        title="FORFAIT 3 SÉANCES"
                        onClick={() =>
                            setData({ ...data, session_count: 3, free: false })
                        }
                        selected={data.session_count === 3}
                    />
                </Col>

                <Col xs={2} className="col"></Col>
            </Row>

            <TitleWithSubTitle
                title="Souhaitez vous appliquer une remise ?"
                subtitle="Cliquez sur votre choix."
                top={true}
            />

            <Row>
                <Col xs={2} className="col"></Col>

                <Col xs={4} className="col">
                    <MyButton
                        title="Partenariat"
                        onClick={() =>
                            setData({ ...data, session_count: 1, free: true })
                        }
                        subtitle="0 MAD"
                        selected={data.free}
                    />
                </Col>

                <Col xs={4} className="col">
                    <MyButton
                        title="Aucune"
                        subtitle=""
                        onClick={() => setData({ ...data, free: false })}
                        selected={!data.free}
                    />
                </Col>

                <Col xs={2} className="col"></Col>
            </Row>

            <TitleWithSubTitle
                title="Quelles zones souhaitez-vous traiter ? "
                subtitle="Cliquez sur vos choix."
                top
                bottom={true}
            />

            <Row style={{ width: "100%" }}>
                {zones.map((szone) => {
                    return (
                        <Col className="col">
                            <MyButton
                                padding="20px"
                                title={szone.name}
                                selected={szone.selected}
                                onClick={() => {
                                    let _zones = zones.map((zone) => {
                                        if (zone.name === szone.name) {
                                            zone.selected = !zone.selected;
                                        }
                                        return zone;
                                    });
                                    setZones(_zones);
                                }}
                            />
                        </Col>
                    );
                })}
            </Row>

            <Validate
                disabled={zones.filter((zone) => zone.selected).length === 0}
                onClick={() => {
                    let treatments = zones
                        .filter((zone) => zone.selected)
                        .map((treatment) => {
                            return generateTreament({
                                type,
                                type_human,
                                stype: "soins",
                                data: {
                                    free: data.free,
                                    price: data.free
                                        ? 0
                                        : data.session_count === 1
                                        ? treatment.price
                                        : treatment.price3,
                                },
                                treatmentObj: {
                                    more: treatment.name,
                                },
                            });
                        });

                    let sessions = zones
                        .filter((zone) => zone.selected)
                        .map((treatment) => {
                            return generateSessions({
                                type,
                                type_human,
                                stype: "soins",
                                data: {
                                    free: data.free,
                                    price: data.free
                                        ? 0
                                        : data.session_count === 1
                                        ? treatment.price
                                        : treatment.price3,
                                    session_count: data.session_count,
                                },
                                sessionObj: {
                                    area: treatment.name,
                                },
                            });
                        });
                    //DONE
                    treatments = treatments.map((treatment, index) => {
                        return {
                            ...treatment,
                            sessions: sessions[index],
                        };
                    });

                    history.push(location.pathname + "-validate", {
                        treatments,
                    });
                }}
            />
        </div>
    );
}

function Cryolipolise({ type, type_human }) {
    const history = useHistory();
    const location = useLocation();

    const [data, setData] = useState({
        session_count: 1,
        free: false,
        price: 3000,
    });

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                textAlign: "left",
                alignSelf: "flex-start",
                width: "100%",
            }}
        >
            <TitleWithSubTitle
                title="Quel rythme de traitement souhaitez-vous suivre ?"
                subtitle="Cliquez sur votre choix."
                bottom={true}
            />

            <Row>
                <Col xs={3} className="col"></Col>

                <Col className="col">
                    <MyButton
                        title="1 SEANCE / 1 ZONE"
                        subtitle="3000 MAD"
                        onClick={() =>
                            setData({ ...data, price: 3000, session_count: 1 })
                        }
                        selected={data.session_count === 1}
                    />
                </Col>

                <Col className="col">
                    <MyButton
                        title="3 SEANCE / 1 ZONE"
                        subtitle="7000 MAD"
                        onClick={() =>
                            setData({ ...data, price: 7000, session_count: 3 })
                        }
                        selected={data.session_count === 3}
                    />
                </Col>

                <Col xs={3} className="col"></Col>
            </Row>

            <Validate
                onClick={() => {
                    // console.log(data)
                    let { treatment, sessions } = generateTreamentAndSessions({
                        data,
                        type,
                        type_human,
                        stype: "soins",
                    });

                    treatment.sessions = sessions;

                    history.push(location.pathname + "-validate", {
                        treatment,
                    });
                }}
            />
        </div>
    );
}

function InjectionBotox({ type, type_human }) {
    const [name, setName] = useState("Autre Injection");

    const history = useHistory();
    const location = useLocation();

    const [zones, setZones] = useState([
        {
            name: "RIDES DU LION",
            selected: false,
            price: 2800,
        },
        {
            name: "RIDES DE LA PATTE D'OIE",
            selected: false,
            price: 2800,
        },
        {
            name: "SOURIRE GINGIVAL",
            selected: false,
            price: 2800,
        },
    ]);

    const [data, setData] = useState({
        session_count: 1,
        free: false,
        price: 280,
    });

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                textAlign: "left",
                alignSelf: "flex-start",
                width: "100%",
            }}
        >
            <TitleWithSubTitle
                title="Quelles zone souhaitez-vous traiter ?"
                subtitle="Cliquez sur la ou les zones souhaitées"
            />

            <Row>
                <Col xs={4} className="col">
                    {zones.map((szone) => {
                        return (
                            <MyButton
                                title={szone.name}
                                subtitle={`${szone.price} MAD`}
                                selected={szone.selected}
                                onClick={() => {
                                    let _zones = zones.map((zone) => {
                                        if (zone.name === szone.name) {
                                            zone.selected = !zone.selected;
                                        }
                                        return zone;
                                    });
                                    setZones(_zones);
                                }}
                            />
                        );
                    })}

                    {/* <MyButton title="RIDES DE LA PATTE D'OIE" subtitle="350€" onClick={() => setName("RIDES DE LA PATTE D'OIE") || setData({...data, price: 350})} selected={name === "RIDES DE LA PATTE D'OIE"} /> */}
                    {/* <MyButton title="SOURIRE GINGIVAL" subtitle="350€" onClick={() => setName("SOURIRE GINGIVAL") || setData({...data, price: 350})} selected={name === "SOURIRE GINGIVAL"}  /> */}
                </Col>
            </Row>

            <Validate
                disabled={zones.filter((zone) => zone.selected).length === 0}
                onClick={() => {
                    let treatments = zones
                        .filter((zone) => zone.selected)
                        .map((treatment) => {
                            return generateTreament({
                                type,
                                type_human,
                                stype: "med",
                                data: {
                                    price:
                                        data.session_count === 1
                                            ? treatment.price
                                            : treatment.price2,
                                },
                                treatmentObj: {
                                    more: treatment.name,
                                },
                            });
                        });

                    let sessions = zones
                        .filter((zone) => zone.selected)
                        .map((treatment) => {
                            return generateSessions({
                                type,
                                type_human,
                                stype: "med",
                                data: {
                                    price:
                                        data.session_count === 1
                                            ? treatment.price
                                            : treatment.price2,
                                    session_count: data.session_count,
                                },
                                sessionObj: {
                                    area: treatment.name,
                                },
                            });
                        });

                    treatments = treatments.map((treatment, index) => {
                        return {
                            ...treatment,
                            sessions: sessions[index],
                        };
                    });

                    history.push(location.pathname + "-validate", {
                        treatments,
                    });
                }}
            />

            {/* <Validate onClick={() => {
            // console.log(data)
            let { treatment, sessions } = generateTreamentAndSessions({
                data, 
                type, 
                type_human,
                stype: "med",
                sessionObj: {
                    area: name
                },
                treatmentObj: {
                    more: name
                }
            })

            treatment.sessions = sessions
            
            history.push(location.pathname + "-validate", {treatment})

        }} /> */}
        </div>
    );
}

function InjectionAH({ type, type_human }) {
    const [zones, setZones] = useState([
        {
            name: "Lèvres",
            selected: false,
            price: 0,
        },
        {
            name: "Menton",
            selected: false,
            price: 0,
        },
        {
            name: "Jawline",
            selected: false,
            price: 0,
        },
        {
            name: "Cernes",
            selected: false,
            price: 0,
        },
        {
            name: "Nez",
            selected: false,
            price: 0,
        },
        {
            name: "Pomettes",
            selected: false,
            price: 0,
        },
        {
            name: "Sillon nasogenien",
            selected: false,
            price: 0,
        },
    ]);

    const [ml, setMl] = useState(0);

    const history = useHistory();
    const location = useLocation();

    const [data, setData] = useState({
        session_count: 1,
        free: false,
        price: 0,
        old_client: false,
    });

    function generatePrice() {
        let price = 0;

        for (let index = 0; index < parseInt(ml); index++) {
            if (index == 0) {
                if (data.old_client) {
                    price += 2500;
                } else {
                    price += 2500;
                }
            } else if (index == 1 || index == 2) {
                price += 2500;
            } else {
                price += 2500;
            }
        }

        return price;
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                textAlign: "left",
                alignSelf: "flex-start",
                width: "100%",
            }}
        >
            <Row>
                <Col xs={7} className="col">
                    <TitleWithSubTitle
                        title="Quelles zones souhaitez-vous traiter ?"
                        subtitle="Cliquez sur la ou les zones souhaitées."
                        bottom={true}
                    />

                    <Row style={{ width: "100%" }}>
                        <Col xs={2} className="col"></Col>
                        <Col xs={8} className="col">
                            <Row style={{ width: "100%" }}>
                                {zones.map((szone) => {
                                    return (
                                        <Col xs={6} className="col">
                                            <MyButton
                                                padding="20px"
                                                title={szone.name}
                                                selected={szone.selected}
                                                onClick={() => {
                                                    let _zones = zones.map(
                                                        (zone) => {
                                                            if (
                                                                zone.name ===
                                                                szone.name
                                                            ) {
                                                                zone.selected =
                                                                    !zone.selected;
                                                            }
                                                            return zone;
                                                        }
                                                    );
                                                    setZones(_zones);
                                                }}
                                            />
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Col>
                    </Row>

                    <TitleWithSubTitle
                        title="Combien de ml souhaitez vous injecter ? "
                        subtitle="Tapez votre choix dans l’espace dédié."
                        bottom
                        top
                    />

                    <Row style={{ width: "100%" }}>
                        <Col xs={6} className="col">
                            <MyInput
                                type="number"
                                placeholder="Tapez votre choix ici"
                                value={ml}
                                onChange={(e) => {
                                    const newNumber = e.target.value.toString();
                                    setMl(
                                        newNumber.substring(
                                            newNumber.length - 1
                                        )
                                    );
                                }}
                            />
                        </Col>
                    </Row>

                    <TitleWithSubTitle
                        title="Êtes-vous patiente My Laser ?"
                        subtitle="Cliquez sur votre choix."
                        top={true}
                        bottom={true}
                    />

                    <Row style={{ width: "100%" }}>
                        <Col xs={6} className="col">
                            <MyButton
                                title="OUI"
                                onClick={() =>
                                    setData({ ...data, old_client: true })
                                }
                                selected={data.old_client === true}
                            />
                        </Col>
                        <Col xs={6} className="col">
                            <MyButton
                                title="NON"
                                onClick={() =>
                                    setData({ ...data, old_client: false })
                                }
                                selected={data.old_client === false}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Validate
                disabled={
                    zones.filter((zone) => zone.selected).length === 0 ||
                    ml == 0
                }
                onClick={() => {
                    let { treatment, sessions } = generateTreamentAndSessions({
                        type,
                        type_human,
                        stype: "med",
                        data: {
                            session_count: 1,
                            price: generatePrice(),
                        },
                        treatmentObj: {
                            area: zones
                                .filter((el) => el.selected)
                                .map((el) => el.name)
                                .join(", "),
                            more: zones
                                .filter((el) => el.selected)
                                .map((el) => el.name)
                                .join(", "),
                        },
                        sessionObj: {
                            area: zones
                                .filter((el) => el.selected)
                                .map((el) => el.name)
                                .join(", "),
                            more: zones
                                .filter((el) => el.selected)
                                .map((el) => el.name)
                                .join(", "),
                        },
                        subdata: {
                            total_ml: ml,
                            injections: zones
                                .filter((el) => el.selected)
                                .map((el) => ({ area: el.name, ml: 0 })),
                        },
                    });

                    treatment.sessions = sessions;

                    history.push(location.pathname + "-validate", {
                        treatment,
                    });
                }}
            />
        </div>
    );
}

function PeelingTCA({ type, type_human }) {
    const history = useHistory();
    const location = useLocation();

    const [data, setData] = useState({
        session_count: 1,
        free: false,
        price: 200,
        area: "Visage",
    });

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                textAlign: "left",
                alignSelf: "flex-start",
                width: "100%",
            }}
        >
            <TitleWithSubTitle
                title="Quel rythme de traitement souhaitez-vous suivre ?"
                subtitle="Cliquez sur votre choix."
            />

            <Row>
                <Col xs={2} className="col"></Col>

                <Col xs={4} className="col">
                    <MyButton
                        title="À LA SÉANCE"
                        subtitle="1900 MAD"
                        onClick={() =>
                            setData({ ...data, session_count: 1, price: 1900 })
                        }
                        selected={data.session_count === 1}
                    />
                </Col>

                <Col xs={4} className="col">
                    <MyButton
                        title="FORFAIT 3 SÉANCES"
                        subtitle="5000 MAD"
                        onClick={() =>
                            setData({ ...data, session_count: 3, price: 5000 })
                        }
                        selected={data.session_count === 3}
                    />
                </Col>

                <Col xs={2} className="col"></Col>
            </Row>

            <Validate
                onClick={() => {
                    // console.log(data)
                    let { treatment, sessions } = generateTreamentAndSessions({
                        data,
                        type,
                        type_human,
                        stype: "med",
                        sessionObj: {
                            area: data.area,
                        },
                    });

                    treatment.sessions = sessions;

                    history.push(location.pathname + "-validate", {
                        treatment,
                    });
                }}
            />
        </div>
    );
}

function VaricositeCouperose({ type, type_human }) {
    const [name, setName] = useState("VARICOSITÉ");
    const [area, setArea] = useState("");

    const history = useHistory();
    const location = useLocation();

    const [data, setData] = useState({
        session_count: 1,
        free: false,
        price: 0,
    });

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                textAlign: "left",
                alignSelf: "flex-start",
                width: "100%",
            }}
        >
            <TitleWithSubTitle
                title="Quel traitement souhaitez-vous réaliser ?"
                subtitle="Cliquez sur votre choix."
                bottom
            />

            <Row>
                <Col xs={3} className="col">
                    <MyButton
                        title="VARICOSITÉ"
                        onClick={() => setName("VARICOSITÉ")}
                        selected={name === "VARICOSITÉ"}
                        padding="15px"
                    />
                </Col>

                <Col xs={3} className="col">
                    <MyButton
                        title="COUPEROSE"
                        onClick={() => setName("COUPEROSE")}
                        selected={name === "COUPEROSE"}
                        padding="15px"
                    />
                </Col>

                <Col xs={8} className="col"></Col>
            </Row>

            <TitleWithSubTitle
                title="Quelle zone souhaitez-vous traiter ? "
                subtitle="Tapez votre choix dans l’espace dédié."
                bottom
                top
            />

            <Row>
                <Col xs={6} className="col">
                    <MyInput
                        type="text"
                        placeholder="Tapez votre choix ici"
                        value={area}
                        onChange={(e) => setArea(e.target.value)}
                    />
                </Col>
            </Row>

            <TitleWithSubTitle
                title="Quel montant souhaitez-vous appliquer à ce traitement après examen ?"
                subtitle="Tapez votre choix dans l’espace dédié."
                bottom
                top
            />

            <Row>
                <Col xs={6} className="col">
                    <MyInput
                        type="text"
                        placeholder="Tapez votre choix ici"
                        value={data.price}
                        onChange={(e) => {
                            //add a regex that change price to number only and convert "," to "."
                            let number = e.target.value
                                .replace(/[^0-9.,]/g, "")
                                .replace(/,/g, ".");
                            setData({ ...data, price: number });
                        }}
                    />
                    <Text>(A partir de 1000 MAD)</Text>
                    {data.price < 1000 && (
                        <Text style={{ color: "red", fontSize: "12px" }}>
                            * Le prix selectionné est trop bas
                        </Text>
                    )}
                </Col>
            </Row>

            <Validate
                disabled={area === "" || data.price <= 0 || data.price < 1000}
                onClick={() => {
                    // console.log(data)
                    let { treatment, sessions } = generateTreamentAndSessions({
                        data,
                        type,
                        type_human,
                        stype: "med",
                        sessionObj: {
                            area: name + " - " + area,
                        },
                        treatmentObj: {
                            more: name + " - " + area,
                        },
                    });

                    treatment.sessions = sessions;

                    history.push(location.pathname + "-validate", {
                        treatment,
                    });
                }}
            />
        </div>
    );
}

function LaserFraxMes({ type, type_human, stype }) {
    const [name, setName] = useState("VERGETURE");
    const [area, setArea] = useState("");

    const history = useHistory();
    const location = useLocation();

    const client = useSelector((state) => state.client);

    const [data, setData] = useState({
        session_count: 1,
        free: false,
        price: 0,
    });

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                textAlign: "left",
                alignSelf: "flex-start",
                width: "100%",
            }}
        >
            <TitleWithSubTitle
                title="Quel traitement souhaitez-vous réaliser ?"
                subtitle="Cliquez sur votre choix."
                bottom
            />

            <Row>
                <Col xs={2} className="col">
                    <MyButton
                        title="VERGETURE"
                        onClick={() =>
                            setName("VERGETURE") ||
                            setData({ ...data, price: 0 }) ||
                            setArea("")
                        }
                        selected={name === "VERGETURE"}
                        padding="35px"
                    />
                </Col>

                <Col xs={2} className="col">
                    <MyButton
                        title="CICATRICE"
                        onClick={() =>
                            setName("CICATRICE") ||
                            setData({ ...data, price: 0 }) ||
                            setArea("")
                        }
                        selected={name === "CICATRICE"}
                        padding="35px"
                    />
                </Col>

                <Col xs={2} className="col">
                    <MyButton
                        title="CICATRICE D’ACNÉ VISAGE"
                        onClick={() =>
                            setName("CICATRICE D’ACNÉ VISAGE") ||
                            setData({ ...data, price: 2500 }) ||
                            setArea("Visage")
                        }
                        selected={name === "CICATRICE D’ACNÉ VISAGE"}
                        padding="35px"
                        subtitle={"2500 MAD"}
                    />
                </Col>

                <Col xs={2} className="col">
                    <MyButton
                        title="CICATRICE D’ACNÉ DOS"
                        onClick={() =>
                            setName("CICATRICE D’ACNÉ DOS") ||
                            setData({
                                ...data,
                                price: client.sexe === "homme" ? 3500 : 3500,
                            }) ||
                            setArea("Dos")
                        }
                        selected={name === "CICATRICE D’ACNÉ DOS"}
                        padding="35px"
                        subtitle={
                            (client.sexe === "homme" ? 3500 : 3500) + " MAD"
                        }
                    />
                </Col>

                <Col xs={4} className="col"></Col>
            </Row>

            <TitleWithSubTitle
                title="Quelle zone souhaitez-vous traiter ? "
                subtitle="Tapez votre choix dans l’espace dédié."
                bottom
                top
            />

            <Row>
                <Col xs={6} className="col">
                    <MyInput
                        type="text"
                        placeholder="Tapez votre choix ici"
                        value={area}
                        disabled={
                            name === "CICATRICE D’ACNÉ VISAGE" ||
                            name === "CICATRICE D’ACNÉ DOS"
                        }
                        onChange={(e) => setArea(e.target.value)}
                    />
                </Col>
            </Row>

            <TitleWithSubTitle
                title="Quel montant souhaitez-vous appliquer à ce traitement après examen ?"
                subtitle="Tapez votre choix dans l’espace dédié."
                bottom
                top
            />

            <Row>
                <Col xs={6} className="col">
                    <MyInput
                        type="text"
                        placeholder="Tapez votre choix ici"
                        value={data.price}
                        disabled={
                            name === "CICATRICE D’ACNÉ VISAGE" ||
                            name === "CICATRICE D’ACNÉ DOS"
                        }
                        onChange={(e) => {
                            //add a regex that change price to number only and convert "," to "."
                            let number = e.target.value
                                .replace(/[^0-9.,]/g, "")
                                .replace(/,/g, ".");
                            setData({ ...data, price: number });
                        }}
                    />
                    <Text>(Entre 2000 - 2500 MAD)</Text>
                    {data.price < 2000 && (
                        <Text style={{ color: "red", fontSize: "12px" }}>
                            * Le prix selectionné est trop bas
                        </Text>
                    )}
                </Col>
            </Row>

            <Validate
                disabled={area === "" || data.price <= 0 || data.price < 180}
                onClick={() => {
                    // console.log(data)
                    let { treatment, sessions } = generateTreamentAndSessions({
                        data,
                        type,
                        type_human,
                        stype: stype,
                        sessionObj: {
                            area: name + " - " + area,
                        },
                        treatmentObj: {
                            more: name + " - " + area,
                        },
                    });

                    treatment.sessions = sessions;

                    history.push(location.pathname + "-validate", {
                        treatment,
                    });
                }}
            />
        </div>
    );
}

function AcneActive({ type, type_human }) {
    const [name, setName] = useState("ACNÉ ACTIVE");

    const history = useHistory();
    const location = useLocation();

    const [data, setData] = useState({
        session_count: 1,
        free: false,
        price: 2500,
    });

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                textAlign: "left",
                alignSelf: "flex-start",
                width: "100%",
            }}
        >
            <TitleWithSubTitle
                title="Quel rythme de traitement souhaitez-vous suivre ?"
                subtitle="Cliquez sur votre choix."
            />

            <Row>
                <Col xs={2} className="col"></Col>

                <Col xs={4} className="col">
                    <MyButton
                        title="ACNÉ ACTIVE"
                        subtitle="2500 MAD"
                        onClick={() =>
                            setName("ACNÉ ACTIVE") ||
                            setData({ ...data, price: 2500 })
                        }
                        selected={name === "ACNÉ ACTIVE"}
                    />
                </Col>

                <Col xs={4} className="col">
                    <MyButton
                        title="ACNÉ ACTIVE + LASER FRAX"
                        subtitle="3500 MAD"
                        onClick={() =>
                            setName("ACNÉ ACTIVE + LASER FRAX") ||
                            setData({ ...data, price: 3500 })
                        }
                        selected={name === "ACNÉ ACTIVE + LASER FRAX"}
                    />
                </Col>

                <Col xs={2} className="col"></Col>
            </Row>

            <Validate
                onClick={() => {
                    // console.log(data)
                    let { treatment, sessions } = generateTreamentAndSessions({
                        data,
                        type,
                        type_human,
                        stype: "med",
                        sessionObj: {
                            area: name,
                        },
                        treatmentObj: {
                            more: name,
                        },
                    });

                    treatment.sessions = sessions;

                    history.push(location.pathname + "-validate", {
                        treatment,
                    });
                }}
            />
        </div>
    );
}

function TachesBrune({ type, type_human }) {
    const [name, setName] = useState("TÂCHE BRUNE");
    const [area, setArea] = useState("MAINS");

    const history = useHistory();
    const location = useLocation();

    const [data, setData] = useState({
        session_count: 1,
        free: false,
        price: 2000,
    });

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                textAlign: "left",
                alignSelf: "flex-start",
                width: "100%",
            }}
        >
            <TitleWithSubTitle
                title="Quel traitement souhaitez-vous réaliser ?"
                subtitle="Cliquez sur votre choix."
                bottom
            />

            <Row>
                <Col xs={3} className="col">
                    <MyButton
                        title="TÂCHE BRUNE"
                        onClick={() => setName("TÂCHE BRUNE")}
                        selected={name === "TÂCHE BRUNE"}
                        padding="15px"
                    />
                </Col>

                <Col xs={3} className="col">
                    <MyButton
                        title="MÉLASMA"
                        onClick={() => setName("MÉLASMA")}
                        selected={name === "MÉLASMA"}
                        padding="15px"
                    />
                </Col>

                <Col xs={3} className="col">
                    <MyButton
                        title="LENTIGO"
                        onClick={() => setName("LENTIGO")}
                        selected={name === "LENTIGO"}
                        padding="15px"
                    />
                </Col>
            </Row>

            <TitleWithSubTitle
                title="Quel traitement souhaitez-vous réaliser ?"
                subtitle="Cliquez sur votre choix."
                bottom
                top
            />

            <Row>
                <Col xs={3} className="col">
                    <MyButton
                        title="MAINS"
                        subtitle="2000 MAD"
                        onClick={() =>
                            setArea("MAINS") ||
                            setData({ ...data, price: 2000 })
                        }
                        selected={area === "MAINS"}
                        padding="35px"
                    />
                </Col>

                <Col xs={3} className="col">
                    <MyButton
                        title="VISAGE"
                        subtitle="2500 MAD"
                        onClick={() =>
                            setArea("VISAGE") ||
                            setData({ ...data, price: 2500 })
                        }
                        selected={area === "VISAGE"}
                        padding="35px"
                    />
                </Col>

                <Col xs={3} className="col">
                    <MyButton
                        title="MAINS + VISAGE"
                        subtitle="4000 MAD"
                        onClick={() =>
                            setArea("MAINS + VISAGE") ||
                            setData({ ...data, price: 4000 })
                        }
                        selected={area === "MAINS + VISAGE"}
                        padding="35px"
                    />
                </Col>

                <Col xs={3} className="col">
                    <MyButton
                        title="AUTRE ZONE*"
                        onClick={() =>
                            setArea("") || setData({ ...data, price: 0 })
                        }
                        selected={area === ""}
                        padding="35px"
                    />
                </Col>
            </Row>

            {area !== "MAINS" &&
                area !== "VISAGE" &&
                area !== "MAINS + VISAGE" && (
                    <>
                        <TitleWithSubTitle
                            title="Quelle zone souhaitez-vous traiter ? "
                            subtitle="Tapez votre choix dans l’espace dédié."
                            bottom
                            top
                        />

                        <Row>
                            <Col xs={6} className="col">
                                <MyInput
                                    type="text"
                                    placeholder="Tapez votre choix ici"
                                    value={area}
                                    onChange={(e) => setArea(e.target.value)}
                                />
                            </Col>
                        </Row>

                        <TitleWithSubTitle
                            title="Quel montant souhaitez-vous appliquer à ce traitement après examen ?"
                            subtitle="Tapez votre choix dans l’espace dédié."
                            bottom
                            top
                        />

                        <Row>
                            <Col xs={6} className="col">
                                <MyInput
                                    type="text"
                                    placeholder="Tapez votre choix ici"
                                    value={data.price}
                                    onChange={(e) => {
                                        //add a regex that change price to number only and convert "," to "."
                                        let number = e.target.value
                                            .replace(/[^0-9.,]/g, "")
                                            .replace(/,/g, ".");
                                        setData({ ...data, price: number });
                                    }}
                                />
                            </Col>
                        </Row>
                    </>
                )}

            <Validate
                disabled={area === "" || data.price <= 0}
                onClick={() => {
                    // console.log(data)
                    let { treatment, sessions } = generateTreamentAndSessions({
                        data,
                        type,
                        type_human,
                        stype: "med",
                        sessionObj: {
                            area: name + " - " + area,
                        },
                        treatmentObj: {
                            more: name + " - " + area,
                        },
                    });

                    treatment.sessions = sessions;

                    history.push(location.pathname + "-validate", {
                        treatment,
                    });
                }}
            />
        </div>
    );
}

function Detatouage({ type, type_human }) {
    const [area, setArea] = useState("");

    const [zones, setZones] = useState([
        {
            name: "SOURCIL",
            selected: false,
            price: 1000,
            price5: 4500,
        },
        {
            name: "S",
            subtitle: "jusqu’à 10cm",

            selected: false,
            price: 1000,
            price5: 5500,
        },
        {
            name: "M",
            subtitle: "11 à 30 cm",

            selected: false,
            price: 1500,
            price5: 7000,
        },
        {
            name: "L",
            subtitle: "31 à 50 cm",

            selected: false,
            price: 2000,
            price5: 9500,
        },
        {
            name: "XL",
            subtitle: "51 à 100 cm",

            selected: false,
            price: 2500,
            price5: 10000,
        },
        {
            name: "XXL",
            subtitle: "101 à 500 cm",

            selected: false,
            price: 3200,
            price5: 12000,
        },
    ]);

    const history = useHistory();
    const location = useLocation();

    const [data, setData] = useState({
        session_count: 1,
        free: false,
        price: 0,
    });

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                textAlign: "left",
                alignSelf: "flex-start",
                width: "100%",
            }}
        >
            <TitleWithSubTitle
                title="Quel traitement souhaitez-vous réaliser ?"
                subtitle="Cliquez sur votre choix."
                bottom
            />

            <Row>
                <Col xs={4} className="col">
                    <MyButton
                        title="À LA SÉANCE"
                        onClick={() => setData({ ...data, session_count: 1 })}
                        selected={data.session_count === 1}
                    />
                </Col>

                <Col xs={4} className="col">
                    <MyButton
                        title="FORFAIT 5 SÉANCES"
                        onClick={() => setData({ ...data, session_count: 5 })}
                        selected={data.session_count === 5}
                    />
                </Col>
            </Row>

            <TitleWithSubTitle
                title="Quelle zone souhaitez-vous traiter ? "
                subtitle="Tapez votre choix dans l’espace dédié."
                bottom
                top
            />

            <Row>
                <Col xs={6} className="col">
                    <MyInput
                        type="text"
                        placeholder="Tapez votre choix ici"
                        value={area}
                        onChange={(e) => setArea(e.target.value)}
                    />
                </Col>
            </Row>

            <TitleWithSubTitle
                title="Quelles est la taille du tatouage ? "
                subtitle="Tapez votre choix dans l’espace dédié."
                bottom
                top
            />

            <Row>
                {zones.map((szone) => {
                    return (
                        <Col className="col">
                            <MyButton
                                padding="35px"
                                title={szone.name}
                                subtitle={szone.subtitle}
                                selected={szone.selected}
                                onClick={() => {
                                    let _zones = zones.map((zone) => {
                                        if (zone.name === szone.name) {
                                            zone.selected = !zone.selected;
                                        } else {
                                            zone.selected = false;
                                        }
                                        return zone;
                                    });
                                    setZones(_zones);
                                }}
                            />
                        </Col>
                    );
                })}
            </Row>

            <Validate
                disabled={
                    zones.filter((zone) => zone.selected).length === 0 ||
                    area === ""
                }
                onClick={() => {
                    // console.log(data)
                    const zone = zones.filter((zone) => zone.selected)[0];

                    let treatments = [];

                    for (let index = 0; index < data.session_count; index++) {
                        let res = generateTreamentAndSessions({
                            data: {
                                free: false,
                                session_count: 1,
                                price:
                                    data.session_count === 1
                                        ? zone.price
                                        : zone.price5 / 5,
                            },
                            type,
                            type_human,
                            stype: "med",
                            sessionObj: {
                                area:
                                    area +
                                    " - " +
                                    zones.filter((zone) => zone.selected)[0]
                                        .name,
                            },
                            treatmentObj: {
                                more:
                                    area +
                                    " - " +
                                    zones.filter((zone) => zone.selected)[0]
                                        .name,
                            },
                        });

                        treatments[index] = res.treatment;
                        treatments[index].sessions = res.sessions;
                    }

                    history.push(location.pathname + "-validate", {
                        treatments,
                    });
                }}
            />
        </div>
    );
}

function Endolift({ type, type_human }) {
    const [zones, setZones] = useState([
        {
            name: "GENOUX",
            selected: false,
            price: 590,
        },
        {
            name: "DESSOUS BRAS",
            selected: false,
            price: 890,
        },
        {
            name: "DOUBLE MENTON",
            selected: false,
            price: 590,
        },
        {
            name: "VISAGE",
            selected: false,
            price: 1190,
        },
        {
            name: "ABDOMEN",
            selected: false,
            price: 1490,
        },
        {
            name: "NOMBRIL",
            selected: false,
            price: 590,
        },
        {
            name: "DOUBLE MENTON + COU",
            selected: false,
            price: 890,
        },
        {
            name: "COU",
            selected: false,
            price: 0,
        },
        {
            name: "JAWLINE",
            selected: false,
            price: 890,
        },
        {
            name: "VISAGE + COU",
            selected: false,
            price: 1490,
        },
        {
            name: "DÉCOLLETÉ",
            selected: false,
            price: 790,
        },
        {
            name: "INTÉRIEUR DES CUISSES",
            selected: false,
            price: 890,
        },
        {
            name: "PAUPIÈRES INFÉRIEURES",
            selected: false,
            price: 590,
        },
        {
            name: "FULL FACE",
            selected: false,
            price: 2290,
        },
    ]);

    const history = useHistory();
    const location = useLocation();

    const [data, setData] = useState({
        session_count: 1,
        free: false,
        price: 0,
        old_client: false,
    });

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                textAlign: "left",
                alignSelf: "flex-start",
                width: "100%",
            }}
        >
            <Row>
                <Col xs={12} className="col">
                    <TitleWithSubTitle
                        title="Quelles zones souhaitez-vous traiter ?"
                        subtitle="Cliquez sur la ou les zones souhaitées."
                        bottom={true}
                    />

                    <Row style={{ width: "100%" }}>
                        <Col xs={12} className="col">
                            <Row style={{ width: "100%" }}>
                                {zones.map((szone) => {
                                    return (
                                        <Col xs={3} className="col">
                                            <MyButton
                                                padding="20px"
                                                title={szone.name}
                                                selected={szone.selected}
                                                onClick={() => {
                                                    let _zones = zones.map(
                                                        (zone) => {
                                                            if (
                                                                zone.name ===
                                                                szone.name
                                                            ) {
                                                                zone.selected =
                                                                    !zone.selected;
                                                            }
                                                            return zone;
                                                        }
                                                    );
                                                    setZones(_zones);
                                                }}
                                            />
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Validate
                disabled={zones.filter((zone) => zone.selected).length === 0}
                onClick={() => {
                    let treatments = zones
                        .filter((zone) => zone.selected)
                        .map((treatment) => {
                            return generateTreament({
                                type,
                                type_human,
                                stype: "med",
                                data: {
                                    price:
                                        data.session_count === 1
                                            ? treatment.price
                                            : treatment.price2,
                                },
                                treatmentObj: {
                                    more: treatment.name,
                                },
                            });
                        });

                    let sessions = zones
                        .filter((zone) => zone.selected)
                        .map((treatment) => {
                            return generateSessions({
                                type,
                                type_human,
                                stype: "med",
                                data: {
                                    price:
                                        data.session_count === 1
                                            ? treatment.price
                                            : treatment.price2,
                                    session_count: data.session_count,
                                },
                                sessionObj: {
                                    area: treatment.name,
                                },
                            });
                        });

                    treatments = treatments.map((treatment, index) => {
                        return {
                            ...treatment,
                            sessions: sessions[index],
                        };
                    });

                    history.push(location.pathname + "-validate", {
                        treatments,
                    });
                }}
            />
        </div>
    );
}

function HairBleaching({ type, type_human }) {
    const history = useHistory();
    const location = useLocation();
    const client = useSelector((state) => state.client);

    const [items, setItems] = useState({
        body: [],
        face: [],
    });
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedPart, setSelectedPart] = useState("body");

    useEffect(() => {
        let sexe = "female";

        if (client.sexe === "homme") {
            sexe = "male";
        }

        Promise.all([
            MyLaserApi.call({
                method: "GET",
                url: `/api/quotations/items?gender=${sexe}&type=body`,
            }),
            MyLaserApi.call({
                method: "GET",
                url: `/api/quotations/items?gender=${sexe}&type=face`,
            }),
        ]).then(([body, face]) => {
            setItems({
                body: body.data,
                face: face.data,
            });
        });
    }, []);

    const [data, setData] = useState({
        session_count: 1,
        free: false,
        price: 0,
    });

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                textAlign: "left",
                alignSelf: "flex-start",
                width: "100%",
            }}
        >
            <Row style={{ marginBottom: "15px" }}>
                <Col xs={2} className="col">
                    <MyButton
                        title="CORPS"
                        onClick={() => setSelectedPart("body")}
                        selected={selectedPart === "body"}
                    />
                </Col>
                <Col xs={2} className="col">
                    <MyButton
                        title="VISAGE"
                        onClick={() => setSelectedPart("face")}
                        selected={selectedPart === "face"}
                    />
                </Col>
            </Row>

            <TitleWithSubTitle
                title="Quelles zone souhaitez-vous traiter ?"
                subtitle="Cliquez sur votre choix."
            />

            <Row style={{ display: selectedPart === "body" ? "flex" : "none" }}>
                {items.body.map((item) => {
                    return (
                        <Col xs={3} className="col">
                            <MyButton
                                title={item.name}
                                subtitle={item.price + " MAD"}
                                selected={selectedItems.includes(item.name)}
                                onClick={() => {
                                    let _selectedItems = selectedItems;

                                    if (selectedItems.includes(item.name)) {
                                        _selectedItems = selectedItems.filter(
                                            (selectedItem) =>
                                                selectedItem !== item.name
                                        );
                                    } else {
                                        _selectedItems.push(item.name);
                                    }

                                    setData({
                                        ...data,
                                        price: _selectedItems.reduce(
                                            (acc, selectedItem) => {
                                                let _item =
                                                    items.body.find(
                                                        (item) =>
                                                            item.name ===
                                                            selectedItem
                                                    ) ||
                                                    items.face.find(
                                                        (item) =>
                                                            item.name ===
                                                            selectedItem
                                                    );
                                                return acc + _item.price;
                                            },
                                            0
                                        ),
                                    });

                                    setSelectedItems([..._selectedItems]);
                                }}
                            />
                        </Col>
                    );
                })}
            </Row>

            <Row style={{ display: selectedPart === "face" ? "flex" : "none" }}>
                {items.face.map((item) => {
                    return (
                        <Col xs={3} className="col">
                            <MyButton
                                title={item.name}
                                subtitle={item.price + " €"}
                                selected={selectedItems.includes(item.name)}
                                onClick={() => {
                                    let _selectedItems = selectedItems;
                                    if (selectedItems.includes(item.name)) {
                                        _selectedItems = selectedItems.filter(
                                            (selectedItem) =>
                                                selectedItem !== item.name
                                        );
                                    } else {
                                        _selectedItems.push(item.name);
                                    }

                                    setData({
                                        ...data,
                                        price: _selectedItems.reduce(
                                            (acc, selectedItem) => {
                                                let _item =
                                                    items.body.find(
                                                        (item) =>
                                                            item.name ===
                                                            selectedItem
                                                    ) ||
                                                    items.face.find(
                                                        (item) =>
                                                            item.name ===
                                                            selectedItem
                                                    );
                                                return acc + _item.price;
                                            },
                                            0
                                        ),
                                    });

                                    setSelectedItems([..._selectedItems]);
                                }}
                            />
                        </Col>
                    );
                })}
            </Row>

            <Validate
                more={data.price + " €"}
                onClick={() => {
                    let treatment = generateTreament({
                        data,
                        type,
                        type_human,
                        stype: "soins",
                        treatmentObj: {
                            more: selectedItems.join(", "),
                        },
                    });

                    let sessions = selectedItems.map((sitem) => {
                        return generateSessions({
                            data,
                            type,
                            type_human,
                            stype: "soins",
                            sessionObj: {
                                area: sitem,
                                forced_price: (
                                    items.body.find(
                                        (item) => item.name === sitem
                                    ) ||
                                    items.face.find(
                                        (item) => item.name === sitem
                                    )
                                ).price,
                            },
                        })[0];
                    });

                    treatment.sessions = sessions;

                    history.push(location.pathname + "-validate", {
                        treatment,
                    });
                }}
            />
        </div>
    );
}

export default CreateSession;
