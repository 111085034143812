import React, { useState, useEffect } from 'react'
import Modal from "../../../components/Modal";
import { Container, Col, Row, Form } from "react-bootstrap";
import MyLaserApi from "../../../api/MyLaserApi";
import Button from "../../../components/Button";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid'

export default ({ show, setShow, callback }) => {

    const params = useParams()

    const [sessionIndex, setSessionIndex] = useState(null);

    const [sessionZone, setSessionZone] = useState("Aiselles");

    const [label, setLabel] = useState("")
 
    useEffect(() => {

        switch (show) {
            case "laser":
                return setLabel("Épilation Laser");
            case "mesotherapy":
                return setLabel("Soin Mésothérapie");
            case "hydrafacial":
                return setLabel("Soin Hydrafaciale");
            case "ah":
                return setLabel("Injection Acide Hyaluronique (AH)");
            case "botox":
                return setLabel("Injection Botox");
            case "hyacorp":
                return setLabel("Injection Hyacorp");
            case "peeling":
                return setLabel("Peeling TCA visage");
            case "hair":
                return setLabel("Traitement Capillaire");
            case "cryolipolyse":
                return setLabel("Cryolipolyse");
            case "peeling-intimate":
                return setLabel("Peeling Intime");
            case "prx":
                return setLabel("PRX");
            default:
                return setLabel("");
        }

    }, [show])

    let treatments = {
        mesotherapy: [
            {
                session_count: 1,
                free: true,
                price: 0,
            },
            {
                session_count: 1,
                free: false,
                price: 200,
            },
            {
                session_count: 5,
                free: false,
                price: 800,
            }
        ],

        prx: [
            {
                session_count: 1,
                free: true,
                price: 0,
            },
            {
                session_count: 1,
                free: false,
                price: 190,
            }
        ],

        peeling: [
            {
                session_count: 1,
                free: false,
                price: 200,
            },
            {
                session_count: 3,
                free: false,
                price: 580,
            }
        ],

        hair: [
            {
                session_count: 1,
                free: false,
                price: 390,
            },
            {
                session_count: 3,
                free: false,
                price: 1090,
            },
            {
                session_count: 5,
                free: false,
                price: 1490,
            }
        ],
        cryolipolyse: [
            {
                session_count: 3,
                free: false,
                price: 390,
                label: "1 Séance pour 1 zone (390€)"
            },
            {
                session_count: 3,
                free: false,
                price: 1090,
                label: "3 Séances pour 1 zone (1090€)"
            }
        ],
        "peeling-intimate": [
            {
                session_count: 1,
                free: false,
                price: 290,
                show: () => sessionZone === "Aiselles" || sessionZone === "Périanale"
            },
            {
                session_count: 2,
                free: false,
                price: 390,
                show: () => sessionZone === "Aiselles" || sessionZone === "Périanale"
            },

            {
                session_count: 1,
                free: false,
                price: 390,
                show: () => sessionZone === "Maillot" || sessionZone === "Aine"
            },
            {
                session_count: 2,
                free: false,
                price: 490,
                show: () => sessionZone === "Maillot" || sessionZone === "Aine"
            },

            {
                session_count: 1,
                free: false,
                price: 440,
                show: () => sessionZone === "Intérieur des cuisses"
            },
            {
                session_count: 2,
                free: false,
                price: 540,
                show: () => sessionZone === "Intérieur des cuisses"
            },
        ]
    }
    
    return (<Modal
        show={show !== false}
        onHide={() => setShow(false)}
        title={"Nouveau traitement " + label}
    >
        
        {(() => {

            let returns = []

            if (show === "peeling-intimate") {

                returns.push(<span>Zone que vous souhaitez :  </span>)

                returns.push(<select onChange={(e) => setSessionZone(e.target.value) && setSessionIndex(null)}> 
                    <option >Aiselles</option>
                    <option >Périanale</option>
                    <option >Maillot</option>
                    <option >Aine</option>
                    <option >Intérieur des cuisses</option>
                </select>, <br/>)

            }

            returns.push(<span>Nombre de séances que vous souhaitez :  </span>, <br/>)
                
            returns.push(<div key={`default-radio`} className="mb-3" style={{marginTop: "15px"}}>

                {

                    treatments[show] && treatments[show].map((treatment, index) => {

                        if (treatment.show && treatment.show() === false) {
                            return
                        }

                        returns.push(<Form.Check 
                            type={"radio"}
                            id={show + "-" + index}
                            label={(() => {

                                if (treatment.label) {
                                    return treatment.label
                                }

                                return `${treatment.session_count} Séance${treatment.session_count > 1 ? "s" : ""} ${treatment.free ? "Offerte" : ""} (${treatment.price}${global.currencySymbol})`

                            })()}
                            value={show + "-" + index}
                            name="session_count"
                            onChange={(e) => setSessionIndex(index)}
                            checked={sessionIndex === index}
                        />)

                    })

                }

            </div>)
            
            return returns

        })()}

        <Button 
            style={{ width: "100%" }}
            disabled={sessionIndex === null}
            onClick={async () => {

                let promises = []

                let uniqid = uuidv4()

                let treatmentShow = treatments[show][sessionIndex]

                let treatment = await MyLaserApi.PostAgenciesClientsIdTreatments(params.id, {
                    type: show,
                    type_human: label,
                    forced_price: treatmentShow.price,
                    more: treatmentShow.free ? "Offerte" : null
                })

                for (let i = 0; i < treatmentShow.session_count; i++) {

                    let sprice = Math.floor(treatmentShow.price / treatmentShow.session_count)

                    if (treatmentShow.session_count == i + 1) {
                        sprice = treatmentShow.price - (sprice * i)
                    }
                    
                    promises.push(MyLaserApi.PostAgenciesClientsIdSessions(params.id, {
                        date: new Date(1970, 6, 6),
                        type: show,
                        type_human: label,
                        area: (() => { 

                            if (treatmentShow.free) {
                                return "Offerte"
                            } else {
                                return "-"
                            }

                        })(),
                        data: {},
                        attachments: [],
                        forced_price: sprice,
                        uniqid: uniqid,
                        treatment_id: treatment.id,
                        employee_id: 0
                    }))

                }

                Promise.all(promises).then(() => {
                    callback()
                    setShow(false)
                });
                
            }}
        >
            Créer le traitement
        </Button>


    </Modal>)

}
