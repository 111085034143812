import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

window.baseUrl = "https://api-panel.my-laser.fr";
window.quotationUrl = "https://devis.my-laser.fr";
window.currencySymbol = "€";
window.country = "fr";

if (window.location.hostname.includes("my-laser.fr")) {
    window.baseUrl = "https://api-panel.my-laser.fr";
    window.quotationUrl = "https://devis.my-laser.fr";
    window.currencySymbol = "€";
    window.country = "fr";
} else if (window.location.hostname.includes("my-laser.ma")) {
    window.baseUrl = "https://api-panel.my-laser.ma";
    window.quotationUrl = "https://devis.my-laser.ma";
    global.currencySymbol = "MAD";
    window.country = "ma";
} else if (window.location.hostname.includes("my-laser.dev.etherial.fr")) {
    window.baseUrl = "https://dev-mylaser.etherial.fr";
    window.quotationUrl = "https://devis.my-laser.fr";
    window.currencySymbol = "€";
    window.country = "fr";
} else if (
    window.location.hostname.includes("localhost") ||
    window.location.hostname.includes("192")
) {
    // window.baseUrl = "https://dev-mylaser.etherial.fr";
    // window.quotationUrl = "http://localhost:3001";
    // window.currencySymbol = "€";
    // window.country = "fr";

    window.baseUrl = "https://api-panel.my-laser.fr";
    window.quotationUrl = "https://devis.my-laser.fr";
    window.currencySymbol = "€";
    window.country = "fr";

    // window.baseUrl = "https://api-panel.my-laser.ma";
    // window.quotationUrl = "https://devis.my-laser.ma";
    // global.currencySymbol = "MAD";
    // window.country = "ma";
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
