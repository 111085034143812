import ImageUploading from "react-images-uploading";
import styled from "styled-components";
import { Container, Col, Image } from "react-bootstrap";
import Button from "./Button";
import { useState } from "react"
 
const ImagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
    width: 100%;
    padding: 15px;
    border: none;
    background-color: #f8f9fb;
    border-radius: 10px;
    margin-bottom: 10px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
    &.spaced-items {
        justify-content: space-between;
    }
`;

const Text = styled.span`
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 14px;
    &.label {
        font-family: CenturyGothicBold;
        color: #6b7389;
        font-size: 15px;
        margin-bottom: 20px;
    }
    &.selected {
        font-family: CenturyGothicBold;
        font-size: 16px;
        margin-bottom: 20px;
        text-decoration-line: underline;
    }
    &.unselected {
        font-family: CenturyGothicBold;
        font-size: 16px;
        margin-bottom: 20px;
        color: #9fa9ae;
    }
`;

export default ({ onChange, label, showButton = true, maxNumber = 99 }) => {

    const [ value, setValue ] = useState([]);

    return <ImageUploading
        multiple
        value={value}
        onChange={(data) => {
            onChange(data)
            setValue(data)
        }}
        maxNumber={maxNumber}
        dataURLKey="data_url"
    >
        {({
            imageList,
            onImageUpload,
            onImageRemove,
            dragProps,
        }) => (
            <div className="upload__image-wrapper">

                { imageList.map( (image, index) => (
                        <div
                            key={index}
                            className="image-item"
                            style={{
                                marginBottom: 20,
                            }}
                        >
                            
                            <ImagesContainer>
                                <Row
                                    className="spaced-items"
                                    style={{
                                        marginBottom: 0,
                                        marginTop: 0,
                                    }}
                                >
                                    <Row
                                        style={{ marginBottom: 0, width: "auto", marginTop: 0, }}
                                    >
                                        <Image
                                            src={
                                                "/img.svg"
                                            }
                                        />
                                        <Text
                                            className="gray"
                                            style={{
                                                marginLeft: 10,
                                            }}
                                        >
                                            {
                                                image
                                                    .file
                                                    .name
                                            }

                                            <br/>

                                            <Button
                                                className="no-background"
                                                style={{
                                                    color: "#F25151"
                                                }}
                                                onClick={() =>
                                                    onImageRemove(
                                                        index
                                                    )
                                                }
                                            >
                                                Supprimer
                                            </Button>

                                        </Text>
                                    </Row>

                                    <img
                                        src={image["data_url"]}
                                        alt=""
                                        width="100"
                                    />

                                    
                                </Row>
                            </ImagesContainer>
                            
                            <div className="image-item__btn-wrapper">
                                
                            </div>
                        </div>
                    )
                )}

                {showButton && <Button
                    className="blue"
                    style={{
                        width: "100%",
                        marginBottom: 20,
                    }}
                    onClick={() => {
                        onImageUpload();
                    }}
                    {...dragProps}
                >
                    {label}
                </Button> }
            </div>
        )}
    </ImageUploading>

}