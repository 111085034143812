import { useState } from 'react'

import Calendar from "react-calendar";

import Picker from "./Picker";
import Modal from "./Modal";

import styled from "styled-components";
import moment from 'moment'

import * as locales from 'react-date-range/dist/locale';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { DateRange } from 'react-date-range';

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;
    &.no-width {
        width: auto;
    }
    &.spaced-items {
        justify-content: space-between;
    }
`;

export default ({ onChange }) => {

    let [showCalendar, setShowCalendar] = useState(false);
    let [showCalendar2, setShowCalendar2] = useState(false);

    let [dateStart, setDateStart] = useState(moment().subtract(7, "days").startOf('day').toDate());
    let [dateEnd, setDateEnd] = useState(moment().endOf('day').toDate());

    let [dateStart2, setDateStart2] = useState(moment().format("MM-YYYY"));
    let [dateEnd2, setDateEnd2] = useState(moment().format("MM-YYYY"));

    const generateMonthOptions = (value) => {
        const valueMoment = moment(value);
        const months = [];
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();

        for (let year = 2021; year <= currentYear; year++) {
            
            const start = year === 2021 ? 0 : 1;
            const end = year === currentYear ? currentMonth : 11;

            for (let month = start; month <= end; month++) {
                let monthyear = moment(`${month + 1}-${year}`, "M-YYYY")
                let formated = monthyear.format("MM-YYYY")
                
                months.push(
                    <option key={formated} value={formated}>
                        {monthyear.format("MMMM YYYY")}
                    </option>
                );

            }

        }

        return months;
    };

    return <>

        <Picker
            className="large"
            options={[
                { label: "Aujourd'hui", value: '5' },
                { label: 'Ces 7 derniers jours', value: '0' },
                { label: 'Ces 30 derniers jours', value: '1' },
                { label: 'Ce mois', value: '2' },
                { label: 'Le mois dernier', value: '3' },
                { label: 'Intervalle personnalisé', value: '4' },
                { label: 'Intervalle Par mois', value: '6' }
            ]}
            placeholder='Ces 7 derniers jours'
            isSearchable={false}
            onChange={(data) => {

                if (data.value === '0') {
                    let start = moment().subtract(7, "days").startOf('day').toDate()
                    let end = moment().endOf('day').toDate()

                    setDateStart(start)
                    setDateEnd(end)

                    onChange({ start, end })
                } else if (data.value === '1') {

                    let start = moment().subtract(30, "days").startOf('day').toDate()
                    let end = moment().endOf('day').toDate()

                    setDateStart(start)
                    setDateEnd(end)

                    onChange({ start, end })

                } else if (data.value === '2') {
                    let start = moment().startOf('month').startOf('day').toDate()
                    let end = moment().endOf('month').endOf('day').toDate()

                    setDateStart(start)
                    setDateEnd(end)

                    onChange({ start, end })

                } else if (data.value === '3') {

                    let start = moment().subtract(1, 'month').startOf('month').startOf('day').toDate()
                    let end = moment().subtract(1, 'month').endOf('month').endOf('day').toDate()

                    setDateStart(start)
                    setDateEnd(end)

                    onChange({ start, end })

                } else if (data.value === '4') {
                    setShowCalendar(true)
                } else if (data.value === '5') {
                    let start = moment().startOf('day').startOf('day').toDate()
                    let end = moment().endOf('day').endOf('day').toDate()

                    setDateStart(start)
                    setDateEnd(end)

                    onChange({ start, end })
                } else if (data.value === '6') {
                    setShowCalendar2(true)
                }

            }}
        />

        <Modal
            show={showCalendar}
            onHide={() => setShowCalendar(false)}
            title={"Intervalle personnalisé"}
            buttonText="Valider"
            size={"xl"}
            onButtonClick={() => {
                setShowCalendar(false)
                onChange({ start: moment(dateStart).startOf('day').startOf('day').toDate(), end: moment(dateEnd).endOf('day').endOf('day').toDate() })
            }}
        >

            <Row className="spaced-items" style={{ width: '100%', alignSelf: "center", "padding-left": "34px" }} >

                <DateRange
                    editableDateInputs={true}
                    onChange={({ selection }) => {
                        setDateStart(moment(selection.startDate).startOf('day').startOf('day').toDate())
                        setDateEnd(moment(selection.endDate).endOf('day').endOf('day').toDate())
                    }}
                    months={3}
                    moveRangeOnFirstSelection={false}
                    ranges={[{
                        startDate: dateStart,
                        endDate: dateEnd,
                        key: 'selection',
                    }]}
                    direction="horizontal"
                    locale={locales["fr"]}
                />

            </Row>

        </Modal>

        <Modal
            show={showCalendar2}
            onHide={() => setShowCalendar(false)}
            title={"Intervalle par mois"}
            buttonText="Valider"
            // size={"xl"}
            onButtonClick={() => {
                setShowCalendar2(false)
                onChange({ start: moment(dateStart2, "MM-YYYY").startOf('month').startOf('day').toDate(), end: moment(dateEnd2, "MM-YYYY").endOf('month').endOf('day').toDate() })
            }}
        >

            <Row className="spaced-items" style={{ width: '100%', alignSelf: "center" }} >

                <div>
                    <label htmlFor="startMonth">Mois de début :</label>
                    <select style={{
                        width: "100%",
                        height: "40px",
                        paddingLeft: "15px",
                        textTransform: "capitalize",
                        marginBottom: "20px",
                        borderRadius: "5px",
                    }} id="startMonth" value={dateStart2} onChange={(e) => setDateStart2(e.target.value)}>
                        <option value="">Select start month</option>
                        {generateMonthOptions(dateStart2)}
                    </select>

                    <label htmlFor="endMonth">Mois de fin :</label>
                    <select style={{
                        width: "100%",
                        height: "40px",
                        paddingLeft: "15px",
                        textTransform: "capitalize",
                        marginBottom: "20px",
                        borderRadius: "5px",
                    }} id="endMonth" value={dateEnd2} onChange={(e) => setDateEnd2(e.target.value)}>
                        <option value="">Select end month</option>
                        {generateMonthOptions(dateEnd2)}
                    </select>

                </div>

            </Row>

        </Modal>

    </>
}