import ImageUploading from "react-images-uploading";
import styled from "styled-components";
import { Container, Col, Image, Row } from "react-bootstrap";
import Button from "./Button";
import { useState } from "react"
 
const ImagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
    width: 100%;
    padding: 15px;
    border: none;
    background-color: #f8f9fb;
    border-radius: 10px;
    margin-bottom: 10px;
`;

export default ({ onChange, label, value, removeOne }) => {

    // const [ value, setValue ] = useState([]);

    return <ImagesContainer>
        <Row >

            { value.map( (image, index) => (
               
                <Col md={2}>
                    <img src={image} style={{width: "100%"}} onClick={() => window.open(image, "_blank") } />
                </Col>
                    
                )
            )}
        </Row>

    </ImagesContainer>

        

}