import { useRef, useState } from 'react'

import "../App.css";
import { useEffect } from 'react';
import styled from "styled-components";
import ImageUploading from "react-images-uploading";
import { Modal, Image } from 'react-bootstrap'

//components
import Picker from "./Picker";
import AreaButton from "./AreaButton";
import Input from "./Input";
import Button from "./Button";
 
import S3Handler from "../services/S3Handler";


const Container = styled.div`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        background-color: rgba(253, 244, 239, 0.64);
        border-width: 1px;
        border-color: #f0b594;
        border-style: solid;
        padding: 10px;
        border-radius: 6px;
        margin-bottom: 20px;
    `;

const Row = styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    `;
const Col = styled.div`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 33%;
    `;
const Text = styled.span`
        font-family: CenturyGothicBold;
        color: #102935;
        font-size: 15px;
        &.label {
            font-family: CenturyGothicRegular;
            font-size: 13px;
            margin-bottom: 10px;
        }
    `;

function ItemDetails({ text, show, onItemChange, data, children }) {

    if (!show) {
        return <></>
    }

    return (
        <Container>
            <Text>{text}</Text>

            <Row>

                <Picker
                    placeholder="Phototype"
                    isSearchable={false}
                    options={["I", "II", "III", "IV", "IVa", "V", "VI"].map((el) => ({ value: el, label: el }))}
                    value={data['phototype']}
                    onChange={(value) => onItemChange({ ...data, phototype: value})}
                    label="Phototype du patient:"
                    backgroundColor="white"
                    marginRight

                />

                <Picker
                    placeholder="Durée de pulse"
                    isSearchable={false}
                    options={[2, 3, 5, 7, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60].map((el) => ({ value: el, label: el }))}
                    value={data['pulse']}
                    onChange={(value) => onItemChange({ ...data, pulse: value})}
                    label="Durée de pulse (ms) :"
                    backgroundColor="white"
                    marginRight
                />

                <Picker
                    placeholder="Fluence"
                    isSearchable={false}
                    options={[7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].map((el) => ({ value: el, label: el }))}
                    value={data['fluence']}
                    onChange={(value) => onItemChange({ ...data, fluence: value})}
                    label="Fluence:"
                    backgroundColor="white"
                />

            </Row>

            <Row>
                <Col>
                    <Text className="label">Machine utilisée :</Text>
                    
                    <Row>
                        <AreaButton
                            className={`${data['machine'] === "Cynosure" ? "selected" : ""}`}
                            onClick={() => onItemChange({ ...data, machine: "Cynosure"})}
                            style={{ marginRight: 10 }}
                        >
                            Cynosure
                        </AreaButton>
                        <AreaButton
                            className={`${data['machine'] === "Candela" ? "selected" : ""
                                }`}
                            onClick={() => onItemChange({ ...data, machine: "Candela"})}
                            style={{ marginRight: 10 }}

                        >
                            Candela
                        </AreaButton>
                    </Row>

                    <Row>

                        <AreaButton
                            className={`${data['machine'] === "Lutronic" ? "selected" : ""
                                }`}
                            onClick={() => onItemChange({ ...data, machine: "Lutronic"})}
                            style={{ marginRight: 10 }}
                        >
                            Lutronic
                        </AreaButton>
                        <AreaButton
                            style={{opacity: 0, marginRight: 10}}
                            className={`${data['machine'] === "Lutronic" ? "selected" : ""
                                }`}
                            onClick={() => onItemChange({ ...data, machine: "Lutronic"})}
                        >
                            Lutronic
                        </AreaButton>
                    </Row>
                </Col>
                <Col>
                    <Text className="label">Technologie :</Text>
                    <Row>
                        <AreaButton
                            className={`${data['technologie'] === 'Alexandrite' ? "selected" : ""
                                }`}
                            onClick={() => onItemChange({ ...data, technologie: "Alexandrite"})}

                            style={{ marginRight: 10 }}
                        >
                            Alexandrite
                        </AreaButton>
                        <AreaButton
                            className={`${data['technologie'] === 'ND YAG' ? "selected" : ""
                                }`}
                            onClick={() => onItemChange({ ...data, technologie: "ND YAG"})}

                        >
                            ND YAG
                        </AreaButton>
                    </Row>
                </Col>
                <Col>
                    <Text className="label">Note :</Text>
                    <Input
                        onChange={(e) => onItemChange({ ...data, note: e.target.value})}
                        value={data['note']}
                        noIcon
                        placeholder="Note : Première séance douloureuse - Emla prescrite pour la prochaine séance…"
                        styleInput={{ backgroundColor: "#fff" }}
                    />
                </Col>
            </Row>
            <Row>
                
                <Col>

                    <Picker
                        placeholder={"Spot"}
                        isSearchable={false}
                        options={[12, 15, 16, 18, 20, 22, 24, 25].map((el) => ({ value: el, label: el }))}
                        value={data['spot']}
                        onChange={(value) => onItemChange({ ...data, spot: value})}
                        label="Taille du spot :"
                        backgroundColor="white"
                        marginRight
                    />


                </Col>

                <Col>

                    {children}

                </Col>

            </Row>
        </Container>
    );
}

export default ItemDetails;