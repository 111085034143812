import React, { useState, useEffect } from 'react'
import Modal from "../../../components/Modal";
import { Form } from "react-bootstrap";
import MyLaserApi from "../../../api/MyLaserApi";
import Button from "../../../components/Button";
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import ButtonLoader from '../../../components/ButtonLoader';

export default ({ data, setShow, callback }) => {

    const [zones, setZones] = useState([]);

    const [items, setItems] = useState([])

    const [quotation, setQuotation] = useState({})

    const [numberOfSession, setNumberOfSession] = useState(1)

    const client = useSelector(state => state.client) || {}

    const [isLoading, setIsLoading] = useState(false)

    const [oldids, setOldids] = useState([])

    let params = useParams()

    useEffect(() => {

        if (data) {

            let arr = []

            let oldidss = []

            JSON.parse(data.items).map((single) => {

                oldidss = [...oldidss, ...single.id]

                if (single.id.length == 1) {
                    arr.push({id: single.id[0], name: single.name})
                } else {

                    let names = single.name.split(' + ')

                    single.id.map((id, index) => {
                        arr.push({id: id, name: names[index]})
                    })

                }
            })

            setOldids(oldidss)

            setItems(arr)
        }

    }, [data])

    let checkZone = (id) => {

        if (!zones.includes(id)) {
            setZones([...zones, id])
        } else {
            setZones(zones.filter((z) => z != id))
        }

        setQuotation({})

    }

    return (<Modal
        show={data !== false}
        onHide={() => {
            setZones([])
            setOldids([])
            setQuotation({})

            setShow(false)
        }}
        title={"Ajouter des séances"}
    >

        {data && <>

            <span>Zone que vous souhaitez ajouter : </span>
            <br />
            <br />

            {items.map((item) => {

                return <Form.Check 
                    // type="checkbox" 
                    id={"select-" + item.name}
                    label={item.name}
                    onChange={() => checkZone(item.id)}
                />

            })}
            <hr />
            <span>Nombre de séances :</span>
            <div className="mb-3">
                {[1,2,3,4,5,6,7,8,9].map((item) => {

                    return <Form.Check 
                        type="radio" 
                        inline
                        label={item}
                        checked={numberOfSession === item}
                        id={`numberofsession` + item}
                        onChange={() => setNumberOfSession(item)}
                    />

                })}
            </div>
            
            <Button 
                style={{ width: "100%", marginTop: 25, marginBottom: 25 }}
                className="blue"
                disabled={zones.length === 0}
                onClick={async () => {
                    let json = (await MyLaserApi.getQuotationPricing(client.sexe === "femme" ? "female" : "male", data.type, { ids: JSON.stringify(zones) }, data.voucher, true, JSON.stringify(oldids)))
                    setQuotation(json)
                }}
            >
                Estimer le prix
            </Button>

            { quotation.data &&
                <>
                    <span> {quotation.data.price}{global.currencySymbol} par séance </span> <br />
                    <ul>

                        {quotation.data.items.map((el) => {
                            return <li> 
                                {(() => {

                                    if (el.price == el.price_before) {
                                        return (<span>
                                            {el.name}: {parseFloat(el.price).toFixed(2)}{global.currencySymbol}
                                        </span>)
                                    } else if (el.price_before_voucher) {

                                        return (<span>
                                            {el.name}: {parseFloat(el.price).toFixed(2)}{global.currencySymbol} <i style={{color: 'red'}}>(Reduction {parseFloat(el.price_before_voucher).toFixed(2)}{global.currencySymbol} -20%)</i> <i style={{textDecoration: 'line-through'}}>{(parseFloat(el.price_before)).toFixed(2)}{global.currencySymbol}</i>
                                        </span>)

                                    } else {

                                        return (<span>
                                            {el.name}: {parseFloat(el.price).toFixed(2)}{global.currencySymbol} <i style={{textDecoration: 'line-through'}}>{parseFloat(el.price_before).toFixed(2)}{global.currencySymbol}</i>
                                        </span>)
                                        
                                    }

                                })()} </li>
                                
                                
                        })}

                    </ul>

                    {/* <ButtonLoader style={{ width: "100%", marginTop: 25, marginBottom: 25 }} onClick={props.onClick2}>Archiver</ButtonLoader> */}

                    <ButtonLoader 
                        style={{ width: "100%", marginTop: 25, marginBottom: 25 }}
                        onClick={() => {

                            return MyLaserApi.convertQuotationToSessions(params.id, data.id, { number_of_sessions: numberOfSession, items: JSON.stringify(quotation.data.items) }).then(() => {
                                setIsLoading(false)
                                setShow(false)
                                toast.success("Le devis a été mis à jour et les séances ont été créées.")
                                callback()
                            }).catch((err) => {
                                toast.error("Une erreur est survenue.")
                            })
                         
                        }}
                    >
                        Créer les séances
                    </ButtonLoader>
                </>
            }

        </>}

    </Modal>)

}
