import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css';
import styled from 'styled-components'
import { Image } from 'react-bootstrap'
import moment from "moment";

const Container = styled.div`
      display: flex;
      flex-direction: Row;
      align-items: center;
      padding: 10px;
      background-color: #fff;
      border-radius: 7px;
   
    `

    const Text = styled.span`
      margin-left: 10px;
      font-family: CenturyGothicRegular;
      color:#102935;
      font-size: 14px;
     
    `
    
function Calendar(props) {

    
    return (
        <Container >
            <Image src={"/calendar.svg"}

            />
            <Text>{moment(new Date()).format('DD MMMM YYYY')}</Text>

        </Container>
    );
}

export default Calendar;