import * as React from "react"

function Users(props) {
    return (
        <svg
            width="20px"
            height="20px"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            {...props}
        >
            <path
                id="fi-rr-users"
                d="M6.25,10.833A3.75,3.75,0,1,1,10,7.083a3.75,3.75,0,0,1-3.75,3.75ZM6.25,5A2.083,2.083,0,1,0,8.333,7.083,2.083,2.083,0,0,0,6.25,5ZM12.5,19.167V18.75a6.25,6.25,0,1,0-12.5,0v.417a.833.833,0,1,0,1.667,0V18.75a4.583,4.583,0,1,1,9.167,0v.417a.833.833,0,1,0,1.667,0ZM20,15a5.833,5.833,0,0,0-9.722-4.348.833.833,0,1,0,1.112,1.242A4.167,4.167,0,0,1,18.333,15,.833.833,0,0,0,20,15ZM14.583,7.5a3.75,3.75,0,1,1,3.75-3.75,3.75,3.75,0,0,1-3.75,3.75Zm0-5.833A2.083,2.083,0,1,0,16.667,3.75,2.083,2.083,0,0,0,14.583,1.667Z"
                fill={props.selected ? "#102935": "#9FA9AE"}
            />
        </svg>
    )
}

export default Users
