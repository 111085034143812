import 'bootstrap/dist/css/bootstrap.min.css'
import '../../App.css';
import styled from 'styled-components'
import { Container, Col } from 'react-bootstrap'
import React, { useState, useEffect, useRef } from 'react';

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import Modal from '../../components/Modal'
import Button from '../../components/Button'

//components
import Header from "../../components/Header";

//api
import MyLaserApi from '../../api/MyLaserApi'

import moment from 'moment';
import { useHistory, useParams } from 'react-router';

import DataTable from 'react-data-table-component';

import { v4 as uuidv4 } from 'uuid'

import Swal from 'sweetalert2'

import TableWithStatistics from "../../components/Tables/TableWithStatistics";

import { DateRange } from 'react-date-range';

import * as locales from 'react-date-range/dist/locale';


const ContainerHome = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100vh;
    background-color: transparent;
    padding-top:30px;
    margin-top: 20px;
    width:96%;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;

    &.spaced-items {
        justify-content: space-between;
    }

    &.col {
        flex-direction: column;
    }

    &.no-width {
        width:auto;
    }

`

const Block = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    background-color: #fff;
    margin-top: 20px;
    border-radius: 10px;
    width: 100%;
    &.no-padding {
        padding: 0px;
    }
`

const InputFilter = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 5px 0px 0px 5px;
    border: 1px solid rgb(229, 229, 229);
    padding: 0px 32px 0px 14px;
`

const ResetButton = styled.button`
    border-radius: 0px 5px 5px 0px;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-size: 14px;
    background-color: rgb(41, 121, 255);
    border: none;
    color: white;
    padding: 8px 32px;
`


const Text = styled.span`
    margin-left: 10px;
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 12px;
    &.bold {
        font-family: CenturyGothicBold;
        font-size: 16px;
    }
    &.white {
        color: #fff;
    }
    &.big {
        font-family: CenturyGothicBold;
        font-size: 20px;
    }
    &.info {
        font-size: 13px;
    }
    &.label {
        color: #87949a;
        font-size: 13px;
        margin-right: 5px;
    }
`;


const Option = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 170px;
    background-color: #EDF0F5;
    color:#102935;
    padding: 10px;
    &.selected {
        background-color: #102935;
        color:#fff;
        border-radius: 10px;
        width: 189px;
    }
    &.left {
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
    }  
    &.right {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
    }
   
`;

const FloatingElement = styled.div`
    position: absolute;
    text-align: center;
    z-index: 9999999999;
    background: #F0B594;
    padding: 20px;
    text-align: center;
    border-radius: 5px;
    color: white;
    border-radius: 5px;
`

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

const fullWidth = window.innerWidth;

const FilterComponent = ( { filterText, onClear, onFilter }) => (
    <>
      <InputFilter id="search" type="text" placeholder="Filtrer les patients" aria-label="Search Input" value={filterText} onChange={onFilter} />
      <ResetButton type="button" onClick={onClear}>Effacer</ResetButton>
    </>
)

function MyActivity() {

    const history = useHistory()

    const user = useSelector((state) => state.user);

    const params = useParams()

    const [intervalDate, setIntervalDate] = useState(({start: moment().startOf('month').startOf('day').toDate(), end: moment().endOf('month').endOf('day').toDate()}))
    // const [intervalDateModal, setIntervalDateModal] = useState(({start: moment().startOf('month').startOf('day').toDate(), end: moment().endOf('month').endOf('day').toDate()}))
    const [showCalendar, setShowCalendar] = useState(false);

    const [currentEmployee, setCurrentEmployee] = useState(null)

    const [sessions, setSessions] = useState([])
    const [payments, setPayments] = useState([])
    const [cpayments, setCPayments] = useState([])

    const [filterText, setFilterText] = React.useState('');
    const [filterText2, setFilterText2] = React.useState('');
    const [filterText3, setFilterText3] = React.useState('');

	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
	const [resetPaginationToggle2, setResetPaginationToggle2] = React.useState(false);
	const [resetPaginationToggle3, setResetPaginationToggle3] = React.useState(false);

    let [statisticsType, setStatisticsType] = useState('payments')

    let [statsTotal, setStatsTotal] = useState({
        payments: 0,
        sessions: 0
    })

    let currentTable = useRef()

	const subHeaderComponentMemo = React.useMemo(() => {
		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={() => {
                if (filterText) {
                    setResetPaginationToggle(!resetPaginationToggle);
                    setFilterText('');
                }
            }} filterText={filterText} />
		);
	}, [filterText, resetPaginationToggle]);

    const subHeaderComponentMemo2 = React.useMemo(() => {
		return (
			<FilterComponent onFilter={e => setFilterText2(e.target.value)} onClear={() => {
                if (filterText) {
                    setResetPaginationToggle2(!resetPaginationToggle2);
                    setFilterText2('');
                }
            }} filterText={filterText2} />
		);
	}, [filterText2, resetPaginationToggle2]);

    const subHeaderComponentMemo3 = React.useMemo(() => {
		return (
			<FilterComponent onFilter={e => setFilterText3(e.target.value)} onClear={() => {
                if (filterText) {
                    setResetPaginationToggle3(!resetPaginationToggle3);
                    setFilterText3('');
                }
            }} filterText={filterText3} />
		);
	}, [filterText3, resetPaginationToggle3]);

    const reload = () => {

        if (params.id) {
            MyLaserApi.call({url: `/employees/${params.id}`}).then(({data}) => {
                setCurrentEmployee(data)
            })
        } else {
            setCurrentEmployee(null)
        }

        MyLaserApi.call({url: `/employees/${params.id ? params.id : "me"}/agencies/clients/payments?start=${intervalDate.start.getTime() / 1000}&end=${intervalDate.end.getTime() / 1000}`}).then(({data}) => {

            let zbra = {}

            data.map((single) => {

                if (single.uniqid) {
                    
                    if (zbra[single.uniqid]) {
                        if (single.treatment) {
                            zbra[single.uniqid].push(single)
                        }
                    } else {
                        if (single.treatment) {
                            zbra[single.uniqid] = [single]
                        }
                    }

                } else {
                    if (single.treatment) {
                        zbra[uuidv4()] = [single]
                    }
                }

            })

            setPayments(zbra)
        })

        MyLaserApi.call({url: `/employees/${params.id ? params.id : "me"}/agencies/clients/sessions?start=${intervalDate.start.getTime() / 1000}&end=${intervalDate.end.getTime() / 1000}`}).then(({data}) => {
            setSessions(data)
        })

        MyLaserApi.call({url: `/employees/${params.id ? params.id : "me"}/agencies/clients/cpayments?start=${intervalDate.start.getTime() / 1000}&end=${intervalDate.end.getTime() / 1000}`}).then(({data}) => {
            // setCPayments(res.data)

            let zbra = {}

            data.map((single) => {

                if (single.uniqid) {
                    
                    if (zbra[single.uniqid]) {
                        if (single.treatment) {
                            zbra[single.uniqid].push(single)
                        }
                    } else {
                        if (single.treatment) {
                            zbra[single.uniqid] = [single]
                        }
                    }

                } else {
                    if (single.treatment) {
                        zbra[uuidv4()] = [single]
                    }
                }

            })

            setCPayments(zbra)
            
        })

    }

    useEffect(() => {
       reload()
    }, [intervalDate, params.id])

    return (

        <Container fluid className="App" >
            
            <Row>
                <Col className="col" md={12} style={{ marginTop: 0 }}>
                    <Header
                        user={user}
                        title={`Hello ${user.firstname},`}
                        subtitle="Bienvenue sur ton espace de travail"
                        icon
                    />

                    <ContainerHome>

                        <Row >
                            
                            <Col
                                className="col"
                                style={{
                                    width: "40%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                }}
                            >
                                <Text className="big">{currentEmployee ? `L'activité de ${currentEmployee.firstname} ${currentEmployee.lastname}`: "Mon activité" }</Text>
                                <Block >
                                    <Text className="bold">
                                        Choisissez la période souhaitée (du {moment(intervalDate.start).format('LL')} au {moment(intervalDate.end).format('LL')})
                                    </Text>
                                    <Row
                                        className={
                                            fullWidth < 1375 ? "col" : null
                                        } 
                                    >
                                        <Row className='spaced-items' style={{ width: '80%', marginTop: 20 }}>
            
                                            <Button className="little" style={{marginLeft: "25px", height: "40px"}} onClick={() => {
                                                setIntervalDate({start: moment().startOf('week').startOf('day').toDate(), end: moment().endOf('week').endOf('day').toDate()})
                                            }}>Cette semaine</Button>
                                            <Button className="little" style={{marginLeft: "25px", height: "40px"}} onClick={() => {
                                                setIntervalDate({start: moment().startOf('month').startOf('day').toDate(), end: moment().endOf('month').endOf('day').toDate()})
                                            }}>Ce mois</Button>
                                            <Button className="little" style={{marginLeft: "25px", height: "40px"}} onClick={() => {
                                                setIntervalDate({start: moment().subtract(1, 'month').startOf('month').startOf('day').toDate(), end: moment().subtract(1, 'month').endOf('month').endOf('day').toDate()})
                                            }}>Le mois dernier</Button>
                                            <Button className="little" style={{marginLeft: "25px", height: "40px"}} onClick={() => {
                                                setShowCalendar(true)
                                            }}>Intervalle personnalisé</Button>
                                            
                                        </Row>
            
                                    </Row>

                                    <Row className="spaced-items" style={{marginLeft: 30}} >
                                    
                                        <Row >

                                            <Option className={`${statisticsType == "payments" ? "selected" : ""} left`} onClick={() => setStatisticsType("payments")}>
                                                <Text className={`${statisticsType == "payments" ? "bold white" : ""}`}  >Chiffre d’Affaires</Text>
                                                <Text className={`${statisticsType == "payments" ? "size-plus white" : "size-plus"}`} >{ statsTotal.payments } {global.currencySymbol}</Text>

                                            </Option>

                                            <Option className={`${statisticsType == "sessions" ? "selected" : ""} right`} onClick={() => setStatisticsType("sessions")}>
                                                <Text className={`${statisticsType == "sessions" ? "bold white" : ""}`}  >Préstations</Text>
                                                <Text className={`${statisticsType == "sessions" ? "size-plus white" : "size-plus"}`}  >{ statsTotal.sessions }</Text>
                                            </Option>

                                        </Row>
                                    
                                    </Row>
            
                                    <TableWithStatistics 
                                        ref={currentTable}
                                        title={`Chiffre d'affaire`}
                                        url={`/employees/${params.id ? params.id : "me"}/statistics`}
                                        intervalDate={intervalDate} 
                                        statisticsType={statisticsType}
                                        headerColumn="employee_id"
                                        customDateFormat="dddd LL"
                                        setHeadersFunction={(data) => {
                                            return []
                                        }}
                                        onReload={() => {
                                            setStatsTotal({
                                                payments: currentTable && currentTable.current && currentTable.current.getValueFromStatistic("payments", "total_amount"),
                                                sessions: currentTable && currentTable.current && currentTable.current.getValueFromStatistic("sessions", "sessions_count")
                                            })
                                        }}
                                    />
                                    
                                </Block>
                                
                            </Col>
            
                        </Row>
                       
                        <Block>


                            <DataTable
                                title={`${currentEmployee ? `Les préstations de ${currentEmployee.firstname} ${currentEmployee.lastname}`: "Mes préstations" } (du ${moment(intervalDate.start).format('LL')} au ${moment(intervalDate.end).format('LL')})`}
                                noDataComponent="Il n'y a aucune prestation à afficher"
                                pagination
                                paginationResetDefaultPage={resetPaginationToggle}
			                    subHeader
                                subHeaderComponent={subHeaderComponentMemo}
                                onRowClicked={(row) => {
                                    history.push(`/patients/${row.agency_client_id}/treatments/laser`)
                                }}
                                columns={[
                                    {
                                        name: 'Date',
                                        selector: (row) => row.date,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Nom de la patiente',
                                        selector: (row) => row.client,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Prestation',
                                        selector: (row) => row.agency_service_type,
                                        sortable: true,
                                        wrap: true
                                    }
                                ]}
                                data={sessions.filter(
                                    (el) => el.agency_client && (el.agency_client.firstname.toLowerCase().includes(filterText.toLowerCase()) || el.agency_client.lastname.toLowerCase().includes(filterText.toLowerCase())),
                                ).map((el) => {

                                    return {
                                        id: el.id,
                                        agency_client_id: el.agency_client_id,
                                        date: moment(el.date).format('LLLL'),
                                        client:  el.agency_client.firstname + " " + el.agency_client.lastname,
                                        agency_service_type: (() => {
                                            let res = `${el.type_human != null ? el.type_human.replace('Épilation Laser', 'E.P') : el.type} ${el.area && el.area != "-" ? "(" + el.area + ")" : ""}`
                                            return res.length > 50 ? res.substring(0, 50) + "..." : res
                                        })()
                                    }
                                })}
                                paginationComponentOptions={{
                                    rowsPerPageText: 'Elements par page',
                                    rangeSeparatorText: 'de',
                                    selectAllRowsItem: false,
                                    selectAllRowsItemText: 'Suivant',
                                }}
                            />
                           
                        </Block>
                       
                        <Block>

                            <DataTable
                                title={`${currentEmployee ? `Le chiffre d'affaire de ${currentEmployee.firstname} ${currentEmployee.lastname}`: "Mon chiffre d'affaire" } (du ${moment(intervalDate.start).format('LL')} au ${moment(intervalDate.end).format('LL')})`}
                                noDataComponent="Il n'y a aucun chiffre d'affaire à afficher"
                                pagination={30}
                                paginationResetDefaultPage={resetPaginationToggle2}
			                    subHeader
                                subHeaderComponent={subHeaderComponentMemo2}
                                onRowClicked={(row) => {
                                    history.push(`/patients/${row.agency_client_id}/payments`)
                                }}
                                columns={[
                                    {
                                        name: 'Date',
                                        selector: (row) => {

                                            let arr = [
                                                row.date,
                                                <br />
                                            ]

                                            var duration = moment.duration(moment().diff(moment(row.created_at)));
                                            var hours = duration.asHours();

                                            if (hours > 48 || row.employee.id != user.id) {
                                                return [arr]
                                            }

                                            if (row.group[0].invoiced) {
                                                return [arr]
                                            }

                                            return [
                                                ...arr,
                                                <span
                                                    style={{
                                                        marginBottom: 20,
                                                        marginTop: 20,
                                                        fontFamily: "CenturyGothicBold",
                                                        fontSize: "12px",
                                                        color: "rgb(136 194 211)"
                                                    }}
                                                    onClick={() => {

                                                        history.push("/sessions/payments/update", {
                                                            payments: row.group
                                                        })
                                                        
                                                    }}
                                                >
                                                    Modifier
                                                </span>,
                                                " / ",
                                                <span
                                                style={{
                                                        marginBottom: 20,
                                                        marginTop: 20,
                                                        fontFamily: "CenturyGothicBold",
                                                        fontSize: "12px",
                                                        color: "rgb(255, 150, 141)"
                                                    }}
                                                    onClick={() => {

                                                        Swal.fire({
                                                            title: 'Attention',
                                                            text: "Vous êtes sur de supprimer ce paiement ?",
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#3085d6',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'Oui je veux le supprimer !',
                                                            cancelButtonText: "Annuler"
                                                        }).then((result) => {

                                                            if (result.isConfirmed) {

                                                                MyLaserApi.deletePayments(row.group.map((g) => g.id)).then(() => {
                                                                    reload()
                                                                })

                                                            }

                                                        })

                                                    }}
                                                >
                                                    Supprimer
                                                </span>
                                            ]

                                        },
                                        sortable: true,

                                    },
                                    {
                                        name: 'Nom de la patiente',
                                        selector: (row) => row.client,
                                        sortable: true,

                                    },
                                    {
                                        name: 'Encaissement par',
                                        selector: (row) => row.collecter,
                                        sortable: true,

                                    },
                                    {
                                        name: "Montant",
                                        selector: (row) => row.amount,
                                        sortable: true
                                    },
                                    {
                                        name: 'Paiements',
                                        selector: (row) => row.agency_income_type,
                                        sortable: true,

                                    },
                                    {
                                        name: 'Prestation',
                                        selector: (row) => row.agency_service_type,
                                        sortable: true,
                                        wrap: true

                                    }
                                    
                                ]}

                                data={
                                    Object.values(payments).filter(
                                        (group) => group[0].agency_client && (group[0].agency_client.firstname.toLowerCase().includes(filterText2.toLowerCase()) || group[0].agency_client.lastname.toLowerCase().includes(filterText2.toLowerCase())),
                                    ).map((group) => {

                                        let agency_income_type_1_amount = group.reduce((s, p) => s + parseFloat(p.agency_income_type_1_amount), 0)
                                        let agency_income_type_2_amount = group.reduce((s, p) => s + parseFloat(p.agency_income_type_2_amount), 0)
                                        let agency_income_type_3_amount = group.reduce((s, p) => s + parseFloat(p.agency_income_type_3_amount), 0)
                                        let agency_income_type_4_amount = group.reduce((s, p) => s + parseFloat(p.agency_income_type_4_amount), 0)
                                        let refunds = 0

                                        group.map((el) => {
                                            refunds += el.refunds.reduce((s,p) => { return s + parseFloat(p.amount_refund)}, 0)
                                        })

                                        return {
                                            group: group,
                                            created_at: group[0].created_at,
                                            ids: group.map((el) => el.id),
                                            date: moment(group[0].date).format("DD/MM/YYYY"),
                                            amount:(agency_income_type_1_amount + agency_income_type_2_amount + agency_income_type_3_amount + agency_income_type_4_amount - refunds).toFixed(2) + ` ${global.currencySymbol}`,

                                            agency_income_type: (() => {
    
                                                let res = []

                                                if (agency_income_type_1_amount) {
                                                    res.push([`Esp ${agency_income_type_1_amount.toFixed(2)}${global.currencySymbol}`, <br />])
                                                } 
                                               
                                                if (agency_income_type_2_amount) {
                                                    res.push([`CB ${agency_income_type_2_amount.toFixed(2)}${global.currencySymbol}`, <br />])
                                                }

                                                if (agency_income_type_3_amount) {
                                                    res.push([`ALMA ${agency_income_type_3_amount.toFixed(2)}${global.currencySymbol}`, <br />])
                                                }

                                                if (agency_income_type_4_amount) {
                                                    res.push([`Ch ${agency_income_type_4_amount.toFixed(2)}${global.currencySymbol}`, <br />])
                                                }
            
                                                if (refunds) {
                                                    res.push([`RBS -${refunds} ${global.currencySymbol}`, <br />])
                                                }

                                                return res
            
                                            })(),

                                            agency_service_type: (() => {

                                                let areas = []

                                                group.map((payment) => {
                                                    areas = [...areas, ...payment.treatment.sessions.map((e) => e.area)]
                                                })

                                                return areas.map((e) => e).filter(onlyUnique).map((area) => [
                                                    `${group[0].treatment.type_human != null ? group[0].treatment.type_human.replace('Épilation Laser', 'E.P') : group[0].treatment.type} ${area && area != "-" ? "(" + area + ")" : ""} x${areas.filter((s) => s === area).length}`, 
                                                    <br />
                                                ])

                                            })(),

                                            agency_client_id: group[0].agency_client_id,

                                            client: group[0].agency_client.firstname + " " + group[0].agency_client.lastname,
                                                
                                            employee: group[0].employee.firstname + " " + group[0].employee.lastname,
                                            collecter:  group[0].collected_by.firstname + " " + group[0].collected_by.lastname,
                                        }

                                    })

                                }

                            />

                        </Block>

                        <Block>

                            <DataTable
                                title={`${currentEmployee ? `Les encaissements de ${currentEmployee.firstname} ${currentEmployee.lastname}`: "Mes encaissements" } (du ${moment(intervalDate.start).format('LL')} au ${moment(intervalDate.end).format('LL')})`}
                                noDataComponent="Il n'y a aucun encaissement à afficher"
                                pagination={30}
                                paginationResetDefaultPage={resetPaginationToggle3}
			                    subHeader
                                subHeaderComponent={subHeaderComponentMemo3}
                                onRowClicked={(row) => {
                                    history.push(`/patients/${row.agency_client_id}/payments`)
                                }}
                                columns={[
                                    {
                                        name: 'Date',
                                        selector: (row) => row.date,
                                        sortable: true,

                                    },
                                    {
                                        name: 'Nom de la patiente',
                                        selector: (row) => row.client,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Nom du praticien',
                                        selector: (row) => row.employee,
                                        sortable: true,
                                    },
                                    {
                                        name: "Montant",
                                        selector: (row) => row.amount,
                                        sortable: true
                                    },
                                    {
                                        name: 'Paiements',
                                        selector: (row) => row.agency_income_type,
                                        sortable: true,

                                    },
                                    {
                                        name: 'Prestation',
                                        selector: (row) => row.agency_service_type,
                                        sortable: true,
                                        wrap: true
                                    }
                                ]}
                                
                                data={
                                    Object.values(cpayments).filter(
                                        (group) => group[0].agency_client && (group[0].agency_client.firstname.toLowerCase().includes(filterText2.toLowerCase()) || group[0].agency_client.lastname.toLowerCase().includes(filterText2.toLowerCase())),
                                    ).map((group) => {

                                        let agency_income_type_1_amount = group.reduce((s, p) => s + parseFloat(p.agency_income_type_1_amount), 0)
                                        let agency_income_type_2_amount = group.reduce((s, p) => s + parseFloat(p.agency_income_type_2_amount), 0)
                                        let agency_income_type_3_amount = group.reduce((s, p) => s + parseFloat(p.agency_income_type_3_amount), 0)
                                        let agency_income_type_4_amount = group.reduce((s, p) => s + parseFloat(p.agency_income_type_4_amount), 0)
                                        let refunds = 0

                                        group.map((el) => {
                                            refunds += el.refunds.reduce((s,p) => { return s + parseFloat(p.amount_refund)}, 0)
                                        })

                                        return {
                                            group: group,
                                            created_at: group[0].created_at,
                                            ids: group.map((el) => el.id),
                                            date: moment(group[0].date).format("DD/MM/YYYY"),
                                            amount:(agency_income_type_1_amount + agency_income_type_2_amount + agency_income_type_3_amount + agency_income_type_4_amount - refunds).toFixed(2) + ` ${global.currencySymbol}`,

                                            agency_income_type: (() => {
    
                                                let res = []

                                                if (agency_income_type_1_amount) {
                                                    res.push([`Esp ${agency_income_type_1_amount.toFixed(2)}${global.currencySymbol}`, <br />])
                                                } 
                                               
                                                if (agency_income_type_2_amount) {
                                                    res.push([`CB ${agency_income_type_2_amount.toFixed(2)}${global.currencySymbol}`, <br />])
                                                }

                                                if (agency_income_type_3_amount) {
                                                    res.push([`ALMA ${agency_income_type_3_amount.toFixed(2)}${global.currencySymbol}`, <br />])
                                                }

                                                if (agency_income_type_4_amount) {
                                                    res.push([`Ch ${agency_income_type_4_amount.toFixed(2)}${global.currencySymbol}`, <br />])
                                                }
            
                                                if (refunds) {
                                                    res.push([`RBS -${refunds} ${global.currencySymbol}`, <br />])
                                                }

                                                return res
            
                                            })(),

                                            agency_service_type: (() => {

                                                let areas = []

                                                group.map((payment) => {
                                                    areas = [...areas, ...payment.treatment.sessions.map((e) => e.area)]
                                                })

                                                return areas.map((e) => e).filter(onlyUnique).map((area) => [
                                                    `${group[0].treatment.type_human != null ? group[0].treatment.type_human.replace('Épilation Laser', 'E.P') : group[0].treatment.type} ${area && area != "-" ? "(" + area + ")" : ""} x${areas.filter((s) => s === area).length}`, 
                                                    <br />
                                                ])

                                            })(),

                                            // agency: group[0].agency.name.replace('MY LASER', ''),

                                            agency_client_id: group[0].agency_client_id,

                                            client: group[0].agency_client.firstname + " " + group[0].agency_client.lastname,
                                                
                                            employee: group[0].employee.firstname + " " + group[0].employee.lastname,
                                            collecter:  group[0].collected_by.firstname + " " + group[0].collected_by.lastname,
                                        }

                                    })

                                }

                            />

                        </Block>

                        
                    </ContainerHome>

                </Col>
            </Row>

            <Modal
                show={showCalendar}
                onHide={() => setShowCalendar(false)}
                title={"Intervalle personnalisé"}
                buttonText="Valider"
                size={"xl"}
                onButtonClick={() => { 
                    setShowCalendar(false)
                    setIntervalDate({start: moment(intervalDate.start).startOf('day').startOf('day').toDate(), end: moment(intervalDate.end).endOf('day').endOf('day').toDate()})
                }}
            >

                <Row className="spaced-items" style={{ width: '100%', alignSelf: "center", "padding-left": "34px" }} >

                    <DateRange
                        editableDateInputs={true}
                        onChange={({selection}) => {
                            setIntervalDate({start: moment(selection.startDate).startOf('day').startOf('day').toDate(), end: moment(selection.endDate).endOf('day').endOf('day').toDate()})
                        }}
                        months={3}
                        moveRangeOnFirstSelection={false}
                        ranges={[{
                            startDate: intervalDate.start,
                            endDate: intervalDate.end,
                            key: 'selection',
                        }]}
                        direction="horizontal"
                        locale={locales["fr"]}
                    />

                </Row>

            </Modal>

            
        </Container>
    );
}

export default MyActivity;