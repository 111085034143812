import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css';
import styled from 'styled-components'
import { Image } from 'react-bootstrap'
import { useRef } from 'react';

const Container = styled.div`
    display: flex;
    flex-direction: Row;
    align-items: center;
    padding: 15px;
    width: 100%;
    background-color: #F8FAFD;
    border-radius: 7px;
    height: 150%;

    &.with-label {
        background-color: #fff;
        border-width: 1px;
        border-color: #EDF0F5;
        border-style: solid;
    }
    &.orange {
        background-color:#F0B59417
    }
    &.big input {
        font-size: 18px
    }
`
const Input = styled.input`
    background-color: transparent;
    border: 0px;
    outline-color: transparent;
    width: 100%;
    margin-left: 10px;
    font-family: CenturyGothicRegular;
    color:#102935;
    font-size: 14px;
    height: 100%;
    &.multiline {
        height: 100%;
    }
    &.whitebg {
        background: white;
    }
`

const TextArea = styled.textarea`
    background-color: white;
    border: 0px;
    outline-color: transparent;
    width: 100%;
    font-family: CenturyGothicRegular;
    color:#102935;
    font-size: 14px;
    padding: 10px;
    border-width: 1px;
        border-color: #EDF0F5;
        border-style: solid;

`
const Label = styled.span`
    font-family: CenturyGothicRegular;
    color:#102935;
    font-size: 13px;
    margin-bottom: 10px;
`
const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
    width: 100%;
    &.small {
        width: 24%;
    }
    &.medium {
        width: 38%;
    }
    &.half {
        width: 49%;
    }
`

function Input2(props) {

    let inputRef = useRef()

    return (
        <Col className={props.className} style={props.style} onClick={() => inputRef.current.focus()}>
            {
                props.label &&
                <Label>{props.label}</Label>
            }
            <Container className={props.className} style={props.styleInput} id={"p" + props.id} s>
                {!props.noIcon &&
                    <Image src={"/search.svg"}

                    />
                }
                {props.textArea ?
                    <TextArea placeholder={props.placeholder} onChange={props.onChange} ref={inputRef} rows={props.rows ?? 4} value={props.value} />
                    :
                    <Input placeholder={props.placeholder}
                        onChange={props.onChange}
                        value={props.value}
                        ref={inputRef}
                        id={props.id}
                        type={props.type}
                        className={props.inputClass}
                        readOnly={props.readOnly}
                        
                    />}
            </Container>
        </Col>
    );
}

export default Input2;