import "../../../App.css";
import styled from "styled-components";
import { Container, Col } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";

import { useHistory } from "react-router";

import "react-calendar/dist/Calendar.css";

//components
import Header from "../../../components/Header";
import Picker from "../../../components/Picker";
import Input from "../../../components/Input";
import DatePicker from "../../../components/DatePicker";
import Button from "../../../components/Button";

//api
import MyLaserApi from "../../../api/MyLaserApi";

import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import Switch from "react-ios-switch";

import S3Picture from "../../../components/S3Picture";

const ContainerInput = styled.div`
    display: flex;
    flex-direction: Row;
    align-items: center;
    padding: 15px;
    width: 100%;
    background-color: #f8fafd;
    border-radius: 7px;
    height: 150%;

    &.with-label {
        background-color: #fff;
        border-width: 1px;
        border-color: #edf0f5;
    }
    &.orange {
        background-color: #f0b59417;
    }
    &.big input {
        font-size: 18px;
    }
`;

const Label = styled.span`
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 13px;
    margin-bottom: 10px;
`;

const ContainerEmployee = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100vh;
    background-color: transparent;
    padding-top: 30px;
    margin-top: 20px;
    width: 96%;
`;
const Block = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    background-color: #fff;
    margin-top: 20px;
    border-radius: 10px;
    width: 100%;
    box-shadow: 5px 2px 5px rgba(16, 41, 53, 0.1);
`;

const Text = styled.span`
    margin-left: 10px;
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 12px;
    &.bold {
        font-family: CenturyGothicBold;
    }
    &.big {
        font-family: CenturyGothicBold;
        font-size: 20px;
    }
    &.white {
        color: #fff;
    }
    &.orange {
        color: #f0b594;
    }
    &.size-plus {
        font-size: 19px;
    }
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;
    &.no-width {
        width: auto;
    }
    &.spaced-items {
        justify-content: space-between;
    }
`;
const Option = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 170px;
    background-color: #edf0f5;
    color: #102935;
    padding: 10px;
    &.selected {
        background-color: #102935;
        color: #fff;
        border-radius: 10px;
        width: 189px;
    }
    &.left {
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
    }
    &.right {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
    }
`;

const List = styled.div`
    margin-top: 20px;
    width: 100%;
`;

const ColInput = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
    width: 100%;
    &.small {
        width: 24%;
    }
    &.medium {
        width: 38%;
    }
    &.half {
        width: 49%;
    }
`;

let roles = [
    { id: 1, name: "Administrateur" },
    { id: 2, name: "Support" },
    { id: 3, name: "Praticien" },
    { id: 4, name: "Comptable" },
    { id: 5, name: "Infirmier" },
    { id: 6, name: "Secretaire" },
    { id: 7, name: "Manager" },
    { id: 8, name: "Medecin" },
];

function getRoleById(role) {
    if (role == 1) {
        return "Administrateur";
    } else if (role == 2) {
        return "Support";
    } else if (role == 3) {
        return "Praticien";
    } else if (role == 4) {
        return "Comptable";
    } else if (role == 5) {
        return "Infirmier";
    } else if (role == 6) {
        return "Secretaire";
    } else if (role == 7) {
        return "Manager";
    } else if (role == 8) {
        return "Medecin";
    }
}

function EmployeeForm(props) {
    const [employee, setEmployee] = useState(props.activeEmployee);
    const agencies = useSelector((state) => state.agencies) || {};

    const [aAgency, setAAgency] = useState(null);
    const [aRole, setARole] = useState(null);

    useEffect(() => {
        console.log("yoo");

        if (
            props.activeEmployee &&
            employee &&
            employee.id &&
            agencies.length > 0
        ) {
            setAAgency({
                value: props.activeEmployee.agency_id,
                label: agencies.find(
                    (el) => el.id == props.activeEmployee.agency_id
                ).name,
            });
            setARole({
                value: props.activeEmployee.role,
                label: roles.find((el) => el.id == props.activeEmployee.role)
                    .name,
            });
        }

        setEmployee(props.activeEmployee);
    }, [agencies, props.activeEmployee]);

    return (
        <Block
            style={{ padding: 0, marginTop: 0 }}
            key={
                props.activeEmployee.firstname +
                " " +
                props.activeEmployee.lastname
            }
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "15px",
                    width: "100%",
                    backgroundColor: "#b4cfd7",
                    borderRadius: "10px",
                    color: "white",
                    textAlign: "center",
                }}
            >
                {employee.id && (
                    <Text
                        style={{
                            color: "white",
                            width: "100%",
                            fontSize: "18px",
                        }}
                    >
                        Données de <br />
                        {props.activeEmployee.lastname}{" "}
                        {props.activeEmployee.firstname}
                    </Text>
                )}

                {!employee.id && (
                    <Text
                        style={{
                            color: "white",
                            width: "100%",
                            fontSize: "18px",
                        }}
                    >
                        Ajout d'un nouveau praticien
                    </Text>
                )}
            </div>

            <div style={{ padding: 20, width: "100%" }}>
                <Row className="spaced-items">
                    {agencies && (
                        <Picker
                            placeholder="Agence"
                            isSearchable={false}
                            options={agencies.map((el) => ({
                                value: el.id,
                                label: el.name,
                            }))}
                            value={aAgency}
                            onChange={(value) => setAAgency(value)}
                            label="Agence"
                            backgroundColor="#F8FAFD"
                            marginRight
                        />
                    )}
                </Row>

                <Row className="spaced-items">
                    <Input
                        noIcon
                        label="Nom"
                        value={employee.lastname}
                        onChange={(e) =>
                            setEmployee({
                                ...employee,
                                lastname: e.target.value,
                            })
                        }
                    />
                </Row>

                <Row className="spaced-items">
                    <Input
                        noIcon
                        label="Prénom"
                        value={employee.firstname}
                        onChange={(e) =>
                            setEmployee({
                                ...employee,
                                firstname: e.target.value,
                            })
                        }
                    />
                </Row>

                <Row className="spaced-items">
                    <Picker
                        placeholder="Role"
                        isSearchable={false}
                        options={roles.map((el) => ({
                            value: el.id,
                            label: el.name,
                        }))}
                        value={aRole}
                        onChange={(value) => setARole(value)}
                        label="Role"
                        backgroundColor="#F8FAFD"
                        marginRight
                    />
                </Row>

                <Row className="spaced-items">
                    <Input
                        noIcon
                        label="Email"
                        value={employee.email}
                        onChange={(e) =>
                            setEmployee({ ...employee, email: e.target.value })
                        }
                    />
                </Row>

                <Row className="spaced-items">
                    <Input
                        noIcon
                        label="Numéro"
                        value={employee.phone_number}
                        onChange={(e) =>
                            setEmployee({
                                ...employee,
                                phone_number: e.target.value,
                            })
                        }
                    />
                </Row>

                {employee.id && (
                    <>
                        <Row className="spaced-items">
                            <ColInput>
                                <Label>Utilisateur actif</Label>
                                <ContainerInput>
                                    <Switch
                                        checked={employee.status}
                                        onChange={(checked) =>
                                            setEmployee({
                                                ...employee,
                                                status: checked,
                                            })
                                        }
                                    />
                                </ContainerInput>
                            </ColInput>
                        </Row>

                        <Button
                            className="blue"
                            style={{ width: "100%" }}
                            onClick={() => {
                                MyLaserApi.sendPasswordToEmployee(
                                    employee.id
                                ).then((res) => {
                                    toast.success(
                                        "Le nouveau mot de passe a été envoyé."
                                    );
                                });
                            }}
                        >
                            Envoyer un nouveau mot de passe
                        </Button>
                        <br />
                        <br />
                    </>
                )}

                <Button
                    style={{ width: "100%" }}
                    onClick={() => {
                        console.log("yoyo ");
                        console.log(employee);
                        console.log(employee.email);

                        if (employee.id) {
                            MyLaserApi.updateEmployee(employee.id, {
                                firstname: employee.firstname,
                                lastname: employee.lastname,
                                agency_id: aAgency.value,
                                role: aRole.value,
                                email: employee.email.toLowerCase(),
                                phone_number: employee.phone_number,
                            }).then(() => {
                                toast.success("L'utilisateur a été modifié.");
                                props.reload();
                                props.closeModal();
                            });
                        } else {
                            console.log(employee);

                            MyLaserApi.createEmployee({
                                firstname: employee.firstname,
                                lastname: employee.lastname,
                                agency_id: aAgency.value,
                                role: aRole.value,
                                email: employee.email.toLowerCase(),
                                phone_number: employee.phone_number,
                            }).then(() => {
                                toast.success("L'utilisateur a été créé.");
                                props.reload();
                                props.closeModal();
                            });
                        }
                    }}
                >
                    {employee.id
                        ? "Enregister les modifications"
                        : "Créer le praticien"}
                </Button>
            </div>
        </Block>
    );
}

function Employees() {
    const history = useHistory();

    let [employees, setEmployees] = useState([]);
    let [intervalDateActiveEmployee, setIntervalDateActiveEmployee] = useState({
        start: moment().subtract(7, "days").startOf("day").toDate(),
        end: moment().endOf("day").toDate(),
    });
    let [currentEmployeeStatistics, setCurrentEmployeeStatistics] =
        useState("payments");
    let [fullStatistics, setFullStatistics] = useState(null);

    let [showCreateEmployeeModal, setShowCreateEmployeeModal] = useState(false);
    let [showEditEmployeeModal, setShowEditEmployeeModal] = useState(false);

    const user = useSelector((state) => state.user);

    const agencies = useSelector((state) => state.agencies);

    useEffect(() => {
        if (user.role != 1) {
            history.push("/home");
        }
    }, [user]);

    let reload = () => {
        MyLaserApi.call({
            url: `/employees?withstats=true&start=${
                intervalDateActiveEmployee.start.getTime() / 1000
            }&end=${intervalDateActiveEmployee.end.getTime() / 1000}`,
        }).then(({ data }) => {
            let filtred = data.filter((s) => s.status === true && s.agency_id);
            setEmployees(filtred);
        });
    };

    useEffect(() => {
        reload();
    }, []);

    useEffect(() => {
        MyLaserApi.call({
            url: `/employees/statistics?start=${
                intervalDateActiveEmployee.start.getTime() / 1000
            }&end=${intervalDateActiveEmployee.end.getTime() / 1000}`,
        }).then(({ data }) => {
            setFullStatistics(data);
        });

        reload();
    }, [intervalDateActiveEmployee]);

    if (!user || user?.role !== 1) {
        return <></>;
    }

    return (
        <Container fluid className="App">
            <Row>
                <Col
                    className="col"
                    md={12}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Header
                        user={user}
                        title={`Hello ${user.firstname},`}
                        subtitle="Bienvenue sur ton espace de travail"
                        icon
                    />

                    <ContainerEmployee>
                        <Row className="spaced-items">
                            <Text className="big">
                                L’activité de vos praticiens
                            </Text>
                            <Button
                                className="custom-width"
                                onClick={() => setShowCreateEmployeeModal(true)}
                            >
                                Ajouter un praticien
                            </Button>
                        </Row>

                        <Block>
                            <Row className="spaced-items">
                                <Row>
                                    <Option
                                        className={`${
                                            currentEmployeeStatistics ==
                                            "payments"
                                                ? "selected"
                                                : ""
                                        } left`}
                                        onClick={() =>
                                            setCurrentEmployeeStatistics(
                                                "payments"
                                            )
                                        }
                                    >
                                        <Text
                                            className={`${
                                                currentEmployeeStatistics ==
                                                "payments"
                                                    ? "bold white"
                                                    : ""
                                            }`}
                                        >
                                            Chiffre d’Affaires
                                        </Text>
                                        <Text
                                            className={`${
                                                currentEmployeeStatistics ==
                                                "payments"
                                                    ? "size-plus white"
                                                    : "size-plus"
                                            }`}
                                        >
                                            {fullStatistics &&
                                                global.numberWithSpaces(
                                                    fullStatistics.payments.reduce(
                                                        (tot, current) =>
                                                            tot +
                                                            parseFloat(
                                                                current.total_amount
                                                            ),
                                                        0
                                                    )
                                                )}{" "}
                                            {global.currencySymbol}
                                        </Text>
                                    </Option>

                                    <Option
                                        className={`${
                                            currentEmployeeStatistics ==
                                            "sessions"
                                                ? "selected"
                                                : ""
                                        } right`}
                                        onClick={() =>
                                            setCurrentEmployeeStatistics(
                                                "sessions"
                                            )
                                        }
                                    >
                                        <Text
                                            className={`${
                                                currentEmployeeStatistics ==
                                                "sessions"
                                                    ? "bold white"
                                                    : ""
                                            }`}
                                        >
                                            Patients
                                        </Text>
                                        <Text
                                            className={`${
                                                currentEmployeeStatistics ==
                                                "sessions"
                                                    ? "size-plus white"
                                                    : "size-plus"
                                            }`}
                                        >
                                            {fullStatistics &&
                                                fullStatistics.sessions.reduce(
                                                    (tot, current) =>
                                                        tot +
                                                        parseFloat(
                                                            current.sessions_count
                                                        ),
                                                    0
                                                )}
                                        </Text>
                                    </Option>
                                </Row>

                                <DatePicker
                                    onChange={(dates) => {
                                        setIntervalDateActiveEmployee(dates);
                                    }}
                                />
                            </Row>
                        </Block>

                        <List>
                            {agencies &&
                                agencies.map((agency) => (
                                    <div>
                                        <DataTable
                                            title={`Détail des praticiens de ${agency.name}`}
                                            noDataComponent="Il n'y a rien à afficher"
                                            columns={[
                                                {
                                                    name: "Nom",
                                                    wrap: true,

                                                    selector: (employee) => (
                                                        <>
                                                            <S3Picture
                                                                className="user-profile-img-small"
                                                                type="user-profil"
                                                                file={
                                                                    employee.picture
                                                                }
                                                            />
                                                            <span
                                                                style={{
                                                                    marginLeft:
                                                                        "10px",
                                                                    textTransform:
                                                                        "capitalize",
                                                                    fontWeight:
                                                                        "bold",
                                                                }}
                                                            >
                                                                {
                                                                    employee.firstname
                                                                }{" "}
                                                                {
                                                                    employee.lastname
                                                                }
                                                            </span>
                                                        </>
                                                    ),
                                                },
                                                {
                                                    name: "Role",
                                                    selector: (employee) =>
                                                        getRoleById(
                                                            employee.role
                                                        ),
                                                },
                                                {
                                                    name: "Contact",
                                                    wrap: true,
                                                    selector: (employee) => {
                                                        return (
                                                            <>
                                                                {employee.email}{" "}
                                                                <br />{" "}
                                                                {
                                                                    employee.phone_number
                                                                }{" "}
                                                            </>
                                                        );
                                                    },
                                                },
                                                {
                                                    name:
                                                        "C.A " +
                                                        moment().format("MMM"),
                                                    sortable: true,
                                                    selector: (employee) =>
                                                        employee.current_month,
                                                    cell: (employee) => (
                                                        <span
                                                            style={{
                                                                color:
                                                                    employee.current_month >
                                                                    25000
                                                                        ? "green"
                                                                        : "none",
                                                                fontWeight:
                                                                    employee.current_month >
                                                                    25000
                                                                        ? "bold"
                                                                        : "none",
                                                            }}
                                                        >
                                                            {global.numberWithSpaces(
                                                                parseFloat(
                                                                    employee.current_month
                                                                )
                                                            )}{" "}
                                                            {
                                                                global.currencySymbol
                                                            }
                                                        </span>
                                                    ),
                                                },
                                                {
                                                    name:
                                                        "C.A " +
                                                        moment()
                                                            .subtract(
                                                                1,
                                                                "month"
                                                            )
                                                            .format("MMM"),
                                                    sortable: true,
                                                    selector: (employee) =>
                                                        employee.month_minus_one,
                                                    cell: (employee) => (
                                                        <span
                                                            style={{
                                                                color:
                                                                    employee.month_minus_one >
                                                                    25000
                                                                        ? "green"
                                                                        : "none",
                                                                fontWeight:
                                                                    employee.month_minus_one >
                                                                    25000
                                                                        ? "bold"
                                                                        : "none",
                                                            }}
                                                        >
                                                            {global.numberWithSpaces(
                                                                parseFloat(
                                                                    employee.month_minus_one
                                                                )
                                                            )}{" "}
                                                            {
                                                                global.currencySymbol
                                                            }
                                                        </span>
                                                    ),
                                                },
                                                {
                                                    name: "C.A période",
                                                    sortable: true,
                                                    selector: (employee) =>
                                                        employee.period,
                                                    cell: (employee) => (
                                                        <span>
                                                            {global.numberWithSpaces(
                                                                parseFloat(
                                                                    employee.period
                                                                )
                                                            )}{" "}
                                                            {
                                                                global.currencySymbol
                                                            }
                                                        </span>
                                                    ),
                                                },
                                                {
                                                    name: "Actions",
                                                    cell: (employee) => (
                                                        <>
                                                            <span
                                                                onClick={() => {
                                                                    setShowEditEmployeeModal(
                                                                        employee
                                                                    );
                                                                }}
                                                            >
                                                                Modifier
                                                            </span>
                                                            {" / "}
                                                            <span
                                                                onClick={() => {
                                                                    history.push(
                                                                        `/admin/employees/${employee.id}/activity`
                                                                    );
                                                                }}
                                                            >
                                                                Consulter
                                                            </span>
                                                        </>
                                                    ),
                                                },
                                            ]}
                                            data={employees.filter(
                                                (emp) =>
                                                    emp.agency_id === agency.id
                                            )}
                                        />
                                        <hr />
                                    </div>
                                ))}
                        </List>
                    </ContainerEmployee>
                </Col>
            </Row>

            <Modal
                show={showCreateEmployeeModal}
                onHide={() => setShowCreateEmployeeModal(false)}
                size={"md"}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <EmployeeForm
                    activeEmployee={{}}
                    reload={reload}
                    closeModal={() => {
                        setShowCreateEmployeeModal(false);
                    }}
                />
            </Modal>

            <Modal
                show={showEditEmployeeModal !== false}
                onHide={() => setShowEditEmployeeModal(false)}
                size={"md"}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <EmployeeForm
                    activeEmployee={showEditEmployeeModal}
                    reload={reload}
                    closeModal={() => {
                        setShowEditEmployeeModal(false);
                    }}
                />
            </Modal>
        </Container>
    );
}

export default Employees;
