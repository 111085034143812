import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container, Col, Badge } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";

import Button from "../../../components/Button";
import ButtonA from "../../../components/ButtonA";
import Header from "../../../components/Header";

import MyLaserApi from "../../../api/MyLaserApi";
import { useParams, useHistory } from "react-router";
import moment from "moment";
import { NavLink } from "react-router-dom";

import { toast } from "react-toastify";

import { useSelector } from "react-redux";
import Swal from "sweetalert2";

import QRCode from "react-qr-code";

import ModalC from "../../../components/Modal";
import ButtonLoader from "../../../components/ButtonLoader";

import ModalAddSessionsElectrolyse from "./../Sessions/ModalAddSessionsElectrolyse";

const Text = styled.span`
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 13px;
    &.bold {
        font-family: CenturyGothicBold;
        color: #f0b594;
        font-size: 15px;
    }
    &.gray {
        color: #545c73;
        margin-left: 10px;
        text-decoration: underline;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 96%;
    margin-top: 20px;
    &.spaced-items {
        justify-content: space-between;
        align-items: center;
    }
`;

function unique(array, propertyName) {
    return array.filter(
        (e, i) =>
            array.findIndex((a) => a[propertyName] === e[propertyName]) === i
    );
}

function Quotation() {
    let user = useSelector((state) => state.user);
    let params = useParams();
    let history = useHistory();

    const [tmpConsent, setTmpConsent] = useState(null);
    const [quotations, setQuotations] = useState([]);
    const [consents, setConsents] = useState([]);
    const [showQrCode, setShowQrCode] = useState(false);
    const [loading, setLoading] = useState(false);

    const [modal, showModal] = useState(false);

    const reload = () => {
        return new Promise((resolve) => {
            setLoading(true);

            MyLaserApi.call({
                method: "GET",
                url: `/agencies/clients/${params.id}/quotations/others?type=${params.type}`,
            }).then((quotations) => {
                setQuotations(quotations.data);
                setLoading(false);
                resolve();
            });

            MyLaserApi.getClientConsents(params.id).then((res) => {
                if (res.status === 200) {
                    setConsents(res.data);
                }
            });
        });
    };

    useEffect(() => {
        reload();
    }, [params.type]);

    return (
        <Container fluid className="App">
            <Row>
                <Col
                    md={12}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Header type="client" />

                    <Row
                        style={{
                            alignItems: "flex-end",
                            marginBottom: "30px",
                            marginTop: "30px",
                        }}
                    >
                        <Col
                            xs={12}
                            style={{ textAlign: "left", textAlign: "right" }}
                        >
                            <Button
                                className="blue custom-width"
                                style={{ margin: "auto" }}
                                onClick={() => {
                                    history.push(
                                        `/patients/${params.id}/quotations/${params.type}/add`
                                    );
                                }}
                            >
                                Créer un devis
                            </Button>
                        </Col>
                    </Row>

                    {loading && (
                        <Row
                            style={{
                                width: "100%",
                                alignItems: "self-start",
                                paddingLeft: "25px",
                                paddingRight: "25px",
                                borderBottom:
                                    "1px solid rgba(128,128,128, 0.2)",
                                marginLeft: "0px",
                                paddingBottom: "30px",
                                paddingTop: "30px",
                            }}
                        >
                            <Col
                                xs={12}
                                className="col"
                                style={{
                                    alignContent: "self-start",
                                    textAlign: "center",
                                    alignItems: "self-start",
                                }}
                            >
                                <p
                                    style={{
                                        fontSize: "14px",
                                        textAlign: "center",
                                    }}
                                >
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                        style={{ marginRight: "5px" }}
                                    />{" "}
                                    Chargement des devis
                                </p>
                            </Col>
                        </Row>
                    )}

                    {!loading && quotations.length === 0 && (
                        <Row
                            style={{
                                width: "100%",
                                alignItems: "self-start",
                                paddingLeft: "25px",
                                paddingRight: "25px",
                                borderBottom:
                                    "1px solid rgba(128,128,128, 0.2)",
                                marginLeft: "0px",
                                paddingBottom: "30px",
                                paddingTop: "30px",
                            }}
                        >
                            <Col
                                xs={12}
                                className="col"
                                style={{
                                    alignContent: "self-start",
                                    textAlign: "center",
                                    alignItems: "self-start",
                                }}
                            >
                                <p
                                    style={{
                                        fontSize: "14px",
                                        textAlign: "center",
                                    }}
                                >
                                    Il n'y a aucun devis pour le moment
                                </p>
                            </Col>
                        </Row>
                    )}

                    {!loading &&
                        quotations.map((quotation) => {
                            let treatments = JSON.parse(
                                quotation.treatments_data
                            );
                            let consent = consents.find(
                                (el) => el.type === quotation.consent
                            );
                            let status = 1;
                            let payments = [];
                            let sessions = [];
                            let totals = {
                                payment_payed: 0,
                                payment_to_pay: 0,
                                session_not_done: 0,
                                session_to_do: 0,
                            };

                            if (quotation.converted) {
                                status = 2;

                                quotation.treatments.map((treatment) => {
                                    payments = [
                                        ...payments,
                                        ...treatment.payments,
                                    ];

                                    treatment.sessions.map((session) => {
                                        sessions.push(session);
                                    });
                                });

                                totals.payment_payed = unique(
                                    payments,
                                    "id"
                                ).reduce((s, p) => s + p.amount_income, 0);
                                totals.payment_to_pay = unique(
                                    quotation.treatments,
                                    "id"
                                ).reduce((s, p) => s + p.forced_price, 0);
                                totals.session_not_done = unique(
                                    sessions,
                                    "id"
                                ).filter((s) => s.date.includes("1970")).length;

                                if (
                                    totals.session_not_done === 0 &&
                                    totals.payment_payed >=
                                        totals.payment_to_pay
                                ) {
                                    status = 3;
                                }
                            }

                            return (
                                <Row
                                    style={{
                                        width: "100%",
                                        alignItems: "self-start",
                                        paddingLeft: "25px",
                                        paddingRight: "25px",
                                        borderBottom:
                                            "1px solid rgba(128,128,128, 0.2)",
                                        marginLeft: "0px",
                                        paddingBottom: "30px",
                                        paddingTop: "30px",
                                    }}
                                >
                                    <Col
                                        xs={3}
                                        className="col"
                                        style={{
                                            alignContent: "self-start",
                                            textAlign: "left",
                                            alignItems: "self-start",
                                        }}
                                    >
                                        <h4
                                            style={{
                                                color: "#102935",
                                                fontWeight: "bold",
                                                textAlign: "left",
                                                paddingBottom: "15px",
                                                wordBreak: "break-all",
                                            }}
                                        >
                                            {quotation.type_human}
                                        </h4>
                                        <div
                                            style={{
                                                width: "30px",
                                                height: "3px",
                                                background: "#F0B594",
                                                marginBottom: "15px",
                                            }}
                                        >
                                            {" "}
                                        </div>

                                        {(() => {
                                            if (status === 1) {
                                                return (
                                                    <Badge
                                                        pill
                                                        bg="success"
                                                        style={{
                                                            background:
                                                                "rgb(251, 231, 132) none repeat scroll 0% 0%",
                                                        }}
                                                    >
                                                        {" "}
                                                        Devis - En attente{" "}
                                                    </Badge>
                                                );
                                            } else if (status === 2) {
                                                return (
                                                    <Badge
                                                        pill
                                                        bg="success"
                                                        style={{
                                                            background:
                                                                "rgb(255, 150, 141)",
                                                        }}
                                                    >
                                                        {" "}
                                                        Devis - En cours{" "}
                                                    </Badge>
                                                );
                                            } else if (status === 3) {
                                                return (
                                                    <Badge
                                                        pill
                                                        bg="success"
                                                        style={{
                                                            background:
                                                                "#8cb197",
                                                        }}
                                                    >
                                                        {" "}
                                                        Devis - Fini & Payé{" "}
                                                    </Badge>
                                                );
                                            }
                                        })()}
                                    </Col>

                                    <Col
                                        xs={6}
                                        className="col"
                                        style={{
                                            alignContent: "self-start",
                                            textAlign: "left",
                                            alignItems: "self-start",
                                        }}
                                    >
                                        {(() => {
                                            if (status === 1) {
                                                return (
                                                    <p
                                                        style={{
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        Devis réalisé le{" "}
                                                        {moment(
                                                            quotation.created_at
                                                        ).format("LL")}{" "}
                                                        <br />
                                                        {treatments.map(
                                                            (
                                                                treatment,
                                                                index
                                                            ) => {
                                                                return (
                                                                    <>
                                                                        {
                                                                            treatment
                                                                                .sessions
                                                                                .length
                                                                        }{" "}
                                                                        Séance
                                                                        de{" "}
                                                                        {
                                                                            treatment
                                                                                .sessions[0]
                                                                                .type_human
                                                                        }{" "}
                                                                        {treatment
                                                                            .sessions[0]
                                                                            .area &&
                                                                        treatment
                                                                            .sessions[0]
                                                                            .area !=
                                                                            "-"
                                                                            ? ` (${treatment.sessions[0].area})`
                                                                            : ""}
                                                                        <br />
                                                                    </>
                                                                );
                                                            }
                                                        )}
                                                        Montant total à payer{" "}
                                                        {treatments.reduce(
                                                            (old, newo) =>
                                                                old +
                                                                newo.forced_price,
                                                            0
                                                        )}{" "}
                                                        €
                                                    </p>
                                                );
                                            } else if (
                                                status === 2 ||
                                                status === 3
                                            ) {
                                                return (
                                                    <p
                                                        style={{
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        Devis réalisé le{" "}
                                                        {moment(
                                                            quotation.created_at
                                                        ).format("LL")}{" "}
                                                        <br />
                                                        {quotation.treatments.map(
                                                            (
                                                                treatment,
                                                                index
                                                            ) => {
                                                                return (
                                                                    <>
                                                                        {
                                                                            treatment.sessions.filter(
                                                                                (
                                                                                    s
                                                                                ) =>
                                                                                    !s.date.includes(
                                                                                        "1970"
                                                                                    )
                                                                            )
                                                                                .length
                                                                        }{" "}
                                                                        /{" "}
                                                                        {
                                                                            treatment
                                                                                .sessions
                                                                                .length
                                                                        }{" "}
                                                                        Séance
                                                                        de{" "}
                                                                        {
                                                                            treatment
                                                                                .sessions[0]
                                                                                .type_human
                                                                        }{" "}
                                                                        {treatment
                                                                            .sessions[0]
                                                                            .area &&
                                                                        treatment
                                                                            .sessions[0]
                                                                            .area !=
                                                                            "-"
                                                                            ? ` (${treatment.sessions[0].area})`
                                                                            : ""}
                                                                        <br />
                                                                    </>
                                                                );
                                                            }
                                                        )}
                                                        Montant payé:{" "}
                                                        {totals.payment_payed.toFixed(
                                                            2
                                                        )}
                                                        {global.currencySymbol}{" "}
                                                        sur{" "}
                                                        {totals.payment_to_pay.toFixed(
                                                            2
                                                        )}
                                                        {global.currencySymbol}
                                                    </p>
                                                );
                                            }
                                        })()}
                                    </Col>

                                    <Col xs={3} className="col">
                                        {(() => {
                                            if (
                                                quotation.consent != "" &&
                                                quotation.consent
                                            ) {
                                                if (consent) {
                                                    return (
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Button
                                                                style={{
                                                                    width: "49%",
                                                                    marginBottom:
                                                                        "15px",
                                                                    marginRight:
                                                                        "1%",
                                                                }}
                                                                className="reverse"
                                                                onClick={() => {
                                                                    window.open(
                                                                        encodeURI(
                                                                            "https://docs.google.com/viewer?url=" +
                                                                                consent.link
                                                                        )
                                                                    );
                                                                }}
                                                            >
                                                                CONSENT.
                                                            </Button>

                                                            <Button
                                                                style={{
                                                                    width: "49%",
                                                                    marginBottom:
                                                                        "15px",
                                                                }}
                                                                className="reverse"
                                                                onClick={() => {
                                                                    setShowQrCode(
                                                                        true
                                                                    );
                                                                    setTmpConsent(
                                                                        consent
                                                                    );
                                                                }}
                                                            >
                                                                QRCode
                                                            </Button>
                                                        </div>
                                                    );
                                                }

                                                if (!consent) {
                                                    return (
                                                        <NavLink
                                                            style={{
                                                                width: "100%",
                                                                marginBottom:
                                                                    "15px",
                                                            }}
                                                            to={`/patients/${params.id}/consents/create?type=${quotation.consent}`}
                                                        >
                                                            <Button
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                GENERER LE
                                                                CONSENTEMENT
                                                            </Button>
                                                        </NavLink>
                                                    );
                                                }
                                            } else {
                                                return <></>;
                                            }
                                        })()}

                                        {(status == 1 ||
                                            status == 2 ||
                                            status == 3) && (
                                            <div style={{ width: "100%" }}>
                                                <Button
                                                    style={{
                                                        width: "49%",
                                                        marginBottom: "15px",
                                                        marginRight: "1%",
                                                    }}
                                                    className="reverse"
                                                    onClick={() => {
                                                        window.open(
                                                            encodeURI(
                                                                "https://docs.google.com/viewer?url=" +
                                                                    quotation.link
                                                            )
                                                        );
                                                    }}
                                                >
                                                    DEVIS.
                                                </Button>

                                                <Button
                                                    style={{
                                                        width: "49%",
                                                        marginBottom: "15px",
                                                    }}
                                                    className="reverse"
                                                    onClick={() => {
                                                        setShowQrCode(true);
                                                        setTmpConsent(
                                                            quotation
                                                        );
                                                    }}
                                                >
                                                    QRCode
                                                </Button>

                                                <Button
                                                    className="blue"
                                                    style={{
                                                        width: "100%",
                                                        marginBottom: 15,
                                                    }}
                                                    onClick={() => {
                                                        showModal(quotation);
                                                    }}
                                                >
                                                    Ajouter des séances au devis
                                                </Button>
                                            </div>
                                        )}

                                        {status == 1 &&
                                            ((quotation.consent != "" &&
                                                quotation.consent &&
                                                consent) ||
                                                quotation.consent == "" ||
                                                user.role === 1 ||
                                                user.role === 7) && (
                                                <ButtonLoader
                                                    style={{
                                                        width: "100%",
                                                        marginBottom: "15px",
                                                    }}
                                                    onClick={async () => {
                                                        let extra = {};

                                                        if (
                                                            treatments[0]
                                                                .sessions[0]
                                                                .type ===
                                                                "dermamelan" ||
                                                            treatments[0]
                                                                .sessions[0]
                                                                .type ===
                                                                "blemiderm"
                                                        ) {
                                                            let qt =
                                                                await Swal.fire(
                                                                    {
                                                                        title: "Crème Skin Care",
                                                                        text: "Voulez-vous fournir la crème préparatoire au traitement à votre patient ?",
                                                                        // icon: 'info',
                                                                        showCancelButton: true,
                                                                        showDenyButton: true,

                                                                        confirmButtonColor:
                                                                            "#3085d6",
                                                                        cancelButtonColor:
                                                                            "grey",
                                                                        confirmButtonText:
                                                                            "Oui",
                                                                        cancelButtonText:
                                                                            "Annuler",
                                                                        denyButtonText:
                                                                            "Non",
                                                                    }
                                                                );

                                                            if (
                                                                qt.isDismissed
                                                            ) {
                                                                return;
                                                            }

                                                            extra = {
                                                                cream: qt.isConfirmed
                                                                    ? 1
                                                                    : 0,
                                                            };
                                                        }

                                                        // console.log(treatments)

                                                        // return new Promise((resolve) => resolve(true))

                                                        return MyLaserApi.call({
                                                            method: "POST",
                                                            url: `/agencies/clients/${params.id}/quotations/others/${quotation.id}/sessions`,
                                                            body: {
                                                                type: treatments[0]
                                                                    .sessions[0]
                                                                    .type,
                                                                type_human:
                                                                    treatments[0]
                                                                        .sessions[0]
                                                                        .type_human,
                                                                treatments:
                                                                    treatments,
                                                                extra: extra,
                                                            },
                                                        }).then((quotation) => {
                                                            reload().then(
                                                                () => {
                                                                    history.push(
                                                                        `/patients/${params.id}/treatments/${treatments[0].sessions[0].type}`
                                                                    );
                                                                }
                                                            );
                                                        });
                                                    }}
                                                >
                                                    COMMENCER LE TRAITEMENT
                                                </ButtonLoader>
                                            )}

                                        {/* DELETE ACITON */}

                                        {status == 1 && (
                                            <ButtonLoader
                                                style={{
                                                    width: "100%",
                                                    background: "#F25151",
                                                }}
                                                onClick={() => {
                                                    return MyLaserApi.deleteQuotationOther(
                                                        params.id,
                                                        quotation.id,
                                                        false
                                                    ).then((quotation) => {
                                                        reload();
                                                    });
                                                }}
                                            >
                                                SUPPRIMER LE DEVIS{" "}
                                            </ButtonLoader>
                                        )}

                                        {status == 2 &&
                                            (user.role == 1 ||
                                                user.role == 7) && (
                                                <ButtonLoader
                                                    style={{
                                                        width: "100%",
                                                        background: "#F25151",
                                                    }}
                                                    onClick={() => {
                                                        return MyLaserApi.deleteQuotationOther(
                                                            params.id,
                                                            quotation.id,
                                                            true
                                                        ).then((quotation) => {
                                                            if (
                                                                quotation.data
                                                                    .message
                                                            ) {
                                                                toast.info(
                                                                    quotation
                                                                        .data
                                                                        .message
                                                                );
                                                            }

                                                            reload();
                                                        });
                                                    }}
                                                >
                                                    ARRETER LE TRAITEMENT
                                                </ButtonLoader>
                                            )}
                                    </Col>
                                </Row>
                            );
                        })}
                </Col>
            </Row>

            <ModalAddSessionsElectrolyse
                data={modal}
                setShow={showModal}
                callback={() => {
                    reload();
                }}
            />

            <ModalC
                show={showQrCode}
                onHide={() => setShowQrCode(false)}
                title={"QRCode consentement"}
                onButtonClick={() => setShowQrCode(false)}
                size="lg"
                style={{ width: "100%", textAlign: "center" }}
            >
                {showQrCode && (
                    <QRCode
                        size={256}
                        style={{
                            height: "auto",
                            maxWidth: "80%",
                            width: "80%",
                            alignSelf: "center",
                        }}
                        value={
                            "https://docs.google.com/viewer?url=" +
                            tmpConsent.link
                        }
                        viewBox={`0 0 256 256`}
                    />
                )}
            </ModalC>
        </Container>
    );
}

export default Quotation;
