

import styled from "styled-components";
import { Container, Col } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useSelector } from "react-redux";
import Input from "../../components/Input";
import ConsentItem from "../../components/ConsentItem";
import PatientListHeader from "../../components/PatientListHeader";
import Table from "../../components/Table";
import { useHistory} from "react-router-dom";

//components
import Header from "../../components/Header";
import Picker from "../../components/Picker";
import Modal from "../../components/Modal";

//api
import MyLaserApi from "../../api/MyLaserApi";
import colors from "../../services/Colors"


import moment from 'moment'
import DatePicker from "../../components/DatePicker";

import MouseTooltip from 'react-sticky-mouse-tooltip';

import Button from '../../components/Button'

import TableWithStatistics from "../../components/Tables/TableWithStatistics";

import { DateRange } from 'react-date-range';

import * as locales from 'react-date-range/dist/locale';

const ContainerHome = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100vh;
    background-color: transparent;
    padding-top: 30px;
    margin-top: 20px;
    width: 96%;
`;

const Text = styled.span`
    margin-left: 10px;
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 12px;
    &.bold {
        font-family: CenturyGothicBold;
        font-size: 16px;
    }
    &.white {
        color: #fff;
    }
    &.big {
        font-family: CenturyGothicBold;
        font-size: 20px;
    }
    &.info {
        font-size: 13px;
    }
    &.label {
        color: #87949a;
        font-size: 13px;
        margin-right: 5px;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;
    &.col {
        flex-direction: column;
    }
    &.no-width {
        width: auto;
    }
    &.spaced-items {
        justify-content: space-between;
    }
`;

const Block = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    background-color: #fff;
    margin-top: 20px;
    border-radius: 10px;
    width: 95%;
    box-shadow: 5px 2px 5px rgba(16, 41, 53, 0.1);
    min-height: 300px;
    &.no-padding {
        padding: 0px;
    }
`;

const Option = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 170px;
    background-color: #EDF0F5;
    color:#102935;
    padding: 10px;
    &.selected {
        background-color: #102935;
        color:#fff;
        border-radius: 10px;
        width: 189px;
    }
    &.left {
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
    }  
    &.right {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
    }
   
`;

const FloatingElement = styled.div`
    position: absolute;
    text-align: center;
    z-index: 9999999999;
    background: #F0B594;
    padding: 20px;
    text-align: center;
    border-radius: 5px;
    color: white;
    border-radius: 5px;
`

const fullWidth = window.innerWidth;

function Home() {

    let history = useHistory();

    let [consents, setConsents] = useState([]);
    let [lastClients, setLastClients] = useState([]);

    let [statisticsType, setStatisticsType] = useState('payments')
    
    let [intervalDate, setIntervalDate] = useState(({start: moment().startOf('month').startOf('day').toDate(), end: moment().endOf('month').endOf('day').toDate()}))

    let [fullStatistics, setFullStatistics] = useState(null)

    let [tooltip, setTooltip] = useState(null)

    let [showCalendar, setShowCalendar] = useState(false);

    let [pointerEvent, setPointerEvent] = useState(false);

    let [showArchived, setShowArchived] = useState(false);

    let [statsTotal, setStatsTotal] = useState({
        payments: 0,
        sessions: 0
    })

    let currentTable = useRef()


    const user = useSelector((state) => state.user);

    useEffect(() => {

        MyLaserApi.getAllClients();

        
        reloadData();
    }, []);

    useEffect(() => {

        MyLaserApi.call({url: `/employees/me/statistics?start=${intervalDate.start.getTime() / 1000}&end=${intervalDate.end.getTime() / 1000}`}).then(({ data }) => {
            setFullStatistics(data)
        })

    }, [intervalDate]);


    let reloadData = () => {

        MyLaserApi.call({url: `/agencies/clients/lasts`}).then((res) => {
            setLastClients(res.data.rows)
        })

    };

    return (

        <Container fluid className="App">
        
            <Row>
                <Col style={{ backgroundColor: "#fff" }}>
                
                </Col>
                <Col
                    className="col"
                    md={12}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Header
                        user={user}
                        title={`Hello ${user.firstname},`}
                        subtitle="Bienvenue sur ton espace de travail"
                        icon
                    />

                    <ContainerHome>
                        <Row className="spaced-items">
                            
                            <Col
                                className="col"
                                style={{
                                    width: "40%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                }}
                            >
                                <Text className="big">Mon activité</Text>

                                <Block>
                                    <Text className="bold">
                                        Choisissez la période souhaitée 
                                    </Text>
                                    <Row
                                        className={
                                            fullWidth < 1375 ? "col" : null
                                        } 
                                    >
                                        <Row className='spaced-items' style={{ width: '80%', marginTop: 20 }}>

                                            <Button className="little" style={{marginLeft: "25px", height: "40px"}} onClick={() => {
                                                setIntervalDate({start: moment().startOf('week').startOf('day').toDate(), end: moment().endOf('week').endOf('day').toDate()})
                                            }}>Cette semaine</Button>
                                            <Button className="little" style={{marginLeft: "25px", height: "40px"}} onClick={() => {
                                                setIntervalDate({start: moment().startOf('month').startOf('day').toDate(), end: moment().endOf('month').endOf('day').toDate()})
                                            }}>Ce mois</Button>
                                            <Button className="little" style={{marginLeft: "25px", height: "40px"}} onClick={() => {
                                                setIntervalDate({start: moment().subtract(1, 'month').startOf('month').startOf('day').toDate(), end: moment().subtract(1, 'month').endOf('month').endOf('day').toDate()})
                                            }}>Le mois dernier</Button>
                                            <Button className="little" style={{marginLeft: "25px", height: "40px"}} onClick={() => {
                                                setShowCalendar(true)
                                            }}>Intervalle personnalisé</Button>
                                           
                                        </Row>

                                    </Row>

                                    <Row className="spaced-items" style={{marginLeft: 30}} >
                                    
                                        <Row >

                                            <Option className={`${statisticsType == "payments" ? "selected" : ""} left`} onClick={() => setStatisticsType("payments")}>
                                                <Text className={`${statisticsType == "payments" ? "bold white" : ""}`}  >Chiffre d’Affaires</Text>
                                                <Text className={`${statisticsType == "payments" ? "size-plus white" : "size-plus"}`} >{ statsTotal.payments } {global.currencySymbol}</Text>

                                            </Option>

                                            <Option className={`${statisticsType == "sessions" ? "selected" : ""} right`} onClick={() => setStatisticsType("sessions")}>
                                                <Text className={`${statisticsType == "sessions" ? "bold white" : ""}`}  >Préstations</Text>
                                                <Text className={`${statisticsType == "sessions" ? "size-plus white" : "size-plus"}`}  >{ statsTotal.sessions }</Text>
                                            </Option>

                                        </Row>
                                    
                                    </Row>

                                    {/* /employees/me/statistics */}

                                    <TableWithStatistics 
                                        ref={currentTable}
                                        title={`Chiffre d'affaire`}
                                        url={`/employees/me/statistics`}
                                        intervalDate={intervalDate} 
                                        statisticsType={statisticsType}
                                        headerColumn="employee_id"
                                        customDateFormat="dddd LL"
                                        setHeadersFunction={(data) => {
                                            return []
                                        }}
                                        onReload={() => {
                                            setStatsTotal({
                                                payments: currentTable && currentTable.current && currentTable.current.getValueFromStatistic("payments", "total_amount"),
                                                sessions: currentTable && currentTable.current && currentTable.current.getValueFromStatistic("sessions", "sessions_count")
                                            })
                                        }}
                                    />
                                    
                                    
                                </Block>
                                <Block >
                                    <Text className="big">
                                        Nouveaux patients inscrits via le questionnaire médical{" "}
                                    </Text>


                                     {lastClients.length === 0 && (
                                        <div style={{textAlign: 'center', margin: 'auto'}}>
                                            Il n'y a aucun nouveaux patients <br /><br />
                                            <Button onClick={() => reloadData()}> Rafraichir </Button>
                                        </div>
                                    )}

                                    {lastClients.map((el) => {
                                        return (
                                            <ConsentItem 
                                                {...el}
                                                onClick2={() => {
                                                    // MyLaserApi.deleteConsent(el.id).then(() => {
                                                    //     reloadData()
                                                    // })

                                                    return MyLaserApi.call({url: `/agencies/clients/${el.id}/lasts/archive`, method: "POST"}).then(() => {
                                                        reloadData()
                                                    })

                                                }}
                                                onClick={() => {
                                                    history.push(`/patients/${el.id}/informations`);
                                                }}
                                            />
                                        )
                                    })}

                                </Block>
                            </Col>
                        </Row>
                    </ContainerHome>
                </Col>
            </Row>

            <Modal
                show={showCalendar}
                onHide={() => setShowCalendar(false)}
                title={"Intervalle personnalisé"}
                buttonText="Valider"
                size={"xl"}
                onButtonClick={() => { 
                    setShowCalendar(false)
                    setIntervalDate({start: moment(intervalDate.start).startOf('day').startOf('day').toDate(), end: moment(intervalDate.end).endOf('day').endOf('day').toDate()})
                }}
            >

                <Row className="spaced-items" style={{ width: '100%', alignSelf: "center", "padding-left": "34px" }} >

                    <DateRange
                        editableDateInputs={true}
                        onChange={({selection}) => {
                            setIntervalDate({start: moment(selection.startDate).startOf('day').startOf('day').toDate(), end: moment(selection.endDate).endOf('day').endOf('day').toDate()})
                        }}
                        months={3}
                        moveRangeOnFirstSelection={false}
                        ranges={[{
                            startDate: intervalDate.start,
                            endDate: intervalDate.end,
                            key: 'selection',
                        }]}
                        direction="horizontal"
                        locale={locales["fr"]}
                    />

                </Row>

            </Modal>

        </Container>

    );
}

export default Home;
