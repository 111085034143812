import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css';
import styled from 'styled-components'
import React, { useState, useEffect } from "react";
import moment from 'moment'

import Button from './Button'
import ButtonLoader from './ButtonLoader';
import Swal from 'sweetalert2';

const Container = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
padding-top:30px;
width: 100%;   
`

const Row = styled.div`
display: flex;
flex-direction: row;
align-items: center;
margin-bottom: 10px;
width: 100%;
&.spaced-items {
  width: 30%;
  justify-content: space-between;
}
`

const Col = styled.div`
display: flex;
flex-direction: column;
align-items: center;
align-items: flex-start;
width: 100%;
&.flex-end-item {
  align-items: flex-end;
}
`

const Text = styled.span`
margin-left: 10px;
font-family: CenturyGothicRegular;
color: #102935;
font-size: 12px;
&.bold {
  font-family: CenturyGothicBold;
  font-size: 14px;
}
&.gray{
  color:#9FA9AE;
}
`
const Line = styled.div`
  height: 1px;
  background-color: #EDF0F5;
  width: 90%;
  align-self:flex-end;
`
const Name = styled.div`
  padding: 15px;
  background-color: #FDF4EF;
  font-family: CenturyGothicBold;
  font-size: 11px;
  color: #102935;
  border-radius: 8px;
`

function ConsentItem(props) {

    return (
        <Container  >
            <Col>
                <Row>
 
                    <Row>
                        <Name>Nouveau</Name>
                        <Col >
                            <Text className="bold">{props.firstname} {props.lastname}</Text>
                        </Col>
                    </Row>

                    <Col className="flex-end-item">
                        <Text className="bold gray">{moment(props.created_at).format('DD/MM/YYYY')}</Text>
                        <Text className="gray">{moment(props.created_at).format('HH[h]mm')}</Text>
                    </Col>
                </Row>
                <Row className="spaced-items">
                    <Button onClick={props.onClick}>Consulter</Button>
                    <ButtonLoader className="reverse" onClick={props.onClick2}>Archiver</ButtonLoader>

                </Row>
                <Line />
            </Col>
        </Container>
    );
}

export default ConsentItem;