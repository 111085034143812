

import styled from "styled-components";
import { Container, Col } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import moment from 'moment'
import { useSelector } from "react-redux";

import "react-calendar/dist/Calendar.css";


//components
import Header from "../../components/Header";
import DatePicker from "../../components/DatePicker";

//api
import MyLaserApi from "../../api/MyLaserApi";

import { useParams } from "react-router";

import DataTable from 'react-data-table-component';

import Swal from 'sweetalert2'
import { v4 as uuidv4 } from 'uuid';
import { DataExchange } from "aws-sdk";


const ContainerEmployee = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100vh;
    background-color: transparent;
    padding-top: 30px;
    margin-top: 20px;
    width: 96%;
`;
const Block = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    background-color: #fff;
    margin-top: 20px;
    border-radius: 10px;
    width: 100%;
    box-shadow: 5px 2px 5px rgba(16, 41, 53, 0.1);
    `

const Text = styled.span`
    margin-left: 10px;
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 12px;
    &.bold {
        font-family: CenturyGothicBold;
    }
    &.big {
        font-family: CenturyGothicBold;
        font-size: 20px;
    }
    &.white {
        color: #fff;
    }
    &.orange {
        color: #F0B594;
    }
    &.size-plus {
        font-size: 19px;
    }
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;
    &.no-width {
        width: auto;
    }
    &.spaced-items {
        justify-content: space-between;
    }
`;

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

function AdminInvoices() {

    let params = useParams()

    let [payments, setPayments] = useState([])
    let [data2, setData2] = useState([])

    const user = useSelector((state) => state.user);
    const agencies = useSelector((state) => state.agencies);
    const [cAgency, setCAgency] = useState({})

    const [ainvoice, setAinvoice] = useState({})

    const [intervalDate, setIntervalDate] = useState(({start: moment().startOf('month').startOf('day').toDate(), end: moment().endOf('month').endOf('day').toDate()}))
 
    const reload = () => {

        MyLaserApi.call({url: `/agencies/${params.id}/ainvoice`}).then(({ data }) => {
            setAinvoice(data)
        })

        MyLaserApi.call({url: `/agencies/${params.id}/clients/payments?start=${intervalDate.start.getTime() / 1000}&end=${intervalDate.end.getTime() / 1000}&onlyverified=true`}).then(({ data }) => {
            setPayments(data)

            let zbra = {}

            data.map((single) => {

                if (single.uniqid) {
                    
                    if (zbra[single.uniqid]) {
                        if (single.treatment) {
                            zbra[single.uniqid].push(single)
                        }
                    } else {
                        if (single.treatment) {
                            zbra[single.uniqid] = [single]
                        }
                    }

                } else {
                    if (single.treatment) {
                        zbra[uuidv4()] = [single]
                    }
                }

            })

            setData2(zbra)

        })
    }

    useEffect(() => {

        reload()        

        if (agencies && agencies.length > 0) {
            setCAgency(agencies.find((agency) => agency.id == params.id))
        }


    }, [params.id, intervalDate, agencies]);

    return (
        <Container fluid className="App">

            <Row>
               
                <Col
                    className="col"
                    md={12}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >

                    <Header
                        user={user}
                        title={`Hello ${user.firstname},`}
                        subtitle="Bienvenue sur ton espace de travail"
                        icon
                    />

                    <ContainerEmployee>
                        <Row className="spaced-items">
                        <div style={{width: "50%"}}>
                            <Text className="big">Facture My Laser : {cAgency && cAgency.name} </Text> 

                            {global.country == "fr" && <div>
                            
                                {ainvoice.tot_sum &&<p style={{fontSize: "12px", paddingLeft: "20px"}}>

                                    <br />
                                    <i>Total espèce des centres depuis le 01/02/2022  (Saint-Denis / Lyon / Marseille): {global.numberWithSpaces( parseFloat( ainvoice.tot_sum) ) } {global.currencySymbol}</i>
                                    
                                    <br />
                                    Total espèce du centre sur la periode: {global.numberWithSpaces( parseFloat( payments.reduce( (s, p) => s +  (p.show_it_special === true ?  parseFloat(p.agency_income_type_1_amount ) : 0)  , 0 )) ) } {global.currencySymbol}
                                    
                                    <br />
                                    Total espèce du centre depuis le 01/02/2022 : {global.numberWithSpaces( parseFloat( ainvoice.tot_cb2) ) } {global.currencySymbol}

                                </p>}

                            </div>}
                        
                        </div>

                            <div style={{width: "50%"}}>

                                <DatePicker 
                                    onChange={(dates) => {
                                        setIntervalDate(dates)
                                    }}
                                />

                            </div>

                        </Row>

                        <Block>

                                <DataTable
                                    title={`Toutes les facturations (du ${moment(intervalDate.start).format('LL')} au ${moment(intervalDate.end).format('LL')})`}
                                    noDataComponent="Il n'y a aucune facture à afficher"
                                    pagination
                                    paginationPerPage={100}

                                    data={
                                        Object.values(data2).filter((group) => {

                                            let agency_income_type_1_amount = group.reduce((s, p) => s + (p.show_it_special === true ? parseFloat(p.agency_income_type_1_amount) : 0), 0)
                                            let agency_income_type_2_amount = group.reduce((s, p) => s + parseFloat(p.agency_income_type_2_amount), 0)
                                            let agency_income_type_3_amount = group.reduce((s, p) => s + parseFloat(p.agency_income_type_3_amount), 0)
                                            let agency_income_type_4_amount = group.reduce((s, p) => s + parseFloat(p.agency_income_type_4_amount), 0)

                                            let total = agency_income_type_1_amount + agency_income_type_2_amount + agency_income_type_3_amount + agency_income_type_4_amount

                                            if (total != 0) {
                                                return true
                                            }

                                        }).map((group) => {

                                            let agency_income_type_1_amount = group.reduce((s, p) => s + (p.show_it_special ? parseFloat(p.agency_income_type_1_amount) : 0), 0)
                                            let agency_income_type_2_amount = group.reduce((s, p) => s + parseFloat(p.agency_income_type_2_amount), 0)
                                            let agency_income_type_3_amount = group.reduce((s, p) => s + parseFloat(p.agency_income_type_3_amount), 0)
                                            let agency_income_type_4_amount = group.reduce((s, p) => s + parseFloat(p.agency_income_type_4_amount), 0)
                                            let refunds = 0

                                            let total = agency_income_type_1_amount + agency_income_type_2_amount + agency_income_type_3_amount + agency_income_type_4_amount

                                            group.map((el) => {
                                                refunds += el.refunds.reduce((s,p) => { return s + parseFloat(p.amount_refund)}, 0)
                                            })
    
                                            return {
                                                group: group,
                                                created_at: group[0].created_at,
                                                ids: group.map((el) => el.id),
                                                date: moment(group[0].date).format("DD/MM/YYYY"),
                                                amount: <>
                                                    {total - refunds} {global.currencySymbol}
                                                    <br />
                                                    {group[0].invoiced == false && <span
                                                        style={{
                                                            marginBottom: 20,
                                                            marginTop: 20,
                                                            fontFamily: "CenturyGothicBold",
                                                            fontSize: "13px",
                                                            color: "rgb(136 194 211)"
                                                        }}
                                                        onClick={() => {
                                                            Swal.fire({
                                                                title: 'Attention',
                                                                text: "Si vous envoyez la facture, vous ne pourrez plus modifier / supprimer le paiement.",
                                                                icon: 'warning',
                                                                showCancelButton: true,
                                                                confirmButtonColor: '#3085d6',
                                                                cancelButtonColor: '#d33',
                                                                confirmButtonText: 'Oui je veux envoyer la facture !',
                                                                cancelButtonText: "Annuler"
                                                            }).then((result) => {
    
                                                                if (result.isConfirmed) {
                                                                    MyLaserApi.invoicePayments(group.map((g) => g.id)).then(() => {
                                                                        reload()
                                                                    })
                                                                }
    
                                                            })
                                                        }}
                                                    >
                                                        Envoyer la facture
                                                    </span>}
                                                    
                                                </>,
    
                                                payment_type: (() => {
        
                                                    let res = []
    
                                                    if (agency_income_type_1_amount) {
                                                        res.push([`Esp ${agency_income_type_1_amount.toFixed(2)}${global.currencySymbol}`, <br />])
                                                    } 
                                                   
                                                    if (agency_income_type_2_amount) {
                                                        res.push([`CB ${agency_income_type_2_amount.toFixed(2)}${global.currencySymbol}`, <br />])
                                                    }
    
                                                    if (agency_income_type_3_amount) {
                                                        res.push([`ALMA ${agency_income_type_3_amount.toFixed(2)}${global.currencySymbol}`, <br />])
                                                    }
    
                                                    if (agency_income_type_4_amount) {
                                                        res.push([`Ch ${agency_income_type_4_amount.toFixed(2)}${global.currencySymbol}`, <br />])
                                                    }
                
                                                    if (refunds) {
                                                        res.push([`RBS -${refunds} ${global.currencySymbol}`, <br />])
                                                    }
    
                                                    return res
                
                                                })(),
    
                                                service: (() => {
    
                                                    let areas = []
    
                                                    group.map((payment) => {
                                                        areas = [...areas, ...payment.treatment.sessions.map((e) => e.area)]
                                                    })
    
                                                    return areas.map((e) => e).filter(onlyUnique).map((area) => [
                                                        `${group[0].treatment.type_human != null ? group[0].treatment.type_human.replace('Épilation Laser', 'E.P') : group[0].treatment.type} ${area && area != "-" ? "(" + area + ")" : ""} x${areas.filter((s) => s === area).length}`, 
                                                        <br />
                                                    ])
    
                                                })(),
    
                                                // agency: group[0].agency.name.replace('MY LASER', ''),
                                                sexe: group[0].agency_client.sexe === "homme" ? "Monsieur" : "Madame", 
                                                client: group[0].agency_client.firstname + " " + group[0].agency_client.lastname,
                                                employee: group[0].employee.firstname + " " + group[0].employee.lastname,
                                                collecter:  group[0].collected_by.firstname + " " + group[0].collected_by.lastname,
                                            }
    
                                        })

                                    }

                                    columns={[
                                        {
                                            name: "Date de création",
                                            selector: (row) => row.date,
                                            sortable: true
                                        },
                                        {
                                            name: "Nom du patient",
                                            selector: (row) => row.sexe + " " + row.client,
                                            sortable: true
                                        },
                                        {
                                            name: "Nom du praticien",
                                            selector: (row) => row.employee,
                                            sortable: true
                                        },
                                        {
                                            name: "Type de prestation",
                                            selector: (row) => row.service,
                                            sortable: true
                                        },
                                        {
                                            name: "Montant",
                                            selector: (row) => row.amount,
                                            sortable: true
                                        },

                                        {
                                            name: "Moyen de paiement",
                                            selector: (row) => row.payment_type,
                                            sortable: true
                                        },
                                    ]}
                                    icon
                                    patient
                                />

                        </Block>

                    </ContainerEmployee>

                </Col>

            </Row>
            

        </Container>
    );
}

export default AdminInvoices;
