import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css';
import styled from 'styled-components'
import { Image } from 'react-bootstrap'

//components
import Button from './Button'


const Container = styled.div`
display: flex;
flex-direction: Row;
align-items: center;
justify-content: space-between;
width: 100%;
background-color: #F8FAFD;
padding:20px;
border-radius: 15px;
margin-bottom: 20px;
margin-top: 20px;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

`
const Text = styled.span`
margin-left: 10px;
font-family: CenturyGothicBold;
color:#102935;
font-size: 20px;
&.gray {
  color:#B4CFD7;
  font-size: 25px;
  }

`

function SessionHeader(props) {



    return (
        <Container >

            {props.showReturn && props.return ? <Button className="no-background" onClick={props.return}>
                <Image src={"/return.svg"} style={{"transform": "rotate(180deg)"}} />
            </Button> : <p>{' '}</p>}

            <Col>
                <Text>{props.title ? props.title : "Ajout d’une nouvelle séance"}</Text>
                <Text className="gray">{props.text}</Text>
            </Col>
            <Button className="no-background" onClick={props.goBack}>
                <Image src={"/close-black.svg"}
                />
            </Button>
        </Container>
    );
}

export default SessionHeader;