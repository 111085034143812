import 'bootstrap/dist/css/bootstrap.min.css'
import '../../App.css';
import Loader from "react-js-loader";
import styled from 'styled-components'
import { Image } from 'react-bootstrap'
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from 'react';
import { Container, Col } from 'react-bootstrap'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Header from "../../components/Header";
import SideBar from "../../components/SideBar";
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';


import S3Handler from '../../services/S3Handler'

//api
import MyLaserApi from '../../api/MyLaserApi'


const ContainerTreatment = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
background-color: transparent;
margin-top: 20px;
width:96%;
`



const Text = styled.span`

font-family: CenturyGothicRegular;
color:#102935;
font-size: 13px;
&.bold {
  font-family: CenturyGothicBold;
 font-size: 15px;

  }
&.blue {
  color:#102935;
  }
`

const Row = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
width: 100%;
margin-top: 20px;
&.spaced-items {
justify-content: space-between;
}
&.custom-width {
    width: 50%;
}
&.center {
    align-items: center
}
&.no-margin {
    margin-top: 0px;
}

`
const Line = styled.div`
width: 100%;
height: 1px;
background-color: rgba(107,115,137,.3);
margin-top:30px ;
margin-bottom: 30px;
`

const Form = ({ informations, onInformationsChange, UpdateMe, setUserInformations, UpdatePassword }) => (

    <ContainerTreatment >
        <Text className="bold" >Mes informations personnelles</Text>
        <Row className="spaced-items">
            <Input style={{paddingLeft: "10px", paddingRight: "10px"}}  noIcon className="medium with-label" label="Nom" value={informations.lastname} onChange={(e) => onInformationsChange({ lastname: e.target.value })}
            />
            <Input style={{paddingLeft: "10px", paddingRight: "10px"}}  noIcon className="medium with-label" label="Prénom" value={informations.firstname} onChange={(e) => onInformationsChange({ firstname: e.target.value })} />
            <Input style={{paddingLeft: "10px", paddingRight: "10px"}}  noIcon className="medium with-label" label="Email" value={informations.email} onChange={(e) => onInformationsChange({ email: e.target.value })} />
        </Row>
        <Row className="spaced-items">
            <Input style={{paddingLeft: "10px", paddingRight: "10px"}}  noIcon className="medium with-label" label="N° de téléphone" value={informations.phone_number} onChange={(e) => onInformationsChange({ phone_number: e.target.value })} />
            
            <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 30 }}>
                <Text>Centre My Laser auquel j’appartiens</Text>
                {window.country == "fr" && <Row className="spaced-items " style={{ marginTop: 10 }}>
                    <Button className={`custom-width borderd ${informations.agency_id === 2 ? 'selected' : 'regular'}`}>Saint Denis</Button>
                    <Button className={`custom-width borderd ${informations.agency_id === 3 ? 'selected' : 'regular'}`}>Lyon</Button>
                    <Button className={`custom-width borderd ${informations.agency_id === 4 ? 'selected' : 'regular'}`}>Marseille</Button>
                    <Button className={`custom-width borderd ${informations.agency_id === 5 ? 'selected' : 'regular'}`}>Toulouse</Button>
                </Row>}
                {window.country == "ma" && <Row className="spaced-items " style={{ marginTop: 10 }}>
                    <Button className={`custom-width borderd ${informations.agency_id === 1 ? 'selected' : 'regular'}`}>Casablanca</Button>
                </Row>}
            </Col>


        </Row>
        <Row style={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}>
            {/* <Button className="gray custom-width" onClick={setUserInformations} >Annuler les modifications</Button> */}
            <Button className="custom-width" onClick={UpdateMe} >Sauvegarder les modifications</Button>
        </Row>
        <Line />
        <Text className="bold" >Mon mot de passe</Text>
        <Text>Tu peux modifier ton mot de passe à tout moment ici</Text>
        <Row className="spaced-items">
            <Input style={{paddingLeft: "10px", paddingRight: "10px"}} noIcon className="medium with-label" placeholder="************" label="Mot de passe actuel" value={informations.password} onChange={(e) => onInformationsChange({ password: e.target.value })} />
            <Input style={{paddingLeft: "10px", paddingRight: "10px"}}  noIcon className="medium with-label" placeholder="" label="Nouveau mot de passe" value={informations.new_password} onChange={(e) => onInformationsChange({ new_password: e.target.value })} />
            <Input style={{paddingLeft: "10px", paddingRight: "10px"}}  noIcon className="medium with-label" placeholder="" label="Confirmer nouveau mot de passe" value={informations.confirm_password} onChange={(e) => onInformationsChange({ confirm_password: e.target.value })} />
        </Row>
        <Row style={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}>

            <Button className="custom-width" onClick={UpdatePassword} >Changer mon mot de passe</Button>
        </Row>
    </ContainerTreatment>
);





function MyAccount(props) {

    const user = useSelector((state) => state.user);

    const [informations, setInformations] = useState({
        lastname: '',
        firstname: '',
        email: '',
        phone_number: '',
        agency_id: '',
        password: '',
        new_password: '',
        confirm_password: ''
    });

    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setUserInformations()
    }, [user]);


    const setUserInformations = () => {
        if (user.lastname) {
            setInformations({
                ...informations,
                lastname: user.lastname,
                firstname: user.firstname,
                email: user.email,
                phone_number: user.phone_number,
                agency_id: user.agency_id,
            })
        }
    }


    const onInformationsChange = (obj) => {
        setInformations((prevState) => {
            return {
                ...prevState,
                ...obj
            };
        });
    };

    const UpdateMe = () => {
        setLoading(true)
        const user = {
            lastname: informations.lastname,
            firstname: informations.firstname,
            email: informations.email,
            phone_number: informations.phone_number,
        }

        MyLaserApi.UpdateMe(user).then((res) => {

            if (res.status === 200) {
                toast.success("Mise à jour des informations.")
            } else {
                toast.error(MyLaserApi.handleErrors(res.errors))
            }
            setLoading(false)
        })
    };




    const UpdatePassword = () => {
        setLoading(true)
        const password = {
            password_old: informations.password,
            password_new: informations.new_password,
            password_new_verif: informations.confirm_password,
        }

        MyLaserApi.UpdatePassword(password).then((res) => {

            if (res.status === 200) {
                toast.success("Mot de passe mis à jour avec succès.")
            } else {
                toast.error("Une erreur s'est produite !")
            }
            setLoading(false)
        })
    };
    const uploadImage = (event) => {
        let filetmp = event.target.files[0]
        let filename = `${uuidv4()}.jpg`

        if (["image/png", "image/bmp", "image/gif", "image/jpeg"].includes(filetmp.type)) {

            toast.info("🕒 Votre image est en cours de téléversement.")

            S3Handler.upload({
                filename: `user-profil/${filename}`,
                file: filetmp
            }).then((result) => {
                MyLaserApi.UpdateMyPicture({ picture: filename }).then((res) => {
                    if (res.status === 200) {
                        toast.success("🚀 Image chargé avec succès.")
                    } else {
                        toast.error("Une erreur s'est produite !")
                    }
                })


            })


        } else {
            toast.error("L'image n'est pas au bon format.")
        }
    }

    if (user.lastname === undefined) {
        return null
    }
    return (
        <Container fluid className="App" >
            <Row className="no-margin">
                <Col md={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                    <Header
                        user={user}
                        title={`Hello ${user.firstname},`}
                        subtitle="Bienvenue sur ton espace de travail"
                        icon
                        uploadImage={uploadImage}
                    />

                 
                    {loading ? (
                        <div className={"loader"}>
                            <Loader
                                type="spinner-circle"
                                bgColor={"#F0B594"}
                                size={70}
                            />
                        </div>
                    )
                        :
                        <Form
                            informations={informations} onInformationsChange={onInformationsChange} UpdateMe={UpdateMe} setUserInformations={setUserInformations} UpdatePassword={UpdatePassword}
                        />
                    }


                </Col>
            </Row>

        </Container>

    );
}

export default MyAccount;