import * as React from "react"

function BookMark(props) {
    return (
        <svg
            width="20px"
            height="20px"
            viewBox="0 0 18.333 20.002"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            {...props}
        >
            <path
                id="fi-rr-bookmark"
                d="M16.947,20a2.333,2.333,0,0,1-1.656-.7l-5.125-5.095-5.125,5.1a2.333,2.333,0,0,1-2.579.507A2.333,2.333,0,0,1,1,17.627V4.166A4.166,4.166,0,0,1,5.166,0h10a4.166,4.166,0,0,1,4.166,4.166V17.627a2.333,2.333,0,0,1-1.459,2.187,2.389,2.389,0,0,1-.927.185ZM5.166,1.667a2.5,2.5,0,0,0-2.5,2.5V17.627a.7.7,0,0,0,1.2.5h0l5.719-5.684a.833.833,0,0,1,1.175,0l5.712,5.682a.7.7,0,0,0,1.2-.5V4.166a2.5,2.5,0,0,0-2.5-2.5Z"
                transform="translate(-1)"
                fill={props.selected ? "#102935": "#9FA9AE"}
            />
        </svg>
    )
}

export default BookMark
