import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container, Col, Row, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
// import Calendar from "react-calendar";
// import "react-calendar/dist/Calendar.css";

import Button from "../../../components/Button";
import ButtonLoader from "../../../components/ButtonLoader";

import { useHistory, useParams, useLocation } from "react-router-dom";

import SessionHeader from "../../../components/SessionHeader";

//api
import MyLaserApi from "../../../api/MyLaserApi";

import moment from "moment";

function CreateSession() {
    const client = useSelector((state) => state.client);

    const history = useHistory();
    const params = useParams();
    const location = useLocation();

    let treatments = [];

    if (location.state.treatment) {
        treatments.push(location.state.treatment);
    } else {
        treatments = location.state.treatments;
    }

    return (
        <Container fluid style={{ padding: "50px", paddingTop: 0 }}>
            <SessionHeader
                title="Votre devis est prêt !"
                goBack={() => {
                    history.go(-2);
                }}
            />

            <Row
                style={{
                    width: "100%",
                    borderBottom: "4px solid black",
                    marginBottom: "40px",
                    marginTop: "40px",
                }}
            >
                <Col
                    xs={6}
                    className="col"
                    style={{
                        alignItems: "flex-start",
                        marginBottom: "40px",
                        color: "#0B3246",
                        fontWeight: "bold",
                    }}
                >
                    {client.firstname} {client.lastname} <br />
                    {client.address_line} <br />
                    {client.address_zip_code} {client.address_city} <br />
                </Col>
                <Col
                    xs={6}
                    className="col"
                    style={{ alignItems: "flex-end", textAlign: "right" }}
                >
                    <h5 style={{ color: "#F0B594", fontWeight: "bold" }}>
                        DEVIS MY LASER
                    </h5>
                    <span style={{ color: "#0B3246" }}>
                        Le {moment().format("LLLL")}
                    </span>
                </Col>
            </Row>

            <Row style={{ width: "100%" }}>
                <Col xs={12} className="col">
                    {treatments &&
                        treatments.map((treatment, index) => {
                            if (treatment.type === "hair-bleaching") {
                                return treatment.sessions.map(
                                    (session, index) => {
                                        return (
                                            <Row style={{ width: "100%" }}>
                                                <Col
                                                    xs={4}
                                                    className="col"
                                                    style={{
                                                        alignItems:
                                                            "self-start",
                                                    }}
                                                >
                                                    {index === 0 && (
                                                        <h5
                                                            style={{
                                                                color: "#F0B594",
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            TYPE DE SEANCE
                                                        </h5>
                                                    )}
                                                    1 Séance /{" "}
                                                    {session.type_human}{" "}
                                                    {session.area &&
                                                    session.area != "-"
                                                        ? ` - ${session.area}`
                                                        : ""}
                                                </Col>
                                                <Col
                                                    xs={4}
                                                    className="col"
                                                    style={{
                                                        alignItems:
                                                            "self-start",
                                                    }}
                                                >
                                                    {index === 0 && (
                                                        <h5
                                                            style={{
                                                                color: "#F0B594",
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            PRIX DE LA SEANCE
                                                        </h5>
                                                    )}
                                                    {session.forced_price.toFixed(
                                                        2
                                                    )}{" "}
                                                    {window.currencySymbol}
                                                </Col>
                                            </Row>
                                        );
                                    }
                                );
                            }

                            return (
                                <Row style={{ width: "100%" }}>
                                    <Col
                                        xs={4}
                                        className="col"
                                        style={{ alignItems: "self-start" }}
                                    >
                                        {index === 0 && (
                                            <h5
                                                style={{
                                                    color: "#F0B594",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                TYPE DE SEANCE
                                            </h5>
                                        )}
                                        {treatment.sessions.length} Séance /{" "}
                                        {treatment.sessions[0].type_human}{" "}
                                        {treatment.sessions[0].area &&
                                        treatment.sessions[0].area != "-"
                                            ? ` - ${treatment.sessions[0].area}`
                                            : ""}
                                    </Col>
                                    <Col
                                        xs={4}
                                        className="col"
                                        style={{ alignItems: "self-start" }}
                                    >
                                        {index === 0 && (
                                            <h5
                                                style={{
                                                    color: "#F0B594",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                PRIX DE LA SEANCE
                                            </h5>
                                        )}
                                        {(
                                            treatment.forced_price /
                                            treatment.sessions.length
                                        ).toFixed(2)}{" "}
                                        {window.currencySymbol}
                                    </Col>
                                </Row>
                            );
                        })}
                </Col>
            </Row>

            <Row style={{ width: "100%" }}>
                <Col xs={12} className="col">
                    <Row style={{ width: "100%" }}>
                        <Col
                            xs={4}
                            className="col"
                            style={{ alignItems: "flex-start" }}
                        >
                            {" "}
                        </Col>
                        <Col
                            xs={8}
                            className="col"
                            style={{ alignItems: "flex-end" }}
                        >
                            <h5
                                style={{
                                    color: "black",
                                    borderBottom: "3px solid black",
                                    paddingBottom: "5px",
                                    paddingLeft: "70px",
                                    fontWeight: "bold",
                                }}
                            >
                                TOTAL (
                                {treatments.reduce(
                                    (old, newo) => old + newo.sessions.length,
                                    0
                                )}{" "}
                                séances){" "}
                            </h5>
                            <h5
                                style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    fontSize: "32px",
                                }}
                            >
                                {treatments
                                    .reduce(
                                        (old, newo) =>
                                            parseFloat(old) +
                                            parseFloat(newo.forced_price),
                                        0
                                    )
                                    .toFixed(2)}{" "}
                                {window.currencySymbol}{" "}
                            </h5>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row style={{ width: "100%", marginTop: "75px" }}>
                <Col xs={12} className="col">
                    <Row style={{ width: "100%" }}>
                        <Col
                            xs={2}
                            className="col"
                            style={{ alignItems: "self-start" }}
                        >
                            {" "}
                        </Col>

                        <Col
                            xs={8}
                            className="col"
                            style={{ alignItems: "self-end" }}
                        >
                            <ButtonLoader
                                style={{ width: "100%", marginBottom: "15px" }}
                                onClick={() => {
                                    const type = treatments[0].sessions[0].type;
                                    let consent = "";

                                    if (type === "endolift") {
                                        consent = "endolift";
                                    } else if (type === "detatouage") {
                                        consent = "detatouage";
                                    } else if (type === "taches-brune") {
                                        consent =
                                            "traitement-des-lesions-pigmentaires-melasma";
                                    } else if (type === "laser-frax-mes") {
                                        consent =
                                            "traitement-cicatrice-et-remodelage";
                                    } else if (
                                        type === "varicosite-couperose"
                                    ) {
                                        consent = "laser-vasculaire";
                                    } else if (type === "acne-active") {
                                        consent = "gold-tonning";
                                    } else if (type === "ah") {
                                        consent = "acide-hyaluronique";
                                    } else if (type === "peeling") {
                                        consent = "peeling-tca";
                                    } else if (type === "carbon-peel") {
                                        consent = "carbon-peel";
                                    } else if (type === "laser-frax") {
                                        consent =
                                            "traitement-cicatrice-et-remodelage";
                                    } else if (type === "peeling-intimate") {
                                        consent = "peeling-intime";
                                    } else if (type === "prp") {
                                        consent = "traitement-capillaire";
                                    } else if (type === "hair-bleaching") {
                                        consent = "hair-bleaching";
                                    } else if (type === "botox") {
                                        consent = "toxine-botulique";
                                    } else if (type === "blemiderm") {
                                        consent = "blemiderm";
                                    } else if (type === "dermamelan") {
                                        consent = "dermamelan";
                                    } else if (type === "peri-oculaire-meso") {
                                        consent = "mesopeel-mesohyal";
                                    } else if (type === "electrolyse") {
                                        consent = "electrolyse";
                                    }

                                    if (
                                        treatments[0].sessions[0].type ===
                                        "dermamelan"
                                    ) {
                                        treatments[0].sessions[0].area =
                                            "1er séance";
                                        treatments[0].sessions[1].area =
                                            "Suivi J + 30";
                                        treatments[0].sessions[2].area =
                                            "Suivi J + 60";
                                    }

                                    return MyLaserApi.call({
                                        method: "POST",
                                        url: `/agencies/clients/${params.id}/quotations/others`,
                                        body: {
                                            type: type,
                                            type_human:
                                                treatments[0].sessions[0]
                                                    .type_human,
                                            stype: treatments[0].sessions[0]
                                                .stype,
                                            treatments: treatments,
                                            consent: consent,
                                        },
                                    }).then((quotation) => {
                                        history.push(
                                            `/patients/${params.id}/quotations/${params.type}`
                                        );
                                    });
                                }}
                            >
                                Je confirme ce devis
                            </ButtonLoader>
                            <Button
                                style={{ width: "100%", background: "#F25151" }}
                                onClick={() => {
                                    history.goBack();
                                }}
                            >
                                Je ne confirme pas ce devis
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default CreateSession;
