import moment from 'moment'
import Swal from "sweetalert2";
import MyLaserApi from '../api/MyLaserApi';

export const columns = {

    date: ({history, params, user, type, reload, payments, dragging, setDragging, onDrag}) => ({
        name: "Date",
        selector: (row) => {

            let date = moment(row.el.date).format("LL")

            let rtn = [ <div style={{marginTop: 10}}>{date}</div> ]

            if (row.el.old_ard_id || row.el.area === "Consultation") {
                rtn = [ 
                    <div style={{marginTop: 10}}>
                        {date}
                        <br />
                        { (row.el.type === "blemiderm" || row.el.type === "dermamelan") && row.single.cream == 1 && <b>Crème préparatoire fournie à ce jour.</b>}
                    
                    </div> 
                ]
            } else if (!date.includes('1970')) {

                rtn = [ 
                    <div style={{marginTop: 10}}>{date}</div>,
                    <br />
                ]

                var duration = moment.duration(moment().diff(moment(row.created_at)));
                var hours = duration.asHours();

                if ( type && ([1, 2, 7].includes(user.role) || hours < 48) ) {
                
                    rtn = [ ...rtn, 
                        <button
                        class="action-btn"
                            style={{
                                fontFamily: "CenturyGothicBold",
                                fontSize: "12px",
                                color: "rgb(136 194 211)"
                            }}
                            onClick={() => history.push(`/patients/${params.id}/treatments/update-session/${params.option}/${row.el.id}`, { data: row.el })}
                        >
                            Modifier
                        </button> 
                    ]

                    rtn = [ ...rtn, 
                        <button
                            class="action-btn"
                            style={{
                                marginTop: 5,
                                marginBottom: 5,
                                fontFamily: "CenturyGothicBold",
                                fontSize: "12px",
                                color: "rgb(255, 150, 141)"
                            }}
                            // onClick={() => history.push(`/patients/${params.id}/treatments/update-session/${params.option}/${row.el.id}`, { data: row.el })}
                            onClick={() => {

                                Swal.fire({
                                    title: 'Attention',
                                    text: "Vous êtes sur de supprimer les paramètres de cette séance ?",
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Oui je veux les supprimer !',
                                    cancelButtonText: "Annuler"
                                }).then((result) => {

                                    if (result.isConfirmed) {

                                        MyLaserApi.ResetSession(row.el.agency_client_id, row.el.id).then(() => {
                                            reload()
                                        })

                                    }

                                })

                            }}
                        >
                            Supprimer les paramètres
                        </button> 
                    ]

                    if (setDragging && onDrag) {

                        rtn = [ ...rtn,
                            <div
                                class="action-btn"
                                onClick={(e) => {
                                    setDragging(row.el)
                                    // e.dataTransfer.setData("text/plain", JSON.stringify(row.el));
                                }}
                                onDragEnd={(e) => {
                                    setDragging(null)
                                }}
                                style={{
                                    marginTop: 5,
                                    marginBottom: 5,
                                    fontFamily: "CenturyGothicBold",
                                    fontSize: "12px",
                                    color: "#FFBF00",
                                    cursor: "move",
                                    textAlign: "center"
                                }}
    
                            >
                                Dupliquer les paramètres
                            </div>
                        ]

                    }

                }

                return rtn

            } else {

                rtn = [
                    <span
                        style={{
                            marginBottom: 20,
                            marginTop: 20,
                            fontFamily: "CenturyGothicBold",
                            fontSize: "13px",
                            color: "rgb(136 194 211)"
                        }}
                        onClick={() => history.push(`/patients/${params.id}/treatments/update-session/${params.option}/${row.el.id}`, { data: row.el })}
                    >
                        Faire la séance <br />
                        <span style={{fontSize: "12px", fontStyle: 'italic'}}>Reste: {row.rest} </span> 
                        {row.waiting != 0 && [<br/>, <span style={{fontSize: "12px", fontStyle: 'italic', color: '#eb3446'}}> {row.waiting} en attente</span>]}

                    </span> 
                ]

            }

            if (dragging && row.el.area != "Consultation") {

                rtn = [...rtn, 
                    <div 
                        class="action-btn"

                        style={{
                            marginTop: 5,
                            marginBottom: 5,
                            fontFamily: "CenturyGothicBold",
                            fontSize: "12px",
                            color: "#FFBF00",
                            cursor: "move",
                            textAlign: "center"
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            // const dragged = e.dataTransfer.getData("text/plain")

                            onDrag(dragging, row.el)

                        // console.log(e.dataTransfer.getData("text/plain"))

                        // MyLaserApi.DuplicateSession(dragging.id, row.el.id).then(() => {
                        //     reload()
                        // })

                        }}>
                        Copier ici les paramètres
                    </div>    
                ]
            
            }

            return rtn

        },
        wrap: true,
        grow: 2
    }),

    notes: ({ onClick }) => ({
        name: "Notes",
        selector: (row) => {
            let notes = "N/C"

            if (row && row.single && row.single.note) {
                notes = row.single.note.length > 30 ? row.single.note.substring(0, 30) + "..." : row.single.note
            }

            return <span onClick={() => row.single.note.length > 30 && onClick(row.single.note)}>{notes}</span>

        },
        wrap: true,
        grow: 2
    }),

    attachments: ({ onClick }) => ({
        name: "Pièce ajoutée",
        selector: (row) => {
            
            if (row.el.attachments.length === 0) {
                return "Aucunes pièces"
            } else {
                return (<span 
                    
                    onClick={() => onClick(row.el.attachments)}
                    style={{
                        marginBottom: 20,
                        marginTop: 20,
                        fontFamily: "CenturyGothicBold",
                        fontSize: "12px",
                        color: "rgb(136 194 211)",
                    }}
                >
                    {row.el.attachments.length} image(s)
                </span>)
            }

        },
        wrap: true,
        grow: 2
    }),

    employee: () => ({
        name: "Praticien(ne)",
        selector: (row) =>  row.el.employee ? row.el.employee.firstname + " " + row.el.employee.lastname : "N/C",
        wrap: true,
        grow: 2
    }),

    laser_normal: ({name, column}) => ({

        name: name,
        selector: (row) =>  {

            let rtn = [ row.single[column] || "N/C" ]
            
            if (row.single.extra && row.single.extra[column]) {
                rtn = [ ...rtn, <br/>, row.single.extra[column] || "N/C" ]
            }

            return rtn

        }

    }),

    laser_with_label: ({name, column}) => ({

        name: name,
        selector: (row) =>  {

            let rtn = [ (row.single[column] || {label: "N/C"}).label ]
            
            if (row.single.extra && row.single.extra[column]) {
                rtn = [ ...rtn, <br/>, (row.single.extra[column] || {label: "N/C"}).label ]
            }

            return rtn

        },

    }),

    simple: ({name, column}) => ({
        name: name,
        selector: (row) =>  row.el[column] || "N/C",
        wrap: true,
        grow: 2
    })


}