import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css';
import styled from 'styled-components'
import { Modal, Image } from 'react-bootstrap'
import ImageUploading from "react-images-uploading";
import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { Redirect, useHistory } from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//components
import ModalC from './Modal'
import Input from './Input'
import Button from './Button'

//api
import MyLaserApi from '../api/MyLaserApi'

import S3Handler from '../services/S3Handler'
import ImageListUpload from './ImageListUpload'

import Select from 'react-select'

const Label = styled.span`
  font-family: CenturyGothicRegular;
  color:#102935;
  font-size: 13px;
  margin-bottom: 10px;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    width: 100%;
`

const Text = styled.span`
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 13px;
    &.bold {
        font-family: CenturyGothicBold;
        color: #f0b594;
        font-size: 15px;
    }
    &.gray {
        color: #9fa9ae;
    }
    &.underline {
        text-decoration-line: underline;
    }
`;

const ImagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
    width: 100%;
    padding: 10px;
    background-color: #F8F9FB;
    border-radius: 10px;
    margin-bottom: 10px;
`

function CreateSpecialNoteTrackModal(props) {

    const [images, setImages] = useState([]);

    const [comment, setComment] = useState('');

    const [date, setDate] = useState(new Date());

    const createNoteTrack = async () => {
        
        let attachments = await S3Handler.uploadFromList(images)

        let create = {
            date: date,
            comment: comment,
            attachments: attachments,
        }

        return MyLaserApi.CreateNoteTrack(props.clientId, props.sessionId, create).then((res) => {
            if (res.status === 201) {
                toast.success("Suivi envoyée avec succès.")
                props.onHide()
            } else {
                toast.error("Une erreur s'est produite !")
            }
        })
    };


    const onCommentChange = (value) => {
        setComment(value)
    };

    return (

        <ModalC
            show={props.show}
            onHide={props.onHide}
            title={"Ajout un suivi"}
            buttonText="Valider le suivi"
            onButtonClick={createNoteTrack}
            size="lg"
        >

            <div style={{ display: 'flex', alignItems: 'flex-start', width: '100%', flexDirection: 'column' }}>
                <Label style={{ marginBottom: 10 }}>Date</Label>
                <DatePicker selected={date} onChange={(date) => setDate(date)} className="gray" dateFormat={"dd/MM/yyyy"}/>
            </div>

            <br />

            <div style={{ display: 'flex', alignItems: 'flex-start', width: '100%', flexDirection: 'column' , marginTop: "20px"}}>

                <Label style={{ marginBottom: 10 }}>Situation de l’effet secondaire (préciser l’état de la zone touchée ainsi que les indications communiquées au patient)</Label>

                <Input
                    noIcon
                    className="multiline"
                    placeholder="Écrivez le commentaire ici…"
                    value={comment}
                    textArea
                    onChange={(e) => onCommentChange(e.target.value)}
                />

            </div>

            <br />
            
            <div style={{ width: "100%", marginTop: "10px" }}>

                <ImageListUpload onChange={(imageList) => {
                    setImages(imageList)
                }} label="Ajouter des photos" />

            </div>

        </ModalC>
    );
}

export default CreateSpecialNoteTrackModal;