import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css';
import styled from 'styled-components'
import { Image } from 'react-bootstrap'

const Container = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
width: 95%;
align-self: center;
margin-bottom: 20px;
`


const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Text = styled.span`

font-family: CenturyGothicRegular;
color:#102935;
font-size: 12px;

`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

`

function PatientInformationsItem(props) {




   

    return (
        <Container >
            { props.infos.map((info) => {
                return (<Col style={{}}>
                    <Text>{info}</Text>
                </Col>)
            })}

            {  props.image && <Row>
                <Image src={"/img.svg"}

                />
                <Text className="gray" style={{ marginLeft: 10 }}>Voir l’image</Text>
            </Row>}

        </Container>
    );
}

export default PatientInformationsItem;