import React, { useState, useEffect } from "react";
import Modal from "../../../components/Modal";
import { Form } from "react-bootstrap";
import MyLaserApi from "../../../api/MyLaserApi";
import Button from "../../../components/Button";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import ButtonLoader from "../../../components/ButtonLoader";

import Input from "../../../components/Input";

export default ({ data, setShow, callback }) => {
    const [zones, setZones] = useState([]);
    const [zone, setZone] = useState("");

    const [items, setItems] = useState([]);

    const [quotation, setQuotation] = useState({});

    const [selectedDuration, setSelectedDuration] = useState(15);
    const [selectedPrice, setSelectedPrice] = useState(50);

    const client = useSelector((state) => state.client) || {};

    const [isLoading, setIsLoading] = useState(false);

    const [oldids, setOldids] = useState([]);

    let params = useParams();

    useEffect(() => {
        // if (data) {
        //     let arr = [];
        //     let oldidss = [];
        //     JSON.parse(data.items).map((single) => {
        //         oldidss = [...oldidss, ...single.id];
        //         if (single.id.length == 1) {
        //             arr.push({ id: single.id[0], name: single.name });
        //         } else {
        //             let names = single.name.split(" + ");
        //             single.id.map((id, index) => {
        //                 arr.push({ id: id, name: names[index] });
        //             });
        //         }
        //     });
        //     setOldids(oldidss);
        //     setItems(arr);
        // }
    }, []);

    return (
        <Modal
            show={data !== false}
            onHide={() => {
                setZone("");
                setZones([]);
                setOldids([]);
                setQuotation({});

                setShow(false);
            }}
            title={"Ajouter des séances"}
        >
            {data && (
                <>
                    <span>Zone que vous souhaitez ajouter : </span>

                    <input
                        type="text"
                        value={zone}
                        onChange={(e) => setZone(e.target.value)}
                        style={{ width: "100%", borderRadius: 5 }}
                    />
                    <hr />
                    <span>Durée :</span>
                    <div className="mb-3">
                        {[
                            { duration: 15, price: 50 },
                            { duration: 30, price: 95 },
                            { duration: 45, price: 140 },
                            { duration: 60, price: 180 },
                        ].map(({ duration, price }) => {
                            return (
                                <Form.Check
                                    type="radio"
                                    inline
                                    label={duration + " min - " + price + "€"}
                                    checked={selectedDuration === duration}
                                    id={`duration` + duration}
                                    onChange={() => {
                                        setSelectedDuration(duration);
                                        setSelectedPrice(price);
                                    }}
                                />
                            );
                        })}
                    </div>

                    <Button
                        style={{
                            width: "100%",
                            marginTop: 25,
                            marginBottom: 25,
                        }}
                        className="blue"
                        disabled={zone.length == ""}
                        onClick={async () => {
                            MyLaserApi.CreateClientSession(
                                {
                                    date: new Date(1970),
                                    type: "electrolyse",
                                    type_human: "ELECTROLYSE",
                                    area: zone,
                                    data: {
                                        duration: selectedDuration,
                                        price: selectedPrice,
                                    },
                                    attachments: [],
                                    forced_price: selectedPrice,
                                    quotation_other_id: data.id,
                                },
                                data.agency_client_id
                            ).then((res) => {
                                if (res.data) {
                                    toast.success(
                                        "La séance a bien été ajoutée"
                                    );
                                    callback();
                                } else {
                                    toast.error("Une erreur est survenue");
                                }
                            });
                        }}
                    >
                        Créer la séance
                    </Button>
                </>
            )}
        </Modal>
    );
};
