

import styled from "styled-components";
import { Container, Col } from "react-bootstrap";
import React, { useState, useRef, useEffect } from "react";
import moment from 'moment'
import { useSelector } from "react-redux";
import TableWithStatistics from "../../components/Tables/TableWithStatistics";

import "react-calendar/dist/Calendar.css";

import {
    XYPlot,
    XAxis,
    YAxis,
    VerticalGridLines,
    HorizontalGridLines,
    VerticalBarSeries,
    makeWidthFlexible
} from "react-vis";

import MouseTooltip from 'react-sticky-mouse-tooltip';

//components
import Header from "../../components/Header";
import Picker from "../../components/Picker";
import DatePicker from "../../components/DatePicker";
import Button from "../../components/Button";
import Employee from "../../components/Employee";
import PatientListHeader from "../../components/PatientListHeader"
import Table from "../../components/Table"
import { useHistory } from 'react-router';


//api
import MyLaserApi from "../../api/MyLaserApi";

import colors from "../../services/Colors"

import DataTable from 'react-data-table-component';


const FlexibleWidthXYPlot = makeWidthFlexible(XYPlot);

const ContainerEmployee = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100vh;
    background-color: transparent;
    padding-top: 30px;
    margin-top: 20px;
    width: 96%;
`;
const Block = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    background-color: #fff;
    margin-top: 20px;
    border-radius: 10px;
    width: 100%;
    box-shadow: 5px 2px 5px rgba(16, 41, 53, 0.1);
    `

const Text = styled.span`
    margin-left: 10px;
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 12px;
    &.bold {
        font-family: CenturyGothicBold;
    }
    &.big {
        font-family: CenturyGothicBold;
        font-size: 20px;
    }
    &.white {
        color: #fff;
    }
    &.orange {
        color: #F0B594;
    }
    &.size-plus {
        font-size: 19px;
    }
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;
    &.no-width {
        width: auto;
    }
    &.spaced-items {
        justify-content: space-between;
    }
`;
const Option = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 170px;
    background-color: #EDF0F5;
    color:#102935;
    padding: 10px;
    &.selected {
        background-color: #102935;
        color:#fff;
        border-radius: 10px;
        width: 189px;
    }
    &.left {
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
    }  
    &.right {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
    }
   
`;

const List = styled.div`
    margin-top: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
   grid-gap: 20px;

`;

function Sessions() {

    let [intervalDate, setIntervalDate] = useState({start: moment().subtract(7, "days").startOf('day').toDate(), end: moment().endOf('day').toDate()})

    let [statisticsType, setStatisticsType] = useState('payments')

    let [statsTotal, setStatsTotal] = useState({
        payments: 0,
        sessions: 0
    })

    const user = useSelector((state) => state.user);
    const history = useHistory()

    let sessionsTable = useRef()

    useEffect(() => {
        if (user.role != 1) {
            history.push("/home")
        }
    }, [user])

    return (
        <Container fluid className="App">

            <Row>
               
                <Col
                    className="col"
                    md={12}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >

                    <Header
                        user={user}
                        title={`Hello ${user.firstname},`}
                        subtitle="Bienvenue sur ton espace de travail"
                        icon
                    />

                    <ContainerEmployee>
                        <Row className="spaced-items">
                            <Text className="big">L’activité de vos prestations</Text>
                        </Row>

                        <Block>
                            <Row className="spaced-items" >
                                <Row >

                                    <Option className={`${statisticsType == "payments" ? "selected" : ""} left`} onClick={() => setStatisticsType("payments")}>
                                        <Text className={`${statisticsType == "payments" ? "bold white" : ""}`}  >Chiffre d’Affaires</Text>
                                        <Text className={`${statisticsType == "payments" ? "size-plus white" : "size-plus"}`} >{ statsTotal.payments } {global.currencySymbol}</Text>

                                    </Option>

                                    <Option className={`${statisticsType == "sessions" ? "selected" : ""} right`} onClick={() => setStatisticsType("sessions")}>
                                        <Text className={`${statisticsType == "sessions" ? "bold white" : ""}`}  >Patients</Text>
                                        <Text className={`${statisticsType == "sessions" ? "size-plus white" : "size-plus"}`}  >{ statsTotal.sessions }</Text>
                                    </Option>

                                </Row>

                                <DatePicker 
                                    onChange={(dates) => {
                                        setIntervalDate(dates)
                                    }}
                                />
                                
                            </Row>

                        </Block>

                        <br />
                        <br />

                        <Block>

                            <TableWithStatistics 
                                ref={sessionsTable}
                                title="Activité de vos prestations"
                                url="/agencies/clients/sessions/statistics"
                                intervalDate={intervalDate} 
                                statisticsType={statisticsType}
                                headerColumn="type_human"
                                setHeadersFunction={(data) => {
                                    return [...data.sessions.map((el) => el.type_human), ...data.payments.map((el) => el.type_human)].filter(global.onlyUnique).map((el, index) => ({ name: el, id: el }))
                                }}
                                onReload={() => {
                                    setStatsTotal({
                                        payments: sessionsTable && sessionsTable.current && sessionsTable.current.getValueFromStatistic("payments", "total_amount"),
                                        sessions: sessionsTable && sessionsTable.current && sessionsTable.current.getValueFromStatistic("sessions", "sessions_count")
                                    })
                                }}
                            />


                        </Block>

                    </ContainerEmployee>

                </Col>

            </Row>
            


        </Container>
    );
}

export default Sessions;
