import * as React from "react"

function Notes(props) {

    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          enableBackground="new 0 0 106.86 122.88"
          version="1.1"
          viewBox="0 0 106.86 122.88"
          xmlSpace="preserve"
          width="20px"
          height="20px"
        >
          <path fill={props.selected ? "#102935": "#9FA9AE"} d="M46.77 116.58a3.15 3.15 0 110 6.3H7.33c-2.02 0-3.85-.82-5.18-2.15A7.301 7.301 0 010 115.55V7.33c0-2.02.82-3.85 2.15-5.18A7.301 7.301 0 017.33 0h90.02c2.02 0 3.85.83 5.18 2.15a7.301 7.301 0 012.15 5.18v50.14a3.15 3.15 0 11-6.3 0V7.33c0-.28-.12-.54-.31-.72-.19-.19-.45-.31-.72-.31H7.33c-.28 0-.54.12-.73.3-.18.2-.3.45-.3.73v108.21c0 .28.12.54.31.72.19.19.45.31.73.31h39.43v.01zM98.7 74.34c-.51-.49-1.1-.72-1.78-.71-.68.01-1.26.28-1.74.78l-3.91 4.07 10.97 10.59 3.95-4.11c.47-.48.67-1.1.66-1.78-.01-.67-.25-1.28-.73-1.74l-7.42-7.1zm-20.49 39.67c-1.45.46-2.89.94-4.33 1.41-1.45.48-2.89.97-4.33 1.45-3.41 1.12-5.32 1.74-5.72 1.85-.39.12-.16-1.48.7-4.81l2.71-10.45 20.55-21.38 10.96 10.55-20.54 21.38zM31.58 41.08a3.15 3.15 0 110-6.3h41.54a3.15 3.15 0 110 6.3H31.58zm0 44.69c-1.74 0-3.16-1.43-3.16-3.19s1.41-3.19 3.16-3.19h20.47c1.74 0 3.16 1.43 3.16 3.19s-1.41 3.19-3.16 3.19H31.58zm0-22.36a3.15 3.15 0 110-6.3h41.54a3.15 3.15 0 110 6.3H31.58z"></path>
        </svg>
      );
}

export default Notes
