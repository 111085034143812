import * as React from "react"

function Activity(props) {
    return (
        <svg
        width="20px"
        height="20px"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            {...props}
        >
            <path
                id="Trac\xE9_23592"
                data-name="Trac\xE9 23592"
                d="M8.339,2.5,6.667,2.488A2.5,2.5,0,0,0,4.167,0H2.5A2.5,2.5,0,0,0,0,2.5V4.167a2.5,2.5,0,0,0,2.494,2.5L2.483,8.327a.833.833,0,0,0,.828.839h.005a.833.833,0,0,0,.833-.828l.012-1.672h.006a2.5,2.5,0,0,0,2.5-2.5V4.155l1.661.012h.006A.833.833,0,1,0,8.339,2.5ZM5,4.167A.833.833,0,0,1,4.167,5H2.5a.833.833,0,0,1-.833-.833V2.5A.833.833,0,0,1,2.5,1.667H4.167A.833.833,0,0,1,5,2.5Z"
                fill={props.selected ? "#102935" : "#9FA9AE"}
            />
            <path
                id="Trac\xE9_23593"
                data-name="Trac\xE9 23593"
                d="M19.666,0H18a2.5,2.5,0,0,0-2.5,2.494l-1.662-.012h-.005a.833.833,0,0,0-.006,1.667l1.672.012v.006a2.5,2.5,0,0,0,2.5,2.5h.012L18,8.327a.833.833,0,0,0,.828.839h.006a.833.833,0,0,0,.833-.828l.012-1.672a2.5,2.5,0,0,0,2.488-2.5V2.5A2.5,2.5,0,0,0,19.666,0ZM20.5,4.167A.833.833,0,0,1,19.666,5H18a.833.833,0,0,1-.833-.833V2.5A.833.833,0,0,1,18,1.667h1.667A.833.833,0,0,1,20.5,2.5Z"
                transform="translate(-2.166)"
                fill={props.selected ? "#102935" : "#9FA9AE"}
            />
            <path
                id="Trac\xE9_23594"
                data-name="Trac\xE9 23594"
                d="M19.672,15.5l.012-1.662A.833.833,0,0,0,18.855,13H18.85a.833.833,0,0,0-.833.828L18.005,15.5H18A2.5,2.5,0,0,0,15.5,18v.012L13.839,18h-.006a.833.833,0,1,0-.006,1.667l1.672.012A2.5,2.5,0,0,0,18,22.167h1.667a2.5,2.5,0,0,0,2.5-2.5V18a2.5,2.5,0,0,0-2.494-2.5Zm.827,4.167a.833.833,0,0,1-.833.833H18a.833.833,0,0,1-.833-.833V18A.833.833,0,0,1,18,17.167h1.667A.833.833,0,0,1,20.5,18Z"
                transform="translate(-2.166 -2.167)"
                fill={props.selected ? "#102935" : "#9FA9AE"}
            />
            <path
                id="Trac\xE9_23595"
                data-name="Trac\xE9 23595"
                d="M8.339,18.017l-1.673-.012V18a2.5,2.5,0,0,0-2.5-2.5H4.155l.012-1.661A.833.833,0,0,0,3.339,13H3.333a.833.833,0,0,0-.833.828L2.488,15.5A2.5,2.5,0,0,0,0,18v1.667a2.5,2.5,0,0,0,2.5,2.5H4.167a2.5,2.5,0,0,0,2.5-2.494l1.662.012h.005a.833.833,0,1,0,.006-1.667ZM5,19.667a.833.833,0,0,1-.833.833H2.5a.833.833,0,0,1-.833-.833V18a.833.833,0,0,1,.833-.833H4.167A.833.833,0,0,1,5,18Z"
                transform="translate(0 -2.167)"
                fill={props.selected ? "#102935" : "#9FA9AE"}
            />
        </svg>
    )
}

export default Activity
