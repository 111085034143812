
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Container, Col, Form } from "react-bootstrap";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import SignatureCanvas from 'react-signature-canvas'
import { toast } from 'react-toastify';
import Input from '../../../components/Input'

import Button from "../../../components/Button";
import SessionHeader from "../../../components/SessionHeader";

//api
import MyLaserApi from "../../../api/MyLaserApi";


const Text = styled.span`
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 14px;
    &.label {
        font-size: 13px;
        margin-bottom: 10px;
    }
    &.title {
      font-family: CenturyGothicBold;
      color:#6B7389
    }
    &.gray {
      color:#6B7389
    }
`;

const Bottom = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: absolute;
    bottom: 10%;
`;

const Box = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-style: solid;
    border-width: 1px;
    border-color: #707070;
    border-radius: 5px;
    margin-top: 20px;
    min-height: 300px;
    margin-bottom: 40px;
    background: white;
`;


function ConsentSignature(props) {

    const history = useHistory();
    const params = useParams();


    return (
        <Container fluid>

            <SessionHeader
                title="Consentement"
                text={"Récapitulatif (Étape 2/2)"}
                goBack={() => history.goBack()}
            />

            <Consent />

        </Container>
    );
}

function Consent() {

    const history = useHistory();
    const params = useParams();
    let sigCanvas = useRef();

    const [accepted, setAccepted] = useState(false);
    const [signed, setSigned] = useState(false);
    const [comment, onCommentChange] = useState("")

    const signConsent = (image) => {

        MyLaserApi.signConsent(params.id, { image: sigCanvas.toDataURL() }).then((res) => {
            if (res.status === 200) {
                toast.success("Vous avez signé le consentement avec succès.")
                history.push(`/home`)
            } else {
                toast.error("Une erreur s'est produite !")
            }
        })
    }
    

    return (
        <>


            <Col md={12} className="col" >
                <div style={{ width: '90%'}}>
                    <Text className="title">

                        Reglement
                           <br />
                    </Text>
                    <Box>
                        <Text>

                        <div class="elementor-column-wrap elementor-element-populated">
                            <div class="elementor-widget-wrap">
                                <div >
                                    <div class="elementor-widget-container">
                                        <div class="elementor-text-editor elementor-clearfix">
                                            L’utilisation, les indications, contre-indications et potentiels effets indésirables des implants à base d’acide hyaluronique m’ont été expliqués. J’ai répondu en toute sincérité à l’ensemble des questions qui m’ont été posées concernant mes antécédents médicaux et esthétiques. J’ai pu poser toutes les questions que je souhaitais et des réponses satisfaisantes m’ont été apportées					
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div class="elementor-element elementor-element-ce1209f elementor-widget elementor-widget-heading" data-id="ce1209f" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <h2 class="elementor-heading-title elementor-size-default">J’ai bien noté que</h2>
                                    </div>
                                </div>
                                <br />
                                <div class="elementor-element elementor-element-4382a80 elementor-widget elementor-widget-text-editor" data-id="4382a80" data-element_type="widget" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                        <div class="elementor-text-editor elementor-clearfix">
                                            <div class="page" title="Page 1">
                                                <div class="layoutArea">
                                                    <div class="column">
                                                        <p>• L’acide hyaluronique est un gel transparent biorésorbable d’acide hyaluronique réticulé d’origine non animale</p>
                                                        <p>• L’acide hyaluronique est injecté dans le derme ou le derme profond pour corriger rides, plis et contours du visage ou pour augmenter le volume des lèvres, traiter les cernes, reconstituer le volume des pommettes.</p>
                                                        <p>• L’acide hyaluronique offre une tenue moyenne de 6 à 12 mois. Cette tenue est variable en fonction des types de peau, des zones, des quantités injectées et de la technique d’injection.</p>
                                                        <p>• On note, dans certains cas, une tenue d’injection pouvant aller jusqu’à plus d’un an.</p>
                                                        <p>• Dans les lèvres, la durée moyenne de l’acide hyaluronique est de 6 mois, à cause de la forte vascularisation de celles-ci.</p>
                                                        <p>• Des ecchymoses, hématomes peuvent apparaître dans les jours suivant l’injection. Ils disparaissent en une semaine et seront masqués par un maquillage courant</p>
                                                        <p>• Des réactions inflammatoires telles que rougeurs, oedèmes, érythème … peuvent être associés à des démangeaisons ou des douleurs à la pression peuvent survenir après l’injection. Ces réactions peuvent persister une semaine.</p>
                                                        <p>• Des indurations ou nodules peuvent apparaître au point d’injection.</p>
                                                        <p>• De très rares cas de coloration de la zone d’injection ont été rapportés.</p>
                                                        <p>• Une 2ème séance rapprochée (4 à 6 mois) est utile s’il s’agit d’une première injection et que les rides sont marquées.</p>
                                                        <p>• J’ai également eté informé que des cas de nécrose dans la région glabellaire, du nez, de formation d’abcès, de granulomes et d’hypersensibilité ont été décrits après des injections d’acide hyaluronique. La persistance de réactions inflammatoires au-delà d’une semaine ou l’apparition de tout autre effet secondaire doit être signalée au praticien dans les meilleurs délais.</p>
                                                        <p>Le praticien devra y remédier par un traitement approprié. Dans tous les cas, ces effets secondaires peuvent nécessiter, pour disparaître, un traitement qui peut durer plusieurs mois</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-38b73c9" data-id="38b73c9" data-element_type="column">
                        <br />
                            <div class="elementor-column-wrap elementor-element-populated">
                                            <div class="elementor-widget-wrap">
                                        <div class="elementor-element elementor-element-25dcf1e elementor-widget elementor-widget-heading" data-id="25dcf1e" data-element_type="widget" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                            <h2 style={{textAlign: 'center'}} class="elementor-heading-title elementor-size-default">J’ai bien noté que</h2>		</div>
                                </div>
                                <br />
                                <div class="elementor-element elementor-element-31b6501 elementor-widget elementor-widget-text-editor" data-id="31b6501" data-element_type="widget" data-widget_type="text-editor.default">
                                <div class="elementor-widget-container">
                                                <div class="elementor-text-editor elementor-clearfix">
                                <div class="page" title="Page 1"><div class="layoutArea"><div class="column"><p>• La prise d’aspirine et d’anti-inflammatoires est déconseillée.</p></div></div></div>					</div>
                                        </div>
                                </div>
                                <br />
                                <div class="elementor-element elementor-element-cb5b1c6 elementor-widget elementor-widget-heading" data-id="cb5b1c6" data-element_type="widget" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                            <h2 style={{textAlign: 'center'}} class="elementor-heading-title elementor-size-default">Après le traitement</h2>		</div>
                                </div>
                                <div class="elementor-element elementor-element-960c4d9 elementor-widget elementor-widget-heading" data-id="960c4d9" data-element_type="widget" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                            <h2 style={{textAlign: 'center'}} class="elementor-heading-title elementor-size-default">(dans les heures qui suivent l’injection)</h2>		</div>
                                </div>
                                <br />
                                <div class="elementor-element elementor-element-8102c8c elementor-widget elementor-widget-text-editor" data-id="8102c8c" data-element_type="widget" data-widget_type="text-editor.default">
                                <div class="elementor-widget-container">
                                                <div class="elementor-text-editor elementor-clearfix">
                                <div class="page" title="Page 1"><div class="layoutArea"><div class="column"><div class="page" title="Page 1"><div class="layoutArea"><div class="column"><p>• Éviter la consommation d’alcool et l’exposition solaire.</p><p>• Éviter la contraction excessive des muscles de la zone traitée et tout massage.</p><p>• Éviter les sports violents, le sauna, le hammam dans les 3 jours qui suivent la séance.</p></div></div></div></div></div></div>					</div>
                                        </div>
                                </div>
                                        </div>
                                    </div>
                        </div>


                            
                        </Text>
                    </Box>

                    Recommandations du medecin:
                    <Input
                        noIcon
                        className=" multiline"
                        placeholder="Les recommandations du medecin"
                        value={comment}
                        textArea
                        onChange={(e) => onCommentChange(e.target.value)}
                    />

                    <Form.Check type="checkbox" label="J'ai lu et j'accepte "
                        onChange={() => setAccepted(!accepted)}
                    />


                </div>
            </Col>
           
            <Col md={12} className="col">
                <div style={{ width: "50%", display: 'flex', flexDirection: "column", alignItems: 'center' }}>

                    <Text className="title">
                        Signature
                        <br />
                    </Text>

                    
                    <Box>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', }}>
                            <img
                                src={'/reload.svg'}
                                width='2%'
                                onClick={() => sigCanvas.clear() && setSigned(false)}
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                        

                        <SignatureCanvas
                            ref={(ref) => { sigCanvas = ref }}
                            penColor='blue'
                            canvasProps={{ width: 600, height: 400, className: 'sigCanvas' }}
                            onEnd={(props) => {
                                setSigned(true)
                            }}

                        />
                    </Box>
                    

                    <Button
                        disabled={!signed || !accepted}
                        style={{ marginTop: 40,  marginBottom: 40 }}
                        onClick={signConsent}
                    >Valider</Button>

                </div>

            </Col>





        </>
    );
}



export default ConsentSignature;
