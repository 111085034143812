

import styled from "styled-components";
import { Image } from "react-bootstrap";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Container, Col } from "react-bootstrap";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Header from "../../components/Header";
import SideBar from "../../components/SideBar";
import Table from "../../components/Table";
import { useParams, useHistory, NavLink } from "react-router-dom";
import MyLaserApi from '../../api/MyLaserApi'
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';

import QRCode from "react-qr-code";

import ModalC from '../../components/Modal'

const ContainerTreatment = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: transparent;
    margin-top: 20px;
    width: 96%;
`;

const Text = styled.span`
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 13px;
    &.bold {
        font-family: CenturyGothicBold;
        color: #f0b594;
        font-size: 15px;
    }
    &.blue {
        color: #102935;
    }
    &.gray {
        color: #9fa9ae;
    }
    &.underline {
        text-decoration-line: underline;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
    &.spaced-items {
        justify-content: space-between;
    }
    &.custom-width {
        width: 50%;
    }
    &.center {
        align-items: center;
    }
`;


function TreatmentConsent(props) {

    const params = useParams()
    const history = useHistory()

    const [consents, setConsets] = useState([]);
    const [tmpConsent, setTmpConsent] = useState(null)
    const [showQrCode, setShowQrCode] = useState(false)

    useEffect(() => {
        MyLaserApi.getClientConsents(params.id).then((res) => {
            if (res.status === 200) {

                setDataForm(res.data);
            }
        })
    }, []);

    let setDataForm = (data) => {
        let lines = data.map((el) => {
            return {
                id: el.id,
                date: moment(el.created_at).format('LL'),
                type: el.version == "1" ? el.type : el.type_human,
                reference: el.reference,
                link: el.link
            };
        });
        setConsets(lines)

    };

    return (
        <Container fluid className="App">
            <Row>
                
                <Col
                    md={12}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Header
                        type="client"
                    />

                    <ContainerTreatment>

                        <Row className="spaced-items">
                            <Text className="bold"></Text>

                            <NavLink
                                to={`/patients/${params.id}/consents/create`}
                                style={{ width: "60%", textDecorationLine: "none", alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex', marginBottom: 10 }}
                            >
                                <Button>Créer un consentement</Button>
                            </NavLink>

                        </Row>

                        <DataTable
                            title={`Consentements traitements`}
                            noDataComponent={<div style={{paddingTop: 10, paddingBottom: 10}}>Il n'y a aucun consentement à afficher</div>}
                            pagination={30}
                            data={consents}
                            columns={[
                                {
                                    name: "Date",
                                    selector: (row) => row.date,
                                    sortable: true
                                },
                                {
                                    name: "Type",
                                    selector: (row) => row.type,
                                    sortable: true
                                },
                                {
                                    name: "",
                                    selector: (row) => (
                                        <a href={"https://docs.google.com/viewer?url=" + encodeURIComponent(`https://eu2.contabostorage.com/c92d1ab223ed43358d4f98ca65e087d6:eth.my-laser.fr/consents/${row.reference}.pdf`)} without rel="noopener noreferrer" target="_blank">
                                            <Button style={{width: "100%"}} trailingIcon="picture_as_pdf" label="Resume">
                                            Consulter
                                            </Button>
                                        </a>
                                    ),
                                    sortable: true
                                },
                                {
                                    name: "",
                                    selector: (row) => (<Button style={{width: "100%"}} onClick={() => {

                                        setShowQrCode(true)
                                        setTmpConsent(row)

                                    }}>
                                        QRCode 
                                    </Button>),
                                    sortable: true
                                },
                                {
                                    name: "",
                                    selector: (row) => (<Button style={{width: "100%"}} onClick={() => {
                                        MyLaserApi.call({url: "/consents/" + row.id + "/email", method: "POST"}).then((res) => {
                                            toast.success("Email envoyé")
                                        })

                                    }}>
                                        Email 
                                    </Button>),
                                    sortable: true
                                },
                            ]}
                        />

                    </ContainerTreatment>
                </Col>
            </Row>

            <ModalC
                show={showQrCode}
                onHide={() => setShowQrCode(false)}
                title={"QRCode consentement"}
                onButtonClick={() => setShowQrCode(false)}
                size="lg"
                style={{ width: "100%", textAlign: "center" }}
            >
                {showQrCode && <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "80%", width: "80%", alignSelf: "center" }}
                    value={"https://docs.google.com/viewer?url=" + encodeURIComponent(`https://eu2.contabostorage.com/c92d1ab223ed43358d4f98ca65e087d6:eth.my-laser.fr/consents/${tmpConsent.reference}.pdf`)}

                    viewBox={`0 0 256 256`}
                />}
            </ModalC>
        </Container>
    );
}

export default TreatmentConsent;
