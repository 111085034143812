
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Container, Col, Form } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import SignatureCanvas from 'react-signature-canvas'

import "react-datepicker/dist/react-datepicker.css";

import Button from "../../../components/Button";
import Input from "../../../components/Input";
import SessionHeader from "../../../components/SessionHeader";

import MyLaserApi from "../../../api/MyLaserApi";

import { toast } from 'react-toastify';

import moment from 'moment'


const Text = styled.span`
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 14px;
    &.label {
        font-size: 13px;
        margin-bottom: 10px;
    }
    &.title {
      font-family: CenturyGothicBold;
      color:#6B7389
    }
    &.gray {
      color:#6B7389
    }
`;


const Box = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-style: solid;
    border-width: 1px;
    border-color: #707070;
    border-radius: 5px;
    margin-top: 20px;
    min-height: 300px;
    margin-bottom: 40px;
    background: white;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
    &.spaced-items {
        justify-content: space-between;
    }
`;


function AddConsent(props) {

    const history = useHistory();

    return (
        <Container fluid>

            {/* <SessionHeader
                title="Consentement"
                text={"Récapitulatif"}
                goBack={() => history.goBack()}
            /> */}

            <Row>
            
                <Col md={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: "25px" }}>

                    <Button onClick={() => {
                        history.goBack()
                    }}>Fermer la page</Button>

                </Col>

                

            </Row>

            <Consent />

        </Container>
    );
}

function Consent() {

    const [htmlSave, setHtmlSave] = useState(null)

    const history = useHistory();
    const params = useParams();
    let sigCanvas = useRef();

    const [consent, setConsent] = useState(null);

    const [accepted, setAccepted] = useState(false);
    const [signed, setSigned] = useState(false);
    const [comment, onCommentChange] = useState("")
    const [isLoaded, setIsLoaded] = useState(false);

    const [url, setUrl] = useState("")

    const iframeRef = useRef()

    let loadEvent = () => {
        // console.log(iframeRef.current.src)
        // alert("Loaded")

        // if (iframeRef.current) {

        setIsLoaded(true)

        iframeRef.current.contentWindow.postMessage({
            ...consent.items,
            id: { value: params.id }
        }, "*")

        window.addEventListener('message', messageEvent)

        // }

    }

    let messageEvent = (message) => {

        try {
            let res = JSON.parse(message.data)

            setHtmlSave(res.data)
        } catch (err) {
            
        }
        
    }

    useEffect(() => {

        // iframeRef.current.addEventListener("load", loadEvent)

        MyLaserApi.getConsent(params.id).then((res) => {

            if (res.status === 200) {

                setUrl(res.data.items.current_url.value)

                setConsent(res.data)

            }

        })

        return () => {
            if (iframeRef && iframeRef.current) {
                iframeRef.current.removeEventListener("load", loadEvent)
            }
            window.removeEventListener('message', messageEvent)
        }

    }, []);

    const signConsent = (image) => {

        let sentence = ""

        if (comment) {
            
            sentence += `<div class="elementor-element elementor-element-951e8dd elementor-widget elementor-widget-heading" data-id="951e8dd" data-element_type="widget" data-widget_type="heading.default">
                <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">Recommadations du medecin</h2>		
                </div>
            </div>

            <div class="elementor-element elementor-element-8b4a4c4 elementor-widget elementor-widget-text-editor" data-id="8b4a4c4" data-element_type="widget" data-widget_type="text-editor.default">
                <div class="elementor-widget-container">
                    <div class="elementor-text-editor elementor-clearfix">
                        <p>${comment}</p>					
                    </div>
                </div>
            </div>`
        }

        sentence += `<br>

        <p>
            Fait le: ${moment().format('LL')}<br><br>
            Signature: <br>

        </p>
        
        <img src="${sigCanvas.toDataURL()}" />`

        // setHtmlSave(htmlSave.replace("<!--TO REPLACE-->", sentence))

        MyLaserApi.signConsent(params.id, { image: sigCanvas.toDataURL(), doctor_note: comment, html: htmlSave.replace("<!--TO REPLACE-->", sentence) }).then((res) => {

            if (res.status === 200) {
                toast.success("Vous avez signé le consentement avec succès.")
                history.push(`/patients/${consent.agency_client.id}/consents`)
            } else {
                toast.error("Une erreur s'est produite !")
            }

        })
    }

    if (consent == null) {
        return <div>

        </div>
    }

    // if (htmlSave) {
    //     return <div>
    //         <iframe srcDoc={htmlSave} style={{ width: '100%', height: '90vh' }}></iframe>
    //     </div>
    // }

    if (consent.signed == false && !htmlSave) {

        
        return <div>
            {!isLoaded && <div className="loader-container">
                <div className="spinner"></div>
                <br/>
                <center>Chargement en cours...</center>
            </div>}
            <Col md={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: "25px" }}>

                <Button onClick={() => {
                    // history.goBack()
                    loadEvent()
                }}>Charger les données dans le formulaire</Button>

            </Col>
            <iframe 
                ref={(ref) => iframeRef.current = ref }
                src={url} 
                style={{ width: '100%', height: '90vh', display: !isLoaded ? 'none' : 'block' }}
                onLoad={() => {
                    loadEvent()
                }}
                >

                </iframe>
        </div>
    }

    if (consent.signed == true) {
        return (<div style={{}}>
            <iframe 
                ref={(ref) => iframeRef.current = ref }
                srcDoc={consent.html} style={{ width: '100%', height: '90vh' }}></iframe>
        </div>)
    }

    if (htmlSave) {

        return ( <>

            <Row>
                <Col md={6} className="col" >

                    <div style={{ width: '90%'}}>

                        <h2>Aperçu</h2>  

                        <iframe srcDoc={htmlSave} style={{ width: '100%', height: '90vh' }}></iframe>


                    </div>

                </Col>

                <Col md={6}  className="col" >
                
                    <br />
                    <br />

                        {

                            !["Épilation au Laser", "Mesothérapie", "Mésothérapie & Hydrafacial​", "Peeling intime", "Traitement Capillaire", "Consentement éclairé – cryolipolyse"].includes(consent.type) && (
                                <>
                                Recommandations du praticien:
                                    <Input
                                        noIcon
                                        className=" multiline"
                                        placeholder="Les recommandations du praticien"
                                        value={comment}
                                        textArea
                                        onChange={(e) => onCommentChange(e.target.value)}
                                    />

                                    
                                </>
                            )

                        }

                    <Form.Check id={`read-n-accepted`} type="checkbox" label="J'ai lu et j'accepte "
                        onChange={() => setAccepted(!accepted)}
                    />

                    <div style={{ width: "100%", display: 'flex', flexDirection: "column", alignItems: 'center' }}>

                        <Text className="title">
                            Signature
                            <br />
                        </Text>

                        <Box style={{ width: "100%", height: "350px" }}>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', }}>
                                <img
                                    src={'/reload.svg'}
                                    width='2%'
                                    onClick={() => sigCanvas.clear() && setSigned(false)}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                            

                            <SignatureCanvas
                                ref={(ref) => { sigCanvas = ref }}
                                penColor='blue'
                                canvasProps={{ width: "600px", height: "300px", className: 'sigCanvas' }}
                                onEnd={(props) => {
                                    setSigned(true)
                                }}

                            />
                        </Box>


                        <Button
                            disabled={!signed || !accepted}
                            style={{ marginTop: 40,  marginBottom: 40 }}
                            onClick={signConsent}
                        >Valider mon consentement</Button>

                    </div>


                
                </Col>


            </Row>

        </>)
    }

}



export default AddConsent;
