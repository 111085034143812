

import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { Container, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { useParams } from "react-router";
import DataTable from 'react-data-table-component';

import Header from "../../components/Header";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import Button from "../../components/Button";
import MyLaserApi from "../../api/MyLaserApi";

import moment from "moment";

import { v4 as uuidv4 } from 'uuid'
import Swal from "sweetalert2";

const ContainerTreatment = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: transparent;
    margin-top: 20px;
    width: 96%;
`;

const Label = styled.span`
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 13px;
    margin-bottom: 10px;
`;
const Text = styled.span`
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 13px;
    &.bold {
        font-family: CenturyGothicBold;
        font-size: 15px;
        margin: 20px;
    }
    &.blue {
        color: #102935;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
    &.spaced-items {
        justify-content: space-between;
    }
    &.custom-width {
        width: 50%;
    }
    &.center {
        align-items: center;
    }
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background-color: #fff;
  margin-top: 20px;
  border-radius: 10px;
  width: 100%;
  &.no-padding {
  padding: 0px;
      }
`

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

function Payment() {

    let history = useHistory()

    let user = useSelector((state) => state.user)  

    const [modalShow, setModalShow] = useState(false);
    const [date, setDate] = useState(new Date());

    const [reason, setReason] = useState("");
    const [amount, setAmount] = useState(0);

    const [showNoteModal, setShowNoteModal] = useState(false);
    const [note, setNote] = useState("");

    let params = useParams();
    let [data2, setData2] = useState({});

    const [stats, setStats] = useState({
        discounts: 0,
        incomes: 0,
        needed: 0,
        refunds: 0,
    })

    let reload = () => {

        MyLaserApi.call({
            method: "GET",
            url: `/agencies/clients/${params.id}/sessions/payments/statistics`,
        }).then((data) => {
            setStats(data.data)
        })

        MyLaserApi.getClientPayment(params.id).then((res) => {

            let zbra = {}

            res.data.map((single) => {

                if (single.uniqid) {
                    
                    if (zbra[single.uniqid]) {
                        zbra[single.uniqid].push(single)
                    } else {
                        zbra[single.uniqid] = [single]
                    }

                } else {
                    zbra[uuidv4()] = [single]
                }

            })

            console.log(zbra)

            setData2(zbra)

        });

    }

    useEffect(() => {
        reload()
    }, []);

    return (
        <Container fluid className="App">
            <Row>
               
                <Col
                    md={12}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Header
                        type="client"
                    />
                    

                    <ContainerTreatment>

                        <Row className="spaced-items">
                            <Text className="bold"></Text>

                            <NavLink
                                to={`/patients/${params.id}/payment/add`}
                                style={{ width: "60%", textDecorationLine: "none", alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex' }}
                            >
                                <Button>Ajouter un paiement</Button>
                            </NavLink>

                        </Row>

                        <Row>
                            <Col xs={3}>
                                <div className="stat-box">
                                <span>{stats.incomes}€</span><br />
                                <span>Montant réglé</span>
                                </div>
                            </Col>

                            <Col xs={3}>
                                <div className="stat-box">
                                <span style={{color: ((stats.needed - stats.discounts - stats.incomes) === 0 ? '' : 'red')}}>{(stats.needed - stats.discounts - stats.incomes).toFixed(2)}€</span><br />
                                <span>Montant dû ({(stats.needed - stats.incomes).toFixed(2)}€ hors reduction) </span>
                                </div>
                            </Col>

                            <Col xs={3}>
                                <div className="stat-box">
                                <span>{stats.discounts}€</span><br />
                                <span>Remises exceptionnelles</span>
                                </div>
                            </Col>

                            <Col xs={3}>
                                <div className="stat-box">
                                <span>{stats.refunds}€</span> <br />
                                <span>Remboursé</span>
                                </div>
                            </Col>
                        </Row>

                        <Block>

                            <DataTable
                                title={`Paiements patient`}
                                noDataComponent="Il n'y a aucun encaissement à afficher"
                                pagination={30}

                                columns={[
                                    {
                                        name: "Date",
                                        selector: (row) => {

                                            let arr = [
                                                row.date,
                                                <br />
                                            ]

                                            if (user.role == 1) {

                                                arr.push(<button
                                                    className="action-btn"
                                                    style={{
                                                        marginTop: 5,
                                                        marginBottom: 5,
                                                        fontFamily: "CenturyGothicBold",
                                                        fontSize: "12px",
                                                        color: "rgb(136 194 211)"
                                                    }}
                                                    onClick={() => setModalShow(row.id)}
                                                    >
                                                    Rembourser
                                                </button>, <br />
                                                )

                                            } else {

                                                var duration = moment.duration(moment().diff(moment(row.created_at)));
                                                var hours = duration.asHours();

                                                if (hours > 48) {
                                                    return [arr]
                                                }

                                            }

                                            // if (row.employee_id != user.id && row.collected_by_id != user.id)

                                            if (row.group[0].invoiced) {
                                                return [arr]
                                            }

                                            return [
                                                ...arr,
                                                <button
                                                    className="action-btn"
                                                    style={{
                                                        marginTop: 5,
                                                        marginBottom: 5,
                                                        fontFamily: "CenturyGothicBold",
                                                        fontSize: "12px",
                                                        color: "rgb(136 194 211)"
                                                    }}
                                                    onClick={() => {

                                                        history.push("/sessions/payments/update", {
                                                            payments: row.group
                                                        })
                                                        
                                                    }}
                                                >
                                                    Modifier
                                                </button>,
                                                <br />,
                                                <button
                                                    className="action-btn"
                                                    style={{
                                                        marginTop: 5,
                                                        marginBottom: 5,
                                                        fontFamily: "CenturyGothicBold",
                                                        fontSize: "12px",
                                                        color: "rgb(255, 150, 141)"
                                                    }}
                                                    onClick={() => {

                                                        Swal.fire({
                                                            title: 'Attention',
                                                            text: "Vous êtes sur de supprimer ce paiement ?",
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#3085d6',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'Oui je veux le supprimer !',
                                                            cancelButtonText: "Annuler"
                                                        }).then((result) => {

                                                            if (result.isConfirmed) {

                                                                MyLaserApi.deletePayments(row.group.map((g) => g.id)).then(() => {
                                                                    reload()
                                                                })

                                                            }

                                                        })

                                                    }}
                                                >
                                                    Supprimer
                                                </button>
                                            ]

                                        },
                                        sortable: true
                                    },
                                    {
                                        name: "Montant",
                                        selector: (row) => row.amount,
                                        sortable: true
                                    },
                                    {
                                        name: "Moyen de paiement",
                                        selector: (row) => row.agency_income_type,
                                        sortable: true,
                                        wrap: true
                                    },
                                    {
                                        name: "Type de prestation",
                                        selector: (row) => row.agency_service_type,
                                        sortable: true,
                                        wrap: true,
                                        grow: 2

                                    },
                                    {
                                        name: "Note",
                                        selector: (row) => <span onClick={() => {
                                            if (row.note) {
                                                setNote(row.note)
                                                setShowNoteModal(true)
                                            }
                                        }}>
                                            {(() => {
                                                if (row.note && row.note != "") {
                                                    if (row.note.length > 50) {
                                                        return row.note.substring(0, 50) + "..."
                                                    } else {
                                                        return row.note
                                                    }
                                                } else {
                                                    return "Aucune note"
                                                }
                                            })()}
                                        </span>,
                                        wrap: true,
                                        sortable: true,
                                    },
                                    {
                                        name: "Agence",
                                        selector: (row) => row.agency,
                                        sortable: true
                                    },
                                    {
                                        name: <>Encaissement / <br /> Praticien</>,
                                        selector: (row) => <>{row.collecter} <br /> {row.employee}</>,
                                        sortable: true,
                                    },
                                    
                                ]}

                                data={

                                    Object.values(data2).map((group) => {

                                        let agency_income_type_1_amount = group.reduce((s, p) => s + parseFloat(p.agency_income_type_1_amount), 0)
                                        let agency_income_type_2_amount = group.reduce((s, p) => s + parseFloat(p.agency_income_type_2_amount), 0)
                                        let agency_income_type_3_amount = group.reduce((s, p) => s + parseFloat(p.agency_income_type_3_amount), 0)
                                        let agency_income_type_4_amount = group.reduce((s, p) => s + parseFloat(p.agency_income_type_4_amount), 0)
                                        let refunds = 0

                                        group.map((el) => {
                                            refunds += el.refunds.reduce((s,p) => { return s + parseFloat(p.amount_refund)}, 0)
                                        })

                                        return {
                                            id: group[0].id,
                                            group: group,
                                            created_at: group[0].created_at,
                                            ids: group.map((el) => el.id),
                                            date: <div style={{marginTop: 10}}>{moment(group[0].date).format("DD/MM/YYYY")}</div>,
                                            amount: <>
                                                {global.numberWithSpaces(agency_income_type_1_amount + agency_income_type_2_amount + agency_income_type_3_amount + agency_income_type_4_amount - refunds)} {global.currencySymbol}
                                                <br />
                                                {group[0].invoiced == false && <button
                                                    className="action-btn"
                                                    style={{
                                                        marginTop: 5,
                                                        marginBottom: 5,
                                                        fontFamily: "CenturyGothicBold",
                                                        fontSize: "12px",
                                                        color: "rgb(136 194 211)"
                                                    }}
                                                    onClick={() => {
                                                        Swal.fire({
                                                            title: 'Attention',
                                                            text: "Si vous envoyez la facture, vous ne pourrez plus modifier / supprimer le paiement.",
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#3085d6',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'Oui je veux envoyer la facture !',
                                                            cancelButtonText: "Annuler"
                                                        }).then((result) => {

                                                            if (result.isConfirmed) {
                                                                MyLaserApi.invoicePayments(group.map((g) => g.id)).then(() => {
                                                                    reload()
                                                                })
                                                            }

                                                        })
                                                    }}
                                                >
                                                    Envoyer la facture
                                                </button>}
                                                
                                            </>,

                                            agency_income_type: (() => {
    
                                                let res = []

                                                if (agency_income_type_1_amount) {
                                                    res.push([`Esp ${agency_income_type_1_amount.toFixed(2)}${global.currencySymbol}`, <br />])
                                                } 
                                               
                                                if (agency_income_type_2_amount) {
                                                    res.push([`CB ${agency_income_type_2_amount.toFixed(2)}${global.currencySymbol}`, <br />])
                                                }

                                                if (agency_income_type_3_amount) {
                                                    res.push([`ALMA ${agency_income_type_3_amount.toFixed(2)}${global.currencySymbol}`, <br />])
                                                }

                                                if (agency_income_type_4_amount) {
                                                    res.push([`Ch ${agency_income_type_4_amount.toFixed(2)}${global.currencySymbol}`, <br />])
                                                }
            
                                                if (refunds) {
                                                    res.push([`RBS -${refunds} ${global.currencySymbol}`, <br />])
                                                }

                                                return res
            
                                            })(),

                                            agency_service_type: (() => {

                                                let areas = []

                                                group.map((payment) => {
                                                    areas = [...areas, ...payment.treatment.sessions.map((e) => e.area)]
                                                })

                                                return areas.map((e) => e).filter(onlyUnique).map((area) => [
                                                    `${group[0].treatment.type_human != null ? group[0].treatment.type_human.replace('Épilation Laser', 'E.P') : group[0].treatment.type} ${area && area != "-" ? "(" + area + ")" : ""} x${areas.filter((s) => s === area).length}`, 
                                                    <br />
                                                ])

                                            })(),

                                            note: group[0].note,

                                            agency: (group[0].agency || {name: "N/C"}).name,
                                            
                                            employee: group.map((e) => e.employee.firstname + " " + e.employee.lastname).filter(onlyUnique).join(" + "),
                                            collecter:  group.map((e) => e.collected_by.firstname + " " + e.collected_by.lastname).filter(onlyUnique).join(" + "),
                                        }

                                    })

                                }

                            />

                        </Block>

                     
                    </ContainerTreatment>
                </Col>
            </Row>

            <Modal
                show={showNoteModal}
                onHide={() => setShowNoteModal(false)}
                title={"Notes"}
            >
                <Text>{note}</Text>
            </Modal>

            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                title={"Remboursement"}
                buttonText="Valider"
                onButtonClick={() => {

                    return MyLaserApi.createPaymentRefund({
                        date: date,
                        amount_refund: amount,
                        reason: reason
                    }, params.id, modalShow).then(() => {
                        setModalShow(false)

                        MyLaserApi.getClientPayment(params.id).then((res) => {
                            reload()
                        });
                    })

                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "flex-start",
                        width: "100%",
                        flexDirection: "column",
                    }}
                >
                    <Label style={{ marginBottom: 10 }}>Date</Label>
                    <DatePicker
                        selected={date}
                        onChange={(date) => setDate(date)}
                        className="gray"
                        dateFormat={"dd/MM/yyyy"}
                    />
                </div>

                <Input
                    noIcon
                    placeholder="300"
                    label={`Montant (${global.currencySymbol})`}
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    style={{ marginBottom: 20, marginTop: 20 }}
                />

                <Input
                    textArea
                    noIcon
                    placeholder="Raison du remboursement"
                    label="Raison"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    style={{ marginBottom: 20, marginTop: 20 }}
                />
            </Modal>
        </Container>
    );
}

export default Payment;
