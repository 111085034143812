

import styled from "styled-components";
import { Container, Col } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import moment from 'moment'
import { useSelector } from "react-redux";
import TableWithStatistics from "../../components/Tables/TableWithStatistics"

import "react-calendar/dist/Calendar.css";

import MouseTooltip from 'react-sticky-mouse-tooltip';

//components
import Header from "../../components/Header";
import DatePicker from "../../components/DatePicker";
import { useHistory } from 'react-router';


const ContainerEmployee = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100vh;
    background-color: transparent;
    padding-top: 30px;
    margin-top: 20px;
    width: 96%;
`;
const Block = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    background-color: #fff;
    margin-top: 20px;
    border-radius: 10px;
    width: 100%;
    box-shadow: 5px 2px 5px rgba(16, 41, 53, 0.1);
    `

const Text = styled.span`
    margin-left: 10px;
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 12px;
    &.bold {
        font-family: CenturyGothicBold;
    }
    &.big {
        font-family: CenturyGothicBold;
        font-size: 20px;
    }
    &.white {
        color: #fff;
    }
    &.orange {
        color: #F0B594;
    }
    &.size-plus {
        font-size: 19px;
    }
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    /* margin-bottom: 20px; */
    &.no-width {
        width: auto;
    }
    &.spaced-items {
        justify-content: space-between;
    }
`;
const Option = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 170px;
    background-color: #EDF0F5;
    color:#102935;
    padding: 10px;
    &.selected {
        background-color: #102935;
        color:#fff;
        border-radius: 10px;
        width: 189px;
    }
    &.left {
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
    }  
    &.right {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
    }
   
`;

function Agencies() {

    let [intervalDate, setIntervalDate] = useState({start: moment().subtract(7, "days").startOf('day').toDate(), end: moment().endOf('day').toDate()})
    let [statisticsType, setStatisticsType] = useState('payments')
    
    let [statsTotal, setStatsTotal] = useState({
        payments: 0,
        sessions: 0
    })

    let agenciesTable = useRef()
    let history = useHistory()
    
    const user = useSelector((state) => state.user);
    const agencies = useSelector((state) => state.agencies);

    useEffect(() => {
        if (user.role != 1) {
            history.push("/home")
        }
    }, [user])

    if (!user || user.role != 1) {
        return <></>
    }

    return (
        <Container fluid className="App">

            <Row>
               
                <Col
                    className="col"
                    md={12}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >

                    <Header
                        user={user}
                        title={`Hello ${user.firstname},`}
                        subtitle="Bienvenue sur ton espace de travail"
                        icon
                    />

                    <ContainerEmployee>
                        <Row className="spaced-items">
                            <Text className="big">L’activité de vos centres</Text>
                        </Row>

                        <Block>
                            <Row className="spaced-items" >
                                <Row >

                                    <Option className={`${statisticsType == "payments" ? "selected" : ""} left`} onClick={() => setStatisticsType("payments")}>
                                        <Text className={`${statisticsType == "payments" ? "bold white" : ""}`}  >Chiffre d’Affaires</Text>
                                        <Text className={`${statisticsType == "payments" ? "size-plus white" : "size-plus"}`} >{ statsTotal.payments } {global.currencySymbol}</Text>

                                    </Option>

                                    <Option className={`${statisticsType == "sessions" ? "selected" : ""} right`} onClick={() => setStatisticsType("sessions")}>
                                        <Text className={`${statisticsType == "sessions" ? "bold white" : ""}`}  >Patients</Text>
                                        <Text className={`${statisticsType == "sessions" ? "size-plus white" : "size-plus"}`}  >{ statsTotal.sessions }</Text>
                                    </Option>

                                </Row>

                                <DatePicker 
                                    onChange={(dates) => {
                                        setIntervalDate(dates)
                                    }}
                                />
                                
                            </Row>

                        </Block>

                        <br />
                        <br />

                        <Block>

                            {agencies && agencies.length > 0 && <TableWithStatistics 
                                ref={agenciesTable}
                                url="/agencies/statistics"
                                title="Activité de vos centres"
                                intervalDate={intervalDate} 
                                statisticsType={statisticsType}
                                headerColumn="agency_id"
                                setHeadersFunction={(data) => {
                                    return agencies
                                }}
                                onReload={() => {
                                    setTimeout(() => {
                                        setStatsTotal({
                                            payments: agenciesTable && agenciesTable.current && agenciesTable.current.getValueFromStatistic("payments", "total_amount"),
                                            sessions: agenciesTable && agenciesTable.current && agenciesTable.current.getValueFromStatistic("sessions", "sessions_count")
                                        })
                                    }, 500)
                                    
                                }}
                            />}

                        </Block>

                    </ContainerEmployee>

                </Col>

            </Row>

        </Container>
    );
}

export default Agencies;
