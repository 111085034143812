import { useState } from "react";
import Button from "./Button";

export default function ButtonLoader({ onClick, ...props }) {
    
    const [loading, setLoading] = useState(false);

    const handleClick = () => {
        setLoading(true);
        
        let action = onClick()

        if (action instanceof Promise) {

            action.finally(() => {
                setLoading(false);
            })

        }

      };

    return (
        <Button {...props} onClick={handleClick}>
            {loading && (
                <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginRight: "5px" }}
                />
            )}
            {!loading && props.children}
        </Button>
    )

};
