
import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css';
import styled from 'styled-components'




export const AreaButton = styled.button`
    display:flex;
    align-items:center;
    width: 100%; 
    box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, .05); 
    background-color: #fff; 
    color: #102935; 
    font-family: CenturyGothicRegular; 
    border-width: 3px; 
    border-color: #fff; 
    font-size: 13px; 
    margin-left: 0; 
    min-height: 45px;
    outline: none;
    border-style: solid;
    margin-bottom:10px;
    transition: 0.3s;

    &:-moz-focus-inner {
        padding: 0;
        border: 0
    }

    &:hover, &.selected {
        color: #F0B594;
        border-color: #F0B594; 
    }
    &.big {
        width: 354px;
        height: 62px;
    }
    &.small {
        width: 108px;
        height: 49px;
        justify-content: center;
        :nth-child(odd) {
            margin-right:10px
            } 
            
    }
    &.margin-left {
        margin-left: 10px
    }

    
`


export default AreaButton;
