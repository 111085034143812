import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css';
import styled from 'styled-components'
import { Modal, Image } from 'react-bootstrap'
import ImageUploading from "react-images-uploading";
import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { Redirect, useHistory } from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//components
import ModalC from './Modal'
import Input from './Input'
import Button from './Button'

//api
import MyLaserApi from '../api/MyLaserApi'

import S3Handler from '../services/S3Handler'
import ImageListUpload from './ImageListUpload'

import Select from 'react-select'

const Label = styled.span`
  font-family: CenturyGothicRegular;
  color:#102935;
  font-size: 13px;
  margin-bottom: 10px;
`

function CreateSpecialNoteModal(props) {

    const [images, setImages] = useState([]);

    const [comment, setComment] = useState('');
    const [secondary_effect, setSecondaryEffect] = useState('');

    const [date, setDate] = useState(new Date());


    const createNote = async () => {
        
        let attachments = await S3Handler.uploadFromList(images)

        let create = {
            special_note_comment: comment,
            special_note_attachments: attachments,
        }

        if (props.session && props.session.type === "laser") {
            create.special_note_secondary_effect = secondary_effect.value
        } else {
            create.special_note_secondary_effect = secondary_effect
        }

        return MyLaserApi.CreateNote(props.clientId, props.session.id, create).then((res) => {
            if (res.status === 200) {
                toast.success("Note envoyée avec succès.")
                props.onHide()
                // history.go(0)
            } else {
                toast.error("Une erreur s'est produite !")
            }
        })
    };


    const onCommentChange = (value) => {
        setComment(value)
    };

    return (

        <ModalC
            show={props.show}
            onHide={props.onHide}
            title={"Ajout d’une note particulière"}
            buttonText="Valider la note"
            onButtonClick={createNote}
        >

            <div style={{ display: 'flex', alignItems: 'flex-start', width: '100%', flexDirection: 'column' }}>
                <Label style={{ marginBottom: 10 }}>Date</Label>
                <DatePicker selected={date} onChange={(date) => setDate(date)} className="gray" dateFormat={"dd/MM/yyyy"} />
            </div>

            <br />

            <div style={{ display: 'flex', alignItems: 'flex-start', width: '100%', flexDirection: 'column' , marginTop: "15px"}}>

                <Label style={{ marginBottom: 10 }}>Effet secondaire</Label>

                <div style={{ width: '100%' }}>

                    {props.session && props.session.type === "laser" ? <Select
                        options={[
                            { value: 'Brûlures', label: 'Brûlures' },
                            { value: 'Tâches d’hyperpigmentation', label: 'Tâches d’hyperpigmentation' },
                            { value: 'Tâches d’hypopigmentation', label: 'Tâches d’hypopigmentation' },
                            { value: 'Réaction allergique', label: 'Réaction allergique' },
                            { value: 'Rougeurs', label: 'Rougeurs' },
                            { value: 'Pustules (petits boutons blancs)', label: 'Pustules (petits boutons blancs)' },
                        ]}
                        placeholder="Selectionner un effet secondaire"
                        value={secondary_effect}
                        onChange={(value) => {
                            setSecondaryEffect(value)
                        }}
                        isSearchable={false}
                        styles={{
                            control: () => ({
                                backgroundColor: "#F8F9FB",
                                marginBottom: 20,
                                padding: 5,
                                marginTop: 10,
                                display: "flex",
                                borderRadius: 5,
                                width: '100%',
                                fontFamily: "CenturyGothicRegular"
                            })
                        }}
                    /> : <Input placeholder="Effet secondaire" value={secondary_effect} onChange={(e) => setSecondaryEffect(e.target.value)} noIcon={true} />}

                </div>

            </div>

            <br />

            <div style={{ display: 'flex', alignItems: 'flex-start', width: '100%', flexDirection: 'column' , marginTop: "0px"}}>

                <Label style={{ marginBottom: 10 }}>Commentaire</Label>

                <Input
                    noIcon
                    className="multiline"
                    placeholder="Écrivez le commentaire ici…"
                    value={comment}
                    textArea
                    onChange={(e) => onCommentChange(e.target.value)}
                />

            </div>

            <br />
            
            <div style={{ width: "100%", marginTop: "10px" }}>

                <ImageListUpload onChange={(imageList) => {
                    setImages(imageList)
                }} label="Ajouter des photos" />

            </div>

        </ModalC>
    );
}

export default CreateSpecialNoteModal;