import * as React from "react"

function Box(props) {
    return (
        <svg
            width="20px"
            height="20px"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            {...props}
        >
            <path
                id="Trac\xE9_23578"
                data-name="Trac\xE9 23578"
                d="M8.833,13.667h5a.833.833,0,1,0,0-1.667h-5a.833.833,0,1,0,0,1.667Z"
                transform="translate(-1.333 -2)"
                fill={props.selected ? "#102935": "#9FA9AE"}
            />
            <path
                id="Trac\xE9_23579"
                data-name="Trac\xE9 23579"
                d="M15.833,0H4.167A4.172,4.172,0,0,0,0,4.167V5A2.5,2.5,0,0,0,.833,6.862v8.972A4.172,4.172,0,0,0,5,20H15a4.172,4.172,0,0,0,4.167-4.167V6.862A2.5,2.5,0,0,0,20,5V4.167A4.172,4.172,0,0,0,15.833,0ZM1.667,4.167a2.5,2.5,0,0,1,2.5-2.5H15.833a2.5,2.5,0,0,1,2.5,2.5V5a.833.833,0,0,1-.833.833H2.5A.833.833,0,0,1,1.667,5ZM17.5,15.833a2.5,2.5,0,0,1-2.5,2.5H5a2.5,2.5,0,0,1-2.5-2.5V7.5h15Z"
                fill={props.selected ? "#102935": "#9FA9AE"}
            />
        </svg>
    )
}

export default Box
