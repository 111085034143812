import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css';
import styled from 'styled-components'
import { Image } from 'react-bootstrap'
import { useRef } from 'react';

const Container = styled.div`
    display: flex;
    flex-direction: Row;
    align-items: center;
    padding: 15px;
    padding-top: 0;
    padding-left: 0;
    width: 100%;
    background-color: #F8FAFD;
    border-radius: 7px;
    height: 150%;

    &.with-label {
        border-width: 1px;
        border-color: #EDF0F5;
    }
    &.orange {
        background-color:#F0B59417
    }
    &.big input {
        font-size: 18px
    }
`
const Input = styled.input`
    background-color: transparent;
    border: 0px;
    outline-color: transparent;
    width: 100%;
    margin-left: 10px;
    font-family: CenturyGothicRegular;
    color:#102935;
    font-size: 14px;
    height: 100%;
    &.multiline {
        height: 100%;
    }
    &.whitebg {
        background: white;
    }
`

const TextArea = styled.textarea`
    background-color: white;
    border: 0px;
    outline-color: transparent;
    width: 100%;
    font-family: CenturyGothicRegular;
    color:#102935;
    font-size: 14px;
    padding: 10px;
`
const Label = styled.span`
    font-family: CenturyGothicRegular;
    color:#102935;
    font-size: 13px;
    margin-bottom: 10px;
`
const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
    width: 100%;
    &.small {
        width: 24%;
    }
    &.medium {
        width: 38%;
    }
    &.half {
        width: 49%;
    }
`

const CustomSpan = styled.div`
    margin-right: 10px;
    padding: 10px 30px 15px 25px;
    border-radius: 3px;
    background-color: white;
    width: auto;
    display: inline-block;

    &.disabled {
        background-color: #F0F0F0;
        color: #9FA9AE;
    }

    background-color: #fff;
    border-width: 1px;
    border-color: #EDF0F5;
    border-style: solid;

    &.selected {
        border-color: #f0b594;
        color: #f0b594;
    }

`

function Input2(props) {

    return (
        <Col className={props.className} style={props.style} >
            {
                props.label &&
                <Label>{props.label}</Label>
            }
            <Container className={props.className} style={props.styleInput} id={"p" + props.id} >

                {
                    props.choices.map((choice, index) => {
                        return (
                            <CustomSpan key={index} className={props.value === choice ? "selected" : ""} onClick={() => {
                                props.onChange({ target: { value: choice } })
                            }}>{choice}</CustomSpan>
                        )
                    })
                }

            </Container>
        </Col>
    );
}

export default Input2;