import * as React from "react"

function FingerPrint(props) {
    return (
        <svg
            width="20px"
            height="20px"
            viewBox="0 0 19.177 20"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            {...props}
        >
            <path
                id="fi-rr-fingerprint"
                d="M4.976,18.569a.833.833,0,0,1-.429-1.547C7.5,15.249,7.5,11.441,7.5,9.166a2.5,2.5,0,1,1,5,0,.833.833,0,0,1-1.667,0,.833.833,0,1,0-1.667,0c0,2.456,0,7.028-3.761,9.285a.833.833,0,0,1-.428.118Zm4.136,1.184a12.507,12.507,0,0,0,3.3-8.017.833.833,0,0,0-.759-.9.819.819,0,0,0-.9.759,10.762,10.762,0,0,1-2.823,6.984A.833.833,0,0,0,9.112,19.75Zm4.333-.155A14.826,14.826,0,0,0,14.7,16.948a.833.833,0,0,0-1.567-.565,13.211,13.211,0,0,1-1.115,2.351.834.834,0,0,0,1.426.865Zm1.971-5.274a26.214,26.214,0,0,0,.417-5.159A5.833,5.833,0,0,0,5.655,5.277.833.833,0,1,0,6.9,6.389a4.166,4.166,0,0,1,7.269,2.777,24.737,24.737,0,0,1-.385,4.84.833.833,0,0,0,.658.977A.816.816,0,0,0,14.6,15,.833.833,0,0,0,15.416,14.325ZM5.833,9.166a4.166,4.166,0,0,1,.057-.694A.833.833,0,1,0,4.25,8.194a5.809,5.809,0,0,0-.083.972C4.166,13.037,3.045,15,.833,15a.833.833,0,1,0,0,1.667C4.057,16.665,5.833,14,5.833,9.166ZM17.249,19.51A24.8,24.8,0,0,0,19.165,9.166a9.053,9.053,0,0,0-.917-3.995.834.834,0,0,0-1.5.729,7.416,7.416,0,0,1,.75,3.266,23.257,23.257,0,0,1-1.766,9.654.833.833,0,0,0,.417,1.1.82.82,0,0,0,.344.075.833.833,0,0,0,.755-.488ZM2.5,11.666v-2.5a7.508,7.508,0,0,1,7.5-7.5,7.425,7.425,0,0,1,4.5,1.5.833.833,0,0,0,1-1.333A9.083,9.083,0,0,0,10,0,9.177,9.177,0,0,0,.833,9.166v2.5a.833.833,0,0,0,1.667,0Z"
                transform="translate(0 0)"
                fill={props.selected ? "#102935": "#9FA9AE"}
            />
        </svg>
    )
}

export default FingerPrint
