
import "../../../../App.css";
import styled from "styled-components";
import { Image } from "react-bootstrap";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Container, Col } from "react-bootstrap";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal";
import Header from "../../../../components/Header";
import SideBar from "../../../../components/SideBar";
import Table from "../../../../components/Table";
import { useParams, useHistory, useLocation } from "react-router-dom";
import MyLaserApi from '../../../../api/MyLaserApi'
import { useSelector } from "react-redux";

import DataTable from "react-data-table-component";

const ContainerTreatment = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: transparent;
    margin-top: 20px;
    width: 96%;
`;

const Text = styled.span`
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 13px;
    &.bold {
        font-family: CenturyGothicBold;
        color: #f0b594;
        font-size: 15px;
    }
    &.blue {
        color: #102935;
    }
    &.gray {
        color: #9fa9ae;
    }
    &.underline {
        text-decoration-line: underline;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
    &.spaced-items {
        justify-content: space-between;
    }
    &.custom-width {
        width: 50%;
    }
    &.center {
        align-items: center;
    }
`;


function List(props) {

    const params = useParams()
    const history = useHistory()
    const location = useLocation();

    const user = useSelector((state) => state.user);

    const [notes, setNotes] = useState([]);

    const [showOnlyPendingNP, setShowOnlyPendingNP] = useState(true);

    useEffect(() => {

        if (location.pathname === "/mnp") {

            MyLaserApi.call({method: "GET", url: `/users/me/sessions/notes`}).then((res) => {
                if (res.status === 200) {
                    setNotes(res.data);
                }
            })

        } else {

            MyLaserApi.call({method: "GET", url: `/agencies/clients/${params.id}/sessions/notes`}).then((res) => {
                if (res.status === 200) {
                    setNotes(res.data);
                }
            })

        }

    }, []);

    return (
        <Container fluid className="App">
            <Row>
                
                <Col
                    md={12}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    {location.pathname !== "/mnp" ? <Header
                        type="client"
                    /> : <Header
                        user={user}
                        title={`Hello ${user.firstname},`}
                        subtitle="Bienvenue sur ton espace de travail"
                        icon
                    />}

                    <ContainerTreatment>

                        <Row className="spaced-items center" style={{marginBottom: "10px"}}>
                            <Text className="bold blue">
                                NOTES PARTICULIÈRES -{" "}
                                <Text className="bold">
                                    Suivi
                                </Text>
                            </Text>
                            <div style={{marginLeft: "10px"}}>
                                <input 
                                    checked={showOnlyPendingNP} 
                                    onChange={(el) => setShowOnlyPendingNP(!showOnlyPendingNP)} 
                                    type="checkbox" id="archived-consent" /> <label for="archived-consent">Afficher uniquement les notes en attente</label>
                            </div> 
                        </Row>

                        <DataTable
                            title={`Notes particulières`}
                            noDataComponent={<div style={{paddingTop: 10, paddingBottom: 10}}>Il n'y a aucune notes particulières à afficher</div>}
                            paginationPerPage={30}

                            onRowClicked={(row) => {
                                history.push(`/patients/${row.agency_client_id}/treatments/laser/notes/${row.id}/details`)
                            }}

                            conditionalRowStyles={[{
                                when: (row) => row.special_note_status == 1,
                                    style: {
                                        backgroundColor: 'rgba(215, 180, 180, 0.8)',
                                    },
                                },{
                                when: (row) => row.special_note_status == 2,
                                    style: {
                                        backgroundColor: '#B4D7C1',
                                    },
                                },
                            ]}

                            columns={[
                                {
                                    name: "Date",
                                    selector: (row) => moment(row.date).format('LLLL'),
                                    wrap: true,
                                },
                                
                                {
                                    name: "Zone de traitement",
                                    selector: (row) => `${row.type_human} (${row.area})`,
                                    wrap: true,
                                },
                                {
                                    name: "Effets sec.",
                                    selector: (row) => row.special_note_secondary_effect
                                },
                                {
                                    name: "Commentaire",
                                    wrap: true,
                                    grow: 2,
                                    selector: (row) => row.special_note_comment,
                                    
                                },
                                {
                                    name: "Patient",
                                    selector: (row) => row.agency_client ? row.agency_client.firstname + " " + row.agency_client.lastname : "",
                                },
                                {
                                    name: "Praticien(ne)",
                                    selector: (row) => row.employee ? row.employee.firstname + " " + row.employee.lastname : "",
                                }
                            ]}

                            data={notes.filter((note) => {
                                if (showOnlyPendingNP) {
                                    return note.special_note_status == 1
                                }
                                
                                return true
                                
                            })}
                        
                        />

                    </ContainerTreatment>
                </Col>
            </Row>
        </Container>
    );
}

export default List;
