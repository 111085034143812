import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container, Col, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from 'react-toastify';


import Button from "../../components/Button";
import Input from "../../components/Input";
import SessionHeader from "../../components/SessionHeader";
import EmployeePicker from "../../components/EmployeePicker";

//api
import MyLaserApi from "../../api/MyLaserApi";

import moment from 'moment'
import Swal from "sweetalert2";

import { v4 as uuidv4 } from 'uuid'

const Text = styled.span`
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 13px;
    &.label {
        font-family: CenturyGothicBold;
        color: #6b7389;
        font-size: 15px;
        margin-bottom: 20px;
    }
    &.red {
        font-family: CenturyGothicBold;
        color: #C21A1A;
    
    }
    &.bold {
        font-family: CenturyGothicBold;
    }
    &.number {
        color:#F0B594;
        font-size: 50px;
        font-family: Quicksand_Book;
    }

`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
    /* backfround-color:red; */
    &.spaced-items {
        justify-content: space-between;
        align-items: center;
    }
`;

function AddSessionPrice() {

    const location = useLocation();
    const history = useHistory();
    const params = useParams();

    const [employee, setEmployee] = useState(null)

    const [payments, setPayments] = useState([]);

    const [note, setNote] = useState(null);

    const user = useSelector((state) => state.user)

    useEffect(() => {

        setPayments(location.state.checked.map((single) => {
            return {
                single: single,
                agency_income_type_1_amount: 0,
                agency_income_type_2_amount: 0,
                agency_income_type_3_amount: 0,
                agency_income_type_4_amount: 0,
            }
        }))

    }, [])

    

    let createPayment = () => {

        let uniqid = uuidv4()

        Swal.fire({
            title: 'Verification',
            text: "Merci de verifier le montant total qui est de " + 
                payments.reduce((old, p) => 
                    parseFloat(p.agency_income_type_1_amount) + 
                    parseFloat(p.agency_income_type_2_amount) + 
                    parseFloat(p.agency_income_type_3_amount) + 
                    parseFloat(p.agency_income_type_4_amount) + 
                    old, 
                0) + " " + global.currencySymbol,
            // icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui je confirme!',
            cancelButtonText: "Annuler"
        }).then((result) => {

            if (result.isConfirmed) {

                Promise.all(payments.map((payment) => {

                    let pa = {
                        agency_income_type_1_amount: parseFloat(payment.agency_income_type_1_amount),
                        agency_income_type_2_amount: parseFloat(payment.agency_income_type_2_amount),
                        agency_income_type_3_amount: parseFloat(payment.agency_income_type_3_amount),
                        agency_income_type_4_amount: parseFloat(payment.agency_income_type_4_amount),
                    }

                    let tmpArr = [...payment.single.sessions].filter((el) => el.employee_id)

                    let employee_id = null

                    if (tmpArr.length > 0) {

                        tmpArr.sort((a, b) => new Date(a.date) - new Date(b.date));

                        employee_id = tmpArr[0].employee_id

                    }

                    // if (pa.agency_income_type_1_amount + pa.agency_income_type_2_amount + pa.agency_income_type_3_amount + pa.agency_income_type_4_amount != 0) {

                        let obj = {
                            agency_income_type_1_amount: pa.agency_income_type_1_amount,
                            agency_income_type_2_amount: pa.agency_income_type_2_amount,
                            agency_income_type_3_amount: pa.agency_income_type_3_amount,
                            agency_income_type_4_amount: pa.agency_income_type_4_amount,
                            voucher_amount: 0,
                            first_session: false,
                            uniqid: uniqid,
                            employee_id: employee_id,
                            note: note
                        }

                        if (user.role === 1) {
                            obj["collected_by"] = employee
                        }
                        
                        return MyLaserApi.createClientTreatmentPayment(params.id, payment.single.id, obj)

                    // } else {
                    //     return Promise.resolve();
                    // }

                })).then(() => {

                    toast.success("Ajout d'un paiement réussit.")

                    history.push(
                        `/patients/${params.id}/payments`,
                    );

                })
                
            }

        })

    }

    return (

        <Container fluid>

            <SessionHeader
                title="Ajout d’un paiement"
                text={"(Étape 2/2)"}
                goBack={() => history.goBack()}
            />
            
            <Text className="label">Paiement des traitements</Text>

            {

                payments.map((payment, index) => {

                    let treatment = payment.single
                    let payed_price = treatment.payments.reduce((old, current) => old + current.amount_income, 0)
                    let sumtotal = parseFloat(payment["agency_income_type_1_amount"]) + parseFloat(payment["agency_income_type_2_amount"]) + parseFloat(payment["agency_income_type_3_amount"]) + parseFloat(payment["agency_income_type_4_amount"]) 

                    // let empids = treatment.sessions.map((session) => { 
                    //     return session.employee_id
                    // })

                    // console.log(empids)

                    return [
                    
                        <Row style={{background: 'white', padding: "25px", borderRadius: "5px", marginBottom: "10px", flexDirection: "column"}}>

                            <Row style={{marginTop: "5px", flexDirection: "column"}}>
                                Traitement {treatment.type_human} de {treatment.sessions.length} séances. <br/> 
                                {payed_price}{global.currencySymbol} payé sur {treatment.forced_price - treatment.discounts.reduce((p, c) => p + parseFloat(c.amount), 0)}{global.currencySymbol}  <br/> 
                                {
                                    treatment.discounts.length > 0 ? 
                                        treatment.discounts.map((d) => [
                                            <br/>, 
                                            <span style={{fontSize: "12px"}}> Remise Exceptionnelle ({d.reason}) -{d.percentage}% ({d.amount}{global.currencySymbol}) </span>
                                        ]) : "" 
                                }
                            </Row>
                            

                            <Row className="spaced-items" style={{marginTop: "5px"}}>

                                <Col>
                                    <Row className="spaced-items">
                                        <Input noIcon className="big half " label="Montant reçu en espèce" value={payment["agency_income_type_1_amount"]} onChange={(e) => {


                                            payments[index]["agency_income_type_1_amount"] = e.target.value.replace(",", ".")

                                            if (e.target.value == "") {
                                                payments[index]["agency_income_type_1_amount"] = 0
                                            }
                                            
                                            setPayments([...payments])
                                        }} />
                                        <Input noIcon className="big medium" label="Montant reçu en carte" value={payment["agency_income_type_2_amount"]} onChange={(e) => {


                                            payments[index]["agency_income_type_2_amount"] = e.target.value.replace(",", ".")

                                            if (e.target.value == "") {
                                                payments[index]["agency_income_type_2_amount"] = 0
                                            }
                                            
                                            setPayments([...payments])
                                        }}  />
                
                                    </Row>
                                    <Row className="spaced-items">
                                        <Input noIcon className="big half" label="Montant reçu en ALMA" value={payment["agency_income_type_3_amount"]} onChange={(e) => {


                                            payments[index]["agency_income_type_3_amount"] = e.target.value.replace(",", ".")

                                            if (e.target.value == "") {
                                                payments[index]["agency_income_type_3_amount"] = 0
                                            }
                                            
                                            setPayments([...payments])
                                        }}  />
                                        <Input noIcon className="big medium " label="Montant reçu en chèque" value={payment["agency_income_type_4_amount"]} onChange={(e) => {


                                            payments[index]["agency_income_type_4_amount"] = e.target.value.replace(",", ".")

                                            if (e.target.value == "") {
                                                payments[index]["agency_income_type_4_amount"] = 0
                                            }
                                            
                                            setPayments([...payments])
                                        }}  />
                
                                    </Row>
                                    

                                    
                                    {user.role === 1 && <Row className="spaced-items">
                                        <EmployeePicker label="Choisir l'employé qui a encaissé" style={{width: "100%"}} value={employee} onChange={(e) => setEmployee(e.target.value)}></EmployeePicker>
                                    </Row>}
                                </Col>
                                <Col>
                                    <Text className="bold">Reste du montant à percevoir</Text>
                                    <Text className=" number">
                                        { sumtotal }
                                        {" / "}
                                        { (treatment.forced_price - treatment.discounts.reduce((p, c) => p + parseFloat(c.amount), 0) - treatment.payments.reduce((old, current) => old + parseFloat(current.amount_income), 0)).toFixed(2)} {global.currencySymbol}</Text>
                
                                </Col>
                                
                            </Row>

                        </Row>
                    ]


                })

            }

            <Row style={{background: 'white', padding: "5px", borderRadius: "5px", marginBottom: "10px", flexDirection: "column"}}>

                <Row className="spaced-items" style={{background: "white", marginTop: "10px"}}>

                    <Col style={{width: "33%"}}>

                        <Input 
                            noIcon
                            label="Vous pouvez rajouter une note à ce paiement"
                            className="multiline"
                            placeholder="Rajouter une note à ce paiement"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                            textArea
                        />

                    </Col>

                </Row>

            </Row>


           <div style={{width: "100%", textAlign: 'center'}}> 

                <Button 
                    style={{width: "300px", position: 'fixed', bottom: 25, right: 25}}
                    onClick={createPayment}
                    // disabled={(() => {

                    //     if (user.role === 1) {
                    //         return employee == null
                    //     } else {
                    //         return false
                    //     }

                    // })()}
                >
                    Valider
                </Button>

            </div>

        </Container>

    );
}

export default AddSessionPrice;
