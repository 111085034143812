import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css';
import styled from 'styled-components'
import { Modal, Image } from 'react-bootstrap'
import ImageUploading from "react-images-uploading";
import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { Redirect, useHistory } from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//components
import ModalC from './Modal'
import Input from './Input'
import Button from './Button'

//api
import MyLaserApi from '../api/MyLaserApi'

import S3Handler from '../services/S3Handler'
import ImageListUpload from './ImageListUpload'

import Select from 'react-select'

const Label = styled.span`
  font-family: CenturyGothicRegular;
  color:#102935;
  font-size: 13px;
  margin-bottom: 10px;
`

function CreateSpecialNoteModal(props) {

    const [note, setNote] = useState('');

    const createNote = async () => {
        
        return MyLaserApi.UpdatePatientNotes(props.clientId, props.note.id, { note: note }).then((res) => {
            if (res.status === 200) {
                toast.success("Note envoyée avec succès.")
                props.onHide()
                // history.go(0)
            } else {
                toast.error("Une erreur s'est produite !")
            }
        })
        
    };

    useEffect(() => {
        if (props.note) {
            setNote(props.note.note)
        }
    }, [props.show])

    return (

        <ModalC
            show={props.show}
            onHide={props.onHide}
            title={"Mise à jour d’une note"}
            buttonText="Valider la note"
            onButtonClick={createNote}
            size={props.size}
        >

            <div style={{ display: 'flex', alignItems: 'flex-start', width: '100%', flexDirection: 'column' , marginTop: "0px"}}>

                <Label style={{ marginBottom: 10 }}>Note</Label>

                <Input
                    noIcon
                    className="multiline"
                    placeholder="Écrivez la note ici..."
                    value={note}
                    textArea
                    onChange={(e) => setNote(e.target.value)}
                />

            </div>

            <br />
            

        </ModalC>
    );
}

export default CreateSpecialNoteModal;