import * as React from "react"

function Menu(props) {
    return (
        <svg
            width="20px"
            height="20px"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            {...props}
        >
            <path
                id="Trac\xE9_23574"
                data-name="Trac\xE9 23574"
                d="M5.833,0h-2.5A3.333,3.333,0,0,0,0,3.333v2.5A3.333,3.333,0,0,0,3.333,9.167h2.5A3.333,3.333,0,0,0,9.167,5.833v-2.5A3.333,3.333,0,0,0,5.833,0ZM7.5,5.833A1.667,1.667,0,0,1,5.833,7.5h-2.5A1.667,1.667,0,0,1,1.667,5.833v-2.5A1.667,1.667,0,0,1,3.333,1.667h2.5A1.667,1.667,0,0,1,7.5,3.333Z"
                fill={props.selected ? "#102935" : "#9FA9AE"}
            />
            <path
                id="Trac\xE9_23575"
                data-name="Trac\xE9 23575"
                d="M18.833,0h-2.5A3.333,3.333,0,0,0,13,3.333v2.5a3.333,3.333,0,0,0,3.333,3.333h2.5a3.333,3.333,0,0,0,3.333-3.333v-2.5A3.333,3.333,0,0,0,18.833,0ZM20.5,5.833A1.667,1.667,0,0,1,18.833,7.5h-2.5a1.667,1.667,0,0,1-1.667-1.667v-2.5a1.667,1.667,0,0,1,1.667-1.667h2.5A1.667,1.667,0,0,1,20.5,3.333Z"
                transform="translate(-2.167 0)"
                fill={props.selected ? "#102935" : "#9FA9AE"}
            />
            <path
                id="Trac\xE9_23576"
                data-name="Trac\xE9 23576"
                d="M5.833,13h-2.5A3.333,3.333,0,0,0,0,16.333v2.5a3.333,3.333,0,0,0,3.333,3.333h2.5a3.333,3.333,0,0,0,3.333-3.333v-2.5A3.333,3.333,0,0,0,5.833,13ZM7.5,18.833A1.667,1.667,0,0,1,5.833,20.5h-2.5a1.667,1.667,0,0,1-1.667-1.667v-2.5a1.667,1.667,0,0,1,1.667-1.667h2.5A1.667,1.667,0,0,1,7.5,16.333Z"
                transform="translate(0 -2.167)"
                fill={props.selected ? "#102935" : "#9FA9AE"}
            />
            <path
                id="Trac\xE9_23577"
                data-name="Trac\xE9 23577"
                d="M18.833,13h-2.5A3.333,3.333,0,0,0,13,16.333v2.5a3.333,3.333,0,0,0,3.333,3.333h2.5a3.333,3.333,0,0,0,3.333-3.333v-2.5A3.333,3.333,0,0,0,18.833,13ZM20.5,18.833A1.667,1.667,0,0,1,18.833,20.5h-2.5a1.667,1.667,0,0,1-1.667-1.667v-2.5a1.667,1.667,0,0,1,1.667-1.667h2.5A1.667,1.667,0,0,1,20.5,16.333Z"
                transform="translate(-2.167 -2.167)"
                fill={props.selected ? "#102935" : "#9FA9AE"}
            />
        </svg>
    )
}

export default Menu
