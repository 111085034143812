
import "../App.css";
import styled from "styled-components";
import { Image } from "react-bootstrap";

//icons
import Icons from "../convertedIcons/Icons";

//components
import Input from "./Input";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    width: 100%;
    background-color: #b4cfd7;
    border-radius: 10px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
`;

const Text = styled.span`
    margin-left: 10px;
    font-family: CenturyGothicBold;
    color: #102935;
    font-size: 13px;
    &.white {
        color: #fff;
    }
`;

function PatientListHeader({ text = "Patients My Laser", subLabel = false  }) {
   
    return (
        <Container>
            <Row>
                <Text>{text}</Text>
                {subLabel && <a style={{ textDecoration: "none" }} href={subLabel.href}>
                    <Text className="white">{subLabel.text}</Text>
                </a>}
            </Row>
            <Input placeholder="Recherche d’un patient" />
        </Container>
    );
}

export default PatientListHeader;
