

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container, Col, Badge } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";

import Button from "../../components/Button";
import Header from "../../components/Header";

import MyLaserApi from "../../api/MyLaserApi";
import { useParams, useHistory } from "react-router";
import moment from "moment";

import { toast } from 'react-toastify';

import ModalAddSessionsLaser from './../patients/Sessions/ModalAddSessionsLaser'
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

import QRCode from "react-qr-code";

import ModalC from '../../components/Modal'

const Text = styled.span`
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 13px;
    &.bold {
        font-family: CenturyGothicBold;
        color: #f0b594;
        font-size: 15px;
    }
    &.gray {
        color: #545c73;
        margin-left: 10px;
        text-decoration: underline;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 96%;
    margin-top: 20px;
    &.spaced-items {
        justify-content: space-between;
        align-items: center;
    }
`;

function unique(array, propertyName) {
    return array.filter((e, i) => array.findIndex(a => a[propertyName] === e[propertyName]) === i);
 }

function Quotation() {

    let user = useSelector((state) => state.user)
    let params = useParams()
    let history = useHistory()
    let [quotations, setQuotations] = useState([])
    let [modal, showModal] = useState(false)

    const [consent, setConsent] = useState([])

    const [tmpConsent, setTmpConsent] = useState(null)
    const [showQrCode, setShowQrCode] = useState(false)

    let reload = () => {

        MyLaserApi.getPatientQuotations(params.id).then((res) => {
            setQuotations(res.data)
        })

        MyLaserApi.getClientConsents(params.id).then((res) => {
            if (res.status === 200) {
                setConsent(res.data.find((c) => c.type === "laser" || c.type === "Épilation au Laser​"));
            }
        })

    }

    useEffect(() => {
        reload()
    }, [])

    return (
        <Container fluid className="App">
            <Row>
             
                <Col md={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    
                    <Header
                        type="client"
                    />

                    <Row>
                        <Col md={8} style={{ display: 'flex', flexDirection: 'column' }}></Col>
                        <Col md={4} style={{ display: 'flex', flexDirection: 'column' }}>

                            <div style={{width: "100%", alignItems: 'center'}}>

                                <Button className="blue custom-width" style={{width: "100%"}} onClick={() => {
                                    history.push(`/patients/${params.id}/quotations/add`)
                                }}>Créer un devis</Button>
                                <br/>
                                <br/>

                                {!consent && <><Button className="blue custom-width" style={{width: "100%"}} onClick={() => {
                                    history.push(`/patients/${params.id}/consents/create?type=laser`)
                                }}>Generer un consentement</Button></>}

                            </div>

                        </Col>
                    </Row>

                    {consent && <Row>
                        <Col md={8} style={{ display: 'flex', flexDirection: 'column' }}></Col>
                        <Col md={4} style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{width: "100%", alignItems: 'center'}}>

                                <Button style={{width: "49%", marginBottom: "15px", marginRight: "1%", alignSelf: "flex-end"}} className="reverse" onClick={() => {
                                    window.open("https://docs.google.com/viewer?url=" + encodeURIComponent(`https://eu2.contabostorage.com/c92d1ab223ed43358d4f98ca65e087d6:eth.my-laser.fr/consents/${consent.reference}.pdf`));
                                }} >CONSENT.</Button>

                                <Button style={{width: "49%", marginBottom: "15px", alignSelf: "flex-end"}} className="reverse" onClick={() => {
                                    setShowQrCode(true)
                                    setTmpConsent(consent)
                                }}>QRCode</Button>
                            </div>
                        </Col>
                    </Row>}

                    <Row className="spaced-items">
                        
                        <Badge pill bg="success" style={{
                            background: "rgb(251, 231, 132) none repeat scroll 0% 0%"
                        }}> Devis - En attente  </Badge>

                    </Row>

                    <hr />
              
                    
                    {quotations.filter((q) => !q.converted).map((quotation) => {

                        return [<Row className="spaced-items" key={quotation.id}>

                            <Text>Devis réalisé en ligne le {moment(quotation.created_at).format('LL')}<br />
                                { JSON.parse(quotation.items).map((q) => ["- " + q.name, <br/>]) }
                            </Text>

                            <Text>{quotation.price}{global.currencySymbol}/séances (forfait {quotation.type} séances)</Text>

                            <Text>{quotation.voucher != "Aucune" && (<>Réduction (Via code promo): <b>{quotation.voucher}</b></>)}</Text>
                            
                            <div style={{width: "33%"}}>

                                <Button style={{width: '100%'}} onClick={() => {
                                    MyLaserApi.convertQuotationToSessions(params.id, quotation.id).then(() => {
                                        toast.success("Le devis a été mis à jour et les séance ont été créées.")
                                        reload()
                                    })
                                }}>Convertir le devis en séances</Button>
                                
                                <br />
                                <br />

                                <Button style={{width: "33%", marginBottom: "15px", marginRight: "1%"}} className="reverse" onClick={() => {
                                    window.open(encodeURI("https://docs.google.com/viewer?url=" + quotation.link));
                                }} >DEVIS.</Button>

                                <Button style={{width: "33%", marginBottom: "15px"}} className="reverse" onClick={() => {
                                    
                                    setShowQrCode(true)
                                    setTmpConsent(quotation)

                                }}>QRCode</Button>

                                <Button style={{width: "33%", marginBottom: "15px"}} className="reverse" onClick={() => {
                                    
                                    MyLaserApi.call({url: "/quotations/" + quotation.id + "/email", method: "POST"}).then((res) => {
                                        toast.success("Email envoyé")
                                    })

                                }}>Email</Button>
                                
                                <br />
                                <br />

                                <Button style={{width: '100%'}} className="danger" onClick={() => {
                                    MyLaserApi.deleteQuotation(params.id, quotation.id).then(() => {
                                        toast.success("Le devis a été supprimé.")
                                        reload()
                                    })
                                }}>Supprimer le devis</Button>

                            </div>
                            
                        </Row>, <hr />]

                    })}

                    <Row className="spaced-items">

                        <Badge pill bg="success" style={{
                            background: "rgb(255, 150, 141)"
                        }}> Devis - En cours de réalisation via des séances  </Badge>

                    </Row>

                    <hr />
                    
                    {quotations.filter(q => q.converted && q.sessions.filter(s => !s.date.includes('1970')).length != q.sessions.length).map((quotation) => {

                        let payments = []
                        let treatments = []

                        let sessionsDone = quotation.sessions.filter(s => !s.date.includes('1970'))

                        quotation.sessions.map((session) => {
                            payments = [...payments, ...session.treatment.payments]
                            treatments = [...treatments, session.treatment]
                        })

                        return [<Row className="spaced-items" key={quotation.id}>

                            <Text>Devis réalisé en ligne le {moment(quotation.created_at).format('LL')}<br />

                                { unique(quotation.sessions, "area").map((q) => {
                                    let sessions = quotation.sessions.filter((el) => el.area == q.area)

                                    return ["- " + q.area, ` (${sessions.filter(s => !s.date.includes('1970')).length} / ${sessions.length} Séances)`, <br/>]

                                })}
                                
                            </Text>
                            
                            <Text>
                                <br/>
                                1 / 1 séance consultation
                                <br />
                                {sessionsDone.length} / {quotation.sessions.length} seances effectuées
                                <br/>
                                {(() => {
                                    return `Montant à payer ${unique(treatments, "id").reduce((s, p) => (s + p.forced_price), 0 ).toFixed(2)}${global.currencySymbol}`
                                })()}
                                <br />
                                {quotation.voucher != "Aucune" && (<>Réduction (Via code promo): <b>{quotation.voucher}</b></>)}

                            </Text>

                            <div style={{width: "33%"}}>
                              
                                {payments.length === 0 && sessionsDone.length === 1 && <Button style={{width: '100%'}} className="danger" onClick={() => {
                                    MyLaserApi.deleteQuotation(params.id, quotation.id).then(() => {
                                        toast.success("Le devis a été supprimé.")
                                        reload()
                                    })
                                }}>Supprimer le devis</Button>}

                                {sessionsDone.length > 1 && (user.role === 1 || user.role === 7) && <>
                                
                                        <Button className="danger" style={{width: '100%'}} onClick={() => {

                                            Swal.fire({
                                                title: "Attention !",
                                                text: `Vous êtes sur le point d'arrêter ce traitement, en êtes vous sur ?`,
                                                showCancelButton: true,
                                                confirmButtonText: 'Oui',
                                            }).then((result2) => {

                                                if (result2.isConfirmed) {

                                                    MyLaserApi.deleteQuotation(params.id, quotation.id, true).then(() => {
                                                        toast.success("Le devis a été supprimé.")
                                                        reload()
                                                    })

                                                }

                                            })
                                            
                                        }}>
                                            Arreter le traitement
                                        </Button>

                                </>
                                }

                                <br />
                                <br />


                                <Button style={{width: "33%", marginBottom: "15px", marginRight: "1%"}} className="reverse" onClick={() => {
                                    window.open(encodeURI("https://docs.google.com/viewer?url=" + quotation.link));
                                }} >DEVIS.</Button>

                                <Button style={{width: "33%", marginBottom: "15px"}} className="reverse" onClick={() => {
                                    
                                    setShowQrCode(true)
                                    setTmpConsent(quotation)

                                }}>QRCode</Button>

                                <Button style={{width: "33%", marginBottom: "15px"}} className="reverse" onClick={() => {
                                    
                                    MyLaserApi.call({url: "/quotations/" + quotation.id + "/email", method: "POST"}).then((res) => {
                                        toast.success("Email envoyé")
                                    })

                                }}>Email</Button>
                                

                                <Button className="blue" style={{width: '100%'}} onClick={() => {
                                    showModal(quotation)
                                }}>Ajouter des séances au devis</Button>
                                

                                {
                                    unique(payments, "id").reduce((s, p) => (s + p.amount_income), 0 ) == 0 && quotation.sessions.filter(s => !s.date.includes('1970')).length == 0 [
                                        <br />, 
                                        <br />,
                                        <Button className="danger" style={{width: '100%'}} onClick={() => {

                                            Swal.fire({
                                                title: "Attention !",
                                                text: `Vous êtes sur le point de supprimer ce devis, en êtes vous sur ?`,
                                                showCancelButton: true,
                                                confirmButtonText: 'Oui',
                                            }).then((result2) => {

                                                if (result2.isConfirmed) {

                                                    MyLaserApi.deleteQuotation(params.id, quotation.id, false).then(() => {
                                                        toast.success("Le devis a été supprimé.")
                                                        reload()
                                                    })

                                                }

                                            })
                                            
                                        }}>Supprimer le devis</Button>
                                    ]
                                }

                            </div>
                            
                        </Row>, <hr style={{width: "100%"}} />]

                    })}

                    <Row className="spaced-items">

                        <Badge pill bg="success" style={{
                            background: "#8cb197"
                        }}> Devis - Fini  </Badge>

                    </Row>

                    <hr />

                    {quotations.filter(q => q.converted && q.sessions.filter(s => !s.date.includes('1970')).length == q.sessions.length).filter((quotation) => quotation.sessions.length > 0).map((quotation) => {

                        return [<Row className="spaced-items" key={quotation.id}>

                            <Text>Devis réalisé en ligne le {moment(quotation.created_at).format('LL')}<br />
                                { unique(quotation.sessions, "area").map((q) => {
                                    let sessions = quotation.sessions.filter((el) => el.area == q.area)

                                    return ["- " + q.area, ` (${sessions.filter(s => !s.date.includes('1970')).length} / ${sessions.length} Séances)`, <br/>]

                                })}
                            </Text>
                            
                            <Text>
                                <br/>
                                {quotation.sessions.filter(s => !s.date.includes('1970')).length} / {quotation.sessions.length} seances effectuées
                                <br/>
                                {(() => {

                                    let payments = []
                                    let treatments = []

                                    quotation.sessions.map((session) => {
                                        payments = [...payments, ...session.treatment.payments]
                                        treatments = [...treatments, session.treatment]
                                    })

                                    return `Montant payé: ${unique(payments, "id").reduce((s, p) => (s + p.amount_income), 0 ).toFixed(2)}${global.currencySymbol} sur ${unique(treatments, "id").reduce((s, p) => (s + p.forced_price), 0 ).toFixed(2)}${global.currencySymbol}`

                                })()}
                                <br/>
                                {quotation.voucher != "Aucune" && (<>Réduction (Via code promo): <b>{quotation.voucher}</b></>)}

                            </Text>

                            <div style={{width: "33%"}}>

                                <Button className="blue" style={{width: '100%'}} onClick={() => {
                                    showModal(quotation)
                                }}>Ajouter des séances au devis</Button>

                                <br />
                                <br />

                                <Button style={{width: "33%", marginBottom: "15px", marginRight: "1%"}} className="reverse" onClick={() => {
                                    window.open(encodeURI("https://docs.google.com/viewer?url=" + quotation.link));
                                }} >DEVIS.</Button>

                                <Button style={{width: "33%", marginBottom: "15px"}} className="reverse" onClick={() => {
                                    
                                    setShowQrCode(true)
                                    setTmpConsent(quotation)

                                }}>QRCode</Button>

                                <Button style={{width: "33%", marginBottom: "15px"}} className="reverse" onClick={() => {
                                    
                                    MyLaserApi.call({url: "/quotations/" + quotation.id + "/email", method: "POST"}).then((res) => {
                                        toast.success("Email envoyé")
                                    })

                                }}>Email</Button>
                                
                                
                                {/* <br />
                                <br /> */}

                                {/* <Button style={{width: '100%'}} onClick={() => {
                                        // MyLaserApi.convertQuotationToSessions(params.id, quotation.id)
                                }}>Voir les séances de ce devis</Button> */}

                            </div>
                            
                        </Row>, <hr />]

                    })}


                </Col>
            </Row>

            <ModalAddSessionsLaser data={modal} setShow={showModal} callback={() => { reload() }} />

            <ModalC
                show={showQrCode}
                onHide={() => setShowQrCode(false)}
                title={"QRCode consentement"}
                onButtonClick={() => setShowQrCode(false)}
                size="lg"
            >
                {tmpConsent && <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "80%", width: "80%", alignSelf: "center" }}
                    value={"https://docs.google.com/viewer?url=" + encodeURIComponent(tmpConsent.link)}
                    viewBox={`0 0 256 256`}
                />}
            </ModalC>

        </Container>
    );
}

export default Quotation;
