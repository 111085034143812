// import * as AWS from 'aws-sdk'
import { v4 as uuidv4 } from 'uuid';

import S3 from "react-aws-s3";

export default class S3Handler {

    static ReactS3Client = new S3({
        accessKeyId: "AKIAICTGSHTMU6GNVM4A",
        secretAccessKey: "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",
        signatureVersion: "v4",
        region: "eu-central-1",
        bucketName: 'ezee-app-public-dev',
        dirName: "mylaser"
    });

    static upload({ file, filename, contentType = "image/jpeg"}) {
        return S3Handler.ReactS3Client.uploadFile(file, filename)
    }

    static uploadFromList(imageList) {

        let promises = []

        for (const single of imageList) {

            promises.push(new Promise((resolve) => {

                let filename = `${uuidv4()}.${single.file.name.split('.').pop()}`

                if ( ["image/png", "image/bmp", "image/gif", "image/jpeg"].includes( single.file.type ) ) {

                    S3Handler.upload({
                        filename: filename,
                        file: single.file
                    }).then((res) => {
                        resolve(res.location)
                    });

                }

            }))

        }

        return Promise.all(promises)

    }

    static uploadFromListDocuments(imageList) {

        let promises = []

        for (const single of imageList) {

            promises.push(new Promise((resolve) => {

                S3Handler.upload({
                    filename: single.file.name,
                    file: single.file
                }).then((res) => {
                    resolve(res.location)
                });


            }))

        }

        return Promise.all(promises)

    }

}