
import "../App.css";
import styled from "styled-components";

export const ButtonA = styled.a`
    background-color: #f0b594;
    border: 0;
    box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.05);
    width: 48%;
    font-family: CenturyGothicBold;
    font-size: 14px;
    padding: 10px;
    color: #fff;
    border-radius: 8px;
    text-align: center;
    text-decoration: none;
    &:hover {
        background-color: #f0b594;
        color: #fff;
        border: solid;
        border-width: 1px;
        border-color: #f0b594;  
    }
    &.reverse {
        background-color: #fff;
        color: #f0b594;
        border: solid;
        border-width: 1px;
        border-color: #f0b594;
    }
    &.little {
        padding: 5px!important;
        font-size: 12px;
    }
    &.borderd {
        border: solid;
        border-width: 1px;
        border-color: #edf0f5;
        background-color: #fff;
        color: #6b7389;
        box-shadow: none;
    }
    &.blue {
        background-color: #b4cfd7;
    }
    &.gray {
        background-color: rgba(159, 169, 174, 0.22);
        box-shadow: none;
        color: #9fa9ae;
        width: 12%;
        margin-right: 20px;
    }
    &.custom-width {
        width: 24%;
    }
    &.half-width {
        width: 40%;
    }
    &.no-background {
        background-color: transparent;
        width: auto;
        box-shadow: none;
        padding: 0px;
    }
    &.selected {
        background-color: #fdf4ef;
        border-color: #f0b594;
    }
    &.regular {
        font-family: CenturyGothicRegular;
    }
    &:disabled {
        cursor: not-allowed;
        background-color: #6b7389;
    }
    &.danger {
        background-color: #dc3545;
    }
    &.text-blue {
        color: #b4cfd7;
    }
`;

export default ButtonA;