
import "../App.css";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container, Col, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from 'react-toastify';


import Button from "../components/Button";
import Input from "../components/Input";
import SessionHeader from "../components/SessionHeader";
import EmployeePicker from "../components/EmployeePicker";

//api
import MyLaserApi from "../api/MyLaserApi";

import Swal from "sweetalert2";

import { Calendar } from 'react-date-range';

import * as locales from 'react-date-range/dist/locale';

const Text = styled.span`
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 13px;
    &.label {
        font-family: CenturyGothicBold;
        color: #6b7389;
        font-size: 15px;
        margin-bottom: 20px;
    }
    &.red {
        font-family: CenturyGothicBold;
        color: #C21A1A;
    
    }
    &.bold {
        font-family: CenturyGothicBold;
    }
    &.number {
        color:#F0B594;
        font-size: 50px;
        font-family: Quicksand_Book;
    }

`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
    /* backfround-color:red; */
    &.spaced-items {
        justify-content: space-between;
        align-items: center;
    }
`;

function AddSessionPrice() {

    const location = useLocation();
    const history = useHistory();
    const params = useParams();

    const [employee, setEmployee] = useState(null)
    const [employeeSession, setEmployeeSession] = useState(null)

    const [payments, setPayments] = useState([]);

    const user = useSelector((state) => state.user)

    const [date, setDate] = useState(null)

    useEffect(() => {
        setDate(new Date(location.state.payments[0].date))
        setPayments(location.state.payments)

        setEmployee(location.state.payments[0].collected_by_id)
        setEmployeeSession(location.state.payments[0].employee_id)

    }, [])

    let createPayment = () => {

        Swal.fire({
            title: 'Verification',
            text: "Merci de verifier le montant total qui est de " + 
                payments.reduce((old, p) => 
                    parseFloat(p.agency_income_type_1_amount) + 
                    parseFloat(p.agency_income_type_2_amount) + 
                    parseFloat(p.agency_income_type_3_amount) + 
                    parseFloat(p.agency_income_type_4_amount) + 
                    old, 
                0) + " " + global.currencySymbol,
            // icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui je confirme!',
            cancelButtonText: "Annuler"
        }).then((result) => {

            if (result.isConfirmed) {

                Promise.all(payments.map((payment) => {

                    let objtosend = {
                        agency_income_type_1_amount: parseFloat(payment.agency_income_type_1_amount),
                        agency_income_type_2_amount: parseFloat(payment.agency_income_type_2_amount),
                        agency_income_type_3_amount: parseFloat(payment.agency_income_type_3_amount),
                        agency_income_type_4_amount: parseFloat(payment.agency_income_type_4_amount),
                    }

                    if (date) {
                        objtosend["date"] = date
                    }

                    if (employee) {
                        objtosend["collected_by_id"] = employee
                    }

                    if (employeeSession) {
                        objtosend["employee_id"] = employeeSession
                    }

                    return MyLaserApi.updatePayment(payment.id, objtosend)

                })).then(() => {

                    toast.success("Modification des paiements réussit.")

                    history.goBack();

                })
                
            }

        })

    }

    return (

        <Container fluid>

            <SessionHeader
                title="Modification de paiements"
                text={""}
                goBack={() => history.goBack()}
            />
            
            <Text className="label">Paiement des traitements</Text>

            {(user.role == 1 || user.role == 7) && <Row style={{background: 'white', padding: "25px", borderRadius: "5px", marginBottom: "10px", flexDirection: "column"}}>

                <Text className="label">Administration et modification des paiements</Text>

                 <Col>
                    <Row className="spaced-items">
                        <Col>
                            {user.role === 1 && <>Date de l'encaissement

                            <Calendar
                                onChange={(item) => { 
                                    
                                    Swal.fire({
                                        title: 'Attention',
                                        text: "Vous êtes sur le point de modifier la date d'un paiement, êtes vous sur ?",
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonColor: '#3085d6',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: 'Oui je suis sur !',
                                        cancelButtonText: "Annuler"
                                    }).then((result) => {

                                        if (result.isConfirmed) {
                                            item.setHours(12)
                                            setDate(item) 
                                        }

                                    })
                                    
                                }}
                                date={date}
                                maxDate={new Date()}
                                locale={locales["fr"]}
                            /></>}

                            <Row className="spaced-items"> 
                                <EmployeePicker  
                                    label="Choisir le praticien qui a encaissé" 
                                    style={{width: "100%"}} 
                                    forced_agency={location.state.payments[0].agency_id} 
                                    value={employee}
                                    onChange={(e) => setEmployee(e.target.value)}
                                />
                            </Row>

                            <Row className="spaced-items"> 
                                <EmployeePicker  
                                    label="Choisir le praticien qui a pratiqué la séance" 
                                    style={{width: "100%"}} 
                                    forced_agency={location.state.payments[0].agency_id} 
                                    value={employeeSession}
                                    onChange={(e) => setEmployeeSession(e.target.value)}
                                />
                            </Row>

                        </Col>

                    </Row>
                </Col> 


            </Row> }

            {

                payments.map((payment, index) => {

                    let treatment = payment.treatment
                    let payed_price = payment.treatment.payments.filter((s) => payment.id != s.id ).reduce((old, current) => old + parseFloat(current.amount_income), 0)
                    let sumtotal = (parseFloat(payment["agency_income_type_1_amount"]) + parseFloat(payment["agency_income_type_2_amount"]) + parseFloat(payment["agency_income_type_3_amount"]) + parseFloat(payment["agency_income_type_4_amount"])).toFixed(2)

                    return [
                    
                        <Row style={{background: 'white', padding: "25px", borderRadius: "5px", marginBottom: "10px", flexDirection: "column"}}>

                            <Row style={{marginTop: "5px", flexDirection: "column"}}>

                                Traitement {treatment.type_human} de {treatment.sessions.length} séances. <br/> {payed_price}{global.currencySymbol}
                                (+ {sumtotal}{global.currencySymbol} actuellement) payé sur {treatment.forced_price - treatment.discounts.reduce((p, c) => p + parseFloat(c.amount), 0)}{global.currencySymbol} <br/> 

                                {
                                    treatment.discounts.length > 0 ? 
                                        treatment.discounts.map((d) => [
                                            <br/>, 
                                            <span style={{fontSize: "12px"}}> Remise Exceptionnelle ({d.reason}) -{d.percentage}% ({d.amount}{global.currencySymbol}) </span>
                                        ]) : "" 
                                }

                            </Row>

                            <Row className="spaced-items" style={{marginTop: "5px"}}>

                                <Col>
                                    <Row className="spaced-items">
                                        <Input noIcon className="big half " label="Montant reçu en espèce" value={payment["agency_income_type_1_amount"]} onChange={(e) => {


                                            payments[index]["agency_income_type_1_amount"] = e.target.value.replace(",", ".")

                                            if (e.target.value == "") {
                                                payments[index]["agency_income_type_1_amount"] = 0
                                            }
                                            
                                            setPayments([...payments])
                                        }} />
                                        <Input noIcon className="big medium" label="Montant reçu en carte" value={payment["agency_income_type_2_amount"]} onChange={(e) => {


                                            payments[index]["agency_income_type_2_amount"] = e.target.value.replace(",", ".")

                                            if (e.target.value == "") {
                                                payments[index]["agency_income_type_2_amount"] = 0
                                            }
                                            
                                            setPayments([...payments])
                                        }}  />
                
                                    </Row>
                                    <Row className="spaced-items">
                                        <Input noIcon className="big half" label="Montant reçu en ALMA" value={payment["agency_income_type_3_amount"]} onChange={(e) => {


                                            payments[index]["agency_income_type_3_amount"] = e.target.value.replace(",", ".")

                                            if (e.target.value == "") {
                                                payments[index]["agency_income_type_3_amount"] = 0
                                            }
                                            
                                            setPayments([...payments])
                                        }}  />
                                        <Input noIcon className="big medium " label="Montant reçu en chèque" value={payment["agency_income_type_4_amount"]} onChange={(e) => {


                                            payments[index]["agency_income_type_4_amount"] = e.target.value.replace(",", ".")

                                            if (e.target.value == "") {
                                                payments[index]["agency_income_type_4_amount"] = 0
                                            }
                                            
                                            setPayments([...payments])
                                        }}  />
                
                                    </Row>
                                    
                                </Col>
                                <Col>
                                    <Text className="bold">Reste du montant à percevoir</Text>
                                    <Text className=" number">
                                        { sumtotal }
                                        {" / "}
                                        { (treatment.forced_price - treatment.discounts.reduce((p, c) => p + parseFloat(c.amount), 0) - treatment.payments.filter((s) => payment.id != s.id ).reduce((old, current) => old + parseFloat(current.amount_income), 0)).toFixed(2) } 
                                        {global.currencySymbol}</Text>
                
                                </Col>
                                
                            </Row>

                        </Row>
                    ]


                })

            }

           <div style={{width: "100%", textAlign: 'center'}}> 

                <Button 
                    style={{width: "300px", position: 'fixed', bottom: 25, right: 25}}
                    onClick={createPayment}
                    
                >
                    Valider {payments.reduce((old, p) => 
                        parseFloat(p.agency_income_type_1_amount) + 
                        parseFloat(p.agency_income_type_2_amount) + 
                        parseFloat(p.agency_income_type_3_amount) + 
                        parseFloat(p.agency_income_type_4_amount) + 
                        old, 
                    0) + " " + global.currencySymbol}
                </Button>

            </div>

        </Container>

    );
}

export default AddSessionPrice;
