
import "../App.css";
import styled from "styled-components";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import MyLaserApi from "../api/MyLaserApi";
import Employee from "./Employee";
import { useSelector } from "react-redux";

const Label = styled.span`
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 13px;
    margin-bottom: 10px;
`;

const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
    margin-right: 10px !important;
    width: 100%;
    &.small {
        width: 24%;
    }
    &.medium {
        width: 32%;
    }
`;

function EmployeePicker({ className, label, onChange, value, width, style, forced_agency }) {

    let [employees, setEmployees] = useState([])
    let user = useSelector((state) => state.user)  

    let init = () => {

        if (user.role == 1) {

            MyLaserApi.call({url: "/employees"}).then(({ data }) => {
                let filtred = data.filter((s) => s.status === true && s.agency_id != null)

                if (forced_agency !== undefined && forced_agency !== null) {
                    filtred = filtred.filter((s) => s.agency_id === forced_agency)
                }

                setEmployees(filtred)
            })

        }

    }

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        init()
    }, [user])

    if (user.role == 1) {

        return (
            <Col className={className} style={style}>

                {label && <Label>{label}</Label>}

                <select
                    style={{
                        width: "100%",
                        height: "40px",
                        border: "0",
                        borderRadius: "5px",
                        paddingLeft: "15px",
                        textTransform: "capitalize"
                    }}  
                    placeholder={"Séléctionnez un(e) employé(e)"}
                    onChange={onChange}
                    value={value}
                >
                    {employees.map((employee) => <option value={employee.id} style={{textTransform: "capitalize"}}> {employee.firstname + " " + employee.lastname} </option>)}
                </select>

            </Col>
        );

    } else {
        return <div></div>
    }
}

export default EmployeePicker;
