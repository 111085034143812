

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Image } from "react-bootstrap";
import { Container, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Loader from "react-js-loader";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";

import Input from "../../components/Input";
import InputRadio from "../../components/InputRadio";
import Button from "../../components/Button";
import ButtonLoader from "../../components/ButtonLoader";
import Header from "../../components/Header";
import CreatePatientNoteModal from "../../components/CreatePatientNoteModal";
import UpdatePatientNoteModal from "../../components/UpdatePatientNoteModal";

import { useLocation, useParams, useHistory } from "react-router-dom";
import MyLaserApi from "../../api/MyLaserApi";
import DataTable from 'react-data-table-component';
import moment from 'moment'
import { useSelector } from "react-redux";
import Picker from "../../components/Picker";

import Swal from "sweetalert2"

import { Provider, useDispatch } from "react-redux";

import { sampleObjectDetail } from "./PatientInformationMed"

const ContainerInfos = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    background-color: transparent;
    padding-top: 30px;
    /* margin-top: 20px; */
    width: 96%;
`;

const Text = styled.span`
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 13px;
    &.bold {
        font-family: CenturyGothicBold;
        color: #f0b594;
        font-size: 15px;
    }
    &.gray {
        color: #545c73;
        margin-left: 10px;
        text-decoration: underline;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 20px;
    &.spaced-items {
        justify-content: space-between;
    }
`;

const Form = ({
    informations,
    onInformationsChange,
    UpdateClient,
    setClientInformations,
}) => {
    
    const params = useParams();

    return (
    <ContainerInfos>
        <Text className="bold">Informations Personnelles</Text>
        <Row className="spaced-items">
            <Input
                noIcon
                className=" with-label"
                label="Nom"
                value={informations.lastname}
                onChange={(e) =>
                    onInformationsChange({ lastname: e.target.value })
                }
                style={{ width: '30%' }}
            />
            <Input
                noIcon
                className=" with-label"
                label="Prénom"
                value={informations.firstname}
                onChange={(e) =>
                    onInformationsChange({ firstname: e.target.value })
                }
                style={{ width: '30%' }}
            />
         
            <Input
                noIcon
                className="with-label"
                label="Date de naissance"
                value={informations.birthdate}
                onChange={(e) =>
                    onInformationsChange({ birthdate: e.target.value })
                }
                style={{ width: '30%' }}
                type="date"
            />

        </Row>

        <Row className="spaced-items">

            <Picker
                placeholder="Sexe"
                isSearchable={false}
                options={["homme", "femme"].map((el) => ({ value: el, label: el }))}
                value={{value: informations.sexe, label: informations.sexe}}
                onChange={({ value }) => onInformationsChange({ sexe: value })}
                label="Sexe"
                backgroundColor="white"
                style={{ width: '30%' }}
                marginRight
                className="medius"
            />

            <Input
                noIcon
                className=" with-label"
                label="Email"
                value={informations.email}
                onChange={(e) =>
                    onInformationsChange({ email: e.target.value })
                }
                style={{ width: '30%' }}
            />
            <Input
                noIcon
                className=" with-label"
                label="N° de téléphone"
                value={informations.phone_number}
                onChange={(e) =>
                    onInformationsChange({ phone_number: e.target.value })
                }
                style={{ width: '30%' }}
            />
        </Row>
        <Text className="bold" style={{ marginTop: 30 }}>
            Informations Postales
        </Text>
        <Row className="spaced-items">
            <Input
                noIcon
                className=" with-label"
                label="Ville"
                value={informations.address_city}
                onChange={(e) =>
                    onInformationsChange({ address_city: e.target.value })
                }
                style={{ width: '30%' }}
            />
            <Input
                noIcon
                className=" with-label"
                label="Code Postal"
                value={informations.address_zip_code}
                onChange={(e) =>
                    onInformationsChange({ address_zip_code: e.target.value })
                }
                style={{ width: '30%' }}
            />
            <Input
                noIcon
                className=" with-label"
                label="Adresse"
                value={informations.address_line}
                onChange={(e) =>
                    onInformationsChange({ address_line: e.target.value })
                }
                style={{ width: '30%' }}
            />
        </Row>
        
        

        <Row
            style={{
                alignItems: "flex-end",
                justifyContent: "flex-end",
                marginTop: "30px"
            }}
        >
            <ButtonLoader className="custom-width" style={{width: "30%"}} onClick={UpdateClient}>
                Sauvegarder les informations patients
            </ButtonLoader>
        </Row>

    </ContainerInfos>
)};

function PatientInformations() {
    
    const params = useParams();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [notes, setNotes] = useState([])

    const [modalShow, setModalShow] = useState(false);
    const [updateModalShow, setUpdateModalShow] = useState(false);

    const [currentNote, setCurrentNote] = useState(false);

    const [informations, setInformations] = useState({
        lastname: "",
        firstname: "",
        email: "",
        phone_number: "",
        birthdate: moment().format("YYYY-MM-DD"),
        address_city: "",
        address_line: "",
        address_zip_code: "",
        sexe: "",
        human_status: "En traitement",
        human_status_more: "",
        picture: "",
        details: sampleObjectDetail
    });

    const user = useSelector(state => state.user);

    useEffect(() => {
        setClientInformations();
        reloadNotes()
    }, []);

    const reloadNotes = () => {
        MyLaserApi.GetPatientNotes(params.id).then(({data}) => {
            setNotes(data)
        })
    }

    const setClientInformations = () => {

        MyLaserApi.getClient(params.id).then((res) => {

            if (res.status === 200) {

                let details = sampleObjectDetail.data

                if (res.data.details && res.data.details.data) {
                    details = {
                        ...details,
                        ...JSON.parse(res.data.details.data)
                    }
                }

                setInformations({
                    ...informations,
                    lastname: res.data.lastname,
                    firstname: res.data.firstname,
                    email: res.data.email,
                    phone_number: res.data.phone_number,
                    birthdate: res.data.birthdate
                        ? moment(res.data.birthdate).format("YYYY-MM-DD")
                        : moment().format("YYYY-MM-DD"),
                    address_city: res.data.address_city,
                    address_line: res.data.address_line,
                    address_zip_code: res.data.address_zip_code,
                    sexe: res.data.sexe,
                    human_status: res.data.human_status || "En traitement",
                    human_status_more: res.data.human_status_more,
                    picture: res.data.picture,
                    comment: res.data.comment,
                    details: details
                });

                dispatch({
                    type: 'SET_CLIENT',
                    payload: res.data
                })
            
            }

        })

    };

    const onInformationsChange = (obj) => {
        setInformations((prevState) => {
            return {
                ...prevState,
                ...obj,
            };
        });
    };

    const UpdateClient = () => {
        // setLoading(true);


        const client = {
            lastname: informations.lastname,
            firstname: informations.firstname,
            email: informations.email,
            phone_number: informations.phone_number,
            birthdate: moment(informations.birthdate, "YYYY-MM-DD").format("DD/MM/YYYY"),
            address_city: informations.address_city,
            address_line: informations.address_line,
            address_zip_code: informations.address_zip_code,
            sexe: informations.sexe,
            human_status: informations.human_status,
            human_status_more: informations.human_status_more,
            picture: informations.picture,
            comment: informations.comment
        };

        console.log(informations)

        console.log(client)

        return MyLaserApi.UpdateClient(client, params.id).then((res) => {
            if (res.status === 200) {
                toast.success("Mise à jour des informations réussite.");
                // MyLaserApi.getAllClients();
                MyLaserApi.getClient(params.id).then((res) => {
                    if (res.status === 200) {
                        dispatch({
                            type: 'SET_CLIENT',
                            payload: res.data
                        })
                    }
                });

                
            } else {
                toast.error("Une erreur s'est produite !");
            }
            setLoading(false);
        });
    };

    const UpdateClientDetails = () => {
        setLoading(true);

        return MyLaserApi.UpdateClientDetails(params.id, {data: informations.details}).then((res) => {
            if (res.status === 200) {
                toast.success("Mise à jour des informations réussite.");
                // MyLaserApi.getAllClients();
                MyLaserApi.getClient(params.id).then((res) => {
                    if (res.status === 200) {
                        dispatch({
                            type: 'SET_CLIENT',
                            payload: res.data
                        })
                    }
                });
            } else {
                toast.error("Une erreur s'est produite !");
            }
            setLoading(false);
        });
    };


    if (informations === null && informations.details === null) {
        return;
    }

    return (
        <Container fluid className="App">
            <Row style={{marginTop: 0}}>
                <Col
                    md={12}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Header
                        type="client"
                    />

                    <ContainerInfos>

                        <Text className="bold" style={{  marginBottom: 15 }}>Status</Text>
                        <Picker
                            placeholder="Sexe"
                            isSearchable={false}
                            options={["En traitement", "Suspendu", "Clôturé"].map((el) => ({ value: el, label: el }))}
                            value={{value: informations.human_status, label: informations.human_status}}
                            onChange={({ value }) => onInformationsChange({ human_status: value })}
                            label=""
                            backgroundColor="white"
                            style={{ width: '30%' }}
                            marginRight
                            className="medius"
                        />
                        {(informations.human_status == "Suspendu" || informations.human_status == "Clôturé") && <Input 
                            noIcon
                            className="with-label"
                            label={"Informations sur le status"}
                            value={informations.human_status_more}
                            textArea
                            onChange={(e) => onInformationsChange({ human_status_more: e.target.value })}
                            style={{ width: '100%', paddingTop: 10 }}
                          />}


                        <Text className="bold" style={{ marginTop: 30 }}>Notes</Text>
                        <Text style={{ textAlign: "right",marginBottom: 30, color: "rgb(136 194 211)", textDecoration: "underline", fontWeight: "bold",  }} onClick={() => setModalShow(true)} >Ajouter une note</Text>

                        <DataTable
                            noDataComponent={<div style={{paddingTop: 10, paddingBottom: 10}}>Il n'y a aucune note à afficher</div>}
                            pagination={false}
                            columns={[
                                {
                                    name: 'Date',
                                    selector: (row) => <span style={{textTransform: "capitalize" }} > { moment(row.created_at).format('LLLL') } </span>,
                                    sortable: true,
                                    grow: 2
                                },
                                {
                                    name: 'Auteur',
                                    selector: (row) => row.employee.firstname + " " + row.employee.lastname,
                                    sortable: true,
                                    grow: 1
                                },
                                {
                                    name: 'Note',
                                    selector: (row) => row.note,
                                    sortable: true,
                                    wrap: true,
                                    grow: 3
                                },
                                {
                                    name: "Actions",
                                    selector: (row) => {

                                        let rtn = ["Aucune action"]

                                        let fullAction = [

                                            <button
                                                className="action-btn" 
                                                style={{
                                                    marginTop: 5,
                                                    marginBottom: 5,
                                                    fontFamily: "CenturyGothicBold",
                                                    fontSize: "12px",
                                                }}
                                                onClick={() => {
                                                
                                                    setCurrentNote(row)
                                                    setUpdateModalShow(true)
                                                    
                                                    // MyLaserApi.DeletePatientNotes(params.id, row.id).then(() => {
                                                    //     reloadNotes()
                                                    // })
                                                }} 
                                            >Modifier</button>,
                                            <br />,
                                            <button 
                                                className="action-btn" 
                                                // style={{ textAlign: "right", marginTop: 30, color: "#EE3E54", fontWeight: "bold" }} 
                                                style={{
                                                    marginTop: 5,
                                                    marginBottom: 5,
                                                    fontFamily: "CenturyGothicBold",
                                                    fontSize: "12px",
                                                    color: "#EE3E54"
                                                }}
                                                onClick={() => {

                                                    Swal.fire({
                                                        title: 'Êtes vous sur ?',
                                                        text: "Attention ! Une fois cette note supprimée, elle ne sera plus visible !",
                                                        icon: 'warning',
                                                        cancelButtonText: 'Annuler',
                                                        showCancelButton: true,
                                                        confirmButtonColor: '#f0b594',
                                                    }).then(({ isConfirmed }) => {
                                                        if (isConfirmed) {
                                                            MyLaserApi.DeletePatientNotes(params.id, row.id).then(() => {
                                                                reloadNotes()
                                                            })
                                                        }
                                                    })
                                                
                                            }} >Supprimer</button>,
                                            
                                        ]


                                        if (user.role == 1 || user.role == 7) { 

                                            return fullAction
                                        } else if (user.id == row.employee_id) {

                                            var duration = moment.duration(moment().diff(moment(row.created_at)));
                                            var hours = duration.asHours();

                                            if (hours > 48) {
                                                return [rtn]
                                            } else {
                                                return fullAction
                                            }

                                        } else {
                                            return rtn
                                        }

                                    }
                                }

                            ]}
                            data={notes}
                        />

                        </ContainerInfos>

                    {loading ? (
                        <div className={"loader"}>
                            <Loader
                                type="spinner-circle"
                                bgColor={"#F0B594"}
                                size={70}
                            />
                        </div>
                    ) : (
                            <Form
                                informations={informations}
                                onInformationsChange={onInformationsChange}
                                UpdateClient={UpdateClient}
                                setClientInformations={setClientInformations}
                            />
                        )}

                    
                        <hr />

                    <ContainerInfos>

                        <Text className="bold" style={{ marginTop: 0 }}>
                            Informations Médicales
                        </Text>
                        
                        <Row className="spaced-items">
                        {
                            Object.keys(informations.details).filter((key) => !["firstname", "lastname", "birthdate", "phone_number", "email", "address_line", "address_zip_code", "address_city", "agency_id", "sexe", "undersign"].includes(key)).map((key, index) => {
                                
                                if (["treatment_photosensitizer_question", "aesthetic_question", "pregnant_question", "breastfeeding", "treatment_already_done", "using_tanning_cream", "using_lightening_cream", "face_laser_hair_removal_question", "face_injection_question", "face_makeup_question", "treatment_face_question", "event_question"].includes(key)) {

                                    return <InputRadio
                                        choices={["Oui", "Non"]}
                                        className="with-label"
                                        label={informations.details[key].title}
                                        value={informations.details[key].value}
                                        onChange={(e) =>
                                            onInformationsChange({ 
                                                details: {
                                                    ...informations.details,
                                                    [key]: {
                                                        ...informations.details[key],
                                                        value: e.target.value
                                                    }
                                                }
                                            })
                                        }
                                        style={{ width: '100%', paddingTop: 10 }}
                                    />


                                } else {

                                    if ((key === "face_injection_zone" || key === "face_injection_date") && informations.details["face_injection_question"].value != "Oui") {
                                        return <></>
                                    }

                                    if (key === "treatment_photosensitizer_end" && informations.details["treatment_photosensitizer_question"].value != "Oui") {
                                        return <></>
                                    }

                                    if (key === "treatment_already_done_date" && informations.details["treatment_already_done"].value != "Oui") {
                                        return <></>
                                    }

                                    if (key === "face_makeup_zone" && informations.details["face_makeup_question"].value != "Oui") {
                                        return <></>
                                    }

                                    if (key === "face_makeup_date" && informations.details["face_makeup_question"].value != "Oui") {
                                        return <></>
                                    }

                                    if (key === "face_laser_hair_removal_date" && informations.details["face_laser_hair_removal_question"].value != "Oui") {
                                        return <></>
                                    }

                                    if ((key === "treatment_face_zone" || key === "treatment_face_date") && informations.details["treatment_face_question"].value != "Oui") {
                                        return <></>
                                    }

                                    if (key.includes("response") && informations.details[key.replace("response", "question")].value != "Oui") {
                                        return <></>
                                    }

                                    return <Input
                                        noIcon
                                        className="with-label"
                                        label={informations.details[key].title}
                                        value={informations.details[key].value}
                                        onChange={(e) =>
                                            onInformationsChange({ 
                                                details: {
                                                    ...informations.details,
                                                    [key]: {
                                                        ...informations.details[key],
                                                        value: e.target.value
                                                    }
                                                }
                                            })
                                        }
                                        style={{ width: '100%', paddingTop: 10 }}
                                    />

                                }
                                
                                
                            })
                        }
                        </Row> 

                        <Row
                            style={{
                                alignItems: "flex-end",
                                justifyContent: "flex-end",
                                marginTop: "30px"
                            }}
                        >
                            <ButtonLoader className="custom-width" style={{width: "30%"}} onClick={() => UpdateClientDetails()}>
                                Sauvegarder les informations médicales
                            </ButtonLoader>
                        </Row>
                        

                    </ContainerInfos> 
                </Col>
            </Row>


            <CreatePatientNoteModal
                size="lg"
                show={modalShow}
                onHide={() => {
                    setModalShow(false)
                    reloadNotes()
                }}
                clientId={params.id}
            />
            
            <UpdatePatientNoteModal
                size="lg"
                show={updateModalShow}
                onHide={() => {
                    setUpdateModalShow(false)
                    setCurrentNote(null)
                    reloadNotes()
                }}
                clientId={params.id}
                note={currentNote}
            />

        </Container>
    );
}

export default PatientInformations;
