import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components'
import Swal from 'sweetalert2'
import { Container, Col, Image, Card } from 'react-bootstrap'
import 'sweetalert2/dist/sweetalert2.min.css'



import MyLaserApi from '../../api/MyLaserApi'

import Input from '../../components/Input'
import Button from '../../components/Button'

import { useForm } from "react-hook-form";

import {
    Redirect, Link, useHistory
} from "react-router-dom";


const ContainerLogin = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: 100vh;
background-color: transparent;
width:100%;
`
const Text = styled.span`
margin-top:10px;
margin-bottom:10px;
font-family: CenturyGothicRegular;
color:#102935;
font-size: 12px;
&.bold {
font-family: CenturyGothicBold;
font-size: 16px;
}
&.big {
font-family: CenturyGothicBold;
font-size: 20px;
margin-top: 10px;
margin-bottom: 10px;
}
&.info {
font-size: 13px;
}
&.label {
color: #87949A;
font-size: 13px;
margin-right: 5px;
}
`

export default function Login() {

    let emailregx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    let { register, handleSubmit, watch, errors, formState } = useForm({
        mode: 'all'
    });

    let history = useHistory()

    return (

        <Container fluid className="App"  >

            <ContainerLogin >

                <Card style={{ width: '50%' }}>
                    <Card.Img variant="top" src={"/logo-login.svg"}
                    />
                    <Card.Body>
                        <Card.Title>  <Text className="big">Se connecter</Text></Card.Title>
                        <form onSubmit={handleSubmit((data) => {

                            MyLaserApi.auth({ email: data.email, password: data.password }).then((res) => {

                                if (res.status === 200) {

                                    toast.success("🚀 Connexion réussie.")

                                    MyLaserApi.getMe().then((res) => {
                                        localStorage.setItem('role', res.role)

                                        MyLaserApi.getAgencies()

                                        history.push('/')

                                    })

                                } else {
                                    toast.error("Vos identifiants sont invalides.")
                                }


                            })

                        })}>
                            <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <Text>Identifiant</Text>

                                <input {...register("email", { required: true, pattern: emailregx })} type="email" name="email" placeholder="Votre email"
                                    className="input"
                                />

                                <Text>Mot de passe</Text>

                                <input type="password"  {...register("password", { required: true })} name="password" placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                                    className="input"
                                />

                                <Text><a href="#">J'ai oublié mon mot de passe</a></Text>

                                <button type="submit" className="button" value="Se connecter" style={{ marginBottom: 20, backgroundColor: !formState.isValid && '#9396AD' }} disabled={!formState.isValid}  >
                                    Se connecter  </button>

                            </Col>

                        </form>

                    </Card.Body>

                </Card>

            </ContainerLogin>
        </Container>

    );

}
