import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css';
import styled from 'styled-components'
import { Modal, Image } from 'react-bootstrap'

//components
import Button from './Button'
import ButtonLoader from "./ButtonLoader";


const Header = styled.div`
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 15px;
      width: 100%;
      background-color: #102935;
      color:#fff;
      font-family: CenturyGothicBold;
      font-size: 15px;
      justify-content: space-between;
    `

    const Text = styled.span`
      font-family: CenturyGothicRegular;
      color:#102935;
      font-size: 13px;
      margin-bottom: 10px;
      &.bold {
        font-family: CenturyGothicBold;
        }
      &.gray {
        color: #9FA9AE;
      }
      &.underline {
        text-decoration-line: underline;
      }
    `
    const Body = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        align-items: flex-start;
        width: 100%;
       padding: 10px;

    `
    const Row = styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 10px;
        &.space-between {
            justify-content: space-between;
            width: 100%;
        }
    `

function ModalC(props) {

    

    return (
        <Modal
            {...props}
            size={props.size ? props.size : "md"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Header>
                <p>{''}</p>
                {props.title}

                <div className="no-background" onClick={props.onHide}>
                    <Image src={"/close.svg"} style={{height: 25, width: 25}} />
                </div>

            </Header>
            <Body>
                {props.children}
            </Body>
            { props.buttonText && <Modal.Footer>
                <Row className="space-between">
                    <Button className="gray custom-width" style={{ marginRight: 10 }}
                        onClick={props.onHide}
                    >Annuler</Button>
                    <ButtonLoader className="" onClick={props.onButtonClick} >{props.buttonText}</ButtonLoader>
                </Row>
            </Modal.Footer>}
        </Modal>
    );
}

export default ModalC;